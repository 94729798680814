import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import { Card } from '../../../components'
import { SectionCreateGroup, SectionFeed, SectionGroup, SectionOpportunity, SectionStore } from '../sections'
import { WidgetGroups, WidgetNavigator } from '../widgets'
import { Container, ContainerSection, ContainerWidget } from './main-desktop.styled'

function MainDesktop() {
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <Container>
            <ContainerWidget>
                <Card withoutPadding>
                    <WidgetNavigator />
                </Card>
                <Card
                    withoutPadding
                    className="card-group"
                    title={t('my-schedule')}
                    leftIcon="Users"
                    rightIcon="Plus"
                    onRightIconClick={() => history.push('/criar-grupo')}
                >
                    <WidgetGroups />
                </Card>
            </ContainerWidget>

            <ContainerSection>
                <Switch>
                    <Route exact path="/" component={SectionFeed} />
                    <Route path="/grupo" component={SectionGroup} />
                    <Route path="/criar-grupo" component={SectionCreateGroup} />
                    <Route path="/pendentes" component={SectionStore} />
                    <Route path="/oportunidades" component={SectionOpportunity} />
                    <Redirect to="/" />
                </Switch>
            </ContainerSection>
        </Container>
    )
}

export default memo(MainDesktop)
