import { useFormik } from 'formik'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { AccountsAPI, AppAPI } from '#/api'
import Ilustration from '#/assets/images/ilustration-profession.svg'
import { Input, Select } from '#/components'
import { AlertError } from '#/modals'
import { updateAuth } from '#/redux/reducers/auth'
import { setUser } from '#/redux/reducers/user'

import { PageRegister } from '../shared'

function RegisterProfession() {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation()
    const alertErrorRef = useRef()
    const [professions, setProfessions] = useState([])
    const [specializations, setSpecializations] = useState([])
    const [states, setStates] = useState([])
    const [loading, setLoading] = useState(true)

    const {
        isSubmitting,
        values,
        errors,
        touched,
        isValid,
        dirty,
        handleSubmit,
        setFieldValue,
        handleBlur,
    } = useFormik({
        initialValues: { profession: null, council_state: null, council_id: '', specialization: null },
        validationSchema: Yup.object().shape({
            profession: Yup.object().nullable().required(t('select.profession.required')),
            council_state: Yup.object().when(['profession'], {
                is: val => (val && val.has_council_state) || false,
                then: Yup.object().nullable().required(t('select.council_state.required')),
                otherwise: Yup.object().nullable().notRequired(),
            }),
            specialization: Yup.object().when(['profession'], {
                is: val => (val && val.has_specializations) || false,
                then: Yup.object().nullable().required(t('select.specialty.required')),
                otherwise: Yup.object().nullable().notRequired(),
            }),
            council_id: Yup.number().when(['profession'], {
                is: val => (val && val.has_council) || false,
                then: Yup.number()
                    .typeError(t('onlynumber'))
                    .min(3, t('input.council.min'))
                    .required(t('input.council.required')),
                otherwise: Yup.number().notRequired(),
            }),
        }),
        onSubmit: async values => {
            try {
                const result = await AccountsAPI.update(values)
                const { pending_stages } = result
                dispatch(updateAuth({ pending_stages }))
                dispatch(setUser(result))
                history.push('/cadastro-concluido')
            } catch ({ message }) {
                _showAlertErro(message)
            }
        },
    })

    const _setUserData = useCallback(() => {
        if (user) {
            const { profession, council, specialization } = user
            if (profession) {
                setFieldValue('profession', profession)
            }
            if (profession?.has_council && council) {
                setFieldValue('council_id', council.id)
                setFieldValue('council_state', { uf: council.state })
            }
            if (profession?.has_specializations && specialization) {
                setFieldValue('specialization', specialization)
            }
        }
    }, [user, setFieldValue])

    const _init = useCallback(async () => {
        try {
            const responseProf = await AccountsAPI.professionalList()

            const professions = responseProf.items.map(item => {
                return { label: item.name, value: item, key: item.id }
            })

            setProfessions(professions)
            _setUserData()
        } catch (err) {
        } finally {
            setLoading(false)
        }
    }, [_setUserData])

    useEffect(() => {
        _init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _loadUf = useCallback(async () => {
        try {
            const result = await AppAPI.UFlist()

            const states = result.items.map(item => {
                return { label: item.code, value: { uf: item.code, name: item.name }, key: item.code }
            })
            setStates(states)
        } catch (err) {}
    }, [])

    const _specializations = useCallback(async () => {
        try {
            const responseSpec = await AccountsAPI.specializationList(values.profession?.id)

            const specializations = responseSpec.items.map(item => {
                return { label: item.name, value: item, key: item.id }
            })
            setSpecializations(specializations)
        } catch (err) {}
    }, [values.profession])

    useEffect(() => {
        if (values.profession) {
            if (values.profession.has_council_state) {
                _loadUf()
            } else {
                setStates([])
            }
            if (values.profession.has_specializations) {
                _specializations()
            } else {
                setSpecializations([])
            }
        }
    }, [values.profession, _loadUf, _specializations])

    useEffect(() => {
        return () => {
            if (values.profession != null) {
                setFieldValue('council_state', null)
                setFieldValue('council_id', '')
                setFieldValue('specialization', null)
            }
        }
    }, [values.profession, setFieldValue])

    const _showAlertErro = useCallback(
        message => {
            try {
                alertErrorRef.current && alertErrorRef.current.show({ message })
            } catch (error) {}
        },
        [alertErrorRef],
    )

    return (
        <>
            <PageRegister
                loading={isSubmitting || loading}
                message={t('info-profession.message')}
                title={t('info-profession.title')}
                disabled={!isValid || !dirty || isSubmitting}
                ilustration={Ilustration}
                onNextClick={handleSubmit}
                stepsComplete={4}
            >
                <Select
                    name="profession"
                    label={t('profession')}
                    placeholder={t('select.profession.placeholder')}
                    options={professions}
                    onChange={value => setFieldValue('profession', value)}
                    selectedKey={values.profession?.id}
                    errorMessage={touched.profession && errors.profession}
                />
                {values.profession?.has_council_state && (
                    <Select
                        name="uf"
                        label={t('council_state')}
                        placeholder={t('select.council_state.placeholder')}
                        options={states}
                        onChange={value => setFieldValue('council_state', value)}
                        selectedKey={values.council_state?.uf}
                        errorMessage={touched.council_state && errors.council_state}
                    />
                )}
                {values.profession?.has_council && (
                    <Input
                        name="council"
                        label={t('council')}
                        value={values.council_id}
                        onChangeText={e => setFieldValue('council_id', e)}
                        onBlur={handleBlur('council_id')}
                        errorMessage={touched.council_id && errors.council_id}
                    />
                )}
                {values.profession?.has_specializations && (
                    <Select
                        name="specialty"
                        label={t('specialty')}
                        placeholder={t('select.specialty.placeholder')}
                        options={specializations}
                        onChange={value => setFieldValue('specialization', value)}
                        selectedKey={values.specialization?.id}
                        errorMessage={touched.specialization && errors.specialization}
                    />
                )}
            </PageRegister>
            <AlertError ref={alertErrorRef} />
        </>
    )
}

export default RegisterProfession
