import styled from 'styled-components'

const Container = styled.div`
    .see-more {
        width: 98%;
        margin: 10px auto;
    }
`

const ContainerInfo = styled.div`
    padding: 10px;
`

export { Container, ContainerInfo }
