import React, { memo, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router-dom'

import { Menu, SectionControl } from '#/components'
import { Confirmation } from '#/modals'
import { logout } from '#/redux/thunk/logout'

import Accounts from './accounts/accounts'
import Password from './password/password'
import Privacity from './privacity/privacity'
import Profile from './profile/profile'
import Terms from './terms/terms'
import Theme from './theme/theme'

function SectionCreateGroup() {
    const { t } = useTranslation()
    const confirmationRef = useRef()
    const dispatch = useDispatch()

    const location = useLocation()

    const activeIndex = useMemo(() => {
        if (location) {
            const { pathname } = location

            if (pathname.includes('termos')) {
                return 5
            }
            if (pathname.includes('tema')) {
                return 4
            }
            if (pathname.includes('privacidade')) {
                return 3
            }
            if (pathname.includes('contas')) {
                return 2
            }
            if (pathname.includes('alterar-senha')) {
                return 1
            }
            if (pathname.includes('/configuracoes')) {
                return 0
            }

            return 0
        }
    }, [location])

    const _showConfirmationLeave = useCallback(() => {
        if (confirmationRef.current) {
            confirmationRef.current.show({
                title: 'Sair',
                message: 'Tem certeza que deseja sair?',
                icon: 'LogOut',
                onYesClick: () => {
                    dispatch(logout())
                },
            })
        }
    }, [confirmationRef, dispatch])

    const menuItems = useMemo(() => {
        return [
            {
                title: t('profile'),
                subtitle: t('settings.subtitle.profile'),
                icon: 'User',
                router: '/configuracoes',
            },
            {
                title: t('password'),
                subtitle: t('settings.subtitle.password'),
                icon: 'Key',
                router: '/configuracoes/alterar-senha',
            },
            {
                title: t('accounts'),
                subtitle: t('settings.subtitle.account'),
                icon: 'AtSign',
                router: '/configuracoes/contas',
            },
            {
                title: t('safety'),
                subtitle: t('settings.subtitle.safety'),
                icon: 'Lock',
                router: '/configuracoes/privacidade',
            },
            {
                title: t('theme'),
                subtitle: t('settings.subtitle.theme'),
                icon: 'Feather',
                router: '/configuracoes/tema',
            },
            { separator: true },
            {
                title: t('about'),
                subtitle: t('settings.subtitle.subtitle-time'),
                icon: 'HelpCircle',
                router: '/configuracoes/termos',
            },
            { title: t('log-out'), icon: 'LogOut', onClick: _showConfirmationLeave },
        ]
    }, [t, _showConfirmationLeave])

    return (
        <>
            <SectionControl
                contentControl={
                    <>
                        <Menu activeIndex={activeIndex} items={menuItems} />
                    </>
                }
            >
                <Switch>
                    <Route exact path="/configuracoes" component={Profile} />
                    <Route path="/configuracoes/alterar-senha" component={Password} />
                    <Route path="/configuracoes/privacidade" component={Privacity} />
                    <Route path="/configuracoes/contas" component={Accounts} />
                    <Route path="/configuracoes/termos" component={Terms} />
                    <Route path="/configuracoes/tema" component={Theme} />
                </Switch>
            </SectionControl>
            <Confirmation ref={confirmationRef} />
        </>
    )
}

export default memo(SectionCreateGroup)
