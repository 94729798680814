import { useFormik } from 'formik'
import React, { memo, useEffect, useRef } from 'react'

import { ErrorMessage, Label } from '../shared'
import { Container, Input, InputContainer } from './input-code.styled'

function InputCode({ label, errorMessage, onComplete, onChange }) {
    const refChar1 = useRef(null)
    const refChar2 = useRef(null)
    const refChar3 = useRef(null)
    const refChar4 = useRef(null)
    const refChar5 = useRef(null)
    const refChar6 = useRef(null)

    const { values, setFieldValue, handleSubmit } = useFormik({
        initialValues: {
            char_1: '',
            char_2: '',
            char_3: '',
            char_4: '',
            char_5: '',
            char_6: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            const code = Object.keys(values).reduce((accumulator, key) => (accumulator += values[key]), '')

            try {
                if (onComplete) {
                    await onComplete(code)
                }
            } catch (err) {
            } finally {
            }
        },
    })

    useEffect(() => {
        let countField = 0
        for (let i = 1; i <= 6; i++) {
            if (values[`char_${i}`]) {
                countField++
            }
        }

        if (countField === 6) {
            handleSubmit()
        }

        if (onChange) {
            const code = Object.keys(values).reduce((accumulator, key) => (accumulator += values[key]), '')
            onChange(code)
        }
    }, [values, handleSubmit, onChange])

    const _onKeyDown = (index, e) => {
        if (e.keyCode === 8) {
            switch (index - 1) {
                case 1:
                    !values.char_2 && refChar1.current.focus()
                    break
                case 2:
                    !values.char_3 && refChar2.current.focus()
                    break

                case 3:
                    !values.char_4 && refChar3.current.focus()
                    break

                case 4:
                    !values.char_5 && refChar4.current.focus()
                    break

                case 5:
                    !values.char_6 && refChar5.current.focus()
                    break
                default:
                    break
            }
        }
    }

    const _inputChange = (index, text) => {
        setFieldValue(`char_${index}`, text)

        if (text) {
            switch (index + 1) {
                case 2:
                    refChar2.current.focus()
                    break

                case 3:
                    refChar3.current.focus()
                    break

                case 4:
                    refChar4.current.focus()
                    break

                case 5:
                    refChar5.current.focus()
                    break
                case 6:
                    refChar6.current.focus()
                    break
                default:
                    break
            }
        }
    }

    return (
        <Container>
            {!!label && <Label>{label}</Label>}
            <InputContainer>
                <Input
                    ref={refChar1}
                    value={values.char_1}
                    onChangeText={text => _inputChange(1, text)}
                    onKeyDown={event => _onKeyDown(1, event)}
                    onFocus={() => setFieldValue('char_1', '')}
                />
                <Input
                    ref={refChar2}
                    value={values.char_2}
                    onChangeText={text => _inputChange(2, text)}
                    onKeyDown={event => _onKeyDown(2, event)}
                    onFocus={() => setFieldValue('char_2', '')}
                />
                <Input
                    ref={refChar3}
                    value={values.char_3}
                    onChangeText={text => _inputChange(3, text)}
                    onKeyDown={event => _onKeyDown(3, event)}
                    onFocus={() => setFieldValue('char_3', '')}
                />
                <Input
                    ref={refChar4}
                    value={values.char_4}
                    onChangeText={text => _inputChange(4, text)}
                    onKeyDown={event => _onKeyDown(4, event)}
                    onFocus={() => setFieldValue('char_4', '')}
                />
                <Input
                    ref={refChar5}
                    value={values.char_5}
                    onChangeText={text => _inputChange(5, text)}
                    onKeyDown={event => _onKeyDown(5, event)}
                    onFocus={() => setFieldValue('char_5', '')}
                />
                <Input
                    ref={refChar6}
                    value={values.char_6}
                    onChangeText={text => _inputChange(6, text)}
                    onKeyDown={event => _onKeyDown(6, event)}
                    onFocus={() => setFieldValue('char_6', '')}
                />
            </InputContainer>
            {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Container>
    )
}

export default memo(InputCode)
