import React, { memo, useState } from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'

import { UserAPI } from '../../api'
import heartClick from '../../assets/images/heart-solid.svg'
import heart from '../../assets/images/heart.svg'
import { Content, Img } from './like.styled'

function Like({ likes = 0, is_liked, id }) {
    const [isLike, setIsLike] = useState(is_liked)

    const _handleLike = useCallback(async () => {
        try {
            await UserAPI.getLikeNews(id)
            setIsLike(!isLike)
        } catch (err) {}
    }, [isLike, id])

    return (
        <Content>
            <Img src={isLike ? heartClick : heart} onClick={_handleLike} />
            &nbsp;{likes} {likes > 1 ? 'Curtidas' : 'Curtida'}
        </Content>
    )
}

export default memo(Like)
