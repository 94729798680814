import styled from 'styled-components'

import { Feather } from '../../icons/index'
const Container = styled.div`
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const ContainerSelect = styled.div`
    width: 100%;
    position: relative;
`

const CustomSelect = styled.select`
    background-color: ${({ theme }) => theme.colors.input};
    border: 0;
    border-radius: ${({ theme }) => theme.sizes.radius}px;
    font-size: 14px;
    height: ${({ theme }) => theme.sizes.formHeight}px;
    outline-width: 0;
    padding-left: 8px;
    padding-right: 40px;
    width: 100%;
    color: ${({ theme: { colors }, isNull }) => (isNull ? colors.placeholder : colors.text)};
    appearance: none;
`

const Placeholder = styled.option.attrs({
    value: null,
    hidden: true,
})``

const Chevron = styled(Feather).attrs({ name: 'ChevronDown' })`
    color: ${({ theme }) => theme.colors.text};
    position: absolute;
    pointer-events: none;
    right: 8px;
    top: calc(50% - 12px);
`

export { Container, CustomSelect, Chevron, ContainerSelect, Placeholder }
