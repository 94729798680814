import React, { memo, useCallback, useState } from 'react'

import { Container, ContainerTab, Tab } from './tabs-value.styled'

function TabsValue({ onChange, leftText, rightText }) {
    const [tabActive, setTabActive] = useState(1)

    const _setTabActive = useCallback(
        tab => {
            setTabActive(tab)
            if (onChange) {
                onChange(tab)
            }
        },
        [onChange],
    )

    return (
        <Container>
            <ContainerTab>
                <Tab active={tabActive === 1} onClick={() => _setTabActive(1)}>
                    {leftText}
                </Tab>
                <Tab active={tabActive === 2} onClick={() => _setTabActive(2)}>
                    {rightText}
                </Tab>
            </ContainerTab>
        </Container>
    )
}

export default memo(TabsValue)
