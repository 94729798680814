import styled, { css } from 'styled-components'

const Container = styled.div`
    flex: 1;
    display: flex;
    padding: 16px;
    max-width: 1600px;
    width: 100%;
    align-self: center;
`

const ContainerWidget = styled.div`
    height: calc(100vh - 92px);
    overflow: hidden;
    width: 360px;
    display: flex;
    flex-direction: column;
    .card {
        margin-bottom: 16px;

        :last-child {
            margin-bottom: 0px;
        }
    }
    .card-group {
        flex: 1;
        overflow-y: auto;
    }

    ${({ displayOpportunity }) =>
        displayOpportunity
            ? css`
                  .card-opportunity {
                      display: block;
                  }
                  .card-group {
                      display: none;
                  }
              `
            : css`
                  .card-opportunity {
                      display: none;
                  }
                  .card-group {
                      display: block;
                  }
              `}
`

const ContainerSection = styled.div`
    height: calc(100vh - 92px);
    overflow: hidden;
    flex: 1;
    padding-left: 16px;
    display: flex;
`

export { Container, ContainerSection, ContainerWidget }
