import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import createRootReducer from './reducers'

export const history = createBrowserHistory()

const persistConfig = {
    key: 'plantaoativo',
    storage,
    whitelist: ['auth', 'theme', 'ads'],
}

const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

export const Store = createStore(persistedReducer, compose(applyMiddleware(thunk, routerMiddleware(history))))
export const persistor = persistStore(Store)
