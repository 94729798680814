import classNames from 'classnames'
import React, { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../button/button'
import { InfoEmpty } from '../info-empty/info-empty'
import { Container, ContainerInfo } from './list.styled'
function List({ className, data = [], onRenderItem, loadMore, isLoading, onLoadMoreClick, infoEmptyProps }) {
    const { t } = useTranslation()

    const _renderItem = useCallback(
        (item, index) => {
            if (onRenderItem) {
                return onRenderItem(item, index)
            }
            return null
        },
        [onRenderItem],
    )

    return (
        <Container className={classNames('list', className)}>
            {data.map((item, index) => _renderItem(item, index))}
            {loadMore && (
                <Button
                    className="see-more"
                    onClick={onLoadMoreClick}
                    title={t('see-more')}
                    uppercase
                    variant="outline"
                />
            )}
            {!isLoading && data.length === 0 && (
                <ContainerInfo>
                    <InfoEmpty {...infoEmptyProps} />
                </ContainerInfo>
            )}
        </Container>
    )
}

export default memo(List)
