import React, { memo } from 'react'

import { Container, Icon, Message } from './warning.styled'
function Warning({ children }) {
    return (
        <Container className="warning">
            <Icon name="AlertCircle" />
            <Message>{children}</Message>
        </Container>
    )
}

export default memo(Warning)
