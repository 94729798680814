import { useFormik } from 'formik'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { AccountsAPI, GroupAPI } from '#/api'
import { Button, Input, Select } from '#/components'
import { AlertError, Loading } from '#/modals'
import { updateGroup } from '#/redux/reducers/group'
import { GroupChangeBehavior } from '#/subjects'

import { BackIcon, Container, ContainerTitle, Footer, Title } from '../shared'
function EditInfoGroup() {
    const alertError = useRef()
    const { t } = useTranslation()
    const history = useHistory()

    const dispatch = useDispatch()
    const group = useSelector(state => state.group)

    const [loading, setLoading] = useState(false)
    const [professions, setProfessions] = useState([])
    const {
        isSubmitting,
        handleBlur,
        values,
        errors,
        touched,
        handleSubmit,
        dirty,
        isValid,
        setValues,
        setFieldValue,
    } = useFormik({
        initialValues: {
            name: null,
            category: null,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .nullable(true)
                .min(3, t('input.group_name.min', { min: 3 }))
                .trim()
                .required(t('input.group_name.required')),
            category: Yup.string().nullable(true).required(t('select.profession.required')),
        }),
        onSubmit: async values => {
            try {
                const body = {
                    ...values,
                    institution: values.institution?.value,
                }
                const updatedGroup = await GroupAPI.update(group.id, body)
                dispatch(updateGroup(updatedGroup))
                GroupChangeBehavior.next(updatedGroup)
            } catch ({ message }) {
                if (alertError.current) {
                    alertError.current.show({ message })
                }
            }
        },
    })

    const _goBack = useCallback(() => {
        if (group) {
            history.replace(`/grupo/${group.id}/configuracoes`)
        }
    }, [history, group])

    const _init = useCallback(async () => {
        try {
            setLoading(true)
            const responseSpec = await AccountsAPI.professionalList()

            const professions = responseSpec.items.map(item => {
                return { label: item.name, value: item, key: item.id }
            })
            setProfessions(professions)
        } catch (error) {
            setProfessions([])
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if (group) {
            _init()
            setValues({
                name: group.name,
                category: group.category,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group])

    return (
        <Container>
            <ContainerTitle>
                <BackIcon onClick={_goBack} />
                <Title>{t('group-settings.settings.edit')}</Title>
            </ContainerTitle>

            <Input
                required
                label={t('input.group_name.label')}
                name="group_name"
                value={values.name}
                onChangeText={e => setFieldValue('name', (e || '').toUpperCase())}
                onBlur={handleBlur('name')}
                errorMessage={touched.name && errors.name}
            />
            <Select
                required
                name="profession"
                label={t('select.profession-group.label')}
                options={professions}
                placeholder={t('select.profession.placeholder')}
                onChange={value => setFieldValue('category', value)}
                selectedKey={values.category?.id}
                errorMessage={touched.category && errors.category}
            />
            <Footer>
                <Button
                    title={t('save')}
                    className="button-next"
                    onClick={handleSubmit}
                    dimension="small"
                    disabled={!isValid || !dirty || isSubmitting}
                />
            </Footer>
            <Loading show={loading || isSubmitting} />
            <AlertError ref={alertError} />
        </Container>
    )
}

export default memo(EditInfoGroup)
