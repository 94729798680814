import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalBase } from '../shared'
import { Message } from './modal-terms.styled'
function ModalTerms({ onClose }, ref) {
    const [show, setShow] = useState(false)
    const [message, setMessage] = useState(null)
    const { t } = useTranslation()
    useImperativeHandle(ref, () => ({
        show: (props = {}) => {
            const { message } = props

            setShow(true)
            setMessage(message)
        },
    }))

    const _onClose = useCallback(
        (refresh = false) => {
            setShow(false)
            setTimeout(() => {
                setMessage(null)
            }, 300)
            if (onClose) {
                onClose(refresh)
            }
        },
        [onClose],
    )

    return (
        <ModalBase
            title={t('terms')}
            icon={null}
            buttonText={t('close')}
            visible={show}
            onClose={_onClose}
            style={{ width: '80%' }}
        >
            <Message dangerouslySetInnerHTML={{ __html: message }} />
        </ModalBase>
    )
}
export default memo(forwardRef(ModalTerms))
