import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    padding: 10px 16px;
    background-color: ${({ theme }) => theme.colors.backgroundAccent};
    align-items: center;
`
const ContainerTitles = styled.div`
    flex: 1;
    justify-content: center;
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    font-weight: bold;
    text-align: ${({ center }) => (center ? 'center' : 'start')};
`

const SubTitle = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 12px;
    text-align: ${({ center }) => (center ? 'center' : 'start')};
`

const RightText = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 12px;
`

export { Container, Title, RightText, ContainerTitles, SubTitle }
