import { eachDayOfInterval, endOfMonth, isWeekend, parseISO, startOfMonth } from 'date-fns'
import React, { memo, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { AlertError, ManagerVacancy } from '#/modals'

import {
    Box,
    BoxHeader,
    ConainerHeader,
    ContainerLabel,
    ContainerShift,
    ContainerUser,
    Divider,
    Label,
    NameUser,
    Table,
    Totals,
} from './print-grid-schedule.styled'

function PrintGridSchedule({ period, shedules, header, displayName, extras, vacancies, isDynamic }) {
    const alertErrorRef = useRef()
    const managerScheduleRef = useRef()
    const { t } = useTranslation()

    const days = useMemo(() => {
        if (period) {
            return eachDayOfInterval({
                start: startOfMonth(parseISO(period)),
                end: endOfMonth(parseISO(period)),
            })
        }
        return []
    }, [period])

    return (
        <>
            <Table>
                <tr>
                    <th
                        style={{
                            position: 'inherit',
                        }}
                        colSpan={32}
                    >
                        {header}
                    </th>
                </tr>
                <tr>
                    <BoxHeader className="title" rowSpan={2}>
                        {t('name')}
                    </BoxHeader>
                    {days.map((day, i) => (
                        <BoxHeader key={`week_${i}`} isWeekend={isWeekend(day)} className="title">
                            <ConainerHeader>
                                <span>{getSymbolOfWeek(day.getDay())}</span>
                                <Divider />
                                <span>{transformDay(day.getDate())}</span>
                            </ConainerHeader>
                        </BoxHeader>
                    ))}
                </tr>
                <tbody>
                    {vacancies && (
                        <tr className={'tr-last'}>
                            <td className="td-user">
                                <ContainerUser>
                                    <NameUser>{t('vacancy')}</NameUser>
                                </ContainerUser>
                            </td>
                            <RenderVacancytWorkingTime items={vacancies.items} isDynamic={isDynamic} />
                        </tr>
                    )}
                    {shedules.map(({ user, items }, i) => (
                        <React.Fragment key={`item_${i}`}>
                            <tr className={'tr-last'}>
                                <td className="td-user">
                                    <ContainerUser>
                                        <NameUser>{user[displayName]}</NameUser>
                                        {extras.includes('council') && (
                                            <div>{(user.council || '').replace(' ', '')}</div>
                                        )}
                                        {extras.includes('phone') && <div>{user.phone}</div>}
                                    </ContainerUser>
                                </td>
                                <RenderShiftWorkingTime days={items} />
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </Table>

            <ManagerVacancy ref={managerScheduleRef} />
            <AlertError ref={alertErrorRef} />
        </>
    )
}

const RenderShiftWorkingTime = memo(({ days }) => {
    return (
        <>
            {days.map(({ date, items }, i) => (
                <Box key={`box_${i}`} isWeekend={isWeekend(parseISO(date))}>
                    <ContainerShift>
                        {items.map(({ has_shift, label }, i) => (
                            <React.Fragment key={`shift_${i}`}>
                                {i !== 0 && <Divider />}
                                <ContainerLabel>{has_shift && <Label>{label}</Label>}</ContainerLabel>
                            </React.Fragment>
                        ))}
                    </ContainerShift>
                </Box>
            ))}
        </>
    )
})
const RenderVacancytWorkingTime = memo(({ items, isDynamic }) => {
    const days = useMemo(() => {
        let days = []

        const itemsFiltered = items.filter(({ vacancies, workingtime }) => {
            const result = vacancies.filter(e => {
                if (days.length !== vacancies.length) {
                    days.push({
                        date: e.date,
                        items: [],
                    })
                }
                return isDynamic ? e.available : e.total > 0
            })
            return result.length > 0
        })

        itemsFiltered.forEach(({ vacancies, workingtime }) => {
            vacancies.forEach(item => {
                days = days.map(day => {
                    if (day.date === item.date) {
                        let items = day.items
                        return {
                            ...day,
                            items: [
                                ...items,
                                {
                                    label: workingtime.label,
                                    total: isDynamic ? item.available : item.total,
                                },
                            ],
                        }
                    }
                    return day
                })
            })
        })

        return days
    }, [items, isDynamic])

    return (
        <>
            {days.map(({ date, items }, i) => (
                <Box key={`box_${i}`} isWeekend={isWeekend(parseISO(date))}>
                    <ContainerShift>
                        {items.map(({ total, label }, i) => (
                            <React.Fragment key={`shift_${i}`}>
                                {i !== 0 && <Divider />}
                                <ContainerLabel height={25}>
                                    {total ? <Label>{label}</Label> : null}
                                    {total ? <Totals>{total}</Totals> : null}
                                </ContainerLabel>
                            </React.Fragment>
                        ))}
                    </ContainerShift>
                </Box>
            ))}
        </>
    )
})

function getSymbolOfWeek(day) {
    const weeks = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']

    if (day >= 0 && day < 7) {
        return weeks[day]
    }
    return ''
}

function transformDay(day) {
    if (day < 10) {
        return `0${day}`
    } else {
        return day.toString()
    }
}

export default memo(PrintGridSchedule)
