import styled, { css } from 'styled-components'

import { Feather } from '../icons'

const Container = styled.div`
    display: flex;
    user-select: none;
`

const ContainerImage = styled.div`
    display: flex;
    position: relative;
    align-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.backgroundAvatar};
    ${({ border, color }) => {
        if (border) {
            return css`
                border-color: ${({ theme }) => (color ? color : theme.colors.primary)};
                border-radius: 50px;
                border-width: 3px;
                border-style: solid;
            `
        } else {
            return ''
        }
    }};
    cursor: ${({ onClick, pointer }) => (onClick || pointer ? 'pointer' : 'auto')};
`

const Image = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
`

const Label = styled.div`
    font-weight: bold;
    color: #000;
    text-align: center;
`

const Badge = styled.div`
    background-color: ${({ theme, color }) => color || theme.colors.primary};
    height: 16px;
    width: 16px;
    border-radius: 10px;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 3px;
    justify-content: center;
    align-items: center;
`

const IconBadge = styled.div`
    font-size: 10px;
    color: #fff;
    margin-left: 0.5px;
`

const ContainerIcon = styled.div`
    background-color: ${({ theme }) => theme.colors.background};
    width: 30px;
    height: 30px;
    border-radius: 15px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    box-shadow: 0px 2px 12px -6px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
`

const Icon = styled(Feather).attrs({
    name: 'Image',
})`
    color: ${({ theme }) => theme.colors.primary};
`

const ContainerInfo = styled.div`
    flex: 1;
    padding-left: 8px;
`

const ContainerUserInfo = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`

const Name = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
`

const Circle = styled.div`
    background-color: ${({ theme }) => theme.colors.text};
    width: 6px;
    height: 6px;
    border-radius: 3px;
    overflow: hidden;
    margin: 0px 8px;
`

const Description = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
`
const Extra = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 12px;
`

export {
    ContainerImage,
    Image,
    Label,
    Badge,
    IconBadge,
    ContainerIcon,
    Icon,
    Container,
    Name,
    ContainerInfo,
    Description,
    Circle,
    ContainerUserInfo,
    Extra,
}
