import styled from 'styled-components'

const Container = styled.div`
    margin: 20px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const ContainerTab = styled.div`
    background-color: ${({ theme }) => theme.colors.input};
    border: 0;
    border-radius: ${({ theme }) => theme.sizes.radius}px;
    height: 40px;
    width: 100%;
    display: flex;
`

const Tab = styled.div`
    display: flex;
    flex: 1;
    font-weight: bold;
    color: ${({ theme, active }) => (active ? '#fff' : theme.colors.text)};
    justify-content: center;
    align-items: center;
    background-color: ${({ theme: { colors }, active }) => (active ? colors.primary : 'transparent')};
    border: 0;
    border-radius: ${({ theme }) => theme.sizes.radius}px;
    font-size: 14px;
    outline-width: 0;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
`

export { Container, ContainerTab, Tab }
