import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Button, PageContainer, Stepper } from '../../../../components'
import {
    AlignCenter,
    BackIcon,
    ContainerTitle,
    Content,
    Form,
    Ilustration,
    Message,
    SplitContent,
    Title,
} from './page-register.styled'
function InfoConfirmation({
    children,
    buttonTitle,
    message,
    title,
    disabled,
    stepsComplete = 0,
    ilustration,
    loading,
    onNextClick,
}) {
    const { t } = useTranslation()
    const history = useHistory()
    return (
        <>
            <PageContainer loading={loading}>
                <Content>
                    <SplitContent>
                        <AlignCenter>
                            <Stepper stepsComplete={stepsComplete + 1} steps={5} />
                        </AlignCenter>
                    </SplitContent>
                    <Form
                        onSubmit={e => {
                            e.preventDefault()
                        }}
                    >
                        <ContainerTitle>
                            <BackIcon onClick={() => history.goBack()} />
                            <Title>{title}</Title>
                        </ContainerTitle>
                        {children}
                        <Button
                            title={buttonTitle || t('continue')}
                            dimension="small"
                            className="submit"
                            onClick={onNextClick}
                            disabled={disabled}
                        />
                    </Form>
                    <SplitContent autoHiden>
                        <AlignCenter>
                            <Ilustration src={ilustration} />
                            <Message>{message}</Message>
                        </AlignCenter>
                    </SplitContent>
                </Content>
            </PageContainer>
        </>
    )
}

export default InfoConfirmation
