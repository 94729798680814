import React, { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Button, ButtonText, ContentSection, HeaderGroup, Warning } from '#/components'

import { BackIcon, ContainerTitle, ContentCard, Footer, Title } from './content-container.styled'
function ContentContainer({
    loading,
    overflowHidden,
    title,
    warning,
    disabled,
    buttonTitle,
    onNextClick,
    children,
    notNow,
    importData,
    addText,
    onAddClick,
    onSkipClick,
    arrowBack,
}) {
    const history = useHistory()
    const { t } = useTranslation()

    const _goBack = useCallback(() => {
        history.goBack()
    }, [history])

    return (
        <ContentSection loading={loading} overflowHidden={overflowHidden} withoutPadding>
            <HeaderGroup />
            <ContentCard>
                <ContainerTitle>
                    {!!arrowBack && <BackIcon onClick={_goBack} />}
                    {!!title && <Title>{title}</Title>}
                    {!!addText && (
                        <ButtonText onClick={onAddClick} className="button-add" iconName="Plus">
                            {addText}
                        </ButtonText>
                    )}
                    {/* {importData && <ButtonText iconName="Download">{t('import')}</ButtonText>} */}
                </ContainerTitle>
                {!!warning && <Warning>{warning}</Warning>}
                {children}
                <Footer>
                    {notNow ? <ButtonText onClick={onSkipClick}>{t('not-now')}</ButtonText> : <div />}
                    <Button
                        className="button-next"
                        disabled={disabled}
                        title={buttonTitle || t('next')}
                        dimension="small"
                        onClick={onNextClick}
                    />
                </Footer>
            </ContentCard>
        </ContentSection>
    )
}

export default memo(ContentContainer)
