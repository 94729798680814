import styled from 'styled-components'

import LogoSource from '#/assets/images/plantao-ativo.png'

const Container = styled.div`
    align-self: center;
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    padding: 16px 30px;
    .card {
        flex: 1;
        .card-content {
            height: 1px;
            overflow: hidden;
        }
    }

    @media print {
        padding: 0 !important;
        margin: 0 !important;
    }
`

const ContainerButtons = styled.div`
    display: flex;
    .button {
        &:first-child {
            margin-right: 20px;
        }
    }
`

const ContainerTitle = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 10px;

    @media print {
        margin: 0px;
        display: none !important;
    }
`

const Tool = styled.div`
    margin-right: 16px;
`

const ContainerTool = styled.div`
    display: flex;
`

const TitleTool = styled.div`
    color: #888888;
    font-weight: bold;
    font-size: 16px;
`

const ContainerOptions = styled.div`
    .check-box {
        margin-top: 4px;
    }
    .label {
        color: #888888;
    }
`

const PageA4 = styled.div`
    overflow: hidden;
    /* width: 29.7cm; */
    overflow: auto;
    user-select: none;
    background-color: #fff;
    -webkit-print-color-adjust: exact !important;
    &::-webkit-scrollbar {
        height: 10px;
    }
`

const HeaderPage = styled.div`
    display: flex;
    flex-direction: column;
`

const HeaderTop = styled.div`
    display: flex;
    flex: 1;
    padding: 16px 10px 10px 10px;
    justify-content: space-between;
`

const HeaderBottom = styled.div`
    display: flex;
    width: 100%;
    padding: 5px;
    background-color: #f2f2f3;
    align-items: center;
    justify-content: space-between;
`

const ContainerPageLogo = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const Logo = styled.img.attrs({ src: LogoSource })`
    height: 50px;
`

const ContainerPageTitle = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const PageTitle = styled.div`
    color: #5d5958;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
`
const Subtitle = styled.div`
    color: #5d5958;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
`

const Emission = styled.div`
    color: #5d5958;
    font-size: 10px;
`

const ContainerPageManager = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const Line = styled.div`
    background-color: #000;
    height: 1px;
    width: 70%;
    margin-top: 16px;
`

const ManagerName = styled.div`
    color: #5d5958;
    font-weight: bold;
    text-transform: uppercase;
`

const Profession = styled.div`
    color: #5d5958;
    font-size: 10px;
`
const ContainerGrupoInfo = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 40%;
    overflow: hidden;
`

const GroupTitle = styled.div`
    font-size: 14px;

    text-align: start;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const GroupInstitution = styled.div`
    font-size: 14px;
    text-align: start;
    width: 100%;
    color: #888888;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const ContainerWorkingTimeInfo = styled.div`
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    height: 40px;
    align-items: flex-end;
`

const WorkingTime = styled.div`
    font-size: 14px;
    height: 50%;
    width: 160px;
    display: flex;
    align-items: center;
`

export {
    Container,
    ContainerGrupoInfo,
    ContainerOptions,
    ContainerPageLogo,
    ContainerPageManager,
    ContainerPageTitle,
    ContainerTitle,
    ContainerTool,
    ContainerWorkingTimeInfo,
    Emission,
    GroupInstitution,
    GroupTitle,
    HeaderBottom,
    HeaderPage,
    Subtitle,
    HeaderTop,
    Line,
    Logo,
    ManagerName,
    PageA4,
    PageTitle,
    Profession,
    TitleTool,
    ContainerButtons,
    Tool,
    WorkingTime,
}
