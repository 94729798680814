import styled, { css } from 'styled-components'

import { Feather } from '../../icons'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    min-height: 65px;
    :first-child {
        margin-top: 0px;
    }

    ${({ clickAllArea }) =>
        clickAllArea &&
        css`
            cursor: pointer;
            &:hover {
                background-color: ${({ theme }) => theme.colors.hover};
            }
        `}
`

const Close = styled(Feather).attrs(({ theme }) => ({ name: 'X', color: theme.colors.text }))`
    cursor: pointer;
`

const Menu = styled(Feather).attrs(({ theme }) => ({ name: 'MoreVertical', color: theme.colors.text }))`
    cursor: pointer;
`

const ContainerInfo = styled.div`
    flex: 1;
    padding-left: 8px;
    text-align: start;
`

const Name = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
`

const Description = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
`

export { Close, Container, ContainerInfo, Description, Menu, Name }
