import axios from 'axios'
import { format, parseISO } from 'date-fns'
import ptLocale from 'date-fns/locale/pt'
import replaceEmoji from 'replace-emoji'

import toCurrency from './format-currency'

const shareOpportunity = async opportunity => {
    const {
        id,
        profession,
        institution,
        description,
        qty,
        assistance,
        city,
        sector,
        user,
        value,
        available_days,
    } = opportunity
    try {
        const link = await buildLink(id, makeDescriptionLink(opportunity))
        const fullDescription = description ? `Descrição:\n\n${description}\n` : ''
        let days = ''
        let moneys = ''
        let sectorText = ''
        if (available_days && available_days.length > 0) {
            days = '\nDias disponíveis:'
            const formatedDays = available_days.map(item =>
                format(parseISO(item), 'EE - dd/MM/yyyy', { locale: ptLocale }).toUpperCase(),
            )
            days += `\n${formatedDays.join('\n')}\n`
        }
        if (sector) {
            sectorText = `Setor: ${sector}`
        }
        if (value) {
            moneys = `Valor: ${toCurrency(value.amount)}`
            if (value.value_type !== null) {
                moneys += ` | ${value.value_type === 0 ? 'LÍQUIDO' : 'BRUTO'}`
            }
            if (value.payment_type !== null) {
                moneys += `\nDia do pagamento: ${value.payment_type === 0 ? 'A VISTA' : 'A COMBINAR'}\n`
            }
        } else {
            moneys = 'Valor: A COMBINAR\n'
        }

        const message = `NOVA OPORTUNIDADE \n\nProfissional: ${profession?.name}\nAtendimento: ${
            assistance.title
        }\nQuant. Vagas: ${qty}\nLocal: ${getInstitutionName(
            institution,
            city,
        )}\n${sectorText}\n${moneys}Anunciante: ${
            user.name || user.full_name
        }\n${days}\nEntre em contato pelo link: ${link}\n\n${replaceEmoji(
            fullDescription,
            '',
        )}_ _ _ _ _ _ _ _ _ _ _ _ _ _ _\n\nAnuncie e compartilhe suas vagas pelo Plantão Ativo: https://plantaoativo.page.link/3Jqf`

        window.open(`https://wa.me/?text=${encodeURIComponent(message)}`, '_blank')
    } catch (error) {
        console.log(error)
    } finally {
    }
}

async function buildLink(id, description) {
    try {
        const result = await axios.post(
            'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDDLEdWWLKQkUwkY40pRYKyZD3isDa9VbM',
            {
                dynamicLinkInfo: {
                    domainUriPrefix: 'https://plantaoativo.page.link',
                    link: `https://dashboard.plantaoativo.com/oportunidades/${id}?open`,
                    androidInfo: {
                        androidPackageName: 'br.com.indexdigital.plantaoativo',
                        androidFallbackLink:
                            'https://play.google.com/store/apps/details?id=br.com.indexdigital.plantaoativo',
                    },
                    iosInfo: {
                        iosBundleId: 'br.com.IndexDigital.Plantao-Ativo',
                        iosFallbackLink: 'https://apps.apple.com/us/app/plant%C3%A3o-ativo/id1126420396',
                        iosAppStoreId: '1126420396',
                    },
                    navigationInfo: {
                        enableForcedRedirect: true,
                    },
                    socialMetaTagInfo: {
                        socialTitle: 'Plantão Ativo - Oportunidades',
                        socialDescription: description,
                        socialImageLink:
                            'https://firebasestorage.googleapis.com/v0/b/firebase-plantao-ativo.appspot.com/o/images%2F1024.png?alt=media&token=95db20da-9c86-435b-b2b8-4b8c89dac003',
                    },
                },
                suffix: {
                    option: 'SHORT',
                },
            },
        )
        console.log('data', result.data)
        return result.data.shortLink
    } catch (error) {
        return null
    }
}

function getInstitutionName(institution, city) {
    let instutionName = ''
    if (institution) {
        const { popular_name, name, city, uf } = institution

        if (popular_name) {
            instutionName = popular_name
        } else if (name) {
            instutionName = name
        }

        if (city) {
            if (instutionName) {
                instutionName += ` | ${city}`
            } else {
                instutionName = city
            }
        }

        if (uf) {
            instutionName += ` - ${uf}`
        }
    } else {
        if (city) {
            instutionName = city.name + ' - ' + city.state.code
        }
    }

    return instutionName
}

function makeDescriptionLink(opportunity) {
    const { profession, institution, qty, assistance, city } = opportunity

    return `Categoria: ${profession?.name} | Tipo de Atendimento: ${
        assistance.title
    } | Quant. Vagas: ${qty} | Local: ${getInstitutionName(institution, city)}`
}

export { shareOpportunity }
