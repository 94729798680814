import styled from 'styled-components'

const Container = styled.div`
    position: fixed;
    bottom: 0px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.backgroundBox};
    cursor: pointer;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 30px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media print {
        display: none !important;
    }
`

export { Container }
