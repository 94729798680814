import React, { memo } from 'react'

import { CheckBox } from '#/components/check-box/check-box'

import { Container, Title } from './item-multi-select.styled'
function ItemMultiSelect({ title, onClick, selected }) {
    return (
        <Container onClick={onClick}>
            <Title>{title}</Title>
            <CheckBox square checked={selected} />
        </Container>
    )
}

export default memo(ItemMultiSelect)
