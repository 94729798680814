import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { UserAPI } from '#/api'
import { Calendar, Card, SectionContainer, Sponsored } from '#/components'
import { ListMyShifts } from '#/modals'
import { makeEvent } from '#/utils/calendar-tools'

import {
    Container,
    ContainerCallToAction,
    ContainerCard,
    ContainerIcon,
    ContainerMySchedule,
    ContainerStores,
    ContentCard,
    Icon,
    Image,
    Message,
    TitleCard,
} from './section-stores.styled'

function SectionStores() {
    const [loading, setLoading] = useState(true)
    const location = useLocation()

    const [pick, setPick] = useState(null)

    const _loadSponsored = useCallback(async () => {
        setLoading(true)
        try {
            const result = await UserAPI.getNewsPick()
            setPick(result)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        _loadSponsored()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container>
            <SectionContainer loading={loading}>
                <ContainerCard>
                    <Card className="card-store" withoutPadding>
                        <ContentCard>
                            <ContainerIcon>
                                <Icon name="FileText" />
                            </ContainerIcon>
                            <TitleCard>Em construção</TitleCard>
                            <Message>
                                {location.pathname.includes('pendentes')
                                    ? `No momento, esta função só está disponível no aplicativo do Plantão Ativo. Em
                                breve a versão web contará com esta área pronta.`
                                    : 'No momento, este módulo só está disponível no aplicativo do Plantão Ativo. Anuncie suas vagas no próprio app e receba interessados rapidamente.'}
                            </Message>
                            {location.pathname.includes('oportunidades') && (
                                <Image src="https://api.plantaoativo.com/uploads/DJE-n56oL4kv" />
                            )}
                        </ContentCard>
                        <ContainerCallToAction>
                            <Message bold>
                                Baixe o app no seu celular e tenha uma experiência ainda mais completa.
                            </Message>
                            <ContainerStores>
                                <a
                                    href="https://plantaoativo.page.link/loja-ios"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="store-link"
                                >
                                    <div class="store-icon">
                                        <i class="icon-appstore.svg" />
                                        <img
                                            src="https://plantaoativo.com/wp-content/themes/plantaoativo_2020/assets/images/icon-appstore.svg?ver=1.1.2"
                                            alt="Baixe o Plantão Ativo na App Store"
                                        />
                                    </div>
                                    <div class="store-text">
                                        <div class="store-head">BAIXAR NA</div>
                                        <div class="store-title">App Store</div>
                                    </div>
                                </a>
                                <a
                                    href="https://plantaoativo.page.link/loja-android"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="store-link"
                                >
                                    <div class="store-icon">
                                        <i class="icon-googleplay.svg" />
                                        <img
                                            src="https://plantaoativo.com/wp-content/themes/plantaoativo_2020/assets/images/icon-googleplay.svg?ver=1.1.2"
                                            alt="Baixe o Plantão Ativo no Google Play"
                                        />
                                    </div>
                                    <div class="store-text">
                                        <div class="store-head">DISPONÍVEL NO</div>
                                        <div class="store-title">Google Play</div>
                                    </div>
                                </a>
                            </ContainerStores>
                        </ContainerCallToAction>
                    </Card>
                    <ContainerMySchedule>
                        <Card>
                            <MySchedule />
                        </Card>
                        <Sponsored pick={pick} />
                    </ContainerMySchedule>
                </ContainerCard>
            </SectionContainer>
        </Container>
    )
}

const MySchedule = memo(() => {
    const modalShiftsRef = useRef()
    const [events, setEvents] = useState([])
    const [shifts, setShifts] = useState([])
    const [monthsLoaded, setMonthsLoaded] = useState([])
    const [loadingCalendar, setLoadingCalendar] = useState(false)

    const [currentDate, setCurrentDate] = useState(null)
    const [initialDate, setInitialDate] = useState(null)

    const _mergeMonths = useCallback(
        periods => {
            let months = [...monthsLoaded]
            const { previous, current, next } = periods

            if (!monthsLoaded.length) {
                months = [previous, current, next]
            } else {
                if (monthsLoaded[0].month === current.month) {
                    months = [previous, ...monthsLoaded]
                }
                if (monthsLoaded[monthsLoaded.length - 1].month === current.month) {
                    months = [...monthsLoaded, next]
                }
            }

            return months
        },
        [monthsLoaded],
    )

    const _checkRefresh = useCallback(
        date => {
            if (!monthsLoaded.length) {
                return true
            } else {
                if (monthsLoaded[0].month === date) {
                    return true
                }
                if (monthsLoaded[monthsLoaded.length - 1].month === date) {
                    return true
                }
            }

            return false
        },
        [monthsLoaded],
    )

    const _loadShifts = useCallback(
        async date => {
            if (!date) {
                return
            }

            setLoadingCalendar(true)
            try {
                const result = await UserAPI.getCalendarByMonth(date)

                const months = _mergeMonths(result)

                let shifts = []
                months.forEach(item => {
                    shifts = [...shifts, ...item.days]
                })

                setShifts(shifts)

                setMonthsLoaded(months)
            } catch (error) {
            } finally {
                setLoadingCalendar(false)
            }
        },
        [_mergeMonths],
    )

    const _onMonthChange = useCallback(
        async date => {
            if (initialDate !== date) {
                setInitialDate(null)
            }

            if (currentDate !== date) {
                setCurrentDate(date)

                if (_checkRefresh(date)) {
                    await _loadShifts(date)
                }
            }
        },
        [currentDate, initialDate, _checkRefresh, _loadShifts],
    )

    const _openListModal = useCallback(
        ({ date }) => {
            modalShiftsRef.current && modalShiftsRef.current.show({ date })
        },
        [modalShiftsRef],
    )

    useEffect(() => {
        if (shifts) {
            const events = shifts.map(item =>
                makeEvent({
                    date: item.date,
                    colors: item.shifts,
                    hasInfo: item.user_has_transaction,
                    hasAlert: item.has_conflicts,
                    hasVacancy: item.has_vacancies,
                }),
            )
            setEvents(events)
        }
    }, [shifts])

    return (
        <>
            <Calendar
                events={events}
                initialDate={initialDate}
                onMonthChange={_onMonthChange}
                loading={loadingCalendar}
                onDayPress={_openListModal}
            />
            <ListMyShifts ref={modalShiftsRef} />
        </>
    )
})

export default memo(SectionStores)
