import { format, parseISO } from 'date-fns'
import localePT from 'date-fns/locale/pt-BR'
import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { GroupAPI } from '#/api'
import { SelectDay, TabsValue } from '#/components'
import { ItemDaily } from '#/components/items'
import { Loading } from '#/modals'

import { ShiftDetails, VacancyDetails } from '../shared'
import { Box, Close, Container, ContainerTitle, Padding, Title } from './list-shifts-by-group.styled'
function ListShiftsByGroup(props, ref) {
    const { t } = useTranslation()
    const group_id = useSelector(state => state.group?.id)
    const group_color = useSelector(state => state.group?.color)
    const [tabActive, setTabActive] = useState(1)
    const [visible, setVisible] = useState(false)
    const [onClose, setOnClose] = useState(null)
    const [date, setDate] = useState(null)
    const [daily, setDaily] = useState(null)
    const [shiftSelected, setShiftSelected] = useState(null)
    const [vacancySelected, setVacancySelected] = useState(null)
    const [loading, setLoading] = useState(true)

    const _onClick = useCallback(() => {
        if (onClose) {
            onClose()
        }
        setShiftSelected(null)
        setVacancySelected(null)
        setVisible(false)
        setDate(null)
        setDaily(null)
    }, [onClose])

    const _loadShifts = useCallback(
        async date => {
            if (!date || !group_id) {
                return
            }
            try {
                setLoading(true)

                const result = await GroupAPI.getShiftDaily(group_id, date)
                setDaily(result)
            } catch (error) {
            } finally {
                setLoading(false)
            }
        },
        [group_id],
    )

    useEffect(() => {
        if (date) {
            _loadShifts(date)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date])

    useImperativeHandle(ref, () => ({
        show: (props = {}) => {
            const { date, onClose } = props
            if (onClose) {
                setOnClose(() => onClose)
            }
            setDate(date)
            setVisible(true)
        },
    }))

    if (!visible) {
        return null
    }
    return (
        <Container className="w3-animate-opacity">
            <div style={{ position: 'absolute', height: '100%', width: '100%' }} onClick={_onClick} />
            <Box className="w3-animate-right">
                <ContainerTitle>
                    <Title>
                        {format(parseISO(date), "EEEE, dd 'de' MMMM", {
                            locale: localePT,
                        }).toUpperCase()}
                    </Title>
                    <Close onClick={_onClick} />
                </ContainerTitle>
                {shiftSelected || vacancySelected ? (
                    <>
                        {shiftSelected ? (
                            <ShiftDetails shift={shiftSelected} />
                        ) : (
                            <VacancyDetails vacancy={vacancySelected} />
                        )}
                    </>
                ) : (
                    <>
                        {date && (
                            <>
                                <SelectDay initialDate={date} onSelectedDay={e => setDate(e)} />
                            </>
                        )}
                        <Padding>
                            <TabsValue
                                onChange={e => setTabActive(e)}
                                leftText={t('dynamic')}
                                rightText={t('fixed')}
                            />
                        </Padding>
                        {daily && (
                            <>
                                {tabActive === 1 ? (
                                    <>
                                        {daily.dynamic_schedule.map((item, i) => (
                                            <ItemDaily
                                                key={`dai_${i}`}
                                                dailySchedule={item}
                                                color={group_color && `#${group_color}`}
                                                onShiftClick={e =>
                                                    setShiftSelected({ group: { id: group_id }, ...e })
                                                }
                                                onVacancyClick={e =>
                                                    setVacancySelected({ group: { id: group_id }, ...e })
                                                }
                                            />
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {daily.fixed_schedule.map((item, i) => (
                                            <ItemDaily
                                                key={`dai_${i}`}
                                                color={group_color && `#${group_color}`}
                                                dailySchedule={item}
                                                onVacancyClick={e =>
                                                    setVacancySelected({ group: { id: group_id }, ...e })
                                                }
                                            />
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
                <Loading show={loading} />
            </Box>
        </Container>
    )
}

export default memo(forwardRef(ListShiftsByGroup))
