import styled, { css } from 'styled-components'

import { Feather } from '#/components/icons'
const Contianer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const ContianerItems = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
`
const ContianerFooter = styled.div``

const ItemContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 10px;
    min-height: 40px;
    user-select: none;
    cursor: pointer;
    ${({ color, active, theme }) =>
        active
            ? css`
                  background-color: ${(color || theme.colors.primary) + '80'};
              `
            : css`
                  &:hover {
                      background-color: ${({ theme }) => theme.colors.hover};
                  }
              `}
`

const Icon = styled(Feather)`
    color: ${({ theme, active }) => (active ? '#fff' : theme.colors.text)};
`

const Title = styled.div`
    color: ${({ theme, active }) => (active ? '#fff' : theme.colors.text)};
    font-size: 18px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
`
const SubTitle = styled.div`
    color: ${({ theme, active }) => (active ? '#fff' : theme.colors.textLight)};
    font-size: 12px;
`

const ContainerTitle = styled.div`
    flex: 1;
    padding-left: 10px;
`

const Counter = styled.div`
    color: ${({ theme, active }) => (active ? '#fff' : theme.colors.text)};
`

export {
    Contianer,
    ItemContainer,
    Icon,
    Title,
    Counter,
    ContianerFooter,
    ContianerItems,
    ContainerTitle,
    SubTitle,
}
