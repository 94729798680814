import React, { forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ShiftAPI } from '#/api'
import { Avatar, InfoGroup, Separator, ShiftInfo } from '#/components'
import { ItemMember } from '#/components/items'
import { ActiveEmail, Loading } from '#/modals'

import {
    BannerWarning,
    Bold,
    ContainerAvatar,
    ContainerCallToAction,
    ContainerCoworkers,
    ContainerGroup,
    ContainerStores,
    IconWarning,
    Message,
    Segment,
    SegmentTitle,
    TextWarning,
} from './shift-details.styled'
function ShiftDetails({ shift }) {
    const { t } = useTranslation()
    const unconfirmed = useSelector(state => state.user?.unconfirmed_data || [])
    const activeEmailRef = useRef()
    const [shiftComplete, setShiftComplete] = useState(null)
    const [loading, setLoading] = useState(false)

    const _loadShift = useCallback(async shift => {
        try {
            setLoading(true)
            const result = await ShiftAPI.view(shift.group.id, shift.id)
            setShiftComplete(result)
        } catch (err) {
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if (shift) {
            setShiftComplete(shift)
            _loadShift(shift)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shift])

    if (!shiftComplete) {
        return null
    }
    const { group, user, coworkers } = shiftComplete
    return (
        <>
            <ContainerGroup>
                <InfoGroup group={group} />
            </ContainerGroup>
            <Segment>
                {user && (
                    <ItemMember
                        userId={user.id}
                        name={user.name}
                        photo={user.photo}
                        description={user.description}
                        council={user.council}
                    />
                )}
            </Segment>
            <Separator />
            <Segment>
                <SegmentTitle>{t('shift-details')}</SegmentTitle>
                <ShiftInfo shift={shiftComplete} />
            </Segment>
            <Separator />
            {coworkers?.length > 0 && (
                <>
                    <Segment>
                        <SegmentTitle>{t('who-more')}</SegmentTitle>
                        <ContainerCoworkers>
                            {coworkers.map((item, i) => (
                                <ContainerAvatar key={`member_${i}`}>
                                    <Avatar userId={item.id} name={item.name} source={item.photo} />
                                </ContainerAvatar>
                            ))}
                        </ContainerCoworkers>
                    </Segment>
                    <Separator />
                </>
            )}
            <Segment>
                {unconfirmed.includes('email') && (
                    <BannerWarning>
                        <IconWarning />
                        <TextWarning>
                            {t('warning.active-email.message')}
                            <Bold onClick={() => activeEmailRef.current && activeEmailRef.current.show()}>
                                {t('warning.active-email.click')}
                            </Bold>
                        </TextWarning>
                    </BannerWarning>
                )}
            </Segment>
            <Segment>
                <SegmentTitle>{t('transaction')}</SegmentTitle>
                <ContainerCallToAction>
                    <Message>
                        Por enquanto algumas funções só estão disponíveis no aplicativo Plantão Ativo. Baixe o
                        aplicativo para trocar e ceder plantões
                    </Message>
                    <ContainerStores>
                        <a
                            href="https://plantaoativo.page.link/loja-ios"
                            target="_blank"
                            class="store-link"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="https://plantaoativo.com/assets/appstore-white.svg"
                                alt="Baixe o Plantão Ativo na App Store"
                                width="110"
                            />
                        </a>
                        <a
                            href="https://plantaoativo.page.link/loja-android"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="store-link"
                        >
                            <img
                                src="https://plantaoativo.com/assets/googleplay-white.svg"
                                alt="Baixe o Plantão Ativo no Google Play"
                                width="120"
                            />
                        </a>
                    </ContainerStores>
                </ContainerCallToAction>
            </Segment>
            <Loading show={loading} />
            <ActiveEmail ref={activeEmailRef} />
        </>
    )
}

export default memo(forwardRef(ShiftDetails))
