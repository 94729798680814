import React, { memo } from 'react'

import { Loading } from '#/modals'

import { Container } from './section-container.styled'

const SectionContainer = ({ loading, children }) => {
    return (
        <Container className="section-container">
            {children}
            <Loading show={loading} ignoreRedux={false} />
        </Container>
    )
}

export default memo(SectionContainer)
