import classNames from 'classnames'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import DefaultAvatar from '#/assets/images/profile-picture.svg'
import { setProfile } from '#/redux/reducers/profile'

import {
    Badge,
    Circle,
    Container,
    ContainerIcon,
    ContainerImage,
    ContainerInfo,
    ContainerUserInfo,
    Description,
    Extra,
    Icon,
    IconBadge,
    Image,
    Label,
    Name,
} from './avatar.styled'

const Avatar = ({
    size,
    className,
    border,
    shadow,
    name,
    source,
    color,
    onClick,
    pointer,
    transaction,
    showIconPicker,
    showDetails,
    description,
    extra,
    userId,
    council,
}) => {
    const dispatch = useDispatch()
    const [loadFail, setLoadFail] = useState(false)
    const _color = useMemo(() => {
        if (color) {
            return color.includes('#') ? color : `#${color}`
        }
    }, [color])

    const avatarStyle = useMemo(() => {
        let _size

        _size = {
            width: size || 50,
            height: size || 50,
            borderRadius: (size || 50) / 2,
        }

        return _size
    }, [size])

    const formattedName = useMemo(() => {
        if (!name) {
            return ''
        }

        let arrayName = name.split(' ')

        let nameFormated = ''

        if (arrayName.length === 1) {
            nameFormated = arrayName[0].charAt(0)
        }

        if (arrayName.length > 1) {
            nameFormated = arrayName[0].charAt(0) + arrayName[1].charAt(0)
        }
        return nameFormated.toLocaleUpperCase()
    }, [name])

    const transformedSource = useMemo(() => {
        if (!source) {
            return DefaultAvatar
        }

        return source
    }, [source])

    const _onClick = useCallback(() => {
        if (userId) {
            dispatch(setProfile(userId))
        } else {
            if (onClick) {
                onClick()
            }
        }
    }, [userId, onClick, dispatch])

    return (
        <Container className="avatar">
            <ContainerImage
                shadow={shadow}
                border={border}
                color={_color}
                style={avatarStyle}
                className={classNames('container-avatar', className)}
                onClick={_onClick}
                pointer={pointer}
            >
                {(loadFail || !source) && name ? (
                    <Label style={{ fontSize: (size || 50) / 2 }}>{formattedName}</Label>
                ) : (
                    <Image title={name} src={transformedSource} onError={() => setLoadFail(true)} />
                )}
                {showIconPicker && (
                    <ContainerIcon>
                        <Icon size={18} />
                    </ContainerIcon>
                )}
                {transaction && (
                    <Badge color={_color}>
                        <IconBadge type={transaction.type} />
                    </Badge>
                )}
            </ContainerImage>
            {showDetails && (
                <ContainerInfo>
                    <ContainerUserInfo>
                        {name && <Name>{name}</Name>}
                        {description && (
                            <>
                                <Circle />
                                <Description>{description}</Description>
                            </>
                        )}
                    </ContainerUserInfo>
                    {council && <Description>{council}</Description>}
                    {extra && <Extra>{extra}</Extra>}
                </ContainerInfo>
            )}
        </Container>
    )
}

export default memo(Avatar)
