import styled from 'styled-components'

import LogoSource from '#/assets/images/plantao-ativo.png'

const Container = styled.div`
    position: relative;
    align-self: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px 30px;
    .card {
        flex: 1;
        .card-content {
            height: 1px;
            overflow: hidden;
        }
    }

    @media print {
        @page {
            size: A4;
            padding: 0 !important;
        }
    }
`

const ContainerTitle = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 10px;

    @media print {
        display: none !important;
    }
`

const ContainerButtons = styled.div`
    display: flex;
    .button {
        &:first-child {
            margin-right: 20px;
        }
    }
`

const TableBody = styled.tbody`
    padding: 0 !important;
    margin: 0 !important;

    @media print {
        width: 0 !important;
    }
`

const PageA4 = styled.table`
    width: 210mm;
    overflow: auto;
    user-select: none;
    background-color: #fff;
    -webkit-print-color-adjust: exact !important;

    @media print {
        padding: 0 !important;
        margin: 0 !important;
    }

    &::-webkit-scrollbar {
        height: 10px;
    }
`

const HeaderPage = styled.td`
    margin-bottom: 0px !important;
    .link {
        align-self: flex-end;
        @media print {
            margin: 0px;
            display: none !important;
        }
    }
`

const Comment = styled.div`
    color: #5d5958;
    font-size: 14px;
    padding: 20px 16px;
    white-space: pre-line;
`

const HeaderTop = styled.div`
    display: flex;
    flex: 1;
    padding: 16px 10px 10px 10px;
    justify-content: space-between;
`

const HeaderBottom = styled.div`
    display: flex;
    width: 100%;
    padding: 5px;
    background-color: #f2f2f3;
    align-items: center;
    justify-content: space-between;
`

const ContainerPageLogo = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const Logo = styled.img.attrs({ src: LogoSource })`
    height: 50px;
`

const ContainerPageTitle = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const PageTitle = styled.div`
    color: #5d5958;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
`
const Period = styled.div`
    color: #5d5958;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
`

const Emission = styled.div`
    color: #5d5958;
    font-size: 10px;
`

const ContainerGrupoInfo = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 40%;
    overflow: hidden;
    padding: 4px;
`

const GroupTitle = styled.div`
    font-size: 14px;
    text-align: start;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const GroupInstitution = styled.div`
    font-size: 14px;
    text-align: start;
    width: 100%;
    color: #888888;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const ContainerAdmin = styled.div`
    display: flex;

    flex: 1;

    overflow: hidden;
    padding: 4px 16px;

    @media print {
        page-break-inside: avoid;
    }
`
const ContentAdmin = styled.div`
    width: 100%;
    flex: 1;
`

const LabelAdmin = styled.div`
    font-weight: bold;
`

const AdminName = styled.div`
    font-size: 14px;
    font-weight: bold;
`

const AminInfo = styled.div`
    font-size: 12px;
`

const Line = styled.div`
    background-color: #000;
    height: 1px;
    width: 70%;
    margin-top: 25px;
`

const Tool = styled.div`
    margin-right: 16px;
`

const ContainerTool = styled.div`
    display: flex;
`

const TitleTool = styled.div`
    color: #888888;
    font-weight: bold;
    font-size: 16px;
`

const ContainerOptions = styled.div`
    .check-box {
        margin-top: 4px;
    }
    .label {
        color: #888888;
    }
`

export {
    AdminName,
    AminInfo,
    Container,
    ContainerAdmin,
    ContainerGrupoInfo,
    ContainerPageLogo,
    Tool,
    ContainerTool,
    TitleTool,
    ContainerPageTitle,
    ContainerOptions,
    ContainerTitle,
    ContentAdmin,
    Emission,
    GroupInstitution,
    GroupTitle,
    HeaderBottom,
    HeaderPage,
    Period,
    HeaderTop,
    LabelAdmin,
    Line,
    Logo,
    PageA4,
    Comment,
    PageTitle,
    ContainerButtons,
    TableBody,
}
