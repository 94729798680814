import { useFormik } from 'formik'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { AccountsAPI, GroupAPI, InstitutionAPI } from '#/api'
import { AutoComplete, ChooseColor, Input, Select } from '#/components'
import { AlertError } from '#/modals'
import { setGroup } from '#/redux/reducers/group'

import { ContentContainer } from '../shared'

function CreateGroup() {
    const alertError = useRef()
    const { t } = useTranslation()
    const profession = useSelector(state => state.user?.profession)
    const [loading, setLoading] = useState(false)
    const [professions, setProfessions] = useState([])
    const history = useHistory()
    const dispatch = useDispatch()

    const {
        isSubmitting,
        handleBlur,
        values,
        errors,
        touched,
        handleSubmit,
        dirty,
        isValid,
        setFieldValue,
        setTouched,
    } = useFormik({
        initialValues: {
            name: null,
            institution: null,
            category: null,
            color: null,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .nullable(true)
                .min(3, t('input.group_name.min', { min: 3 }))
                .trim()
                .required(t('input.group_name.required')),
            institution: Yup.object()
                .nullable(true)
                .test('institution-valid', t('autocomplete.institution.required'), value => !!value),
            category: Yup.string().nullable(true).required(t('select.profession.required')),
            color: Yup.string().nullable(true).required(t('choose-color.institution.required')),
        }),
        onSubmit: async values => {
            try {
                const body = {
                    ...values,
                    institution: values.institution?.value,
                }
                const result = await GroupAPI.create(body)
                dispatch(setGroup(result))
                history.replace(`/criar-grupo/${result?.id}/criar-turnos`)
            } catch ({ message }) {
                if (alertError.current) {
                    alertError.current.show({ message })
                }
            }
        },
    })

    const _init = useCallback(async () => {
        try {
            setLoading(true)
            const responseSpec = await AccountsAPI.professionalList()

            const professions = responseSpec.items.map(item => {
                return { label: item.name, value: item, key: item.id }
            })
            setProfessions(professions)
        } catch (error) {
            setProfessions([])
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if (profession) {
            setFieldValue('category', profession)
        }
    }, [profession, setFieldValue])

    useEffect(() => {
        _init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _onSearch = useCallback(async value => {
        if (value) {
            try {
                const result = await InstitutionAPI.list(value)
                return result.items.map(item => ({
                    title: item.name,
                    subTitle: `${item.city} - ${item.uf}`,
                    value: item,
                }))
            } catch (err) {
                return 0
            }
        } else {
            return []
        }
    }, [])

    return (
        <>
            <ContentContainer
                loading={loading}
                overflowHidden={false}
                title={t('create-group.title')}
                warning={t('create-group.warning')}
                disabled={!isValid || !dirty || isSubmitting}
                buttonTitle={t('create')}
                onNextClick={handleSubmit}
            >
                <Input
                    required
                    label={t('input.group_name.label')}
                    name="group_name"
                    placeholder={t('input.group_name.placeholder')}
                    value={values.name}
                    onChangeText={e => setFieldValue('name', (e || '').toUpperCase())}
                    onBlur={handleBlur('name')}
                    errorMessage={touched.name && errors.name}
                />
                <AutoComplete
                    required
                    label={t('autocomplete.institution.label')}
                    placeholder={t('autocomplete.institution.placeholder')}
                    onSearching={_onSearch}
                    value={values.institution}
                    onSelected={e => {
                        setFieldValue('institution', e || null)
                        if (!touched.institution) {
                            setTouched({ ...touched, institution: true })
                        }
                    }}
                    callActionText={t('autocomplete.institution.call')}
                    callActionClick={() => history.push('/criar-grupo/criar-entidade')}
                    errorMessage={touched.institution && errors.institution}
                />
                <Select
                    required
                    name="profession"
                    label={t('select.profession-group.label')}
                    options={professions}
                    placeholder={t('select.profession.placeholder')}
                    onChange={value => setFieldValue('category', value)}
                    selectedKey={values.category?.id}
                    errorMessage={touched.category && errors.category}
                />
                <ChooseColor
                    required
                    label={t('choose-color.institution.label')}
                    onChange={e => setFieldValue('color', e)}
                    errorMessage={touched.color && errors.color}
                />
            </ContentContainer>
            <AlertError ref={alertError} />
        </>
    )
}

export default memo(CreateGroup)
