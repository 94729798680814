import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { loadGroup } from '#/redux/thunk/group'

import { usePrevious } from './tools'

export function useLoadGroup() {
    const dispatch = useDispatch()
    const groupID = useSelector(state => state.group?.id)
    const { group_id } = useParams()
    const prevGroup = usePrevious(group_id)

    useEffect(() => {
        if (group_id) {
            if (prevGroup === group_id) {
                return
            }
            if (groupID === group_id) {
                return
            }

            dispatch(loadGroup(group_id))
        }
    }, [groupID, group_id, dispatch, prevGroup])
}
