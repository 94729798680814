import React, { memo, useMemo } from 'react'

import SourceApple from '#/assets/images/icon-social-apple.png'
import SourceEmail from '#/assets/images/icon-social-email.png'
import SourceFacebook from '#/assets/images/icon-social-facebook.png'
import SourceGoogle from '#/assets/images/icon-social-google.png'
import SourceLinkedin from '#/assets/images/icon-social-linkedin.png'
import SourceMicrosoft from '#/assets/images/icon-social-microsoft.png'

import { Container, Icon, Title } from './social-button.styled'
function SocialButton({ title, type, onClick }) {
    const source = useMemo(() => {
        let source
        switch (type) {
            case 'apple':
                source = SourceApple
                break
            case 'email':
                source = SourceEmail
                break
            case 'facebook':
                source = SourceFacebook
                break
            case 'microsoft':
                source = SourceMicrosoft
                break
            case 'google':
                source = SourceGoogle
                break
            case 'linkedin':
                source = SourceLinkedin
                break

            default:
                source = null
                break
        }

        return source
    }, [type])
    return (
        <Container onClick={onClick}>
            {!!source && <Icon src={source} />}
            <Title>{title}</Title>
        </Container>
    )
}

export default memo(SocialButton)
