import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 4px;
`

const Text = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
`

export { Text, Container }
