import styled from 'styled-components'

const Container = styled.div`
    align-items: center;
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
`

const ContainerInfo = styled.div`
    width: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Name = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 16px;
`

const Date = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 14px;
`

const Time = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 14px;
`

const Line = styled.div`
    height: 3px;
    background-color: ${({ theme, color }) => (color ? color : theme.colors.primary)};
    width: 100%;
`
const ContainerBadge = styled.div`
    width: 100%;
    position: absolute;
    top: 30px;
    .badge {
        background-color: ${({ theme }) => theme.colors.background};
        margin: -2px auto 0;
        transform: translateY(-50%);
    }
`

const ContainerAvatar = styled.div`
    width: 100%;
    display: flex;
    .icon {
        background-color: ${({ theme }) => theme.colors.background};
    }
`

const BorderAvatar = styled.div`
    flex: 1;
    position: relative;
    background-color: ${({ theme, transparent }) => (transparent ? 'transparent' : theme.colors.background)};
`

const BottomContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

export {
    BorderAvatar,
    BottomContent,
    Container,
    ContainerAvatar,
    ContainerInfo,
    Date,
    Line,
    Name,
    Time,
    ContainerBadge,
}
