import { format, parseISO } from 'date-fns'
import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Avatar from '../avatar/avatar'
import Badge from '../badge/badge'
import { FontPA } from '../icons'
import {
    BorderAvatar,
    BottomContent,
    Container,
    ContainerAvatar,
    ContainerBadge,
    ContainerInfo,
    Date,
    Line,
    Name,
    Time,
} from './transaction-view.styled'

function TransactionView({ from, to, type, color, isDateFormated, offers, transaction }) {
    const { t } = useTranslation()
    const fromUser = useMemo(() => {
        if (type === 'exchange' && offers?.length) {
            return {
                user: offers[0].user,
                shift: offers[0].shift,
            }
        }

        if (type === 'assignment_confirmation' && transaction) {
            return { user: transaction.user, shift: transaction.shift }
        }

        return from
    }, [from, type, offers, transaction])

    const toUser = useMemo(() => {
        if (type === 'exchange' && transaction) {
            return {
                user: transaction.user,
                shift: transaction.shift,
            }
        }
        return to
    }, [to, type, transaction])

    const label = useMemo(() => {
        switch (type) {
            case 'exchange':
                return t('exchange-to')
            case 'exchange_confirmation':
                return t('exchange-to')
            case 'exchange_offer':
                return t('exchange_offer_to')
            case 'assignment':
                return t('assignment_to')
            case 'assignment_confirmation':
                return t('assignment_to')
            case 'vacancy':
                return t('get-vacancy')
            default:
                return null
        }
    }, [type, t])

    return (
        <Container className="transaction-view">
            <ContainerBadge>
                <Line color={color} />
                <Badge color={color} title={label} size={12} />
            </ContainerBadge>
            <BottomContent>
                <ContainerInfo>
                    <ContainerAvatar>
                        <BorderAvatar />
                        {fromUser.user ? (
                            <Avatar
                                key={fromUser.user.id}
                                color={color}
                                name={fromUser.user?.name}
                                source={fromUser.user?.photo}
                                border
                                size={60}
                            />
                        ) : (
                            <FontPA name="vacancy" size={60} color={color} />
                        )}
                        <BorderAvatar transparent />
                    </ContainerAvatar>
                    <Name>{fromUser.user?.name}</Name>
                    {isDateFormated ? (
                        <Date>{fromUser.shift?.date}</Date>
                    ) : (
                        <Date>
                            {fromUser.shift?.date && format(parseISO(fromUser.shift.date), 'dd/MM/yyyy')}
                        </Date>
                    )}
                    <Time>{fromUser.shift?.time}</Time>
                </ContainerInfo>

                <ContainerInfo>
                    <ContainerAvatar>
                        <BorderAvatar transparent />
                        {toUser.user ? (
                            <Avatar
                                key={toUser.user.id}
                                color={color}
                                name={toUser.user?.name}
                                source={toUser.user?.photo}
                                border
                                size={60}
                            />
                        ) : (
                            <FontPA name="vacancy" size={60} color={color} />
                        )}
                        <BorderAvatar />
                    </ContainerAvatar>
                    <Name>{toUser.user?.name}</Name>
                    {isDateFormated ? (
                        <Date>{toUser.shift?.date}</Date>
                    ) : (
                        <Date>{toUser.shift?.date && format(parseISO(toUser.shift.date), 'dd/MM/yyyy')}</Date>
                    )}
                    <Time>{toUser.shift?.time}</Time>
                </ContainerInfo>
            </BottomContent>
        </Container>
    )
}

export default memo(TransactionView)
