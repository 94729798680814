import './style.css'

import classNames from 'classnames'
import React, { memo } from 'react'

import { Icon } from './font-pa.styled'
const FontPA = memo(({ name, color, size, className, onClick }) => {
    if (!name) {
        return <div>?</div>
    }

    return (
        <Icon className={classNames('icon', name, className)} onClick={onClick} color={color} size={size} />
    )
})

export { FontPA }
