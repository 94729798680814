import styled from 'styled-components'

import LogoImage from '../../../assets/images/logo.svg'
const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    text-align: start;
    margin-bottom: 10px;
    margin-top: 10px;
`

const Logo = styled.img.attrs({ src: LogoImage })`
    width: 70%;
    min-width: 200px;
    object-fit: contain;
`

const TextButton = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    margin-top: 10px;
    text-decoration: underline;
    cursor: pointer;
`

export { Title, Logo, TextButton }
