import React, { useCallback } from 'react'
import { CornerDownLeft, CornerDownRight, RefreshCcw, Star } from 'react-feather'

import Avatar from '#/components/avatar/avatar'
import { Vacancy } from '#/components/icons'

import { Badge, Container, ContainerAvatar, ContainerCoworkers, WorkingTime } from './item-daily.styled'

function ItemDaily({ dailySchedule, color, onShiftClick, onVacancyClick }) {
    const _onShiftClick = useCallback(
        shift => {
            if (onShiftClick) {
                onShiftClick(shift)
            }
        },
        [onShiftClick],
    )
    const _onVacancyClick = useCallback(
        vacancy => {
            if (onVacancyClick) {
                onVacancyClick(vacancy)
            }
        },
        [onVacancyClick],
    )

    if (!dailySchedule) {
        return null
    }

    const { label, shifts, vacancy } = dailySchedule

    return (
        <Container>
            <WorkingTime>{label}</WorkingTime>
            <ContainerCoworkers>
                {vacancy && (
                    <ContainerAvatar onClick={() => _onVacancyClick(vacancy)}>
                        <Vacancy color={color} amount={vacancy.slots} size={55} />
                    </ContainerAvatar>
                )}

                {shifts.map((item, i) => (
                    <ContainerAvatar key={`member_${i}`} onClick={() => _onShiftClick(item)}>
                        {item.transaction !== null && (
                            <Badge color={color}>
                                {item.transaction.type === 'assignment' && (
                                    <CornerDownRight color="#fefefe" size={12} />
                                )}

                                {item.transaction.type === 'assignment-received' && (
                                    <CornerDownLeft color="#fefefe" size={12} />
                                )}

                                {item.transaction.type === 'exchange' && (
                                    <RefreshCcw color="#fefefe" size={12} />
                                )}

                                {item.transaction.type === 'vacancy' && <Star color="#fefefe" size={12} />}
                            </Badge>
                        )}

                        <Avatar pointer name={item.user.name} source={item.user.photo} size={50} />
                    </ContainerAvatar>
                ))}
            </ContainerCoworkers>
        </Container>
    )
}

export default ItemDaily
