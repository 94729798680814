import styled from 'styled-components'

const ContianerSearch = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    .search {
        flex: 1;
    }
    .button {
        margin-left: 10px;
    }

    @media (max-width: 992px) {
        flex-direction: column;
        .button {
            margin-left: 0px;
            margin-top: 10px;
            width: 100%;
        }
    }
`

export { ContianerSearch }
