import styled from 'styled-components'

import cone from '#/assets/images/cone.png'

const Container = styled.div`
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Title = styled.h1`
    margin-bottom: 20px;
    font-weight: 700;

    @media only screen and (max-width: 992px) {
        font-size: 20pt;
    }
`

const Image = styled.img.attrs({ src: cone })`
    width: 300px;

    @media only screen and (max-width: 992px) {
        width: 240px;
    }
`

const Message = styled.p`
    max-width: 500px;
    text-align: center;

    color: ${({ theme }) => theme.colors.greyDark};

    @media only screen and (max-width: 992px) {
        max-width: 360px;
    }
`

const VerificationTitle = styled.p`
    font-weight: 500;
    color: ${({ theme }) => theme.colors.greyDark};
`

const TimeVerification = styled.span`
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 15px;
`

const ButtonVerificationClick = styled.button`
    border: 0;
    width: 300px;
    height: 40px;
    border-radius: 10px;

    font-weight: 600;

    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.grey};

    &:hover {
        background-color: ${({ theme }) => theme.colors.light};
        cursor: pointer;
    }
`

export { Container, Title, Message, VerificationTitle, ButtonVerificationClick, TimeVerification, Image }
