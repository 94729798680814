import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AuthAPI } from '../../../api'
import { Button, CheckBox, CheckBoxContainer } from '../../../components'
import AlertError from '../../alert-error/alert-error'
import Alert from '../../alert/alert'
import { RecoveryContext } from '../shared'
import { OptionContainer } from './choose-recovery-option.styled'

const ChooseRecoveryOption = () => {
    const alertRef = useRef()
    const alertErrorRef = useRef()
    const { recoveryProps, changeProps } = useContext(RecoveryContext)
    const [options, setOptions] = useState([])
    const [recoveryType, setRecoveryType] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const { t } = useTranslation()

    useEffect(() => {
        setOptions(recoveryProps?.options || [])
    }, [recoveryProps])

    useEffect(() => {
        changeProps({
            message: t('recovery.label'),
        })

        return () => {
            changeProps({
                options: null,
                message: null,
            })
        }
    }, [changeProps, t])

    const _next = useCallback(async () => {
        setIsSubmitting(true)
        if (recoveryType) {
            try {
                const email = recoveryProps?.email
                const result = await AuthAPI.recoverPassword({ channel: recoveryType, email })
                alertRef.current &&
                    alertRef.current.show({
                        title: t('recovery.send-code'),
                        message: result.message,
                        onPress: () => changeProps({ step: 3, channel: recoveryType }),
                    })
            } catch ({ message }) {
                alertErrorRef.current && alertErrorRef.current.show({ message })
            } finally {
                setIsSubmitting(false)
            }
        }
    }, [recoveryProps, recoveryType, changeProps, alertRef, alertErrorRef, t])

    return (
        <>
            <div>
                <OptionContainer>
                    <CheckBoxContainer selectValue={recoveryType} onChange={e => setRecoveryType(e)}>
                        {(options || []).map(({ label, type }, i) => (
                            <CheckBox key={`option_${i}`} label={label} value={type} />
                        ))}
                    </CheckBoxContainer>
                </OptionContainer>
                <Button
                    title={t('recover-password')}
                    disabled={!recoveryType || isSubmitting}
                    onClick={_next}
                />
            </div>
            <Alert ref={alertRef} />
            <AlertError ref={alertErrorRef} />
        </>
    )
}

export default memo(ChooseRecoveryOption)
