import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .separator {
        margin: 10px 0px;
    }

    .button-text {
        align-self: flex-end;
        margin: 0px 16px;
    }
`

const Padding = styled.div`
    padding: 5px 16px;
`

const Label = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ size }) => (size ? size : 16)}px;
    margin: 20px 0px;
    font-weight: bold;
`

const ContainerShiftsTypes = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const LabelShifts = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 12px;
    font-weight: bold;
`
const Hours = styled.span`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 12px;
    font-weight: normal;
`

const ContainerShiftsUser = styled.div`
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    .button-text {
        margin: 16px 0px;
    }
`

const ContainerShift = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4px 0px;
`

const ShiftInfo = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: ${({ right, center }) => {
        if (center) {
            return 'center'
        }
        if (right) {
            return 'flex-end'
        }
        return 'flex-start'
    }};
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
    color: ${({ theme, light }) => (light ? theme.colors.textLight : theme.colors.text)};
`

export {
    Container,
    Label,
    Padding,
    ContainerShiftsTypes,
    LabelShifts,
    Hours,
    ContainerShiftsUser,
    ContainerShift,
    ShiftInfo,
}
