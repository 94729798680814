import React, { memo } from 'react'

import { Container, Text } from './call-action-text.styled'

function CallActionText({ children, onClick }) {
    return (
        <Container onClick={onClick}>
            <Text>{children}</Text>
        </Container>
    )
}

export default memo(CallActionText)
