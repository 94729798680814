import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { removeGroup } from '#/redux/reducers/group'

import GroupInfo from '../../info-group/info-group'
import { AmountMember, Container, ContainerInfo } from './item-group.styled'

function ItemGroup({ group, hover, active }) {
    const dispatch = useDispatch()
    const history = useHistory()

    const _goToGroup = useCallback(() => {
        if (group && !active) {
            dispatch(removeGroup())
            history.push(`/grupo/${group.id}`)
        }
    }, [history, group, active, dispatch])

    if (!group) {
        return null
    }

    const { total_users } = group
    return (
        <Container hover={hover} active={active} onClick={_goToGroup}>
            <ContainerInfo>
                <GroupInfo group={group} />
                <AmountMember>{total_users} membros</AmountMember>
            </ContainerInfo>
        </Container>
    )
}

export default ItemGroup
