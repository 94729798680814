import { format, isToday, isTomorrow, parseISO } from 'date-fns'
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { OpportunityAPI } from '#/api'
import Badge from '#/components/badge/badge'
import ButtonText from '#/components/button-text/button-text'
import Button from '#/components/button/button'
import { Feather, FontPA } from '#/components/icons'
import { OpportunityContext } from '#/contexts'
import { Loading } from '#/modals'
import { formatCurrency, getDistanceWord, shareOpportunity } from '#/utils'

import {
    Bottom,
    ClosedText,
    Container,
    ContainerIcon,
    ContainerInfo,
    Date,
    Label,
    Localization,
    Margin,
    MenuIcon,
    Profession,
    Row,
    SideRight,
    Specializations,
    Text,
    Time,
} from './item-opportunity.styled'
function ItemOpportunity({ opportunity, onStatusUpdate }) {
    const { t } = useTranslation()
    const history = useHistory()
    const opportunityContext = useContext(OpportunityContext)
    const [likes, setLikes] = useState(0)

    const [interests, setInterests] = useState(0)
    const [timeNode, setTimeNode] = useState(null)
    const [reaction, setReaction] = useState(null)
    // const [isSaved, setSaved] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [isInterested, setInterested] = useState(false)
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(null)
    const [hide, setHide] = useState(false)

    const showInterest = useMemo(() => {
        if (opportunity.is_owner || ['finished', 'expired'].includes(opportunity.status)) {
            return false
        }
        return true
    }, [opportunity])

    const value = useMemo(() => {
        if (opportunity?.value) {
            return formatCurrency(opportunity.value.amount)
        } else {
            return t('to-match')
        }
    }, [opportunity, t])

    const statusName = useMemo(() => {
        switch (status) {
            case 'published':
                return t('opportunity-status-published')

            case 'draft':
                return t('opportunity-status-draft')

            case 'expired':
                return t('opportunity-status-expired')

            case 'finished':
                return t('opportunity-status-finished')

            default:
                return ''
        }
    }, [status, t])

    const _toogleReaction = useCallback(
        newReaction => {
            if (newReaction === 'like') {
                if (reaction === 'like') {
                    setLikes(likes > 0 ? likes - 1 : 0)
                    setReaction(null)
                } else {
                    setLikes(likes + 1)
                    setReaction('like')
                }
            }
        },
        [reaction, likes],
    )

    const _reaction = useCallback(
        async type => {
            const lastReaction = reaction
            const lastLikes = likes
            setLoading(true)
            try {
                _toogleReaction(type)
                await OpportunityAPI.reaction(opportunity.id, type)
            } catch (error) {
                setReaction(lastReaction)
                setLikes(lastLikes)
            }
            setLoading(false)
        },
        [opportunity, reaction, likes, _toogleReaction],
    )

    // const _toogleSave = useCallback(async () => {
    //     const lastSave = isSaved
    //     setLoading(true)
    //     try {
    //         if (isSaved) {
    //             setSaved(false)
    //             await OpportunityAPI.unSave(opportunity.id)
    //         } else {
    //             setSaved(true)
    //             await OpportunityAPI.save(opportunity.id)
    //         }
    //     } catch (error) {
    //         setSaved(lastSave)
    //     }
    //     setLoading(false)
    // }, [opportunity, isSaved])

    const _createInterest = useCallback(async () => {
        setLoading(true)
        try {
            await OpportunityAPI.createInterest(opportunity.id, { presentation: '', accepted: true })
            setInterested(true)
        } catch ({ message }) {
            // setMessageError(err)
        }
        setLoading(false)
    }, [opportunity])

    const _cancelInterest = useCallback(async () => {
        setLoading(true)
        try {
            await OpportunityAPI.cancelInterest(opportunity?.id)
            setInterested(false)
        } catch ({ message }) {
            // setMessageError(err)
        }
        setLoading(false)
    }, [opportunity])

    const _share = useCallback(async () => {
        setLoading(true)
        try {
            if (opportunity) {
                await shareOpportunity(opportunity)
            }
        } catch ({ message }) {
            // setMessageError(err)
        }
        setLoading(false)
    }, [opportunity])

    const _updateStatus = useCallback(
        async status => {
            setLoading(true)
            try {
                await OpportunityAPI.updateStatus(opportunity?.id, status)
                setStatus(status)

                if (onStatusUpdate) {
                    onStatusUpdate()
                }
            } catch (error) {}
            setLoading(false)
        },
        [opportunity, onStatusUpdate],
    )

    const _onRemoveItem = useCallback(
        async id => {
            setLoading(true)
            try {
                await OpportunityAPI.remove(opportunity?.id)
                setHide(true)
            } catch (error) {}
            setLoading(false)
        },
        [opportunity],
    )

    const _goToDetails = useCallback(() => {
        history.push(`/oportunidades/${opportunity.id}`)
    }, [history, opportunity])

    const _formatDate = useCallback(
        stringDate => {
            if (!stringDate) {
                return ''
            }
            const date = parseISO(stringDate)
            if (isToday(date)) {
                return t('until-today')
            }
            if (isTomorrow(date)) {
                return t('until-tomorrow')
            }
            return format(date, `'${t('until')}' dd/MM/yyyy`)
        },
        [t],
    )

    const _formatVacancy = useCallback(
        qty => {
            if (qty) {
                if (qty < 2) {
                    return `${qty} ${t('opportunity-vacancy')} `
                } else {
                    return `${qty} ${t('opportunity-vacancies')} `
                }
            }
            return ''
        },
        [t],
    )

    useEffect(() => {
        if (opportunity) {
            setLikes(opportunity.likes)

            setInterests(opportunity.interests)
            setReaction(opportunity.reaction)
            // setSaved(opportunity.is_saved)
            setStatus(opportunity.status)
            setTimeNode(getDistanceWord(opportunity.updated_at))
            setInterested(opportunity.is_interested)
        }
    }, [opportunity])

    useEffect(() => {
        if (status) {
            setIsActive(['published', 'draft'].includes(status))
        }
    }, [status])

    if (hide) {
        return null
    }

    const {
        expires_at,
        profession,
        specializations,
        institution,
        city,
        sector,
        assistance,
        is_owner,
        qty,
        views,
    } = opportunity
    return (
        <Container>
            <Row>
                {!!assistance && <Badge title={assistance.title} />}
                {isActive ? <Date>{_formatDate(expires_at)}</Date> : <ClosedText>{statusName}</ClosedText>}
                <Time>{timeNode}</Time>
                {is_owner && (
                    <MenuIcon
                        onClick={() =>
                            opportunityContext?.openMenu(
                                { ...opportunity, status },
                                {
                                    onRemove: _onRemoveItem,
                                    onClose: () => _updateStatus('finished'),
                                    onReactivate: () => _updateStatus('published'),
                                },
                            )
                        }
                    />
                )}
            </Row>
            <Margin>
                <Row>
                    <Profession>
                        {profession?.name}
                        {(specializations || []).length > 0
                            ? ' | ' + getSpecializations(specializations, ' ', true)
                            : null}
                    </Profession>
                </Row>
                {specializations.length > 1 && (
                    <Row>
                        <Specializations>
                            {specializations.length > 2
                                ? specializations.length > 1 &&
                                  t('amount-specializaties', { amount: specializations.length })
                                : specializations[1].name}
                        </Specializations>
                    </Row>
                )}
                <Row>
                    {!!institution && <Localization>{institution?.popular_name}</Localization>}
                    {!!city && (
                        <Localization>
                            {city.name}, {city.state.code}
                        </Localization>
                    )}
                </Row>
            </Margin>
            <Row>
                <ContainerInfo>
                    <Row>
                        <Label>{t('number-of-opportunity')}:</Label>
                        <Text>{_formatVacancy(qty)}</Text>
                    </Row>

                    {!!sector && (
                        <Row>
                            <Label>{t('sector')}:</Label>
                            <Text>{sector}</Text>
                        </Row>
                    )}

                    <Row>
                        <Label>{t('value')}:</Label>
                        <Text>
                            {value}
                            {opportunity.value && opportunity.value.value_type !== null && (
                                <>{` | ${opportunity.value.value_type === 0 ? t('liquid') : t('gross')}`}</>
                            )}
                        </Text>
                    </Row>

                    {!!opportunity.value && opportunity.value.payment_type !== null && (
                        <Row>
                            <Label>{t('pay-day')}:</Label>
                            <Text>{opportunity.value.payment_type === 0 ? t('in-cash') : t('to-match')}</Text>
                        </Row>
                    )}
                </ContainerInfo>
                {showInterest && (
                    <Button
                        title={t(isInterested ? 'Cancelar interesse' : 'i-am-interested')}
                        dimension="auto"
                        variant={isInterested ? 'danger' : 'primary'}
                        onClick={() =>
                            isInterested
                                ? opportunityContext?.cancelInterest(_cancelInterest)
                                : opportunityContext?.confirmInterest(_createInterest)
                        }
                    />
                )}
            </Row>
            <ButtonText onClick={_goToDetails}>{t('see-details')}</ButtonText>
            <Bottom>
                {is_owner ? (
                    <>
                        <ContainerIcon
                            onClick={() => opportunityContext?.showInterestedUsers(opportunity.id)}
                        >
                            <Feather name="User" pointer />
                            {interests}
                        </ContainerIcon>
                        <ContainerIcon disabled={is_owner} onClick={() => !is_owner && _reaction('like')}>
                            <FontPA name={reaction === 'like' ? 'like' : 'like-o'} />
                            {likes}
                        </ContainerIcon>
                    </>
                ) : (
                    <ContainerIcon disabled={is_owner} onClick={() => !is_owner && _reaction('like')}>
                        <FontPA name={reaction === 'like' ? 'like' : 'like-o'} />
                        {likes}
                    </ContainerIcon>
                )}
                <ContainerIcon>
                    <Feather name="Eye" />
                    {views}
                </ContainerIcon>

                <SideRight>
                    <ContainerIcon onClick={() => opportunityContext?.showReferralsUsers(opportunity.id)}>
                        <Feather name="Send" pointer />
                    </ContainerIcon>
                    <ContainerIcon onClick={_share}>
                        <Feather name="Share2" pointer />
                    </ContainerIcon>
                    {/* {!is_owner && (
                        <ContainerIcon disabled={is_owner} onClick={_toogleSave}>
                            <FontPA name={isSaved ? 'bookmark' : 'bookmark-o'} />
                        </ContainerIcon>
                    )} */}
                </SideRight>
            </Bottom>

            <Loading show={loading} />
        </Container>
    )
}

function getSpecializations(specializations = [], separator, highlighted) {
    if (specializations && specializations.length > 0) {
        if (highlighted) {
            return specializations[0].name
        } else {
            if (specializations.length > 1) {
                return specializations
                    .slice(1, specializations.length)
                    .map(item => item.name)
                    .join(separator)
            } else {
                return ''
            }
        }
    }
    return ''
}

export default memo(ItemOpportunity)
