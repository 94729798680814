import { eachDayOfInterval, format, parseISO } from 'date-fns'
import localePT from 'date-fns/locale/pt-BR'
import { saveAs } from 'file-saver'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { GroupAPI } from '#/api'
import { Button, CheckBox, Link, List } from '#/components'
import { TextArea } from '#/components/forms'
import { ItemPrintReport } from '#/components/items'
import { useLoadGroup } from '#/hooks'
import { Alert, AlertError, CustomModal, Loading } from '#/modals'
import { transformComments } from '#/utils/transform-comments'

import {
    AdminName,
    AminInfo,
    Comment,
    Container,
    ContainerAdmin,
    ContainerButtons,
    ContainerGrupoInfo,
    ContainerOptions,
    ContainerPageLogo,
    ContainerPageTitle,
    ContainerTitle,
    ContainerTool,
    ContentAdmin,
    Emission,
    GroupInstitution,
    GroupTitle,
    HeaderBottom,
    HeaderPage,
    HeaderTop,
    LabelAdmin,
    Line,
    Logo,
    PageA4,
    PageTitle,
    Period,
    TableBody,
    TitleTool,
    Tool,
} from './print-reports.styled'

function PrintReports() {
    useLoadGroup()
    const alertRef = useRef()
    const alertErrorRef = useRef()
    const customModalRef = useRef()
    const group = useSelector(state => state.group)
    const { start_date, end_date, user } = useParams()
    const [extras, setExtras] = useState(['council'])
    const [loading, setLoading] = useState(true)
    const [comment, setComment] = useState(null)
    const [displayName, setDisplayName] = useState('full_name')
    const [usersReports, setUsersReports] = useState([])
    const [workingtimes, setWorkingtimes] = useState([])
    const [displayAll, setDisplayAll] = useState(false)

    const schedulePeriod = useMemo(() => {
        try {
            let period = ''
            if (start_date) {
                period = format(parseISO(start_date), 'dd/MM/yyyy')
            }

            if (end_date) {
                period += ' a ' + format(parseISO(end_date), 'dd/MM/yyyy')
            }
            return period
        } catch (err) {
            return null
        }
    }, [start_date, end_date])

    const emission = useMemo(() => {
        return format(new Date(), "'Emissão em:' HH'h'mm 'de' dd/MM/yyyy", { locale: localePT })
    }, [])

    const _exportCSV = useCallback(() => {
        const days = eachDayOfInterval({
            start: parseISO(start_date),
            end: parseISO(end_date),
        }).map(item => ({ date: format(item, 'yyyy-MM-dd'), format: format(item, 'dd/MM/yyyy') }))

        const fields = `NOME,CATEGORIA,N° PLANTÕES,TURNO,INÍCIO,FIM,CARGA HORÁRIA,${days
            .map(item => item.format)
            .join(',')}\n`

        const rowCSV = []

        usersReports.forEach(item => {
            for (let i = 0; i < workingtimes.length; i++) {
                const e = workingtimes[i]
                const shiftsByWorkingTimes = item.shifts.filter(shift => shift.working_time.label === e.label)
                if (shiftsByWorkingTimes.length > 0) {
                    rowCSV.push({
                        ...item,
                        shifts: shiftsByWorkingTimes,
                        label: e.label,
                        start_hour: e.start_hour,
                        end_hour: e.end_hour,
                    })
                }
            }
        })
        console.log(rowCSV)
        const values = rowCSV.map(
            ({ full_name, totals, description, shifts, label, start_hour, end_hour }) => {
                return `${full_name},${description.replace('/', '-')},${
                    shifts.length
                },${label}  ,${start_hour},${end_hour},${totals.dynamic_hours},${days
                    .map(i => {
                        const shift = shifts.find(e => e.start_date === i.date)
                        return shift ? shift.working_time.label : ''
                    })
                    .join(',')}`
            },
        )

        var blob = new Blob([fields + values.join('\n')], { type: 'text/csv;charset=utf-8;' })
        saveAs(blob, `relatório-${new Date().getTime()}.csv`)
    }, [usersReports, start_date, end_date, workingtimes])

    const _alertExport = useCallback(() => {
        if (alertRef.current) {
            alertRef.current.show({
                icon: 'FileText',
                title: 'Exportar Relatório',
                message: 'O arquivo será exportado como um CSV',
                onPress: () => {
                    setTimeout(() => {
                        _exportCSV()
                    }, 1000)
                },
            })
        }
    }, [alertRef, _exportCSV])

    const _alert = useCallback(() => {
        if (alertRef.current) {
            alertRef.current.show({
                icon: 'Printer',
                title: 'Aviso',
                message:
                    'Para uma melhor qualidade de impressão recomendamos que utilize os navegadores: <b>Chrome</b>, <b>Microsoft Edge</b> ou <b>Chromium<b/>',
                onPress: () => {
                    setTimeout(() => {
                        window.print()
                    }, 1000)
                },
            })
        }
    }, [alertRef])

    const _addComment = useCallback(() => {
        if (customModalRef.current) {
            customModalRef.current.show({
                show: true,
                title: comment ? 'Editar comentário' : 'Adicionar comentário',
            })
        }
    }, [customModalRef, comment])

    const _getReports = useCallback(
        async filterByUser => {
            if (!group || !start_date || !end_date) {
                return
            }

            try {
                setLoading(true)

                const result = await GroupAPI.reportsPrint(group.id, start_date, end_date, {
                    user: filterByUser === 'all' ? null : filterByUser,
                })

                setUsersReports(result.items)
                setWorkingtimes(result.working_times)
            } catch ({ message }) {
                alertErrorRef.current && alertErrorRef.current.show({ message })
            } finally {
                setLoading(false)
            }
        },
        [group, start_date, end_date],
    )

    const _changeExtra = useCallback(
        extra => {
            if (extras.includes(extra)) {
                setExtras(extras.filter(item => item !== extra))
            } else {
                setExtras([...extras, extra])
            }
        },
        [extras],
    )

    useEffect(() => {
        if (group) {
            let filterBy
            if (user !== 'all') {
                filterBy = displayAll ? 'all' : user
            } else {
                filterBy = user
            }

            document.title = `${group.name.toUpperCase()} RELATÓRIO - ${schedulePeriod || 'escala'}`
            _getReports(filterBy)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schedulePeriod, group, user, displayAll])

    if (!group) {
        return null
    }

    return (
        <>
            <Container>
                <ContainerTitle className="tools">
                    <ContainerTool>
                        <Tool>
                            <TitleTool>Nome de exibição</TitleTool>
                            <ContainerOptions>
                                <CheckBox
                                    label="Nome Completo"
                                    checked={displayName === 'full_name'}
                                    onClick={() => setDisplayName('full_name')}
                                />
                                <CheckBox
                                    label="Nome de Tratamento"
                                    checked={displayName === 'name'}
                                    onClick={() => setDisplayName('name')}
                                />
                            </ContainerOptions>
                        </Tool>

                        <Tool>
                            <TitleTool>Extras</TitleTool>
                            <ContainerOptions>
                                <CheckBox
                                    label="Conselho"
                                    square
                                    checked={extras.includes('council')}
                                    onClick={() => _changeExtra('council')}
                                />
                                <CheckBox
                                    label="Telefone"
                                    square
                                    checked={extras.includes('phone')}
                                    onClick={() => _changeExtra('phone')}
                                />
                            </ContainerOptions>
                        </Tool>

                        {user !== 'all' && (
                            <Tool>
                                <TitleTool>Filtrar</TitleTool>
                                <ContainerOptions>
                                    <CheckBox
                                        label="Exibir Todos"
                                        square
                                        checked={displayAll}
                                        onClick={() => setDisplayAll(!displayAll)}
                                    />
                                </ContainerOptions>
                            </Tool>
                        )}
                    </ContainerTool>
                    {!loading && !!schedulePeriod && (
                        <ContainerButtons>
                            <Button
                                variant="white"
                                title="Exportar"
                                dimension="auto"
                                onClick={_alertExport}
                            />
                            <Button title="Imprimir" dimension="auto" onClick={_alert} />
                        </ContainerButtons>
                    )}
                </ContainerTitle>

                {schedulePeriod ? (
                    <PageA4>
                        <HeaderPage>
                            <HeaderTop>
                                <ContainerPageLogo>
                                    <Logo />
                                </ContainerPageLogo>
                                <ContainerPageTitle>
                                    <PageTitle>RELATÓRIO DE PRODUÇÃO</PageTitle>
                                    <Period>{schedulePeriod}</Period>

                                    <Emission>{emission}</Emission>
                                </ContainerPageTitle>
                                <div style={{ flex: 1 }} />
                            </HeaderTop>
                            <HeaderBottom>
                                <ContainerGrupoInfo>
                                    <GroupTitle>{`${group.name} (${group.category.name})`}</GroupTitle>
                                    <GroupInstitution>{group.institution?.name}</GroupInstitution>
                                </ContainerGrupoInfo>
                            </HeaderBottom>

                            <Link onClick={_addComment}>
                                {comment ? 'Editar comentário' : 'Adicionar comentário'}
                            </Link>

                            {comment !== null && <Comment dangerouslySetInnerHTML={{ __html: comment }} />}

                            <ContainerAdmin>
                                {group?.manager && (
                                    <ContentAdmin>
                                        <LabelAdmin>Gestor</LabelAdmin>
                                        <AdminName>{group?.manager.full_name}</AdminName>
                                        <AminInfo>{group?.manager.email}</AminInfo>
                                        <AminInfo>{group?.manager.phone}</AminInfo>
                                        <Line />
                                    </ContentAdmin>
                                )}
                            </ContainerAdmin>
                        </HeaderPage>
                        <TableBody>
                            {usersReports.map((item, i) => (
                                <ItemPrintReport
                                    extras={extras}
                                    key={`item_${i}`}
                                    displayName={displayName}
                                    user={item}
                                />
                            ))}
                        </TableBody>
                    </PageA4>
                ) : (
                    <div>Período Inválido</div>
                )}
            </Container>
            <Alert ref={alertRef} />
            <AlertError ref={alertErrorRef} />
            <Loading show={loading} />
            <CustomModal ref={customModalRef}>
                <>
                    <TextArea value={comment} onChangeText={e => setComment(transformComments(e))} />
                </>
            </CustomModal>
        </>
    )
}

export default PrintReports
