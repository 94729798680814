import styled, { css } from 'styled-components'

const ContainerInputCode = styled.div`
    width: 100%;
    margin: 20px 0px;
`

const Message = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
`

const Bold = styled.span`
    font-weight: bold;
    ${({ primary }) =>
        primary &&
        css`
            color: ${({ theme }) => theme.colors.primary};
        `};
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'auto')};
`

export { Bold, ContainerInputCode, Message }
