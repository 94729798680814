import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { usePrevious } from '#/hooks'

import { ClearIcon, Container, InputSearch, SearchIcon } from './search.styled'
function Search({ onSearch, callActionText, callActionClick }, ref) {
    const [search, setSearch] = useState(null)
    const oldSearch = usePrevious(search)
    const { t } = useTranslation()

    useEffect(() => {
        if (search !== null) {
            if (search !== oldSearch) {
                const timeout = setTimeout(() => {
                    if (onSearch) {
                        onSearch(search)
                    }
                }, 1000)
                return () => {
                    if (timeout) {
                        clearTimeout(timeout)
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, onSearch, oldSearch])

    useImperativeHandle(ref, () => ({
        value: () => {
            return search
        },
        clear: () => {
            _reset()
        },
    }))

    const _reset = useCallback(() => {
        setSearch('')
    }, [])

    return (
        <Container className="search">
            <InputSearch
                value={search}
                placeholder={t('search')}
                onChangeText={e => setSearch(e)}
                callActionText={callActionText}
                callActionClick={callActionClick}
            />
            {search ? <ClearIcon onClick={_reset} /> : <SearchIcon />}
        </Container>
    )
}

export default memo(forwardRef(Search))
