import React, { memo, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AccountsAPI, GroupAPI } from '#/api'
import { ItemMember, Search } from '#/components'
import { useLoadGroup } from '#/hooks'
import { Alert, AlertError, Confirmation, InviteMember } from '#/modals'
import { updateGroup } from '#/redux/reducers/group'

import { GroupContent } from '../shared'

function SearchMembers() {
    const history = useHistory()
    useLoadGroup()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const confirmationRef = useRef()
    const alertErrorRef = useRef()
    const alertRef = useRef()
    const searchRef = useRef()
    const inviteMemberRef = useRef()

    const group_id = useSelector(state => state.group?.id)
    const total_users = useSelector(state => state.group?.total_users)

    const [loading, setLoading] = useState(false)

    const [accounts, setAccounts] = useState([])

    const _searchMember = useCallback(async search => {
        setLoading(true)
        try {
            const result = await AccountsAPI.listAccounts({ search })

            setAccounts(result.items)
        } catch (err) {
            setAccounts([])
        } finally {
            setLoading(false)
        }
    }, [])

    const _addMember = useCallback(
        async member => {
            try {
                setLoading(true)
                await GroupAPI.addMembers([group_id], member.id)
                if (alertRef.current) {
                    alertRef.current.show({
                        title: 'Membro adicionado',
                        message: 'Membro adicionado com sucesso!',
                    })
                }

                if (searchRef.current) {
                    searchRef.current.clear()
                }
                dispatch(updateGroup({ total_users: (total_users || 0) + 1 }))
            } catch ({ message }) {
                if (alertErrorRef.current) {
                    alertErrorRef.current.show({ message })
                }
            } finally {
                setLoading(false)
            }
        },
        [group_id, searchRef, dispatch, total_users],
    )

    const _onInvide = useCallback(
        member => {
            if (confirmationRef.current) {
                confirmationRef.current.show({
                    title: 'Adicionar membro',
                    message: `Tem certeza que deseja adicionar ${member.name} ao grupo?`,
                    icon: 'UserPlus',
                    onYesClick: () => {
                        _addMember(member)
                    },
                })
            }
        },
        [confirmationRef, _addMember],
    )

    const _onSearch = useCallback(
        search => {
            if (search) {
                _searchMember(search)
            } else {
                setAccounts([])
            }
        },
        [_searchMember],
    )

    const _openInviteMember = useCallback(() => {
        if (inviteMemberRef.current) {
            inviteMemberRef.current.show({ group_id })
        }
    }, [inviteMemberRef, group_id])

    return (
        <>
            <GroupContent
                loading={loading}
                title={t('add-member.title')}
                warning={t('add-member.warning')}
                arrowBack
            >
                <Search
                    ref={searchRef}
                    onSearch={_onSearch}
                    callActionText={t('invite-member')}
                    callActionClick={_openInviteMember}
                />

                {accounts.map(item => (
                    <ItemMember
                        key={`member_${item.id}`}
                        userId={item.id}
                        {...item}
                        isInvide
                        onInvide={() => _onInvide(item)}
                    />
                ))}
            </GroupContent>
            <Confirmation ref={confirmationRef} />
            <AlertError ref={alertErrorRef} />
            <Alert ref={alertRef} />
            <InviteMember
                ref={inviteMemberRef}
                onSuccess={() => history.replace(`/grupo/${group_id}/membros`)}
            />
        </>
    )
}

export default memo(SearchMembers)
