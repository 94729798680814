import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AccountsAPI } from '#/api'
import { Button, ContentSection, Switch } from '#/components'
import { Alert, AlertError } from '#/modals'

import { SettingsContent } from '../shared'
import { ContainerButton, ContainerForm } from './privacity.styled'
function Privacity() {
    const { t } = useTranslation()
    const alertErrorRef = useRef()
    const alertRef = useRef()
    const [loading, setLoading] = useState(false)
    const [configurations, setConfigurations] = useState({})

    const _getProfilePrivacy = useCallback(async () => {
        try {
            setLoading(true)
            const data = await AccountsAPI.getProfilePrivacy()

            setConfigurations({
                show_name: data.show_name,
                show_phone: data.show_phone,
                show_email: data.show_email,
                show_about: data.show_about,
            })
        } catch (err) {
            alertErrorRef.current &&
                alertErrorRef.current.show({ message: 'Houve um problema ao carregar as configurações' })
        } finally {
            setLoading(false)
        }
    }, [alertErrorRef])

    const _updateProfilePrivacy = useCallback(async () => {
        try {
            setLoading(true)
            await AccountsAPI.updateProfilePrivacy(configurations)

            alertRef.current &&
                alertRef.current.show({ message: 'Configurações de privacidade salvas com sucesso' })
        } catch (error) {
            alertErrorRef.current &&
                alertErrorRef.current.show({ message: 'Houve um problema ao salvar as configurações' })
        } finally {
            setLoading(false)
        }
    }, [alertErrorRef, alertRef, configurations])

    const setConfiguration = (field, value) => {
        setConfigurations({ ...configurations, [field]: value })
    }

    useEffect(() => {
        _getProfilePrivacy()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <SettingsContent title={t('safety')} iconName="Lock" withoutPadding loading={loading}>
            <ContentSection withoutPadding>
                <ContainerForm>
                    <Switch
                        title={t('switch.privacity.name')}
                        onChange={value => {
                            setConfiguration('show_name', value)
                        }}
                        checked={configurations.show_name}
                    />
                    <Switch
                        title={t('switch.privacity.phone')}
                        onChange={value => {
                            setConfiguration('show_phone', value)
                        }}
                        checked={configurations.show_phone}
                    />
                    <Switch
                        title={t('switch.privacity.email')}
                        onChange={value => {
                            setConfiguration('show_email', value)
                        }}
                        checked={configurations.show_email}
                    />
                    <Switch
                        title={t('switch.privacity.about')}
                        onChange={value => {
                            setConfiguration('show_about', value)
                        }}
                        checked={configurations.show_about}
                    />
                </ContainerForm>
                <ContainerButton>
                    <Button title={t('update')} onClick={_updateProfilePrivacy} />
                </ContainerButton>
            </ContentSection>
            <Alert ref={alertRef} />
            <AlertError ref={alertErrorRef} />
        </SettingsContent>
    )
}

export default memo(Privacity)
