import styled from 'styled-components'

const ContainerControl = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 16px;
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
`

const Message = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    margin-bottom: 20px;
    font-size: 12px;
`

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`

export { ButtonContainer, Message, Title, ContainerControl }
