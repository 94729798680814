import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Badge } from '#/components'
import { formatCurrency } from '#/utils'

import { Container, ContainerAmounts, ContainerInfo, IconInfo, Info } from './shift-info.styled'
function ShiftInfo({ shift }) {
    const { t } = useTranslation()
    const { amount, date, time, has_special_amount, available } = shift
    return (
        <Container>
            <ContainerInfo>
                <IconInfo name="Calendar" />
                <Info>{date}</Info>
            </ContainerInfo>
            <ContainerInfo>
                <IconInfo name="Clock" />
                <Info>{time}</Info>
            </ContainerInfo>
            {amount && (
                <ContainerInfo>
                    <ContainerAmounts>
                        <IconInfo name="DollarSign" />
                        {has_special_amount && <IconInfo name="Plus" size={12} plus />}
                        <Info>{formatCurrency(amount)}</Info>
                    </ContainerAmounts>
                    {has_special_amount && <Badge title={t('special-shift')} />}
                </ContainerInfo>
            )}
            {available && (
                <ContainerInfo>
                    <IconInfo name="Star" />
                    <Info>{`${available} ${available > 1 ? 'vagas' : 'vaga'}`}</Info>
                </ContainerInfo>
            )}
        </Container>
    )
}

export default memo(ShiftInfo)
