import React, { memo } from 'react'

import TextButton from '#/components/button-text/button-text'

import { Container, ContainerTitles, RightText, SubTitle, Title } from './title-bar.styled'

function TitleBar({ title, buttonText, onPress, rightText, center, subTitle }) {
    return (
        <Container>
            <ContainerTitles>
                <Title center={center}>{title}</Title>
                {subTitle && <SubTitle center={center}>{subTitle}</SubTitle>}
            </ContainerTitles>
            {buttonText && <TextButton onPress={onPress}>{buttonText}</TextButton>}
            {rightText && <RightText>{rightText}</RightText>}
        </Container>
    )
}

export default memo(TitleBar)
