import { format } from 'date-fns'
import React, { memo, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { GroupAPI } from '#/api'
import { Button, ButtonText, ContentSection, List, NavLink } from '#/components'
import { DatePicker, GroupForms, Select } from '#/components/forms'
import { ItemReport } from '#/components/items'
import { useLoadGroup } from '#/hooks'
import { AlertError } from '#/modals'

import { ContainerSelect, ContianerSettings, Info, Label, MoreIcon, Padding } from './group-reports.styled'

const initialStateParams = {
    page: 1,
    limit: 50,
    user: null,
}

function reducerParams(state, newParams) {
    return {
        ...state,
        ...newParams,
    }
}

function GroupReports() {
    useLoadGroup()
    const alertErrorRef = useRef()
    const group = useSelector(state => state.group)
    const { t } = useTranslation()

    const [params, setParams] = useReducer(reducerParams, initialStateParams)

    const [minDate] = useState(new Date(2016, 1, 1))
    const [today] = useState(new Date())
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [total, setTotal] = useState(0)

    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)

    const [usersReports, setUsersReports] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    const period = useMemo(() => {
        let period = ''
        if (startDate) {
            period = format(startDate, 'dd/MM/yyyy')
        }

        if (endDate) {
            period += ' - ' + format(endDate, 'dd/MM/yyyy')
        }
        return period
    }, [startDate, endDate])

    const routerPrint = useMemo(() => {
        if (startDate && endDate && group) {
            return `/imprimir-relatorio/${group.id}/${format(startDate, 'yyyy-MM-dd')}/${format(
                endDate,
                'yyyy-MM-dd',
            )}/${params.user || 'all'}`
        }
        return null
    }, [startDate, endDate, params, group])

    const _getReports = useCallback(async () => {
        if (!group || !startDate || !endDate) {
            return
        }

        try {
            setLoading(true)

            const result = await GroupAPI.reports(
                group.id,
                format(startDate, 'yyyy-MM-dd'),
                format(endDate, 'yyyy-MM-dd'),
                params,
            )

            setUsersReports(result.items)
            setTotal(result.total)
            setParams({
                page: result.current_page,
            })
        } catch ({ message }) {
            alertErrorRef.current && alertErrorRef.current.show({ message })
        } finally {
            setLoading(false)
        }
    }, [group, startDate, endDate, params])

    const _loadMore = useCallback(async () => {
        if (total <= usersReports.length) {
            return
        }

        if (loading) {
            return
        }
        setLoading(true)
        if (params.page + 1 === currentPage) {
            return
        }

        setCurrentPage(currentPage + 1)
        try {
            const result = await GroupAPI.reports(
                group.id,
                format(startDate, 'yyyy-MM-dd'),
                format(endDate, 'yyyy-MM-dd'),
                { ...params, page: params.page + 1 },
            )
            setTotal(result.total)
            setParams({
                page: result.current_page,
            })

            setUsersReports([...usersReports, ...result.items])
        } catch (err) {
            setCurrentPage(params.page)
        } finally {
            setLoading(false)
        }
    }, [params, loading, usersReports, currentPage, group, startDate, endDate, total])

    const _loadMembers = useCallback(async () => {
        try {
            setLoading(true)
            const response = await GroupAPI.getMembers(group.id, {
                type: 'with_trashed',
                start_date: format(startDate, 'yyyy-MM-dd'),
                end_date: format(endDate, 'yyyy-MM-dd'),
            })
            const options = response.items.map(item => ({
                key: item.id,
                label: item.full_name,
                value: item.id,
            }))
            setOptions([{ key: 'all', label: 'Todos' }, ...options])

            setUsersReports([])
            setTotal(0)
            setParams({
                page: 1,
            })
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }, [group, startDate, endDate])

    useEffect(() => {
        if (group && startDate && endDate) {
            _loadMembers()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group, startDate, endDate])

    return (
        <>
            <ContentSection withoutPadding loading={loading}>
                <Padding>
                    <GroupForms>
                        <DatePicker
                            label={t('start2')}
                            onChange={date => setStartDate(date)}
                            minDate={minDate}
                            maxDate={today}
                        />
                        <DatePicker
                            label={t('end')}
                            onChange={date => setEndDate(date)}
                            minDate={minDate}
                            maxDate={today}
                        />
                    </GroupForms>
                    <ContianerSettings>
                        <ContainerSelect>
                            <Select
                                options={options}
                                disabled={!startDate || !endDate}
                                selectedKey={params.user || 'all'}
                                onChange={e => setParams({ user: e === 'all' ? null : e })}
                            />
                        </ContainerSelect>

                        <Button
                            title={t('generate-reports')}
                            dimension="auto"
                            disabled={!startDate || !endDate}
                            onClick={_getReports}
                        />
                    </ContianerSettings>

                    {!!routerPrint && (
                        <NavLink to={routerPrint} target="_blank">
                            <ButtonText iconName="Printer">{t('print')}</ButtonText>
                        </NavLink>
                    )}
                    {group && (
                        <>
                            <Label>{t('group-reports.label')}</Label>
                            <Info>{t('group-reports.institution', { v: group.institution?.name })}</Info>
                            <Info>{t('group-reports.group', { v: group.name })}</Info>
                            <Info>{t('group-reports.manager', { v: group.manager.name })}</Info>
                            {group.assist && (
                                <Info>{t('group-reports.assist', { v: group.assist.name })}</Info>
                            )}
                            <Info>
                                {t('period')}: {period}
                            </Info>
                        </>
                    )}
                </Padding>

                <List
                    data={usersReports}
                    onRenderItem={(item, i) => <ItemReport key={`item_${i}`} user={item} />}
                />
                {usersReports.length < total && <MoreIcon onClick={_loadMore} />}
            </ContentSection>
            <AlertError ref={alertErrorRef} />
        </>
    )
}

export default memo(GroupReports)
