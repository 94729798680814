import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Select } from '#/components'

import { ModalBase } from '../shared'
import { Icon } from './manager-vacancy.styled'

const OPTIONS = [
    {
        label: '0',
        key: '0',
        value: 0,
    },
    { label: '1', key: '1', value: 1 },
    { label: '2', key: '2', value: 2 },
    { label: '3', key: '3', value: 3 },
    { label: '4', key: '4', value: 4 },
    { label: '5', key: '5', value: 5 },
    { label: '6', key: '6', value: 6 },
    { label: '7', key: '7', value: 7 },
    { label: '8', key: '8', value: 8 },
    { label: '9', key: '9', value: 9 },
    { label: '10', key: '10', value: 10 },
    { label: '11', key: '11', value: 11 },
    { label: '12', key: '12', value: 12 },
    { label: '13', key: '13', value: 13 },
    { label: '14', key: '14', value: 14 },
    { label: '15', key: '15', value: 15 },
    { label: '16', key: '16', value: 16 },
    { label: '17', key: '17', value: 17 },
    { label: '18', key: '18', value: 18 },
    { label: '19', key: '19', value: 19 },
    { label: '20', key: '20', value: 20 },
]

function ManagerVacancy(props, ref) {
    const { t } = useTranslation()
    const [show, setShow] = useState(false)
    const [value, setValue] = useState(null)
    const [callback, setCallback] = useState(null)

    useImperativeHandle(ref, () => ({
        show: (amount, callback) => {
            setCallback({ function: callback })
            setValue(amount)
            setShow(true)
        },
    }))

    const _onClose = useCallback(() => {
        setShow(false)
    }, [])

    return (
        <ModalBase
            visible={show}
            onClose={_onClose}
            title={t('vacancy')}
            icon={null}
            onButtonClick={() => callback?.function(value)}
        >
            <Icon />
            <Select
                options={OPTIONS}
                label={t('manager-vacancy.label')}
                selectedKey={(value || '').toString()}
                onChange={e => setValue(e)}
            />
        </ModalBase>
    )
}

export default memo(forwardRef(ManagerVacancy))
