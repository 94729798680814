import styled from 'styled-components'

const Container = styled.div`
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`
const CustomTextArea = styled.textarea`
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.input};
    border: 0;
    border-radius: ${({ theme }) => theme.sizes.radius}px;
    font-size: 14px;
    min-height: ${({ theme }) => theme.sizes.formHeight * 3}px;
    outline-width: 0;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    resize: ${({ readOnly, disabled }) => (readOnly || disabled ? 'none' : 'vertical')};
    &::placeholder {
        color: ${({ theme }) => theme.colors.placeholder};
    }
`

export { Container, CustomTextArea }
