import React, { memo, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { GroupAPI } from '#/api'
import { ChooseColor } from '#/components'
import { ItemMenu } from '#/components/items'
import { Loading } from '#/modals'
import { AlertError, Confirmation } from '#/modals'
import { removeGroup, updateGroup } from '#/redux/reducers/group'
import { GroupChangeBehavior, RemoveGroupBehavior } from '#/subjects'
function OptionsSettings() {
    const { t } = useTranslation()
    const history = useHistory()
    const group = useSelector(state => state.group)
    const dispatch = useDispatch()

    const confirmationRef = useRef()
    const alertErrorRef = useRef()
    const [loading, setLoading] = useState(false)

    const _goTo = useCallback(
        page => {
            history.replace(`/grupo/${group.id}/configuracoes/${page}`)
        },
        [group, history],
    )

    const _changeColor = useCallback(
        async color => {
            if (!group || !color || color.includes(group.color)) {
                return
            }

            try {
                setLoading(true)
                await GroupAPI.changeColor(group.id, color)
                const updatedGroup = { ...group, color: color.replace('#', '') }
                dispatch(updateGroup(updatedGroup))
                GroupChangeBehavior.next(updatedGroup)
            } catch ({ message }) {
                if (alertErrorRef.current) {
                    alertErrorRef.current.show({ message })
                }
            } finally {
                setLoading(false)
            }
        },
        [dispatch, group, alertErrorRef],
    )

    const _leaveGroup = useCallback(async () => {
        try {
            setLoading(true)
            await GroupAPI.leave(group.id)
            history.replace('/')
            dispatch(removeGroup())
            RemoveGroupBehavior.next(group.id)
        } catch ({ message }) {
            if (alertErrorRef.current) {
                alertErrorRef.current.show({ message })
            }
        } finally {
            setLoading(false)
        }
    }, [alertErrorRef, group, history, dispatch])

    const _showConfirmationLeave = useCallback(() => {
        if (confirmationRef.current) {
            confirmationRef.current.show({
                title: 'Sair do grupo',
                message: 'Tem certeza que deseja sair do grupo?',
                icon: 'LogOut',
                onYesClick: () => {
                    _leaveGroup()
                },
            })
        }
    }, [confirmationRef, _leaveGroup])

    return (
        <>
            <ChooseColor onChange={_changeColor} value={group?.color && `#${group.color}`} />
            {group?.is_admin && (
                <>
                    <ItemMenu
                        title={t('group-settings.settings.edit')}
                        onClick={() => _goTo('informacoes')}
                    />

                    <ItemMenu
                        title={t('group-settings.settings.working-time')}
                        onClick={() => _goTo('turnos')}
                    />
                </>
            )}
            <ItemMenu title={t('exit')} onClick={_showConfirmationLeave} />
            <Loading show={loading} />
            <Confirmation ref={confirmationRef} />
            <AlertError ref={alertErrorRef} />
        </>
    )
}

export default memo(OptionsSettings)
