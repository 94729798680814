import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'

import BetaBadge from '#/components/beta-badge/beta-badge'
import Separator from '#/components/separator/separator'

import {
    ContainerTitle,
    Contianer,
    ContianerFooter,
    ContianerItems,
    Counter,
    Icon,
    ItemContainer,
    SubTitle,
    Title,
} from './menu.styled'
function Menu({ items = [], activeIndex, color, footerComponent }) {
    const history = useHistory()
    return (
        <Contianer>
            <ContianerItems>
                {items.map(({ title, subtitle, icon, counter, router, onClick, separator, beta }, i) => (
                    <React.Fragment key={`item_${i}`}>
                        {separator ? (
                            <Separator />
                        ) : (
                            <ItemContainer
                                onClick={
                                    onClick
                                        ? onClick
                                        : () => {
                                              if (router) {
                                                  history.push(router)
                                              }
                                          }
                                }
                                color={color}
                                active={activeIndex === i}
                                key={`item${i}`}
                            >
                                <Icon activeColor={color} name={icon} active={activeIndex === i} />
                                <ContainerTitle>
                                    <Title activeColor={color} active={activeIndex === i}>
                                        {title}
                                    </Title>
                                    {subtitle && (
                                        <SubTitle activeColor={color} active={activeIndex === i}>
                                            {subtitle}
                                        </SubTitle>
                                    )}
                                </ContainerTitle>
                                {counter && <Counter active={activeIndex === i}>{counter}</Counter>}
                                {beta && <BetaBadge />}
                            </ItemContainer>
                        )}
                    </React.Fragment>
                ))}
            </ContianerItems>
            <ContianerFooter>{footerComponent}</ContianerFooter>
        </Contianer>
    )
}

export default memo(Menu)
