import styled, { css } from 'styled-components'

import { Feather } from '../icons'
const Container = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    position: relative;
    user-select: none;

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: auto;
            opacity: 0.5;
        `}
    color: ${({ theme: { colors }, type }) => (type === 'danger' ? colors.danger : colors.primary)};
`
const Icon = styled(Feather)`
    margin-right: 4px;
`

const Text = styled.div`
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
`

export { Text, Container, Icon }
