import { createActions, createReducer } from 'reduxsauce'

export const {
    Types,
    Creators: { setProfile, removeProfile },
} = createActions({
    setProfile: ['user_id'],
    removeProfile: [],
})

const INITIAL_STATE = null

const set = (state = INITIAL_STATE, action) => action.user_id

const remove = (state = INITIAL_STATE, action) => null

const reducers = createReducer(INITIAL_STATE, {
    [Types.SET_PROFILE]: set,
    [Types.REMOVE_PROFILE]: remove,
})

export default reducers
