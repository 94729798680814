import { createActions, createReducer } from 'reduxsauce'

export const {
    Types,
    Creators: { showLoading },
} = createActions({
    showLoading: ['show'],
})

const INITIAL_STATE = false

const set = (state = INITIAL_STATE, action) => (action.show !== null ? action.show : state)

const reducer = createReducer(INITIAL_STATE, {
    [Types.SHOW_LOADING]: set,
})

export default reducer
