import React, { memo } from 'react'

import { Loading } from '../../modals'
import Header from '../header/header'
import { Container, Content, FloatContainer } from './page-container.styled'

function PageContainer({
    children,
    loading,
    hideHeader,
    backgroundContrast,
    floatcontainer,
    overflowYHidden,
}) {
    return (
        <>
            <Container
                backgroundContrast={backgroundContrast}
                className="page-container"
                overflowYHidden={overflowYHidden}
            >
                {!hideHeader && <Header className="header" />}
                <Content className="content-page" hideHeader={hideHeader} overflowYHidden={overflowYHidden}>
                    {children}
                </Content>
                {floatcontainer && <FloatContainer className="footer">{floatcontainer}</FloatContainer>}
            </Container>
            <Loading show={loading} />
        </>
    )
}

export default memo(PageContainer)
