import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AccountsAPI, GroupAPI } from '#/api'
import { ItemMember, Search } from '#/components'
import { useLoadGroup } from '#/hooks'
import { Alert, AlertError, Confirmation, InviteMember } from '#/modals'
import { updateGroup } from '#/redux/reducers/group'

import { ContentContainer } from '../shared'

function AddMember() {
    useLoadGroup()

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const confirmationRef = useRef()
    const inviteMemberRef = useRef()
    const alertErrorRef = useRef()
    const alertRef = useRef()
    const searchRef = useRef()

    const group_id = useSelector(state => state.group?.id)
    const user_id = useSelector(state => state.user?.id)
    const total_users = useSelector(state => state.group?.total_users)

    const [loading, setLoading] = useState(false)

    const [members, setMembers] = useState([])
    const [isSearching, setIsSearching] = useState(false)

    const listMembers = useMemo(() => {
        return members.sort((a, b) => {
            if (a.role === 'manager' || a.role === 'assist') {
                if (a.role === 'assist' && b.role === 'manager') {
                    return 1
                }
                return -1
            }
            return 0
        })
    }, [members])

    const _loadMembers = useCallback(async () => {
        try {
            setLoading(true)
            const response = await GroupAPI.getMembers(group_id)
            setMembers(response.items)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }, [group_id])

    const _searchMember = useCallback(async search => {
        setLoading(true)
        try {
            const result = await AccountsAPI.listAccounts({ search })

            setMembers(result.items)
        } catch (err) {
            setMembers([])
        } finally {
            setLoading(false)
        }
    }, [])

    const _onSearch = useCallback(
        search => {
            setIsSearching(!!search)
            if (search) {
                _searchMember(search)
            } else {
                if (group_id) {
                    _loadMembers()
                }
            }
        },
        [_searchMember, group_id, _loadMembers],
    )

    const _addMember = useCallback(
        async member => {
            try {
                setLoading(true)
                await GroupAPI.addMembers([group_id], member.id)
                if (alertRef.current) {
                    alertRef.current.show({
                        title: 'Membro adicionado',
                        message: 'Membro adicionado com sucesso!',
                    })
                }

                if (searchRef.current) {
                    searchRef.current.clear()
                }
                dispatch(updateGroup({ total_users: (total_users || 0) + 1 }))
            } catch ({ message }) {
                if (alertErrorRef.current) {
                    alertErrorRef.current.show({ message })
                }
            } finally {
                setLoading(false)
            }
        },
        [group_id, searchRef, dispatch, total_users],
    )

    const _removeMember = useCallback(
        async member => {
            try {
                setLoading(true)
                await GroupAPI.removeMember(group_id, member.id)

                setMembers(members.filter(item => item.id !== member.id))

                if (alertRef.current) {
                    alertRef.current.show({
                        title: 'Excluir membro',
                        message: 'Membro Excluído com sucesso!',
                    })
                }
                dispatch(updateGroup({ total_users: (total_users || 0) - 1 }))
            } catch ({ message }) {
                if (alertErrorRef.current) {
                    alertErrorRef.current.show({ message })
                }
            } finally {
                setLoading(false)
            }
        },
        [alertErrorRef, alertRef, members, group_id, dispatch, total_users],
    )

    const _onInvide = useCallback(
        member => {
            if (confirmationRef.current) {
                confirmationRef.current.show({
                    title: 'Adicionar membro',
                    message: `Tem certeza que deseja adicionar ${member.name} ao grupo?`,
                    icon: 'UserPlus',
                    onYesClick: () => {
                        _addMember(member)
                    },
                })
            }
        },
        [confirmationRef, _addMember],
    )

    const _onRemove = useCallback(
        member => {
            if (confirmationRef.current) {
                confirmationRef.current.show({
                    title: 'Excluir membro',
                    message: `Tem certeza que deseja excluir ${member.name} do grupo?`,
                    icon: 'Trash2',
                    onYesClick: () => {
                        _removeMember(member)
                    },
                })
            }
        },
        [confirmationRef, _removeMember],
    )
    const _openInviteMember = useCallback(() => {
        if (inviteMemberRef.current) {
            inviteMemberRef.current.show({ group_id })
        }
    }, [inviteMemberRef, group_id])

    useEffect(() => {
        if (group_id) {
            _loadMembers()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group_id])

    return (
        <>
            <ContentContainer
                loading={loading}
                title={t('add-member.title')}
                warning={t('add-member.warning')}
                notNow
                onNextClick={() => history.push(`/grupo/${group_id}`)}
                onSkipClick={() => history.push(`/grupo/${group_id}`)}
            >
                <Search
                    ref={searchRef}
                    onSearch={_onSearch}
                    callActionText={t('invite-member')}
                    callActionClick={_openInviteMember}
                />

                {listMembers.map(item => (
                    <ItemMember
                        key={`member_${item.id}`}
                        {...item}
                        userId={item.id}
                        isRemovable={user_id !== item.id && !isSearching}
                        isInvide={isSearching}
                        onInvide={() => _onInvide(item)}
                        onRemove={() => _onRemove(item)}
                    />
                ))}
            </ContentContainer>
            <Confirmation ref={confirmationRef} />
            <AlertError ref={alertErrorRef} />
            <Alert ref={alertRef} />
            <InviteMember ref={inviteMemberRef} onSuccess={_loadMembers} />
        </>
    )
}

export default memo(AddMember)
