const validateUserData = user => {
    if (!user) {
        return null
    }

    const pendingStages = user?.pending_stages || []
    const socialAccounts = user?.social_accounts || []

    if (pendingStages.includes('email')) {
        return '/email-pessoal'
    }

    if (pendingStages.includes('password') && socialAccounts.length === 0) {
        return '/adicionar-senha'
    }

    if (pendingStages.includes('name') || pendingStages.includes('phone')) {
        return '/informacoes-pessoais'
    }

    if (pendingStages.includes('profession')) {
        return 'informacoes-profissionais'
    }

    return null
}

export default validateUserData
