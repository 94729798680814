import { parseISO } from 'date-fns'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import localePT from 'date-fns/locale/pt-BR'
function dateBrToUe(dateStr) {
    if (dateStr) {
        const [day, month, year] = dateStr.split('/')
        return `${year}-${month}-${day}`
    }
    return null
}

function dateUeToBr(dateStr) {
    if (dateStr) {
        const [year, month, day] = dateStr.split('-')
        return `${day}/${month}/${year}`
    }
    return null
}

function getDistanceWord(date) {
    if (!date) {
        return null
    }
    const distance = formatDistanceToNowStrict(parseISO(date), {
        locale: localePT,
    })

    return distance.trim()
}

function minutesToHours(data) {
    if (!data) {
        return '0h'
    }
    var minutes = data % 60
    var hours = (data - minutes) / 60

    return hours + 'h' + (minutes < 10 ? `0${minutes}` : minutes)
}

export { dateBrToUe, dateUeToBr, getDistanceWord, minutesToHours }
