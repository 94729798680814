import { Axios } from './axios-config'

const login = ({ login, password }) => {
    const body = { login, password }

    return Axios().post('auth/login', body)
}

const loginSocial = (provider, token) => {
    const body = { token }

    return Axios().post(`auth/social/${provider}`, body)
}

const unLinkSocial = provider => {
    return Axios().delete(`auth/social/${provider}`)
}

const recoverOptions = ({ email }) => {
    const body = { email }

    return Axios().post('auth/password/recovery/options', body)
}

const recoverPassword = ({ email, channel }) => {
    const body = { email, channel }

    return Axios().post('auth/password/recovery', body)
}

const resetPassword = ({ email, password, password_confirmation, token }) => {
    const body = {
        email,
        password,
        password_confirmation,
        token,
    }

    return Axios().post('auth/password/reset', body)
}

const registerPassword = data => {
    return Axios().post('auth/register/complete', data)
}

const registerUser = data => {
    return Axios().post('auth/register?validate_type=email', data)
}

const activateAccount = data => {
    return Axios().post('auth/register/activate?validate_type=email', data)
}

const resentToken = data => {
    return Axios().post('auth/register/resend?validate_type=email', data)
}

const refreshToken = token => {
    if (token) {
        Axios().interceptors.request.use(config => {
            config.headers.post.Authorization = 'Bearer ' + token
            return config
        })
    }

    return Axios().post('auth/refresh')
}

export default {
    login,
    loginSocial,
    recoverPassword,
    recoverOptions,
    resetPassword,
    registerUser,
    registerPassword,
    activateAccount,
    resentToken,
    refreshToken,
    unLinkSocial,
}
