import classNames from 'classnames'
import React, { memo } from 'react'

import { Container } from './link.styled'

function Link({ children, type, onClick, className }) {
    return (
        <Container onClick={onClick} className={classNames('link', className)} type={type}>
            {children}
        </Container>
    )
}

export default memo(Link)
