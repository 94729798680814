import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { AppAPI, AuthAPI } from '#/api'
import oopsSource from '#/assets/images/oops.png'
import { Button, CheckTerms } from '#/components'
import { checkAccount } from '#/redux/thunk'

import AlertError from '../alert-error/alert-error'
import Loading from '../loading/loading'
import ModalTerms from '../modal-terms/modal-terms'
import { ModalBase } from '../shared'
import { Bold, Container, Image, Message, Title } from './confirm-register.styled'
function ConfirmRegister({ onClose }, ref) {
    const [show, setShow] = useState(false)
    const dispatch = useDispatch()
    const alertErrorRef = useRef()
    const modalTermsRef = useRef()

    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState(null)
    const [provider, setProvider] = useState(null)
    const [email, setEmail] = useState(null)
    const [checkTerms, setCheckTerms] = useState(false)

    const _showAlertErro = useCallback(
        message => {
            try {
                alertErrorRef.current && alertErrorRef.current.show({ message })
            } catch (error) {}
        },
        [alertErrorRef],
    )

    const _createAccount = useCallback(async () => {
        setLoading(true)
        try {
            const user = await AuthAPI.loginSocial(provider, token)
            dispatch(checkAccount(user))
        } catch ({ message }) {
            _showAlertErro(message)
        }
        setLoading(false)
    }, [token, provider, dispatch, _showAlertErro])

    useImperativeHandle(ref, () => ({
        show: (props = {}) => {
            setShow(true)
            console.log('props', props)
            const { token, provider, email } = props
            setToken(token)
            setProvider(provider)
            setEmail(email)
        },
    }))

    const _onClose = useCallback(
        (refresh = false) => {
            setShow(false)
            setTimeout(() => {
                setToken(null)
                setProvider(null)
                setEmail(null)
                setCheckTerms(false)
            }, 300)
            if (onClose) {
                onClose(refresh)
            }
        },
        [onClose],
    )

    const _showTerms = useCallback(async () => {
        try {
            setLoading(true)

            const { content } = await AppAPI.getTermsUse()

            modalTermsRef.current && modalTermsRef.current.show({ message: content })
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }, [modalTermsRef])

    if (!show) {
        return null
    }
    return (
        <>
            <ModalBase
                withoutPadding
                showButton={false}
                title={t('create-new-account')}
                icon={null}
                visible={show}
                headerPrimary
                onClose={_onClose}
            >
                <Container>
                    {email ? (
                        <Title>
                            <Bold>Oops! </Bold>
                            {t('modal.confirm-register.title')}
                        </Title>
                    ) : (
                        <Title>{t('modal.confirm-register.title-no-email')}</Title>
                    )}
                    <Image src={oopsSource} />
                    {email ? (
                        <Message>
                            <Bold>{` ${email} `}</Bold>
                            {t('modal.confirm-register.description')}
                        </Message>
                    ) : (
                        <Message>{t('modal.confirm-register.description-no-email')}</Message>
                    )}
                    <CheckTerms
                        text={
                            <>
                                {t('modal.confirm-register.terms')}
                                <Bold click onClick={_showTerms}>
                                    {t('terms')}.
                                </Bold>
                            </>
                        }
                        checked={checkTerms}
                        onChange={e => setCheckTerms(e)}
                    />
                    <Button disabled={!checkTerms} title={t('create-new-account')} onClick={_createAccount} />
                    <Loading show={loading} />
                </Container>
            </ModalBase>
            <AlertError ref={alertErrorRef} />
            <ModalTerms ref={modalTermsRef} />
        </>
    )
}

export default memo(forwardRef(ConfirmRegister))
