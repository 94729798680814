import React, { memo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, ContentSection, Warning } from '#/components'

import {
    BackIcon,
    ContainerText,
    ContainerTitle,
    ContentCard,
    Description,
    Icon,
    Title,
} from './header-opportunity.styled'

function ContentOpportunity({
    loading,
    overflowHidden,
    title,
    warning,
    children,
    arrowBack,
    withoutPadding,
    description,
    icon,
    rightButtonText,
    rightButtonClick,
}) {
    const history = useHistory()

    const _goBack = useCallback(() => {
        history.goBack()
    }, [history])

    return (
        <ContentSection loading={loading} overflowHidden={overflowHidden} withoutPadding>
            <ContainerTitle>
                {!!arrowBack && <BackIcon onClick={_goBack} />}
                {!!icon && <Icon name={icon} />}
                <ContainerText>
                    {!!title && <Title>{title}</Title>}
                    {!!description && <Description>{description}</Description>}
                </ContainerText>
                {!!rightButtonText && (
                    <Button onClick={rightButtonClick} title={rightButtonText} dimension="auto" />
                )}
            </ContainerTitle>
            <ContentCard withoutPadding={withoutPadding}>
                {!!warning && <Warning>{warning}</Warning>}
                {children}
            </ContentCard>
        </ContentSection>
    )
}

export default memo(ContentOpportunity)
