import React, { memo } from 'react'

import { ContentSection, Warning } from '#/components'

import { ContainerTitle, ContentCard, Icon, Title } from './settings-content.styled'

function GroupContent({ loading, overflowHidden, title, warning, children, iconName, withoutPadding }) {
    return (
        <ContentSection loading={loading} overflowHidden={overflowHidden} withoutPadding={withoutPadding}>
            <ContentCard>
                <ContainerTitle>
                    {!!iconName && <Icon name={iconName} />}
                    {!!title && <Title>{title}</Title>}
                </ContainerTitle>
                {!!warning && <Warning>{warning}</Warning>}
                {children}
            </ContentCard>
        </ContentSection>
    )
}

export default memo(GroupContent)
