import styled from 'styled-components'

const ContentCard = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    padding: ${({ withoutPadding }) => (withoutPadding ? 0 : 10)}px;
`

export { ContentCard }
