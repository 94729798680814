import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalBase } from '../shared'
import { ContainerOption, Option } from './menu.styled'

function Menu({ onClose }, ref) {
    const [show, setShow] = useState(false)
    const [title, setTitle] = useState(null)
    const [options, setOptions] = useState([])
    const { t } = useTranslation()

    useImperativeHandle(ref, () => ({
        show: (props = {}) => {
            const { title, options } = props

            setShow(true)
            setOptions(options || [])
            setTitle(title != null ? title : t('warning'))
        },
    }))

    const _onClose = useCallback(() => {
        setShow(false)
        setTimeout(() => {
            setTitle(null)
            setOptions([])
        }, 300)
        if (onClose) {
            onClose()
        }
    }, [onClose])

    const _onClickOption = useCallback(
        callback => {
            _onClose()
            if (callback) {
                callback()
            }
        },
        [_onClose],
    )

    return (
        <ModalBase
            title={title}
            visible={show}
            showButton={false}
            icon={null}
            onClose={_onClose}
            withoutPadding
        >
            <ContainerOption>
                {options.map((item, i) => (
                    <Option key={`option_${i}`} onClick={() => _onClickOption(item.onClick)}>
                        {item.title}
                    </Option>
                ))}
            </ContainerOption>
        </ModalBase>
    )
}
export default memo(forwardRef(Menu))
