import styled from 'styled-components'

const Container = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    .button-text {
        align-self: flex-end;
    }
    margin-bottom: 50px;
`
const ContainerButton = styled.div`
    display: flex;
    justify-content: flex-end;
    .button-text {
        margin-left: 16px;
    }
`

export { Container, ContainerButton }
