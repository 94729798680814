import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorMessage, Label } from '../shared'
import { Container, ContainerTab, Tab } from './gender.styled'

function Gender({ errorMessage, onChange, value }) {
    const { t } = useTranslation()
    const [tabActive, setTabActive] = useState(null)

    useEffect(() => {
        setTabActive(value)
    }, [value])

    useEffect(() => {
        if (onChange) {
            onChange(tabActive)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabActive])

    return (
        <Container>
            <Label htmlFor="">{t('gender')}:</Label>
            <ContainerTab>
                <Tab active={tabActive === 1} onClick={() => setTabActive(1)}>
                    {t('male')}
                </Tab>
                <Tab active={tabActive === 2} onClick={() => setTabActive(2)}>
                    {t('feminine')}
                </Tab>
            </ContainerTab>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Container>
    )
}

export default memo(Gender)
