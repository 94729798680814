import styled from 'styled-components'

const Container = styled.div`
    background-color: ${({ color }) => (color ? color : 'transparent')};
    padding: 10px;
    display: flex;
    @media (max-width: 992px) {
        flex-direction: column;
    }
`

const Title = styled.div`
    color: ${({ hasColor, theme }) => (hasColor ? '#fff' : theme.colors.text)};
    font-weight: bold;
    text-transform: uppercase;
`

const Institution = styled.div`
    color: ${({ hasColor, theme }) => (hasColor ? '#fff' : theme.colors.text)};
    text-transform: uppercase;
`

const Category = styled.div`
    color: ${({ hasColor, theme }) => (hasColor ? '#fff' : theme.colors.textLight)};
    font-size: 12px;
`

const ContainerInfo = styled.div`
    flex: 1;
    padding-left: 10px;
    @media (max-width: 992px) {
        padding-left: 16px;
    }
`

const Circle = styled.div`
    background-color: ${({ hasColor, theme }) => (hasColor ? '#fff' : theme.colors.text)};
    width: 10px;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 5px;
    @media (max-width: 992px) {
        position: absolute;
    }
`

const LeftComponent = styled.div`
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 992px) {
        margin-top: 10px;
    }
`

export { Container, Title, Institution, Category, ContainerInfo, Circle, LeftComponent }
