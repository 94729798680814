import styled from 'styled-components'

import { Feather } from '#/components'
const Container = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    .button-text {
        align-self: flex-end;
    }
`

const ContentCard = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
`

const TopBarCard = styled.div`
    height: 90px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
    margin-bottom: -50px;
`

const CallName = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 18px;
    padding: 0 10px;
`
const Name = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 14px;
    padding: 0 10px;
`
const Description = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 14px;
    padding: 0 10px;
`
const Council = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 14px;
    padding: 0 10px;
`

const Row = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`

const Title = styled.div`
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    flex: 1;
`

const Icon = styled(Feather).attrs({ size: 20 })`
    color: ${({ theme }) => theme.colors.primary};
`

const ContainerInfo = styled.div`
    display: flex;
    align-items: center;
    margin: 5px 0px;
`

const Info = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    margin-left: 8px;
    flex: 1;
`

const ContainerPicture = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export {
    CallName,
    Container,
    ContainerInfo,
    ContainerPicture,
    ContentCard,
    Council,
    Description,
    Icon,
    Info,
    Name,
    Row,
    Title,
    TopBarCard,
}
