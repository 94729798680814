import styled from 'styled-components'

import { Feather } from '#/components'
const Container = styled.div`
    display: flex;
    flex: 1;
    .section-container {
        ::-webkit-scrollbar {
            width: 0px; /* Remove scrollbar space */
            background: transparent; /* Optional: just make scrollbar invisible */
        }
    }
    .block-container {
        max-width: 390px;
    }
`
const ContainerCard = styled.div`
    display: flex;
    .card-store {
        flex: 1;
        height: fit-content;
    }
`
const ContainerMySchedule = styled.div`
    max-width: 390px;
    margin-left: 16px;
`

const ContentCard = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 16px;
`

const ContainerIcon = styled.div`
    border: 3px solid ${({ theme }) => theme.colors.text};
    width: 80px;
    height: 80px;
    border-radius: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
`

const Icon = styled(Feather).attrs({ size: 60 })`
    color: ${({ theme }) => theme.colors.text};
    height: 100%;
`

const TitleCard = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0px;
`

const ContainerCallToAction = styled.div`
    width: 100%;
    padding: 16px 8px;
    background-color: ${({ theme }) => theme.colors.primary + '20'};
    overflow: hidden;
    border-radius: 8px;
    margin-top: 20px;
`

const Message = styled.div`
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
    font-size: 16px;
`

const ContainerStores = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 16px;

    .store-icon {
        margin-right: 0.625rem;
        width: 1.8rem;
        line-height: 1;
    }
    .store-link {
        background-color: ${({ theme }) => theme.colors.primary + '20'};
        color: ${({ theme }) => theme.colors.text};
        padding: 0.5rem 0.8rem;
        display: -webkit-inline-box;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        width: fit-content;
        margin: 0 2%;
        border-radius: 0.625rem;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        text-decoration: none;
        text-align: left;
    }

    .store-head {
        font-size: 0.625rem;
        margin-bottom: 0;
        line-height: 1.5;
    }

    .store-title {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1;
    }
`

const Image = styled.img`
    margin-top: 20px;
    width: 390px;
    height: 390px;
    object-fit: contain;
`

export {
    Container,
    ContainerCallToAction,
    ContainerCard,
    ContainerIcon,
    ContainerMySchedule,
    ContainerStores,
    ContentCard,
    Icon,
    Message,
    TitleCard,
    Image,
}
