import classNames from 'classnames'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { Box, Container, Icon, Label } from './check-box.styled'
const CheckBoxContext = createContext(null)

const CheckBox = ({ label, checked, className, value, onClick, square }) => {
    const groupContext = useContext(CheckBoxContext)
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        if (groupContext) {
            if (value && checked) {
                if (groupContext.groupValue !== value) {
                    groupContext.setValue(value)
                }
            }
        } else {
            setIsActive(checked)
        }
    }, [checked, groupContext, value])

    useEffect(() => {
        if (groupContext && groupContext.groupValue !== null) {
            setIsActive(groupContext.groupValue === value)
        }
    }, [groupContext, value])

    const _onClick = useCallback(() => {
        if (groupContext) {
            if (groupContext.groupValue !== value) {
                groupContext.setValue(value)
            }
        }
        if (onClick) {
            onClick()
        }
    }, [groupContext, value, onClick])

    return (
        <Container className={classNames('check-box', className)} onClick={_onClick}>
            <Box square={square} active={isActive}>
                {!!isActive && <Icon />}
            </Box>
            {!!label && <Label className="label">{label}</Label>}
        </Container>
    )
}

const CheckBoxContainer = ({ children, onChange, selectValue }) => {
    const [groupValue, setGroupValue] = useState(null)

    const _setValue = useCallback(
        value => {
            setGroupValue(value)

            if (onChange) {
                onChange(value)
            }
        },
        [onChange],
    )

    useEffect(() => {
        _setValue(selectValue)
    }, [selectValue, _setValue])

    return (
        <CheckBoxContext.Provider value={{ groupValue, setValue: _setValue }}>
            {children}
        </CheckBoxContext.Provider>
    )
}

export { CheckBox, CheckBoxContainer }
