import React, { memo } from 'react'
import Lottie from 'react-lottie'

import { ButtonText, SectionControl, Stepper } from '#/components'

import { ButtonContainer, ContainerControl, Message, Title } from './create-group-container.styled'

function SectionCreateGroup({
    stepsComplete = 0,
    children,
    animation,
    titleDescription,
    description,
    buttonText,
    onButtonClick,
}) {
    return (
        <SectionControl
            contentControl={
                <ContainerControl>
                    {!!animation && (
                        <Lottie
                            options={{
                                loop: false,
                                autoplay: true,
                                animationData: animation,
                            }}
                        />
                    )}
                    {!!titleDescription && <Title>{titleDescription}</Title>}
                    {!!description && <Message>{description}</Message>}
                    <Stepper stepsComplete={stepsComplete + 1} steps={3} />
                    <ButtonContainer>
                        {buttonText && <ButtonText onClick={onButtonClick}>{buttonText}</ButtonText>}
                    </ButtonContainer>
                </ContainerControl>
            }
        >
            {children}
        </SectionControl>
    )
}

export default memo(SectionCreateGroup)
