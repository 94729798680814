import { Axios } from './axios-config'

const view = group_id => {
    return Axios().get(`groups/${group_id}`)
}

const listGroups = data => {
    const params = {
        page: data.page,
        limit: data.limit,
        search: data.search || null,
        role: data.role,
        type: data.type,
    }

    return Axios().get('groups', { params })
}

const listByAdmin = (user_id, { page = 1, limit = 20, search = null, role }) => {
    const params = {
        page,
        limit,
        role,
        search: search === '' ? null : search,
    }

    return Axios().get(`groups/check-member/${user_id}`, { params })
}

const getGroupCalendar = (groupId, date) => {
    return Axios().get(`groups/${groupId}/calendar/monthly/${date}`)
}

const getGroupNotices = (groupId, page) => {
    return Axios().get(`groups/${groupId}/notices?page=${page}`)
}

const getGroupNoticeInternal = (groupId, noticeId) => {
    return Axios().get(`groups/${groupId}/notices/${noticeId}`)
}

const getGroupNoticeStatistics = (groupId, noticeId) => {
    return Axios().get(`groups/${groupId}/notices/${noticeId}/statistics`)
}

const deleteNotice = (groupId, noticeId) => {
    return Axios().delete(`groups/${groupId}/notices/${noticeId}`)
}

const create = data => {
    var body = new FormData()

    Object.keys(data).forEach(key => {
        const item = data[key]
        if (['institution', 'category', 'color'].indexOf(key) === -1) {
            body.append(key, item)
        }
    })

    if (data.category) {
        body.append('category', data.category.id)
    }
    if (data.institution) {
        body.append('institution_id', data.institution.id)
    }
    if (data.color) {
        body.append('color', (data.color || '').replace('#', ''))
    }

    return Axios().post('groups', body, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
}

const getMembers = (group_id, params) => {
    return Axios().get(`groups/${group_id}/members`, { params })
}

const addMembers = (groups, member_id) => {
    const body = {
        user_id: member_id,
        groups,
    }

    return Axios().post('groups/members', body)
}

const inviteMember = (group_id, body) => {
    return Axios().post(`groups/${group_id}/invitations`, body)
}

const removeMember = (group_id, user_id) => {
    return Axios().delete(`groups/${group_id}/members/${user_id}`)
}

const setManager = (group_id, body) => {
    return Axios().patch(`groups/${group_id}/manager`, body)
}

const addAssist = (group_id, body) => {
    return Axios().patch(`groups/${group_id}/assist`, body)
}

const removeAssist = group_id => {
    return Axios().delete(`groups/${group_id}/assist`)
}

const createAnnouncement = body => {
    const data = new FormData()

    data.append('title', body.title)
    data.append('content', body.content)

    if (body.attachment) {
        data.append('attachment', body.attachment)
    }

    for (let i = 0; i < body.selectedGroups.length; i++) {
        data.append(`groups[${i}]`, body.selectedGroups[i])
    }

    return Axios().post('groups/notices', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
}

const getGroupHistorySettings = group_id => {
    return Axios().get(`groups/${group_id}/historic/settings`, {
        headers: { Accept: 'application/json' },
    })
}

const getGroupHistory = (group_id, data) => {
    const params = {
        page: data.page,
        limit: data.limit,
        type: data.type,
        month: data.month,
        user_id: data.member === 'all' ? null : data.member,
    }

    return Axios().get(`groups/${group_id}/historic`, {
        headers: { Accept: 'application/json' },
        params,
    })
}

const changeColor = (group_id, color) => {
    let body = {
        color: color.replace('#', ''),
    }

    return Axios().patch(`groups/${group_id}/settings/change-color`, body, {
        headers: { Accept: 'application/json' },
    })
}

const update = (group_id, data) => {
    let body = {
        name: data.name,
        category: data.category.id,
    }

    return Axios().patch(`groups/${group_id}`, body, {
        headers: { Accept: 'application/json' },
    })
}

const leave = group_id => {
    return Axios().post(
        `groups/${group_id}/exit`,
        {},
        {
            headers: { Accept: 'application/json' },
        },
    )
}

const listWorkingTimes = group_id => {
    return Axios().get(`groups/${group_id}/working-times`)
}

const createWorkingTimes = (group_id, data) => {
    var body = new FormData()

    body.append('label', (data.label || '').trim().toUpperCase())
    body.append('start_hour', `${data.start_hour}:00`)
    body.append('end_hour', `${data.end_hour}:00`)

    return Axios().post(`groups/${group_id}/working-times`, body, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
}

const removeWorkingTimes = (group_id, id) => {
    return Axios().delete(`groups/${group_id}/working-times/${id}`, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
}

const updateWorkingTimes = (group_id, id, data) => {
    let body = { label: (data.label || '').trim().toUpperCase() }

    return Axios().patch(`groups/${group_id}/working-times/${id}`, body, {
        headers: { 'Content-Type': 'application/json' },
    })
}

const importWorkingTimes = (group_id, origin_group_id) => {
    let body = { group_id: origin_group_id }

    return Axios().post(`groups/${group_id}/import-working-times`, body, {
        headers: { 'Content-Type': 'application/json' },
    })
}

const getShiftDaily = (group_id, date) => {
    return Axios().get(`groups/${group_id}/calendar/daily/${date}`)
}

const getVacancy = (group_id, vacancy_id) => {
    return Axios().get(`groups/${group_id}/vacancies/${vacancy_id}`)
}

const reports = (group_id, start_date, end_date, params) => {
    return Axios().get(`groups/${group_id}/report/${start_date}/${end_date}`, { params })
}
const reportsPrint = (group_id, start_date, end_date, params) => {
    return Axios().get(`groups/${group_id}/report/${start_date}/${end_date}/print`, { params })
}

const listRoles = group_id => {
    return Axios().get(`groups/${group_id}/roles`)
}

const showRole = (group_id, role_id) => {
    return Axios().get(`groups/${group_id}/roles/${role_id}`)
}

const addMemberRole = (group_id, role_id, user_id) => {
    let body = {
        user_id: user_id,
    }

    return Axios().post(`groups/${group_id}/roles/${role_id}/members`, body)
}

const removeMemberRole = (group_id, role_id, user_id) => {
    return Axios().delete(`groups/${group_id}/roles/${role_id}/members/${user_id}`)
}

export default {
    listGroups,
    getGroupCalendar,
    getGroupNotices,
    getGroupNoticeInternal,
    getGroupNoticeStatistics,
    deleteNotice,
    create,
    update,
    setManager,
    addAssist,
    leave,
    removeAssist,
    getVacancy,
    reports,
    getMembers,
    addMembers,
    inviteMember,
    getShiftDaily,
    removeMember,
    createAnnouncement,
    view,
    getGroupHistorySettings,
    getGroupHistory,
    changeColor,
    listByAdmin,
    reportsPrint,
    listWorkingTimes,
    createWorkingTimes,
    removeWorkingTimes,
    updateWorkingTimes,
    importWorkingTimes,
    listRoles,
    showRole,
    addMemberRole,
    removeMemberRole,
}
