import React, { memo } from 'react'

import Card from '#/components/card/card'

import SectionContainer from '../section-container/section-container'
import { ContentCard } from './content-section.styled'

function ContentSection({ loading, overflowHidden, children, withoutPadding }) {
    return (
        <SectionContainer loading={loading}>
            <Card className="card-container" overflowHidden={overflowHidden} withoutPadding>
                <ContentCard withoutPadding={withoutPadding}>{children}</ContentCard>
            </Card>
        </SectionContainer>
    )
}

export default memo(ContentSection)
