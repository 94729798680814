import styled, { css } from 'styled-components'

import { Feather } from '../icons'

const Container = styled.div`
    display: flex;
    margin: 5px 0px;
    ${({ horizontal, flexStart }) =>
        horizontal
            ? css`
                  flex-direction: row;
                  justify-content: ${flexStart ? 'flex-start' : 'space-between'};
                  flex-wrap: wrap;

                  ${ContainerInfo} {
                      margin-left: 5px;
                      :first-child {
                          margin-left: 0px;
                      }
                  }
              `
            : css`
                  flex-direction: column;
                  ${ContainerInfo} {
                      margin: 5px 0px;
                  }
              `};
`

const ContainerInfo = styled.div`
    display: flex;
    width: fit-content;
    align-items: center;
    margin: 5px;
`

const Icon = styled(Feather).attrs({ size: 20 })`
    color: ${({ theme }) => theme.colors.primary};
    height: 100%;
`
const Label = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    margin-left: 4px;
`

export { Container, Icon, ContainerInfo, Label }
