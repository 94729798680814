import React, { memo, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, InputCode } from '../../../components'
import { RecoveryContext } from '../shared'
import { Message, Title } from './send-token.styled'
const SendToken = () => {
    const { t } = useTranslation()

    const { changeProps, recoveryProps } = useContext(RecoveryContext)
    const [code, setCode] = useState(null)
    const [valid, setValid] = useState(false)
    return (
        <>
            <div>
                <Title>{getTitle(recoveryProps.channel, t)}</Title>
                <Message>{getMessage(recoveryProps.channel, t)}</Message>
                <InputCode
                    label={t('input.code.label')}
                    onChange={e => setValid((e || '').length === 6)}
                    onComplete={code => setCode(code)}
                />
                <Button
                    disabled={!valid}
                    title={t('recovery.code.reset-password')}
                    onClick={() => changeProps({ code, step: 4 })}
                />
            </div>
        </>
    )
}

function getTitle(channel, t) {
    if (channel === 'email') {
        return t('recovery.code.verify.email')
    } else {
        return t('recovery.code.verify.phone')
    }
}
function getMessage(channel, t) {
    if (channel === 'email') {
        return t('recovery.code.info.email')
    } else {
        return t('recovery.code.info.phone')
    }
}

export default memo(SendToken)
