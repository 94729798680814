import React, {
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useImperativeHandle,
    useReducer,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { OpportunityAPI } from '#/api'
import { List } from '#/components'
import { ItemMember } from '#/components/items'
import { setProfile } from '#/redux/reducers/profile'

import Loading from '../loading/loading'
import { ModalBase } from '../shared'
import { Container, ContainerItem } from './interested-users.styled'

const initialStateParams = {
    page: 1,
    limit: 20,
}

function reducerParams(state, newParams) {
    return {
        ...state,
        ...newParams,
    }
}

function InterestedUsers({ onClose }, ref) {
    const [show, setShow] = useState(false)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [interesteds, setInteresteds] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingMore, setLoadingMore] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [params, setParams] = useReducer(reducerParams, initialStateParams)

    const [opportunityId, setOpportunityId] = useState(null)

    useImperativeHandle(ref, () => ({
        show: (props = {}) => {
            const { opportunity_id } = props
            setOpportunityId(opportunity_id)
            setShow(true)
        },
    }))

    const _onClose = useCallback(() => {
        setShow(false)
        setTimeout(() => {
            setInteresteds([])
            setOpportunityId(null)
        }, 300)
        if (onClose) {
            onClose()
        }
    }, [onClose])

    const _listInteresteds = async () => {
        setLoading(true)
        try {
            const result = await OpportunityAPI.listInterests(opportunityId, initialStateParams)
            setInteresteds(result.items)
            setTotal(result.total)
        } catch (error) {}
        setLoading(false)
    }

    const _pagination = async () => {
        if (total <= interesteds.length) {
            return
        }

        if (loadingMore) {
            return
        }

        if (params.page + 1 <= currentPage) {
            return
        }
        setLoadingMore(true)
        setCurrentPage(currentPage + 1)
        try {
            const result = await OpportunityAPI.listInterests(opportunityId, { page: params.page + 1 })
            setParams({
                page: result.current_page,
            })
            setCurrentPage(result.current_page)
            setInteresteds([...interesteds, ...result.items])
        } catch (err) {
            setCurrentPage(params.page)
        } finally {
            setLoadingMore(false)
        }
    }

    const _onClick = useCallback(
        userId => {
            dispatch(setProfile(userId))
        },
        [dispatch],
    )

    useEffect(() => {
        if (opportunityId) {
            _listInteresteds()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opportunityId])

    return (
        <ModalBase
            title={t('interest')}
            visible={show}
            showButton={false}
            icon={null}
            onClose={_onClose}
            withoutPadding
            headerPrimary
        >
            <Container>
                <List
                    data={interesteds}
                    onRenderItem={({ user }, i) => (
                        <ContainerItem key={`user_${i}`} onClick={() => _onClick(user.id)}>
                            <ItemMember {...user} clickAllArea />
                        </ContainerItem>
                    )}
                    loadMore={interesteds.length < params.total}
                    onLoadMoreClick={_pagination}
                    isLoading={loadingMore}
                />
            </Container>
            <Loading show={loading} />
        </ModalBase>
    )
}
export default memo(forwardRef(InterestedUsers))
