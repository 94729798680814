import styled from 'styled-components'

import sourceWhatsapp from '#/assets/images/whatsapp.png'
import { Feather } from '#/components/icons'
const Container = styled.div`
    position: relative;
    min-height: 200px;
`
const Content = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    .button-text {
        align-self: flex-end;
    }
`

const ContentCard = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
`

const TopBarCard = styled.div`
    height: 90px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
    margin-bottom: -50px;
`

const CallName = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 18px;
    padding: 0 10px;
`
const Name = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 14px;
    padding: 0 10px;
`
const Description = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 14px;
    padding: 0 10px;
`
const Council = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 14px;
    padding: 0 10px;
`

const Row = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`

const Title = styled.div`
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    text-align: start;
    flex: 1;
`

const Icon = styled(Feather).attrs({ size: 20 })`
    color: ${({ theme }) => theme.colors.primary};
`

const ContainerInfo = styled.div`
    display: flex;
    align-items: center;
    margin: 5px 0px;
`

const Info = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    margin-left: 8px;
    text-align: start;
    flex: 1;
`

const ContainerPicture = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const ImageWhats = styled.img.attrs({ src: sourceWhatsapp })`
    object-fit: contain;
    height: 25px;
    width: 25px;
    margin-right: 10px;
`

const ButtonWhatsApp = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: center;
    border-radius: ${({ theme }) => theme.sizes.radius}px;
    border: 2px solid transparent;
    text-align: center;
    height: fit-content;
    background-color: #08c98e;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    transition: 0.4s;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.shadow};
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently*/
`

export {
    CallName,
    ButtonWhatsApp,
    Content,
    ContainerInfo,
    ContainerPicture,
    ContentCard,
    Council,
    Description,
    Icon,
    Info,
    Name,
    Row,
    Title,
    Container,
    ImageWhats,
    TopBarCard,
}
