import styled from 'styled-components'

import { Feather } from '#/components/icons'
const Container = styled.div`
    display: flex;
    position: fixed;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000080;
    justify-content: flex-end;
    z-index: 10;
`

const Box = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: ${({ theme }) => theme.sizes.radius}px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
`

const ContainerTitle = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 16px;
`

const Close = styled(Feather).attrs({ size: 18, name: 'X' })`
    color: ${({ theme }) => theme.colors.textOpacity};
    position: absolute;
    right: 8px;
    cursor: pointer;
`

const Message = styled.div`
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
    font-size: 16px;
`

const ContainerStores = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 16px;

    .store-icon {
        margin-right: 0.625rem;
        width: 1.8rem;
        line-height: 1;
    }
    .store-link {
        background-color: ${({ theme }) => theme.colors.primary + '20'};
        color: ${({ theme }) => theme.colors.text};
        padding: 0.5rem 0.8rem;
        display: -webkit-inline-box;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        width: fit-content;
        margin: 0 2%;
        border-radius: 0.625rem;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        text-decoration: none;
        text-align: left;
    }

    .store-head {
        font-size: 0.625rem;
        margin-bottom: 0;
        line-height: 1.5;
    }

    .store-title {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1;
    }
`

export { Box, Close, Container, ContainerTitle, Message, ContainerStores }
