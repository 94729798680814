import styled from 'styled-components'

import InputForm from '../input/input'

const Container = styled.div`
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`
const InputContainer = styled.div`
    width: 100%;
    display: flex;
`

const Input = styled(InputForm).attrs({ maxLength: 1, mask: '9' })`
    padding: 0px 5px;
    margin-bottom: 0px;
    :first-child {
        padding-left: 0px;
    }
    :last-child {
        padding-right: 0px;
    }

    input {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.text};
    }
`

export { Container, Input, InputContainer }
