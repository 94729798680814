import styled from 'styled-components'

const ContainerDetails = styled.div`
    display: flex;
    width: 100%;
    padding: 10px;
    flex-wrap: wrap;
`

const ContainerCalendar = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ContainerInfo = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    .badge {
        padding: 2px 16px;
        margin-top: 40px;
        @media (max-width: 1390px) {
            margin-top: 0px;
        }
    }
    .button {
        margin: 10px 0px;
    }
`

const ContainerToday = styled.div`
    @media (max-width: 1390px) {
        display: none;
    }
`
const LabelToday = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 20px;
`

const Today = styled.div`
    background-color: ${({ color, theme }) => (color ? color : theme.colors.primary)};
    color: #fff;
    font-size: 50px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 90px;
    height: 90px;
    border-radius: 5px;
    box-shadow: 0px 3px 6px ${({ color, theme }) => (color ? color : theme.colors.primary)};
    margin: 10px 0px;
`

const WeekDay = styled.div`
    margin-top: 10px;
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 12px;
    text-transform: capitalize;
`
const Month = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
`

const ContainerNotices = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

export {
    ContainerDetails,
    ContainerCalendar,
    ContainerInfo,
    Today,
    LabelToday,
    ContainerToday,
    WeekDay,
    Month,
    ContainerNotices,
}
