import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { GroupAPI } from '#/api'
import { Button, ContentSection, ItemMember, Search } from '#/components'
import { useLoadGroup } from '#/hooks'
import { Alert, AlertError, Confirmation, ModalMenu } from '#/modals'
import { updateGroup } from '#/redux/reducers/group'
import { loadGroup } from '#/redux/thunk/group'

import { ContianerSearch } from './group-members.styled'

function GroupMembers() {
    useLoadGroup()
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const confirmationRef = useRef()
    const alertErrorRef = useRef()
    const alertRef = useRef()
    const menuRef = useRef()

    const group = useSelector(state => state.group)
    const is_admin = useSelector(state => state.group?.is_admin)
    const user = useSelector(state => state.user)

    const [loading, setLoading] = useState(true)

    const [roles, setRoles] = useState([])
    const [members, setMembers] = useState([])
    const [fullMembers, setFullMembers] = useState([])

    const listMembers = useMemo(() => {
        const manager = members.filter(member => member.role === 'manager')
        const assist = members.filter(member => member.role === 'assist')
        const analyst = members.filter(member => member.role === 'analyst')
        const observer = members.filter(member => member.role === 'observer')
        const member = members.filter(member => member.role === 'member')

        return [...manager, ...assist, ...analyst, ...observer, ...member]
    }, [members])

    const _loadMembers = useCallback(async () => {
        try {
            setLoading(true)
            const responseMembers = await GroupAPI.getMembers(group?.id)
            const responseRoles = await GroupAPI.listRoles(group?.id)

            setFullMembers(responseMembers.items)
            setMembers(responseMembers.items)
            setRoles(responseRoles.items)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }, [group])

    const _setManager = useCallback(
        async member => {
            setLoading(true)
            try {
                await GroupAPI.setManager(group?.id, { user_id: member.id })
                dispatch(updateGroup({ manager: member, is_admin: false }))
                dispatch(loadGroup(group?.id))
            } catch ({ message }) {
                if (alertErrorRef.current) {
                    alertErrorRef.current.show({ message })
                }
            } finally {
                setLoading(false)
            }
        },
        [group, dispatch, alertErrorRef],
    )

    const _addAssist = useCallback(
        async member => {
            setLoading(true)
            const role = roles.filter(role => role.name === 'Assist')[0]

            try {
                await GroupAPI.addMemberRole(group?.id, role?.id, member.id)

                _loadMembers()
            } catch ({ message }) {
                if (alertErrorRef.current) {
                    alertErrorRef.current.show({ message })
                }
            } finally {
                setLoading(false)
            }
        },
        [_loadMembers, group?.id, roles],
    )

    const _removeAssist = useCallback(
        async member => {
            setLoading(true)
            const role = roles.filter(role => role.name === 'Assist')[0]

            try {
                await GroupAPI.removeMemberRole(group?.id, role?.id, member.id)
                _loadMembers()
            } catch ({ message }) {
                if (alertErrorRef.current) {
                    alertErrorRef.current.show({ message })
                }
            } finally {
                setLoading(false)
            }
        },
        [_loadMembers, group?.id, roles],
    )

    const _addAnalyst = useCallback(
        async member => {
            setLoading(true)
            const role = roles.filter(role => role.name === 'Analyst')[0]
            try {
                await GroupAPI.addMemberRole(group?.id, role?.id, member.id)
                _loadMembers()
            } catch ({ message }) {
                if (alertErrorRef.current) {
                    alertErrorRef.current.show({ message })
                }
            } finally {
                setLoading(false)
            }
        },
        [_loadMembers, group?.id, roles],
    )

    const _removeAnalyst = useCallback(
        async member => {
            setLoading(true)
            const role = roles.filter(role => role.name === 'Analyst')[0]

            try {
                await GroupAPI.removeMemberRole(group?.id, role?.id, member.id)
                _loadMembers()
            } catch ({ message }) {
                if (alertErrorRef.current) {
                    alertErrorRef.current.show({ message })
                }
            } finally {
                setLoading(false)
            }
        },
        [roles, group?.id, _loadMembers],
    )

    const _addObserver = useCallback(
        async member => {
            setLoading(true)
            const role = roles.filter(role => role.name === 'Observer')[0]
            try {
                await GroupAPI.addMemberRole(group?.id, role?.id, member.id)
                _loadMembers()
            } catch ({ message }) {
                if (alertErrorRef.current) {
                    alertErrorRef.current.show({ message })
                }
            } finally {
                setLoading(false)
            }
        },
        [_loadMembers, group?.id, roles],
    )

    const _removeObserver = useCallback(
        async member => {
            setLoading(true)
            const role = roles.filter(role => role.name === 'Observer')[0]

            try {
                await GroupAPI.removeMemberRole(group?.id, role?.id, member.id)
                _loadMembers()
            } catch ({ message }) {
                if (alertErrorRef.current) {
                    alertErrorRef.current.show({ message })
                }
            } finally {
                setLoading(false)
            }
        },
        [_loadMembers, group?.id, roles],
    )

    const _removeMember = useCallback(
        async member => {
            try {
                setLoading(true)
                await GroupAPI.removeMember(group.id, member.id)
                let newMembers = fullMembers.filter(item => item.id !== member.id)
                setFullMembers(newMembers)
                setMembers(newMembers)

                if (alertRef.current) {
                    alertRef.current.show({
                        title: 'Excluir membro',
                        message: 'Membro Excluído com sucesso!',
                    })
                }
                const data = { total_users: (group.total_users || 0) - 1 }
                if (group?.assist?.id === member.id) {
                    data.assist = null
                }

                dispatch(updateGroup(data))
            } catch ({ message }) {
                if (alertErrorRef.current) {
                    alertErrorRef.current.show({ message })
                }
            } finally {
                setLoading(false)
            }
        },
        [alertErrorRef, alertRef, fullMembers, group, dispatch],
    )

    const _onSetManager = useCallback(
        member => {
            if (confirmationRef.current) {
                confirmationRef.current.show({
                    title: 'Tornar gestor',
                    message:
                        'Ao confirmar você aceita que deixará de ser GESTOR deste grupo. Tem certeza que deseja continuar?',
                    icon: 'User',
                    onYesClick: () => {
                        _setManager(member)
                    },
                })
            }
        },
        [confirmationRef, _setManager],
    )

    const _onRemove = useCallback(
        member => {
            if (confirmationRef.current) {
                confirmationRef.current.show({
                    title: 'Excluir membro',
                    message: `Tem certeza que deseja excluir ${member.name} do grupo?`,
                    icon: 'Trash2',
                    onYesClick: () => {
                        _removeMember(member)
                    },
                })
            }
        },
        [confirmationRef, _removeMember],
    )

    const _openMenu = useCallback(
        member => {
            let options = []

            if (group?.manager?.id === user?.id) {
                if (member.role !== 'manager') {
                    options.push({ title: t('become-manager'), onClick: () => _onSetManager(member) })
                }

                if (member.role !== 'assist' && member.role === 'member') {
                    options.push({ title: t('become-aux'), onClick: () => _addAssist(member) })
                } else {
                    member.role === 'assist' &&
                        options.push({ title: t('remove-aux'), onClick: () => _removeAssist(member) })
                }

                if (member.role !== 'analyst' && member.role === 'member') {
                    options.push({ title: t('become-anl'), onClick: () => _addAnalyst(member) })
                } else {
                    member.role === 'analyst' &&
                        options.push({ title: t('remove-anl'), onClick: () => _removeAnalyst(member) })
                }

                if (member.role !== 'observer' && member.role === 'member') {
                    options.push({ title: t('become-obs'), onClick: () => _addObserver(member) })
                } else {
                    member.role === 'observer' &&
                        options.push({ title: t('remove-obs'), onClick: () => _removeObserver(member) })
                }
            }

            if (
                member.role !== 'assist' &&
                member.role !== 'observer' &&
                member.role !== 'analyst' &&
                member.role === 'member'
            ) {
                options.push({ title: t('remove-member'), onClick: () => _onRemove(member) })
            }

            menuRef.current && menuRef.current.show({ title: 'Gerenciar membro', options })
        },
        [
            group,
            user?.id,
            t,
            _onSetManager,
            _addAssist,
            _removeAssist,
            _addAnalyst,
            _removeAnalyst,
            _addObserver,
            _removeObserver,
            _onRemove,
        ],
    )

    const _onSearch = useCallback(
        search => {
            if (search) {
                const filterMembers = fullMembers.filter(item =>
                    item.name.toLowerCase().includes(search.toLowerCase()),
                )
                setMembers(filterMembers)
            } else {
                setMembers(fullMembers)
            }
        },
        [fullMembers],
    )

    useEffect(() => {
        if (group?.id) {
            return _loadMembers()
        }
    }, [_loadMembers, group])

    const MenuScreenRoles = () => {
        const roles = {
            manager: group?.role !== null && group?.role.name === 'Manager',
            assist: group?.role !== null && group?.role.name === 'Assist',
        }

        return listMembers.map((item, i) => (
            <>
                {roles.manager ? (
                    <ItemMember
                        key={`member_${item.id + i}`}
                        {...item}
                        userId={item.id}
                        hasMenu={item.role !== 'manager'}
                        onMenuClick={() => _openMenu(item)}
                        badge={item.role !== 'member' ? t(checkRole(item.role)) : null}
                    />
                ) : roles.assist ? (
                    <ItemMember
                        key={`member_${item.id + i}`}
                        {...item}
                        userId={item.id}
                        hasMenu={
                            item.role !== 'assist' &&
                            item.role !== 'observer' &&
                            item.role !== 'analyst' &&
                            item.role !== 'manager'
                        }
                        onMenuClick={() => _openMenu(item)}
                        badge={item.role !== 'member' ? t(checkRole(item.role)) : null}
                    />
                ) : (
                    <ItemMember
                        key={`member_${item.id + i}`}
                        {...item}
                        userId={item.id}
                        hasMenu={false}
                        onMenuClick={() => _openMenu(item)}
                        badge={item.role !== 'member' ? t(checkRole(item.role)) : null}
                    />
                )}
            </>
        ))
    }

    return (
        <>
            <ContentSection loading={loading}>
                <ContianerSearch>
                    <Search onSearch={_onSearch} />
                    {is_admin && (
                        <Button
                            title={t('add-members')}
                            dimension="auto"
                            onClick={() => history.push(`/grupo/${group?.id}/procurar-membros`)}
                        />
                    )}
                </ContianerSearch>
                <MenuScreenRoles />
            </ContentSection>
            <Confirmation ref={confirmationRef} />
            <AlertError ref={alertErrorRef} />
            <Alert ref={alertRef} />
            <ModalMenu ref={menuRef} />
        </>
    )
}

function checkRole(role) {
    if (role === 'manager') {
        return 'manager'
    }

    if (role === 'assist') {
        return 'assist'
    }

    if (role === 'analyst') {
        return 'analyst'
    }

    if (role === 'observer') {
        return 'observer'
    }

    return null
}

export default memo(GroupMembers)
