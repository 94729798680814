import React, { memo, useCallback, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OpportunityAPI } from '#/api'
import { ContentSection, List } from '#/components'
import { ItemOpportunity } from '#/components/items'

import { ContentOpportunity } from '../shared'
const initialStateParams = {
    page: 1,
    total: 0,
    type: 'public',
}
function reducerParams(state, newParams) {
    return {
        ...state,
        ...newParams,
    }
}

function OpportunityList() {
    const { t } = useTranslation()
    const [params, setParams] = useReducer(reducerParams, initialStateParams)
    const [loading, setLoading] = useState(true)
    const [opportunities, setOpportunities] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    const _loadOpportunities = useCallback(async () => {
        setLoading(true)
        try {
            const result = await OpportunityAPI.list({ ...initialStateParams })
            setParams({
                page: result.current_page,
                total: result.total,
            })
            setOpportunities(result.items)
        } catch (err) {
            setParams()
        } finally {
            setLoading(false)
        }
    }, [])

    const _loadMore = useCallback(async () => {
        if (params.total <= opportunities.length) {
            return
        }

        if (loading) {
            return
        }

        if (params.page + 1 === currentPage) {
            return
        }

        setCurrentPage(currentPage + 1)
        try {
            const result = await OpportunityAPI.list({ ...params, page: params.page + 1 })
            setParams({
                page: result.current_page,
                total: result.total,
            })
            setOpportunities([...opportunities, ...result.items])
        } catch (err) {
            setCurrentPage(params.page)
        } finally {
            setLoading(false)
        }
    }, [params, loading, opportunities, currentPage])

    useEffect(() => {
        _loadOpportunities()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ContentSection loading={loading} withoutPadding>
            <ContentOpportunity
                title={t('for-you')}
                description={t('opportunity-list.description')}
                icon="User"
            >
                <List
                    data={opportunities}
                    onRenderItem={(item, i) => (
                        <ItemOpportunity opportunity={item} key={`opportunity_${i}`} />
                    )}
                    loadMore={opportunities.length < params.total}
                    onLoadMoreClick={_loadMore}
                    isLoading={loading}
                    // infoEmptyProps={{
                    //     iconName: 'Users',
                    //     title: t('empty.groups.title'),
                    //     message: t('empty.groups.message'),
                    //     buttonText: t('create-group'),
                    //     onButtonClick: () => history.push('/criar-grupo'),
                    // }}
                />
            </ContentOpportunity>
        </ContentSection>
    )
}

export default memo(OpportunityList)
