import { Axios } from './axios-config'

const list = params => {
    return Axios().get('opportunities', { params })
}

const speedy = params => {
    return Axios().get('opportunities/speedy', { params })
}

const view = id => {
    return Axios().get(`/opportunities/${id}`)
}

const create = body => {
    Object.keys(body).forEach(key => {
        if (body[key] === null || body[key] === undefined) {
            delete body[key]
        }
    })

    return Axios().post('opportunities', body)
}

const update = (id, body) => {
    return Axios().patch(`opportunities/${id}`, body)
}
const updateStatus = (id, status) => {
    return Axios().patch(`/opportunities/${id}/status`, { status })
}

const remove = id => {
    return Axios().delete(`opportunities/${id}`)
}

const search = params => {
    return Axios().get('opportunities/search', { params })
}

const listAssistanceTypes = () => {
    return Axios().get('assistance-types')
}

const listInterests = (id, params) => {
    return Axios().get(`opportunities/${id}/interests`, { params })
}

const getPreferences = () => {
    return Axios().get('opportunities/settings')
}

const addPreferences = body => {
    return Axios().post('opportunities/settings', body)
}

const reaction = (idOpportunity, type) => {
    const body = { type }

    return Axios().post(`opportunities/${idOpportunity}/reactions`, body)
}

const save = idOpportunity => {
    return Axios().post(`opportunities/${idOpportunity}/saves`)
}

const unSave = idOpportunity => {
    return Axios().delete(`opportunities/${idOpportunity}/saves`)
}

const createInterest = (IdOpportunity, body) => {
    return Axios().post(`opportunities/${IdOpportunity}/interests`, body)
}

const cancelInterest = idOpportunity => {
    return Axios().delete(`opportunities/${idOpportunity}/interests`)
}

const myInterests = params => {
    return Axios().get('opportunities/mine/interests', { params })
}

const mySaves = params => {
    return Axios().get('opportunities/mine/saves', { params })
}

const myOpportunities = params => {
    return Axios().get('opportunities/mine', { params })
}

const listIndications = params => {
    return Axios().get('opportunities/indications', {
        params: params,
    })
}

const listUsers = params => {
    return Axios().get('opportunities/indications/users', {
        params: { limit: 30, ...params },
    })
}

const createIndication = (userId, opportunityId) => {
    const body = { user_id: userId, opportunity_id: opportunityId }

    return Axios().post('opportunities/indications', body)
}

const getInfo = () => {
    return Axios().get('opportunities/info')
}

const opportunitiesByIndication = (userId, params) => {
    return Axios().get(`opportunities/indications/${userId}`, {
        params: params,
    })
}

export default {
    listAssistanceTypes,
    addPreferences,
    getPreferences,
    list,
    reaction,
    save,
    unSave,
    createInterest,
    cancelInterest,
    myInterests,
    mySaves,
    search,
    view,
    myOpportunities,
    create,
    remove,
    listInterests,
    update,
    updateStatus,
    speedy,
    listIndications,
    listUsers,
    createIndication,
    getInfo,
    opportunitiesByIndication,
}
