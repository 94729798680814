import styled, { css } from 'styled-components'

const Table = styled.table`
    width: 100%;
    text-align: center;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0px;
    color: #646464;

    th {
        border-top: 1px solid #cccccd;
        border-bottom: 1px solid #cccccd;
        border-right: 1px solid #cccccd;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        padding: 0px;
        z-index: 2;
        background-color: #fff;
        :first-child {
            border-left: 1px solid #cccccd;
        }
    }

    td {
        border-top: 1px solid #cccccd;
        border-right: 1px solid #cccccd;
        font-size: 12px;
        overflow: hidden;
        word-break: normal;
    }

    .tr-last {
        td {
            border-bottom: 2px solid #7d7d7d;
        }
    }

    .title {
        font-weight: bold;
    }

    .td-user {
        width: 1%;
        white-space: nowrap;
        font-size: 10px;
        border-bottom: 2px solid #7d7d7d;
        border-left: 1px solid #cccccd;
    }
    @media print {
        margin: 0 !important;
        padding: 0 !important;
    }
`

const BoxHeader = styled.th`
    font-size: 12px;
    font-weight: normal;
    overflow: hidden;
    word-break: normal;
    text-align: center;
    vertical-align: top;
    user-select: none;
    position: inherit !important;

    ${({ isWeekend }) => {
        if (isWeekend) {
            return css`
                background-color: #f2f2f3 !important;
            `
        }
    }}
`

const ConainerHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Divider = styled.div`
    height: 0.5px;
    width: 100%;
    background-color: #cccccd;
`

const Box = styled.td`
    padding: 0px;
    text-align: left;

    ${({ isWeekend }) => {
        if (isWeekend) {
            return css`
                pointer-events: none;
                background-color: #f2f2f3 !important;
            `
        }
        if (isWeekend) {
            return css`
                background-color: #f2f2f3 !important;
            `
        }
    }};
`

const ContainerShift = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    overflow: hidden;
    .avatar {
        .container-avatar {
            border-width: 1px;
        }
    }
`

const ContainerUser = styled.div`
    width: 150px;
    max-height: 70px;
    text-align: start;
    padding: 2px;
`

const NameUser = styled.div`
    width: 100%;
    font-weight: bold;
    font-size: 12px;
    white-space: normal;
    line-height: 1.4;
`
const ContainerLabel = styled.div`
    height: ${({ height }) => (height ? height : 20)}px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

const Label = styled.div`
    font-weight: bold;
    text-transform: uppercase;
`

const Totals = styled.div`
    position: absolute;
    top: -1px;
    right: 1px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 8px;
`

export {
    Box,
    BoxHeader,
    ConainerHeader,
    Table,
    ContainerShift,
    ContainerUser,
    Divider,
    NameUser,
    Label,
    ContainerLabel,
    Totals,
}
