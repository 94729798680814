import styled from 'styled-components'

import { Feather } from '../icons'
const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 10px;
`

const Message = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    flex: 1;
    padding-left: 10px;
    font-size: 12px;
    text-align: start;
`

const Icon = styled(Feather).attrs({ size: 20 })`
    color: ${({ theme }) => theme.colors.text};
    height: 100%;
`

export { Container, Message, Icon }
