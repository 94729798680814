import styled from 'styled-components'

import { Feather } from '#/components/icons'

const Container = styled.div`
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
`
const ContainerGroup = styled.div`
    background-color: ${({ theme }) => theme.colors.backgroundAccent};
    padding: 16px;
`

const Segment = styled.div`
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    display: flex;
    flex-direction: column;
    .button-text {
        align-self: center;
    }
    .list {
        width: 100%;
        .item-member {
            flex: 1;
        }
    }
`

const SegmentTitle = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 18px;
`
const ContainerVancancy = styled.div`
    display: flex;
    align-items: center;
`
const LabelVacancy = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 16px;
    margin-left: 5px;
`

const ContainerCoworkers = styled.div`
    width: 100%;
    display: flex;
    overflow-y: none;
    overflow-x: auto;
`

const ContainerAvatar = styled.div`
    padding: 5px;
`
const ContainerMember = styled.div`
    cursor: pointer;
    user-select: none;
    display: flex;
    width: 100%;
    align-items: center;
`
const ContainerButtons = styled.div`
    display: flex;
    margin: 16px;
    justify-content: space-between;
`
const Amount = styled.div`
    position: absolute;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    right: -20px;
    top: 2px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.danger};
    padding: 1px;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`

const BannerWarning = styled.div`
    display: flex;
    background-color: ${({ theme }) => `${theme.colors.yellow}1f`};
    border-color: ${({ theme }) => theme.colors.yellow};
    border-width: ${({ theme }) => theme.sizes.borderWidth}px;
    border-radius: ${({ theme }) => theme.sizes.radius}px;
    padding: 4px 16px 16px 16px;
    width: 100%;
`
const IconWarning = styled(Feather).attrs({ name: 'Mail' })`
    color: ${({ theme }) => theme.colors.yellow};
    font-size: 25px;
`

const TextWarning = styled.div`
    flex: 1;
    color: ${({ theme }) => theme.colors.text};
    font-weight: 200;
    font-size: 13.5px;
    line-height: 20px;
    padding-left: 10px;
`
const Bold = styled.div`
    font-weight: bold;
    cursor: pointer;
`

export {
    Amount,
    Container,
    ContainerAvatar,
    ContainerButtons,
    ContainerCoworkers,
    ContainerGroup,
    ContainerMember,
    BannerWarning,
    IconWarning,
    TextWarning,
    Bold,
    ContainerVancancy,
    LabelVacancy,
    Segment,
    SegmentTitle,
}
