import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OpportunityAPI } from '#/api'
import { Button, List, Search } from '#/components'
import { ItemMember } from '#/components/items'

import Loading from '../loading/loading'
import { ModalBase } from '../shared'
import { Container, ContainerItem } from './indicate-users.styled'

function IndicateUsers({ onClose }, ref) {
    const [show, setShow] = useState(false)

    const { t } = useTranslation()

    const [interesteds, setInteresteds] = useState([])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState(null)

    const [indicationsId, setIndicationsId] = useState([])
    const [opportunityId, setOpportunityId] = useState(null)

    useImperativeHandle(ref, () => ({
        show: (props = {}) => {
            const { opportunity_id } = props
            setOpportunityId(opportunity_id)
            setShow(true)
        },
    }))

    const _onClose = useCallback(() => {
        setShow(false)
        setTimeout(() => {
            setInteresteds([])
            setOpportunityId(null)
        }, 300)
        if (onClose) {
            onClose()
        }
    }, [onClose])

    const _listInteresteds = useCallback(async () => {
        setLoading(true)
        try {
            const result = await OpportunityAPI.listUsers({ search, opportunity_id: opportunityId })
            setInteresteds(result.items)
        } catch (error) {}
        setLoading(false)
    }, [opportunityId, search])

    const _indicate = useCallback(
        async userId => {
            const oldIndications = [...indicationsId]
            try {
                setIndicationsId([...indicationsId, userId])
                await OpportunityAPI.createIndication(userId, opportunityId)
            } catch (error) {
                setIndicationsId(oldIndications)
            }
        },
        [opportunityId, indicationsId],
    )

    useEffect(() => {
        let timeout
        timeout = setTimeout(() => {
            if (opportunityId) {
                _listInteresteds()
            }
        }, 1000)

        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, opportunityId])

    return (
        <ModalBase
            title={t('indicate')}
            visible={show}
            showButton={false}
            icon={null}
            onClose={_onClose}
            withoutPadding
            headerPrimary
        >
            <Container>
                <Search onSearch={e => setSearch(e)} />
                <List
                    data={interesteds}
                    onRenderItem={({ user }, i) => (
                        <ContainerItem key={`user_${i}`}>
                            <ItemMember userId={user.id} {...user} />
                            <Button
                                onClick={() => !indicationsId.includes(user.id) && _indicate(user.id)}
                                title={indicationsId.includes(user.id) ? t('sent') : t('send')}
                                variant={indicationsId.includes(user.id) ? 'success' : 'primary'}
                                dimension="auto"
                            />
                        </ContainerItem>
                    )}
                />
            </Container>
            <Loading show={loading} />
        </ModalBase>
    )
}
export default memo(forwardRef(IndicateUsers))
