import styled, { css } from 'styled-components'

import SourceLogo from '#/assets/images/logo-negative.svg'

const Container = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.header};
    height: 60px;
    box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.75);
    z-index: 10;
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
`

const Logo = styled.img.attrs({ src: SourceLogo })`
    height: 100%;
    object-fit: contain;
    cursor: pointer;
`

const HeaderRight = styled.div`
    color: #fff;
`

const Bold = styled.span`
    font-weight: bold;
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'auto')};
    margin: 0px 10px 0px 4px;
    ${({ primary }) =>
        primary &&
        css`
            color: ${({ theme }) => theme.colors.primary};
        `};
    ${({ margin }) =>
        margin &&
        css`
            margin-left: 5px;
        `};
`

const ContainerUser = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    .avatar {
        cursor: pointer;
    }
`

export { Container, Logo, HeaderRight, Bold, ContainerUser }
