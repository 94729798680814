import styled from 'styled-components'

import { Feather, FontPA } from '../icons'
const Container = styled.div`
    box-shadow: 0px 2px 4px 1px rgba(9, 9, 9, 0.15);
    border-radius: ${({ theme }) => theme.sizes.radius}px;
    overflow: ${({ overflowHidden }) => (overflowHidden ? 'hidden' : 'visible')};
    background-color: ${({ theme }) => theme.colors.background};
    display: flex;
    flex-direction: column;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: ${({ withoutPadding }) => (withoutPadding ? 0 : 10)}px;
`

const HeaderCard = styled.div`
    display: flex;
    border-bottom: 1px ${({ theme }) => theme.colors.backgroundContrast} solid;
    margin-bottom: 10px;
    padding: 10px;
    align-items: center;
`

const ContainerTitle = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
`

const SubTitle = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 12px;
`

const LeftIconFeather = styled(Feather).attrs({ size: 20 })`
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.textLight};
`

const LeftIconFontPA = styled(FontPA).attrs({ size: 20 })`
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.textLight};
`

const RightText = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 12px;
`

const RightIcon = styled(Feather).attrs({ size: 20 })`
    color: ${({ theme }) => theme.colors.primary};
    margin-left: 5px;
    cursor: pointer;
`

export {
    Container,
    HeaderCard,
    Title,
    Content,
    LeftIconFeather,
    LeftIconFontPA,
    RightIcon,
    ContainerTitle,
    SubTitle,
    RightText,
}
