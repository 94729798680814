import classNames from 'classnames'
import React, { memo } from 'react'

import { Container, Icon, Text } from './button-text.styled'
function ButtonText({ children, iconName, disabled, onClick, className, type }) {
    return (
        <Container
            onClick={disabled ? null : onClick}
            disabled={disabled}
            className={classNames('button-text', className)}
            type={type}
        >
            {!!iconName && <Icon name={iconName} size={20} />}
            <Text>{children}</Text>
        </Container>
    )
}

export default memo(ButtonText)
