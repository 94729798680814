import React, { memo, useCallback, useEffect, useReducer, useRef, useState } from 'react'

import { UserAPI } from '#/api'
import { Calendar, Card, List, Post, SectionContainer, Sponsored } from '#/components'
import { ListMyShifts } from '#/modals'
import { makeEvent } from '#/utils/calendar-tools'

import { Container, ContainerFeed, ContainerMySchedule } from './section-feed.styled'
const initialStateParams = {
    page: 1,
    total: 0,
}
function reducerParams(state, newParams) {
    return {
        ...state,
        ...newParams,
    }
}

function SectionFeed() {
    const [params, setParams] = useReducer(reducerParams, initialStateParams)
    const [loading, setLoading] = useState(true)
    const [feeds, setFeeds] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [pick, setPick] = useState(null)
    const [ads, setAds] = useState(null)

    const _loadFeed = useCallback(async () => {
        setLoading(true)
        try {
            const result = await UserAPI.getFeed(params)
            setParams({
                page: result.current_page,
                total: result.total,
            })
            setFeeds(result.items)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }, [params])

    const _loadSponsored = useCallback(async () => {
        setLoading(true)
        try {
            const adsNews = await UserAPI.getNewsPick({ type: 'ads' })
            const blogNews = await UserAPI.getNews({ type: 'image' })

            if (blogNews && blogNews.items.length > 0) {
                setPick(blogNews.items[0])
            } else {
                setPick(null)
            }
            setAds(adsNews)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        _loadFeed()
        _loadSponsored()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _loadMore = useCallback(async () => {
        if (params.total <= feeds.length) {
            return
        }

        if (loading) {
            return
        }

        if (params.page + 1 === currentPage) {
            return
        }

        setCurrentPage(currentPage + 1)
        try {
            const result = await UserAPI.getFeed({ ...params, page: params.page + 1 })
            setParams({
                page: result.current_page,
                limit: result.per_page,
                total: result.total,
            })
            setFeeds([...feeds, ...result.items])
        } catch (err) {
            setCurrentPage(params.page)
        } finally {
            setLoading(false)
        }
    }, [params, loading, feeds, currentPage])

    return (
        <Container>
            <SectionContainer loading={loading}>
                <ContainerFeed>
                    <List
                        data={feeds}
                        onRenderItem={(item, i) => (
                            <Post
                                data={item.data}
                                type={item.type}
                                key={`post_${i}`}
                                date={item.created_at}
                            />
                        )}
                        loadMore={feeds.length < params.total}
                        onLoadMoreClick={_loadMore}
                        isLoading={loading}
                    />
                    <ContainerMySchedule>
                        <Card>
                            <MySchedule />
                        </Card>
                        {ads && <Sponsored pick={ads} />}
                        {pick && <Sponsored pick={pick} />}
                    </ContainerMySchedule>
                </ContainerFeed>
            </SectionContainer>
        </Container>
    )
}

const MySchedule = memo(() => {
    const modalShiftsRef = useRef()
    const [events, setEvents] = useState([])
    const [shifts, setShifts] = useState([])
    const [monthsLoaded, setMonthsLoaded] = useState([])
    const [loadingCalendar, setLoadingCalendar] = useState(false)

    const [currentDate, setCurrentDate] = useState(null)
    const [initialDate, setInitialDate] = useState(null)

    const _mergeMonths = useCallback(
        periods => {
            let months = [...monthsLoaded]
            const { previous, current, next } = periods

            if (!monthsLoaded.length) {
                months = [previous, current, next]
            } else {
                if (monthsLoaded[0].month === current.month) {
                    months = [previous, ...monthsLoaded]
                }
                if (monthsLoaded[monthsLoaded.length - 1].month === current.month) {
                    months = [...monthsLoaded, next]
                }
            }

            return months
        },
        [monthsLoaded],
    )

    const _checkRefresh = useCallback(
        date => {
            if (!monthsLoaded.length) {
                return true
            } else {
                if (monthsLoaded[0].month === date) {
                    return true
                }
                if (monthsLoaded[monthsLoaded.length - 1].month === date) {
                    return true
                }
            }

            return false
        },
        [monthsLoaded],
    )

    const _loadShifts = useCallback(
        async date => {
            if (!date) {
                return
            }

            setLoadingCalendar(true)
            try {
                const result = await UserAPI.getCalendarByMonth(date)

                const months = _mergeMonths(result)

                let shifts = []
                months.forEach(item => {
                    shifts = [...shifts, ...item.days]
                })

                setShifts(shifts)

                setMonthsLoaded(months)
            } catch (error) {
            } finally {
                setLoadingCalendar(false)
            }
        },
        [_mergeMonths],
    )

    const _onMonthChange = useCallback(
        async date => {
            if (initialDate !== date) {
                setInitialDate(null)
            }

            if (currentDate !== date) {
                setCurrentDate(date)

                if (_checkRefresh(date)) {
                    await _loadShifts(date)
                }
            }
        },
        [currentDate, initialDate, _checkRefresh, _loadShifts],
    )

    const _openListModal = useCallback(
        ({ date }) => {
            modalShiftsRef.current && modalShiftsRef.current.show({ date })
        },
        [modalShiftsRef],
    )

    useEffect(() => {
        if (shifts) {
            const events = shifts.map(item =>
                makeEvent({
                    date: item.date,
                    colors: item.shifts,
                    hasInfo: item.user_has_transaction,
                    hasAlert: item.has_conflicts,
                    hasVacancy: item.has_vacancies,
                }),
            )
            setEvents(events)
        }
    }, [shifts])

    return (
        <>
            <Calendar
                events={events}
                initialDate={initialDate}
                onMonthChange={_onMonthChange}
                loading={loadingCalendar}
                onDayPress={_openListModal}
            />
            <ListMyShifts ref={modalShiftsRef} />
        </>
    )
})

export default memo(SectionFeed)
