import React, { memo } from 'react'

import {
    Container,
    ContainerShift,
    ContainerShiftsUser,
    MemberContainer,
    MemberInfo,
    MemberName,
    ShiftContent,
    ShiftInfo,
    ShiftInfoContent,
} from './item-print-report.styled'
function ItemPrintReport({ user, displayName = 'full_name', extras }) {
    const { council, shifts, totals, description, phone } = user
    return (
        <Container>
            <MemberContainer>
                <MemberName>{user[displayName]}</MemberName>
                {!!description && <MemberInfo>{description}</MemberInfo>}
                {council && extras.includes('council') && (
                    <MemberInfo>{(council || '').replace(' ', '')}</MemberInfo>
                )}
                {phone && extras.includes('phone') && <MemberInfo>{phone}</MemberInfo>}
            </MemberContainer>

            <ContainerShiftsUser>
                <ContainerShift>
                    <ShiftContent>
                        <ShiftInfo infoBar>TURNOS</ShiftInfo>
                    </ShiftContent>
                    <div>
                        {shifts
                            .filter(shift => ({ time: shift.time }))
                            .map(({ time }) => (
                                <ShiftInfoContent>
                                    <ShiftInfo info>{time}</ShiftInfo>
                                </ShiftInfoContent>
                            ))}
                    </div>
                </ContainerShift>
                <ContainerShift>
                    <ShiftContent right>
                        <ShiftInfo infoBar>PLANTÕES</ShiftInfo>
                    </ShiftContent>
                    <div>
                        {shifts
                            .filter(shift => ({ date: shift.date }))
                            .map(({ date }) => (
                                <ShiftInfoContent right>
                                    <ShiftInfo info>{date}</ShiftInfo>
                                </ShiftInfoContent>
                            ))}
                    </div>
                </ContainerShift>
            </ContainerShiftsUser>
            <ContainerShift>
                <ShiftContent>
                    <ShiftInfo infoBar>{totals.shifts}</ShiftInfo>
                </ShiftContent>
            </ContainerShift>
            <ContainerShift>
                <ShiftContent right>
                    <ShiftInfo infoBar>{totals.dynamic_hours}</ShiftInfo>
                </ShiftContent>
            </ContainerShift>
        </Container>
    )
}

export default memo(ItemPrintReport)
