import styled, { css } from 'styled-components'

import { Feather } from '#/components/icons'

const Container = styled.div`
    width: 100%;
`
const ContainerInfo = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin: 8px 0px;
`

const ContainerAmounts = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
`

const IconInfo = styled(Feather).attrs(({ size }) => ({ size: size || 20 }))`
    color: ${({ theme }) => theme.colors.primary};

    ${({ plus }) =>
        plus &&
        css`
            position: absolute;
            left: 12px;
            top: 2px;
        `}
`

const Info = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    margin-left: 10px;
`

export { Container, ContainerInfo, IconInfo, Info, ContainerAmounts }
