import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import Separator from '#/components/separator/separator'
import TitleBar from '#/components/title-bar/title-bar'

import {
    Container,
    ContainerShift,
    ContainerShiftsTypes,
    ContainerShiftsUser,
    Hours,
    Label,
    LabelShifts,
    Padding,
    ShiftInfo,
} from './item-report.styled'
function ItemReport({ user }) {
    const { t } = useTranslation()

    const { full_name, council, shifts, totals, description } = user

    return (
        <Container>
            <TitleBar title={full_name} subTitle={council} rightText={description} />
            <Padding>
                <ContainerShiftsTypes>
                    <LabelShifts>
                        {/* {t('fixed-schedule')}: <Hours>{totals.fixed_hours}</Hours> */}
                    </LabelShifts>
                    <LabelShifts>
                        {t('dynamic-schedule')}: <Hours>{totals.dynamic_hours}</Hours>
                    </LabelShifts>
                </ContainerShiftsTypes>
            </Padding>
            <>
                <Separator />
                <ContainerShiftsUser>
                    {shifts.length > 0 && (
                        <>
                            <Label>{t('shifts')}:</Label>
                            {shifts.map((item, i) => (
                                <ItemShift
                                    key={`shift_${i}`}
                                    columnDate={item.date}
                                    columnWorkingtime={item.time}
                                    columnValue={item.amount}
                                />
                            ))}
                        </>
                    )}

                    {totals && (
                        <ItemShift
                            bold
                            columnDate={`Total: ${totals.shifts}`}
                            columnWorkingtime=""
                            columnValue={totals.amount}
                        />
                    )}
                </ContainerShiftsUser>
            </>
            <Separator />
        </Container>
    )
}

const ItemShift = memo(({ bold, columnDate, icon, columnWorkingtime, columnValue }) => {
    // const { t } = useTranslation()
    return (
        <ContainerShift>
            <ShiftInfo bold={bold}>{columnDate}</ShiftInfo>
            {/* <ShiftInfo center>{icon && <FontPA name={icon} />}</ShiftInfo> */}
            <ShiftInfo bold={bold} right>
                {columnWorkingtime}
            </ShiftInfo>
            {/* <ShiftInfo bold={bold} right>
                {Number(columnValue) ? formatCurrency(columnValue) : <Link>{t('add-value')}</Link>}
            </ShiftInfo> */}
        </ContainerShift>
    )
})

export default memo(ItemReport)
