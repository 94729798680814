import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import AddMemberAnim from '#/assets/animation/add-members.json'
import CreateGroupAnim from '#/assets/animation/create-group.json'
import CreateWorkingTimeAnim from '#/assets/animation/create-working-time.json'
import CreatedGroupAnim from '#/assets/animation/created-group.json'

import AddMember from './add-member/add-member'
import CreateGroup from './create-group/create-group'
import CreateInstitution from './create-institution/create-institution'
import CreateWorkingTime from './create-working-time/create-working-time'
import CreatedGroup from './created-group/created-group'
import { CreateGroupContainer } from './shared'
function SectionCreateGroup() {
    const { t } = useTranslation()
    const group_id = useSelector(state => state.group?.id)

    const location = useLocation()
    const history = useHistory()

    const containerProps = useMemo(() => {
        if (location) {
            const { pathname } = location
            if (pathname.includes('criar-turnos')) {
                return {
                    titleDescription: t('create-working-time.description.title'),
                    description: t('create-working-time.description'),
                    animation: CreateWorkingTimeAnim,
                    stepsComplete: 1,
                }
            }
            // if (pathname.includes('criar-valores')) {
            //     return {
            //         titleDescription: t('create-values.description.title'),
            //         description: t('create-values.description'),
            //         animation: CreateCreateValuesAnim,
            //         stepsComplete: 2,
            //     }
            // }
            if (pathname.includes('adicionar-membros')) {
                return {
                    titleDescription: t('add-member.description.title'),
                    description: t('add-member.description'),
                    animation: AddMemberAnim,
                    stepsComplete: 2,
                }
            }
            if (pathname.includes('grupo-criado')) {
                return {
                    titleDescription: t('created-group.description.title'),
                    description: t('created-group.description'),
                    animation: CreatedGroupAnim,
                    stepsComplete: 3,
                    buttonText: t('start'),
                    onButtonClick: () => {
                        history.push(`/grupo/${group_id}`)
                    },
                }
            }

            return {
                titleDescription: t('create-group.description.title'),
                description: t('create-group.description'),
                animation: CreateGroupAnim,
            }
        }
    }, [location, t, group_id, history])

    return (
        <CreateGroupContainer animation={CreateGroupAnim} {...containerProps}>
            <Switch>
                <Route exact path="/criar-grupo" component={CreateGroup} />
                <Route exact path="/criar-grupo/criar-entidade" component={CreateInstitution} />
                <Route path="/criar-grupo/:group_id/criar-turnos" component={CreateWorkingTime} />
                {/* <Route path="/criar-grupo/:group_id/criar-valores" component={CreateValues} /> */}
                <Route path="/criar-grupo/:group_id/adicionar-membros" component={AddMember} />
                <Route path="/criar-grupo/:group_id/grupo-criado" component={CreatedGroup} />
            </Switch>
        </CreateGroupContainer>
    )
}

export default memo(SectionCreateGroup)
