import { useFormik } from 'formik'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'

import { AccountsAPI, AppAPI, AuthAPI } from '#/api'
import Ilustration from '#/assets/images/ilustration-register.svg'
import { CheckTerms, Input } from '#/components'
import { Alert, AlertError, ModalTerms } from '#/modals'
import { setAds } from '#/redux/reducers/ads'
import { checkAccount } from '#/redux/thunk'

import { PageRegister } from '../shared'
import { Bold } from './register-email.styled'

function RegisterEmail() {
    const dispatch = useDispatch()
    const location = useLocation()
    const { t } = useTranslation()
    const alertErrorRef = useRef()
    const alertRef = useRef()
    const modalTermsRef = useRef()
    const [loading, setLoading] = useState(false)
    const { isSubmitting, values, errors, touched, isValid, dirty, handleSubmit, setFieldValue, handleBlur } =
        useFormik({
            initialValues: {
                email: '',
                check_terms: false,
            },
            validationSchema: Yup.object().shape({
                email: Yup.string()
                    .trim()
                    .email(t('input.email.invalid'))
                    .required(t('input.email.required')),
                check_terms: Yup.boolean().oneOf([true], t('terms.required')),
            }),
            onSubmit: async values => {
                try {
                    let result
                    if (location.pathname === '/criar-conta') {
                        result = await AuthAPI.registerUser(values)
                    } else {
                        result = await AccountsAPI.update(values)
                    }
                    dispatch(checkAccount(result))
                } catch ({ message }) {
                    _showAlertErro(message)
                } finally {
                }
            },
        })

    const _showAlertErro = useCallback(
        message => {
            try {
                alertErrorRef.current && alertErrorRef.current.show({ message })
            } catch (error) {}
        },
        [alertErrorRef],
    )

    const _showTerms = useCallback(async () => {
        try {
            setLoading(true)

            const { content } = await AppAPI.getTermsUse()

            modalTermsRef.current && modalTermsRef.current.show({ message: content })
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }, [modalTermsRef])

    const _getParams = useCallback(() => {
        const query = new URLSearchParams(location.search)
        dispatch(setAds(query.get('ads')))
    }, [location, dispatch])

    useEffect(() => {
        _getParams()
    }, [_getParams])

    useEffect(() => {
        console.log('errors', errors)
    }, [errors])

    return (
        <>
            <PageRegister
                loading={isSubmitting || loading}
                hasAccount
                title={t('create-account.title')}
                message={t('create-account.message')}
                disabled={!isValid || !dirty || isSubmitting}
                ilustration={Ilustration}
                onNextClick={handleSubmit}
                stepsComplete={0}
                buttonTitle={t('register')}
            >
                <Input
                    name="email"
                    label={t('input.email.label')}
                    type="email"
                    value={values.email}
                    onChangeText={e => setFieldValue('email', e)}
                    onBlur={handleBlur('email')}
                    errorMessage={touched.email && errors.email}
                />

                <CheckTerms
                    text={
                        <>
                            {t('create-account.terms')}
                            <Bold primary onClick={_showTerms}>
                                {t('terms')}.
                            </Bold>
                        </>
                    }
                    checked={values.check_terms}
                    onChange={e => setFieldValue('check_terms', e)}
                />
            </PageRegister>
            <AlertError ref={alertErrorRef} />
            <Alert ref={alertRef} />
            <ModalTerms ref={modalTermsRef} />
        </>
    )
}

export default RegisterEmail
