import styled from 'styled-components'

const Container = styled.div`
    padding: 10px 16px;
    width: 100%;
    background-color: ${({ current, groupColor, theme }) =>
        current && groupColor ? `#${groupColor}` : theme.colors.background};
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    user-select: none;
    &:hover {
        background-color: ${({ theme, current }) => !current && theme.colors.hover};
    }
`

const ContainerText = styled.div`
    flex: 1;
`

const MonthText = styled.div`
    text-transform: uppercase;
    font-size: 16px;
    color: ${({ current, theme }) => (current ? '#fff' : theme.colors.textLight)};
    font-weight: bold;
`

const Status = styled.div`
    text-transform: uppercase;
    margin-top: 4px;
    font-size: 14px;
    color: ${({ current, status, theme }) =>
        current
            ? '#fff'
            : {
                  draft: theme.colors.danger,
                  publi: theme.colors.primary,
              }[status] || theme.colors.textLight};
`

const TextAccent = styled.div`
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: ${({ current, theme }) => (current ? '#fff' : theme.colors.primary)};
`

export { Container, MonthText, Status, TextAccent, ContainerText }
