const getMonthName = month => {
    let label = ''

    switch (Number(month)) {
        case 1:
            label = 'Janeiro'
            break
        case 2:
            label = 'Fevereiro'
            break
        case 3:
            label = 'Março'
            break
        case 4:
            label = 'Abril'
            break
        case 5:
            label = 'Maio'
            break
        case 6:
            label = 'Junho'
            break
        case 7:
            label = 'Julho'
            break
        case 8:
            label = 'Agosto'
            break
        case 9:
            label = 'Setembro'
            break
        case 10:
            label = 'Outubro'
            break
        case 11:
            label = 'Novembro'
            break
        case 12:
            label = 'Dezembro'
            break
        default:
            label = ''
            break
    }
    return label
}

export default getMonthName
