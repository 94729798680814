import styled from 'styled-components'

import { Feather, FontPA } from '../../../../components'

const ContainerItem = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    padding: 12px 10px;
    border-bottom: 1px ${({ theme }) => theme.colors.backgroundContrast} solid;
    :last-child {
        border: none;
    }
    &:hover {
        background-color: ${({ theme }) => theme.colors.hover};
    }
`

const FeatherIcon = styled(Feather).attrs({ size: 25 })`
    color: ${({ active, theme }) => (active ? theme.colors.primary : theme.colors.textLight)};
    height: 100%;
`

const FontPAIcon = styled(FontPA).attrs({ size: 25 })`
    color: ${({ active, theme }) => (active ? theme.colors.primary : theme.colors.textLight)};
    height: 100%;
`

const Title = styled.div`
    color: ${({ active, theme }) => (active ? theme.colors.primary : theme.colors.textLight)};
    flex: 1;
    padding-left: 16px;
    font-size: 16px;
    user-select: none;
    text-transform: capitalize;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
`

export { ContainerItem, FeatherIcon, FontPAIcon, Title }
