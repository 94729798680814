import styled from 'styled-components'

import { Feather } from '#/components'

const Container = styled.div`
    margin-top: 16px;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
    width: 100%;
`
const Image = styled.img`
    width: 390px;
    object-fit: contain;

    cursor: pointer;
`

const Message = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    user-select: none;
    margin: 10px 10px 10px 10px;
    word-wrap: break-word;
`

const SeePlus = styled.div`
    padding: 0 20px 0 20px;
    border-radius: 0 0 16px 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: ${({ theme }) => theme.colors.primary};

    cursor: pointer;
`
const Badge = styled.div`
    margin-left: 5px;
    margin-top: 5px;
    position: absolute;
    border-radius: 6px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 700;
    color: white;
    background-color: #000000;
    opacity: 0.3;
`

const SeePlusText = styled.p`
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.grey};
`

const Title = styled.span`
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 20px;
    font-size: 16px;
    font-weight: 500;
`

const Icon = styled(Feather)`
    color: ${({ theme }) => theme.colors.grey};
`

export { Container, Image, Message, SeePlus, SeePlusText, Title, Icon, Badge }
