import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { formatCurrency } from '../../utils'
import { ErrorMessage } from '../forms/shared'
import {
    Amount,
    Box,
    Circle,
    Container,
    ContainerBoard,
    ContainerBox,
    ContainerBoxDay,
    ContainerLabel,
    ContainerTitleBox,
    Content,
    Label,
    TitleBox,
} from './board-values.styled'
function BoardValues(
    {
        workingtimes = [],
        onChange,
        changeable = [],
        label,
        amount,
        readOnly,
        errorMessage,
        disabledBlocks = [],
        isEdit,
    },
    ref,
) {
    const { t } = useTranslation()
    const color = useSelector(({ group }) => group?.color && `#${group.color}`)
    const [daysSelecteds, setDaysSelecteds] = useState([])

    useImperativeHandle(ref, () => ({
        clear: () => {
            setDaysSelecteds([])
            onChange([])
        },
    }))

    useEffect(() => {
        if (isEdit && changeable) {
            setDaysSelecteds(changeable)
        }
    }, [isEdit, changeable])

    const _onBoardChange = useCallback(
        item => {
            if (daysSelecteds.find(e => e.workingtime_id === item.workingtime_id && e.day === item.day)) {
                const newData = daysSelecteds.filter(
                    e => !(e.workingtime_id === item.workingtime_id && e.day === item.day),
                )
                setDaysSelecteds(newData)
                if (onChange) {
                    onChange(newData)
                }
            } else {
                const newData = [...daysSelecteds, item]
                setDaysSelecteds(newData)
                if (onChange) {
                    onChange(newData)
                }
            }
        },
        [daysSelecteds, onChange],
    )

    const _checkIsSelected = useCallback(
        (id, day, days) => {
            if (days && days.length > 0) {
                return days.includes(day)
            }

            return (daysSelecteds || []).some(item => item.workingtime_id === id && item.day === day)
        },
        [daysSelecteds],
    )

    const _checkDisabled = useCallback(
        (workingtime_id, day) => {
            return disabledBlocks.some(array => {
                return array.some(({ days, id }) => {
                    let hasShiftEdit = false
                    if (isEdit) {
                        hasShiftEdit = changeable.some(item => {
                            if (item.workingtime_id === workingtime_id && item.day === day) {
                                return true
                            }
                            return false
                        })
                        if (hasShiftEdit) {
                            return false
                        }
                    }
                    if (workingtime_id === id) {
                        if (days && days.length > 0) {
                            return days.includes(day)
                        }
                    }
                    return false
                })
            })
        },
        [disabledBlocks, isEdit, changeable],
    )

    return (
        <Container>
            <ContainerBoard>
                <ContainerTitleBox>
                    <ContainerLabel>
                        {!!label && <Label>{label}</Label>}
                        {!!amount && <Amount>{formatCurrency(amount)}</Amount>}
                    </ContainerLabel>
                    {workingtimes.map(({ start_hour, end_hour }, i) => (
                        <TitleBox key={`time_${i}`}>{`${formatter(start_hour)} ${t('at')} ${formatter(
                            end_hour,
                        )}`}</TitleBox>
                    ))}
                </ContainerTitleBox>
                <Content>
                    <ContainerBox>
                        <ContainerBoxDay>
                            <Box className="title">D</Box>
                            <Box className="title">S</Box>
                            <Box className="title">T</Box>
                            <Box className="title">Q</Box>
                            <Box className="title">Q</Box>
                            <Box className="title">S</Box>
                            <Box className="title">S</Box>
                        </ContainerBoxDay>
                        {workingtimes.map(({ id, days }, i) => (
                            <ContainerBoxDay key={`borad_${i}`}>
                                <BoxDay
                                    readOnly={readOnly}
                                    color={color}
                                    selected={_checkIsSelected(id, 'sun', days)}
                                    disabled={_checkDisabled(id, 'sun')}
                                    value="sun"
                                    workingtimeId={id}
                                    onChange={_onBoardChange}
                                />
                                <BoxDay
                                    readOnly={readOnly}
                                    color={color}
                                    selected={_checkIsSelected(id, 'mon', days)}
                                    disabled={_checkDisabled(id, 'mon')}
                                    value="mon"
                                    workingtimeId={id}
                                    onChange={_onBoardChange}
                                />
                                <BoxDay
                                    readOnly={readOnly}
                                    color={color}
                                    selected={_checkIsSelected(id, 'tue', days)}
                                    disabled={_checkDisabled(id, 'tue')}
                                    value="tue"
                                    workingtimeId={id}
                                    onChange={_onBoardChange}
                                />
                                <BoxDay
                                    readOnly={readOnly}
                                    selected={_checkIsSelected(id, 'wed', days)}
                                    disabled={_checkDisabled(id, 'wed')}
                                    color={color}
                                    value="wed"
                                    workingtimeId={id}
                                    onChange={_onBoardChange}
                                />
                                <BoxDay
                                    readOnly={readOnly}
                                    color={color}
                                    selected={_checkIsSelected(id, 'thu', days)}
                                    disabled={_checkDisabled(id, 'thu')}
                                    value="thu"
                                    workingtimeId={id}
                                    onChange={_onBoardChange}
                                />
                                <BoxDay
                                    readOnly={readOnly}
                                    color={color}
                                    selected={_checkIsSelected(id, 'fri', days)}
                                    disabled={_checkDisabled(id, 'fri')}
                                    value="fri"
                                    workingtimeId={id}
                                    onChange={_onBoardChange}
                                />
                                <BoxDay
                                    readOnly={readOnly}
                                    color={color}
                                    selected={_checkIsSelected(id, 'sat', days)}
                                    disabled={_checkDisabled(id, 'sat')}
                                    value="sat"
                                    workingtimeId={id}
                                    onChange={_onBoardChange}
                                />
                            </ContainerBoxDay>
                        ))}
                    </ContainerBox>
                </Content>
            </ContainerBoard>
            {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Container>
    )
}

const BoxDay = ({ color, selected, value, workingtimeId, onChange, readOnly, disabled }) => {
    const _onClick = useCallback(() => {
        if (onChange) {
            onChange({ workingtime_id: workingtimeId, day: value })
        }
    }, [onChange, workingtimeId, value])
    return (
        <Box disabled={disabled || readOnly} onClick={readOnly || disabled ? null : _onClick}>
            {selected && <Circle color={color} />}
        </Box>
    )
}

function formatter(time) {
    return (time || '').replace(':', 'h')
}

export default memo(forwardRef(BoardValues))
