import styled from 'styled-components'

import { Feather } from '#/components/icons'
const ContianerSettings = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    .button {
        margin-bottom: 20px;
    }
    @media (max-width: 992px) {
        flex-direction: column;
        align-items: center;
        .button {
            margin-top: 10px;
            width: 100%;
        }
    }
`

const ContainerSelect = styled.div`
    flex: 1;
    margin-right: 10px;
    @media (max-width: 992px) {
        margin-right: 0px;
    }
`

const Label = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    font-weight: bold;
`
const Info = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 12px;
    margin: 1px 0px;
`

const Padding = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    .nav-link {
        align-self: flex-end;
    }
`

const MoreIcon = styled(Feather).attrs({ name: 'PlusCircle', size: 30 })`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 4px;
    align-self: center;
    margin: 20px 0px;
`

export { ContainerSelect, ContianerSettings, Info, Label, MoreIcon, Padding }
