import styled, { css } from 'styled-components'

const Container = styled.div`
    background-color: ${({ theme: { colors }, backgroundContrast }) =>
        backgroundContrast ? colors.backgroundContrast : colors.background};
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    ${({ overflowYHidden }) =>
        overflowYHidden &&
        css`
            overflow-y: hidden;
        `}
`

const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: ${({ hideHeader }) => (hideHeader ? 0 : 60)}px;
    overflow-y: hidden;
`

const FloatContainer = styled.div`
    position: absolute;
    right: 50px;
    bottom: 30px;
`

export { Container, Content, FloatContainer }
