import React, { memo } from 'react'
import Lottie from 'react-lottie'
import { useSelector } from 'react-redux'

import loadingData from '#/assets/animation/loading.json'

import { Container } from './loading.styled'
function Loading({ show, ignoreRedux = true, size }) {
    const loading = useSelector(state => state.isLoading)

    return (!ignoreRedux && loading) || show ? (
        <Container>
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: loadingData,
                }}
                height={size || 120}
                width={size || 120}
            />
        </Container>
    ) : null
}

export default memo(Loading)
