import styled from 'styled-components'

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    .button-text {
        align-self: flex-end;
    }

    .list {
        margin-top: 30px;
    }
`

const ContainerForm = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    .input {
        margin-left: 10px;
        :first-child {
            margin-left: 0px;
        }
    }
`

export { ContainerForm, Container }
