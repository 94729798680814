import classNames from 'classnames'
import React, { forwardRef, memo, useCallback, useImperativeHandle } from 'react'

import { ErrorMessage, Label } from '../shared'
import { Container, CustomInput } from './input-currency.styled'

const currencyConfig = {
    locale: 'pt-BR',
    formats: {
        number: {
            BRL: {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
}

function InputCurrency(
    {
        label,
        errorMessage,
        placeholder,
        onChangeText,
        onBlur,
        onFocus,
        value,
        disabled,
        required,
        className,
        ...props
    },
    ref,
) {
    useImperativeHandle(ref, () => ({}))

    const _handleChange = useCallback(
        (event, value) => {
            event.preventDefault()

            if (onChangeText) {
                onChangeText(value)
            }
        },
        [onChangeText],
    )

    return (
        <Container className={classNames('input', className)}>
            {!!label && (
                <Label htmlFor="" required={required}>
                    {label}:
                </Label>
            )}

            <CustomInput
                className="input-field"
                type="text"
                placeholder={placeholder}
                value={value || 0}
                onChange={_handleChange}
                onBlur={onBlur}
                onFocus={onFocus}
                currency="BRL"
                config={currencyConfig}
                disabled={disabled}
                {...props}
            />

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Container>
    )
}

export default memo(forwardRef(InputCurrency))
