import { useFormik } from 'formik'
import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { GroupAPI } from '#/api'
import { Button } from '#/components'
import { Input } from '#/components/forms'

import AlertError from '../alert-error/alert-error'
import Loading from '../loading/loading'
import { ModalBase } from '../shared'
import { Container, ContainerForms } from './invite-member.styled'
function InviteMember({ onSuccess }, ref) {
    const alertErrorRef = useRef()
    const [show, setShow] = useState(false)

    const { t } = useTranslation()

    const [groupId, setGroupId] = useState(null)

    const { isSubmitting, values, errors, touched, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            name: '',
            email: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .min(3, t('input.name-invide.min'))
                .trim()
                .required('input.name-invide.required'),
            email: Yup.string()
                .email('input.email-invide.invalid')
                .trim()
                .required('input.email-invide.required'),
        }),

        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true)

                const body = {
                    name: values.name,
                    email: values.email,
                }

                await GroupAPI.inviteMember(groupId, body)

                resetForm()
                if (onSuccess) {
                    onSuccess()
                }
                _onClose()
            } catch ({ message }) {
                _showAlertErro(message)
            } finally {
                setSubmitting(false)
            }
        },
    })

    useImperativeHandle(ref, () => ({
        show: (props = {}) => {
            const { group_id } = props
            setGroupId(group_id)
            setShow(true)
        },
    }))

    const _showAlertErro = useCallback(
        message => {
            try {
                alertErrorRef.current && alertErrorRef.current.show({ message })
            } catch (error) {}
        },
        [alertErrorRef],
    )

    const _onClose = useCallback(() => {
        setShow(false)
        setTimeout(() => {
            setGroupId(null)
        }, 300)
    }, [])

    return (
        <ModalBase
            title={t('invide')}
            visible={show}
            showButton={false}
            icon={null}
            onClose={_onClose}
            withoutPadding
            headerPrimary
        >
            <Container>
                <ContainerForms>
                    <Input
                        label={t('input.name-invide.label')}
                        placeholder={t('input.name-invide.placeholder')}
                        value={values.name}
                        onChangeText={value => setFieldValue('name', value)}
                        errorMessage={touched.name && errors.name}
                    />

                    <Input
                        label={t('input.email-invide.label')}
                        placeholder={t('input.email-invide.placeholder')}
                        value={values.email}
                        onChangeText={value => setFieldValue('email', value)}
                        errorMessage={touched.email && errors.email}
                    />
                </ContainerForms>
                <Button title={t('invide')} onClick={handleSubmit} />
            </Container>
            <Loading show={isSubmitting} />
            <AlertError ref={alertErrorRef} />
        </ModalBase>
    )
}
export default memo(forwardRef(InviteMember))
