import { createActions, createReducer } from 'reduxsauce'

export const {
    Types,
    Creators: { setUser, updateUser, removeUser },
} = createActions({
    setUser: ['user'],
    updateUser: ['user'],
    removeUser: [],
})

const INITIAL_STATE = null

const set = (state = INITIAL_STATE, action) => action.user

const update = (state = INITIAL_STATE, action) => ({
    ...state,
    ...action.user,
})

const remove = (state = INITIAL_STATE, action) => null

const reducers = createReducer(INITIAL_STATE, {
    [Types.SET_USER]: set,
    [Types.UPDATE_USER]: update,
    [Types.REMOVE_USER]: remove,
})
export default reducers
