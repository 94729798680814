import styled, { css } from 'styled-components'

const Container = styled.div`
    position: relative;
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    ${({ block, marginRight }) =>
        block &&
        css`
            max-width: 320px;
            ${marginRight
                ? css`
                      margin-right: 16px;
                  `
                : css`
                      margin-left: 16px;
                  `}
        `}

    .warning {
        margin: 14px 0px;
    }

    .button-next {
        align-self: flex-end;
    }

    .card-container {
        overflow-y: auto;
        height: 100%;
    }
`

export { Container }
