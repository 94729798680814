import styled from 'styled-components'

import { Feather } from '#/components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px 30px;
    overflow: hidden;
    .card {
        flex: 1;
        .card-content {
            height: 1px;
            overflow: hidden;
        }
    }
`

const ContainerTools = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 30px 16px;
    align-items: center;
    @media (max-width: 992px) {
        flex-direction: column;
        padding: 16px;
        .search {
            width: 100%;
        }
        .badge {
            margin: 16px 0px;
        }
    }
`

const ContainerButtons = styled.div`
    display: flex;
    align-items: center;
    div {
        margin: 0px 10px;
    }
    :last-child {
        margin-left: 0px;
    }
    :first-child {
        margin-right: 0px;
    }

    @media (max-width: 992px) {
        width: 100%;
        justify-content: space-between;
    }
`

const ContainerTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    .button-add {
        margin-right: 10px;
    }
    margin-bottom: 10px;
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 18px;
    flex: 1;
`
const BackIcon = styled(Feather).attrs({ name: 'ArrowLeft' })`
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    margin-right: 4px;
`
const Period = styled.div`
    color: #fff;
    font-weight: bold;
    text-transform: capitalize;
`

const Manager = styled.div`
    color: #fff;
    font-size: 12px;
`

const ContainerGrid = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    &::-webkit-scrollbar {
        height: 10px;
    }
`

const BannerWarning = styled.div`
    display: flex;
    background-color: ${({ theme }) => `${theme.colors.yellow}1f`};
    border-color: ${({ theme }) => theme.colors.yellow};
    border-width: ${({ theme }) => theme.sizes.borderWidth}px;
    border-radius: ${({ theme }) => theme.sizes.radius}px;
    padding: 4px 16px 16px 16px;
    width: 100%;
    max-width: 400px;
`
const IconWarning = styled(Feather).attrs({ name: 'Mail' })`
    color: ${({ theme }) => theme.colors.yellow};
    font-size: 25px;
`

const TextWarning = styled.div`
    flex: 1;
    color: ${({ theme }) => theme.colors.text};
    font-weight: 200;
    font-size: 13.5px;
    line-height: 20px;
    padding-left: 10px;
`
const Bold = styled.div`
    font-weight: bold;
    cursor: pointer;
`

export {
    BackIcon,
    BannerWarning,
    Bold,
    Container,
    ContainerButtons,
    ContainerGrid,
    ContainerTitle,
    ContainerTools,
    IconWarning,
    Manager,
    Period,
    TextWarning,
    Title,
}
