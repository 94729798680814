import React, { memo } from 'react'

import { Container, Title } from './badge.styled'

const Badge = ({ title, color, style, size, bold = true, upperCase = true, variant }) => {
    return (
        <Container variant={variant} className="badge" style={style} color={color}>
            <Title variant={variant} size={size} color={color} bold={bold} upperCase={upperCase}>
                {title}
            </Title>
        </Container>
    )
}

export default memo(Badge)
