import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Alert } from '#/modals'

import { status } from '../../api/axios-config'
import {
    ButtonVerificationClick,
    Container,
    Image,
    Message,
    TimeVerification,
    Title,
    VerificationTitle,
} from './manutence.styles'

const Manutence = () => {
    const history = useHistory()
    const alertRef = useRef()

    const [minutes, setMinutes] = useState(1)
    const [seconds, setSeconds] = useState(59)

    useEffect(() => {
        const _getClockTime = setInterval(async () => {
            if (seconds > 0) {
                setSeconds(seconds - 1)
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    try {
                        const response = await status()

                        if (response.status === 'running') {
                            return history.push('/login')
                        }
                    } catch (err) {}

                    setMinutes(1)
                    setSeconds(59)
                } else {
                    setMinutes(minutes - 1)
                    setSeconds(59)
                }
            }
        }, 1000)

        return () => {
            clearInterval(_getClockTime)
        }
    }, [minutes, seconds, history])

    const _verifyApiStatus = async () => {
        try {
            const response = await status()

            if (response.status !== 'running') {
                if (alertRef.current) {
                    return alertRef.current.show({
                        title: 'Ainda não terminamos.',
                        message:
                            'Precisamos de mais um tempinho para realizar os últimos ajustes. Por favor, tente novamente mais tarde.',
                    })
                }
            }

            return history.push('/login')
        } catch (err) {
            if (alertRef.current) {
                return alertRef.current.show({
                    title: 'Ainda não terminamos.',
                    message:
                        'Precisamos de mais um tempinho para realizar os últimos ajustes. Por favor, tente novamente mais tarde.',
                })
            }
        }
    }

    return (
        <>
            <Container>
                <Title>Estamos em Manutenção</Title>
                <Image />
                <Message>
                    Precisamos de mais um tempinho para realizar os ultimos ajustes. Por favor, tente
                    novamente mais tarde.
                </Message>

                <VerificationTitle>Próxima verificação automática em:</VerificationTitle>
                <TimeVerification>
                    0{minutes}:{seconds <= 9 ? '0' + seconds : seconds}
                </TimeVerification>
                <ButtonVerificationClick onClick={() => _verifyApiStatus()}>
                    VERIFICAR DISPONIBILIDADE
                </ButtonVerificationClick>
            </Container>
            <Alert ref={alertRef} />
        </>
    )
}

export default Manutence
