import React, { memo } from 'react'

import { Loading } from '#/modals'

import { Container } from './block-container.styled'

const BlockContainer = ({ loading, children, marginRight }) => {
    return (
        <Container className="block-container" block marginRight={marginRight}>
            {children}
            <Loading show={loading} ignoreRedux={false} />
        </Container>
    )
}

export default memo(BlockContainer)
