import styled from 'styled-components'

import { Feather } from '../../../icons'
const Container = styled.div`
    display: flex;
    align-items: center;
`

const Icon = styled(Feather).attrs({ size: 20 })`
    color: ${({ theme }) => theme.colors.primary};
    height: 100%;
`

const Label = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    margin-left: 4px;
    font-weight: bold;
`

export { Container, Icon, Label }
