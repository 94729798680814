import { format, parseISO } from 'date-fns'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from '#/utils'

import ButtonText from '../../button-text/button-text'
import {
    Bold,
    Container,
    ContainerButton,
    ContainerInfo,
    ContainerValues,
    Info,
    RowValues,
    Title,
    Value,
    WorkingTime,
} from './item-special-value.styled'

function ItemSpecialValue({ onDelete, onUpdate, variations = [], label, date }) {
    const { t } = useTranslation()
    return (
        <Container>
            <Title>{label}</Title>

            <ContainerInfo>
                {date && (
                    <Info>
                        <Bold>{t('date')}:</Bold> {format(parseISO(date), 'dd/MM/yyyy')}
                    </Info>
                )}
                <Info>
                    <Bold>{t('special-values')}:</Bold>
                </Info>

                {variations.map(({ workingtime, amount, original_amount }, i) => (
                    <RowValues key={`value_${i}`}>
                        <TextWorkingTime workingtime={workingtime} />
                        <ContainerValues>
                            <Value old>{formatCurrency(original_amount)}</Value>
                            <Value>{formatCurrency(amount)}</Value>
                        </ContainerValues>
                    </RowValues>
                ))}
            </ContainerInfo>
            {(onDelete || onUpdate) && (
                <ContainerButton>
                    {!!onUpdate && (
                        <ButtonText onClick={onUpdate} iconName="Edit2">
                            {t('edit')}
                        </ButtonText>
                    )}
                    {!!onDelete && (
                        <ButtonText onClick={onDelete} iconName="Trash2">
                            {t('delete')}
                        </ButtonText>
                    )}
                </ContainerButton>
            )}
        </Container>
    )
}

function TextWorkingTime({ workingtime }) {
    const { t } = useTranslation()
    const { label, start_hour, end_hour } = workingtime
    return <WorkingTime> {`${label} - ${start_hour} ${t('at')} ${end_hour}`}</WorkingTime>
}

export default memo(ItemSpecialValue)
