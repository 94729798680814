import { createActions, createReducer } from 'reduxsauce'

export const {
    Types,
    Creators: { setTheme },
} = createActions({
    setTheme: ['theme'],
})

const INITIAL_STATE = 'default'

const set = (state = INITIAL_STATE, action) => action.theme

const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_THEME]: set,
})

export default reducer
