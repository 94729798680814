import React, { memo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ContentSection, Switch } from '#/components'
import { Alert, AlertError } from '#/modals'
import { setTheme } from '#/redux/reducers/theme'

import { SettingsContent } from '../shared'
import { ContainerForm } from './theme.styled'
function Theme() {
    const theme = useSelector(state => state.theme)
    const dispatch = useDispatch()

    const { t } = useTranslation()

    const alertErrorRef = useRef()
    const alertRef = useRef()

    const _setDarkTheme = isDark => {
        if (isDark) {
            dispatch(setTheme('dark'))
        } else {
            dispatch(setTheme('default'))
        }
    }

    return (
        <SettingsContent title={t('theme')} iconName="Feather" withoutPadding>
            <ContentSection withoutPadding>
                <ContainerForm>
                    <Switch
                        title={t('switch.theme.dark')}
                        onChange={_setDarkTheme}
                        checked={theme === 'dark'}
                    />
                </ContainerForm>
            </ContentSection>
            <Alert ref={alertRef} />
            <AlertError ref={alertErrorRef} />
        </SettingsContent>
    )
}

export default memo(Theme)
