import styled from 'styled-components'

import { Feather } from '#/components'

const Container = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex: 1;
    .button-text {
        align-self: flex-end;
        margin-bottom: 30px;
    }
`

const ContainerTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    .button-add {
        margin-right: 20px;
    }
    margin-bottom: 10px;
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 16px;
    flex: 1;
`

const BackIcon = styled(Feather).attrs({ name: 'ArrowLeft' })`
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    margin-right: 4px;
`

const Footer = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`

export { BackIcon, ContainerTitle, Title, Footer, Container }
