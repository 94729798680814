import styled, { css } from 'styled-components'

const Container = styled.div`
    border-width: 2px;
    border-radius: 4px;
    padding: 2px 4px;
    border-color: ${({ theme, color }) => (color ? color : theme.colors.primary)};
    border-style: solid;
    width: fit-content;
    height: fit-content;

    ${({ variant, theme }) => {
        if (variant) {
            return css`
                border-color: ${theme.colors[variant]};
            `
        }
    }}
`

const Title = styled.div`
    color: ${({ theme, color }) => (color ? color : theme.colors.primary)};
    text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
    font-size: ${({ size }) => (size ? size : 10)}px;
    font-weight: ${({ bold }) => (bold ? 'bold' : '500')};
    user-select: none;
    ${({ variant, theme }) => {
        if (variant) {
            return css`
                color: ${theme.colors[variant]};
            `
        }
    }}
`

export { Container, Title }
