import React, { memo, useCallback } from 'react'

import { CheckBox } from '../check-box/check-box'
import { Container, Text } from './check-terms.styled'

function CheckBoxTerms({ text, checked, onChange }) {
    const _toggle = useCallback(() => {
        if (onChange) {
            onChange(!checked)
        }
    }, [onChange, checked])

    return (
        <Container>
            <CheckBox checked={checked} onClick={_toggle} />
            <Text>{text}</Text>
        </Container>
    )
}

export default memo(CheckBoxTerms)
