import styled, { css } from 'styled-components'

import { Feather, FontPA } from '#/components/icons'
const DEVICE_WIDTH = 370

const BOX_SIZE = (DEVICE_WIDTH - 20) / 7
const DAY_BOX_SIZE = BOX_SIZE - 10

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: ${DEVICE_WIDTH}px;
    user-select: none;
    position: relative;
`
const HeaderCalendar = styled.div`
    padding: 8px 16px;
    width: 100%;
    align-items: center;
    flex-direction: row;
    height: 50px;
    display: flex;
`
const MonthName = styled.div`
    flex: 1;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 500;
`

const BodyCalendar = styled.div`
    display: flex;
    flex-direction: column;
    width: ${({ width }) => width || DEVICE_WIDTH};
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    height: ${BOX_SIZE * 6}px;
`

const ContainerCalendar = styled.div`
    display: flex;
    flex: 1;
`

const ContainerBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${BOX_SIZE}px;
    height: ${({ noneDay }) => (noneDay ? 0 : BOX_SIZE - 8) + 2}px;
    align-content: center;
    justify-content: center;
    align-items: center;
`

const DayBoxDefault = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${DAY_BOX_SIZE}px;
    height: ${DAY_BOX_SIZE}px;
    border-radius: ${({ square }) => (square ? 4 : DAY_BOX_SIZE / 2)}px;
    cursor: pointer;
    ${({ colors, oldDay, theme }) => {
        if (oldDay) {
            return css`
                background-color: ${theme.colors.calendarOldDayBackground};
            `
        }

        if ((colors || []).length > 1) {
            return css`
                background-image: linear-gradient(${colors.join()});
            `
        }

        if ((colors || []).length === 1) {
            return css`
                background-color: ${colors[0]};
            `
        }

        return css`
            &:hover {
                background-color: ${({ theme }) => theme.colors.hover};
            }
        `
    }}
`

const WeekBox = styled.div`
    justify-content: center;
    align-items: center;
    width: ${DAY_BOX_SIZE}px;
    height: ${DAY_BOX_SIZE}px;
    display: flex;
`

const NumberDay = styled.div`
    font-size: ${DAY_BOX_SIZE / 2.2}px;
    color: ${({ theme, hasEvent }) => (hasEvent ? '#fff' : theme.colors.calendarDaysColor)};
    opacity: ${({ oldDay, hasEvent }) => (oldDay && !hasEvent ? 0.5 : 1.0)};
`

const Label = styled.div`
    font-size: ${DAY_BOX_SIZE / 2.2}px;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
`

const Circle = styled.div`
    background-color: #fff;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    align-self: center;
    bottom: 10%;
    margin-top: 5px;
    position: absolute;
`
const Today = styled.div`
    position: absolute;
    width: ${DAY_BOX_SIZE + 6}px;
    height: ${DAY_BOX_SIZE + 6}px;
    border-radius: ${({ square }) => (square ? 4 : (DAY_BOX_SIZE + 6) / 2)}px;
    pointer-events: none;
    border: 2px solid ${({ theme }) => theme.colors.calendarToday};
`

const ContainerConflict = styled.div`
    user-select: none;
    position: absolute;
    display: flex;
    right: 5%;
    top: 5%;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.danger};
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1.5px solid ${({ theme }) => theme.colors.background};

    font-weight: bold;
    color: #fff;
    text-align: center;
    padding: 1px 0px 1px 1px;
    font-size: 13px;
`

const IconVacancy = styled(FontPA).attrs({ name: 'vacancy', size: 8 })`
    position: absolute;
    right: 5%;
    top: 5%;
    color: ${({ theme, color }) => (color ? color : theme.colors.primary)};
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.background};
    padding: 1px;
    font-size: 14px;
    border-radius: 50%;
`

const Arrow = styled(Feather)`
    font-size: 10px;
    color: ${({ theme }) => theme.colors.primary};
    align-self: center;
    font-size: 25px;
    width: 35px;
    height: 35px;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    cursor: pointer;
    &:hover {
        background-color: ${({ theme }) => theme.colors.hover};
    }
`

export {
    Arrow,
    BodyCalendar,
    Circle,
    Container,
    ContainerBox,
    ContainerCalendar,
    ContainerConflict,
    DayBoxDefault,
    HeaderCalendar,
    IconVacancy,
    Label,
    MonthName,
    NumberDay,
    Today,
    WeekBox,
}
