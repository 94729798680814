import styled from 'styled-components'

import { Feather } from '#/components/icons'

const Container = styled.div`
    flex: 1;
    .check-box-value {
        margin-bottom: 20px;
    }
`

const Row = styled.div`
    display: flex;
    width: 100%;
    margin: 2px 0px;
    .button {
        border-radius: 2px;
        height: 28px;
        padding: 0px 6px;
    }
    .check-box {
        width: 150px;
    }
`

const ContainerTitleSpecializations = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const Close = styled(Feather).attrs({ name: 'X', size: 30 })`
    color: ${({ theme }) => theme.colors.text};
    align-self: flex-end;
    cursor: pointer;
`

const ContainerForm = styled.div`
    padding: 16px;
    .button {
        margin: 20px 0px;
    }
`

const VacanciesCounter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
`

const ActionContainer = styled.div`
    display: flex;
    align-items: center;
    user-select: none;
`

const ActionIconLeft = styled(Feather).attrs({ name: 'Minus' })`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 22px;
    margin-right: 20px;
    cursor: pointer;
`

const ActionIconRight = styled(Feather).attrs({ name: 'Plus' })`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 22px;
    margin-left: 20px;
    cursor: pointer;
`

const Value = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 16px;
`

export {
    ActionContainer,
    ActionIconLeft,
    ActionIconRight,
    Close,
    ContainerForm,
    ContainerTitleSpecializations,
    Row,
    VacanciesCounter,
    Value,
    Container,
}
