import { addMonths, format, isSameMonth, parseISO, subMonths } from 'date-fns'
import localePT from 'date-fns/locale/pt-BR'
import React, { memo, useEffect, useState } from 'react'

import { Loading } from '#/modals'
import { generateMonth, monthMapformatDate } from '#/utils/calendar-tools'

import {
    Arrow,
    BodyCalendar,
    Circle,
    Container,
    ContainerBox,
    ContainerCalendar,
    ContainerConflict,
    DayBoxDefault,
    HeaderCalendar,
    IconVacancy,
    Label,
    MonthName,
    NumberDay,
    Today,
    WeekBox,
} from './calendar.styled'

const Calendar = ({
    initialDate,
    events,
    onMonthChange,
    onDayPress,
    choose,
    loading,
    freeze,
    width,
    minDate,
    onDayLongPress,
    square,
}) => {
    const [today, setToday] = useState(new Date())
    const [date, setDate] = useState(new Date())
    const [minDateCalendar, setMinDateCalendar] = useState(null)
    const [months, setMonths] = useState([])
    const [mapMonths, setMapMonths] = useState([])

    useEffect(() => {
        if (initialDate) {
            setDate(parseISO(initialDate))
        }
    }, [initialDate])

    useEffect(() => {
        if (minDate) {
            setMinDateCalendar(parseISO(minDate))
        }
    }, [minDate])

    useEffect(() => {
        setToday(new Date())
    }, [])

    useEffect(() => {
        if (months.length > 0) {
            const calendars = months.map(item => generateMonth(item))
            setMapMonths(calendars)

            if (onMonthChange && calendars[0]) {
                let calendar = calendars[0]
                let date
                if (calendar.monthNumeric < 10) {
                    date = `${calendar.year}-0${calendar.monthNumeric}`
                } else {
                    date = `${calendar.year}-${calendar.monthNumeric}`
                }
                onMonthChange(date)
            }
        }
    }, [months, onMonthChange])

    useEffect(() => {
        if (date) {
            setMonths([date])
        }
    }, [date, minDateCalendar])

    const getMonthName = () => {
        if (!date) {
            return ''
        }
        const name = format(date, 'MMMM yyyy', { locale: localePT })

        return name
    }

    const _nextMonth = () => {
        setDate(addMonths(date, 1))
    }

    const _prevMonth = () => {
        setDate(subMonths(date, 1))
    }

    const _isSameMonth = index => {
        const month = months[index]
        if (month) {
            if (!isSameMonth(month, date)) {
                setDate(month)
            }
        }
    }

    const _verifyDay = (monthMap, day) => {
        let result = {}
        let oldMonth = false
        let currentMonth = false
        let isOld = false
        let isToday = false
        if (day) {
            if (monthMap.year < today.getFullYear()) {
                oldMonth = true
            } else if (monthMap.year > today.getFullYear()) {
                oldMonth = false
            } else {
                if (monthMap.monthNumeric < today.getMonth() + 1) {
                    oldMonth = true
                }
                if (monthMap.monthNumeric === today.getMonth() + 1) {
                    currentMonth = true
                }
            }

            if (oldMonth) {
                isOld = true
            }

            if (currentMonth) {
                isOld = Number(day) < today.getDate()
                isToday = Number(day) === today.getDate()
            }

            let dayEvent = { hasEvent: false, color: [], hasInfo: false, hasAlert: false, data: null }
            result = {
                ...dayEvent,
                onClick: !freeze
                    ? () => _onDayPress({ date: monthMapformatDate(monthMap, day), payload: dayEvent, isOld })
                    : null,
                onLongPress: !freeze
                    ? () =>
                          _onDayLongPress({
                              date: monthMapformatDate(monthMap, day),
                              payload: dayEvent,
                              isOld,
                          })
                    : null,
            }

            if (Array.isArray(events) && events.length > 0) {
                const event = events.find(item => item.date === monthMapformatDate(monthMap, day))
                if (event) {
                    result = {
                        ...event.payload,
                        onClick: !freeze ? () => _onDayPress(event) : null,
                        onLongPress: !freeze
                            ? () =>
                                  _onDayLongPress({
                                      date: monthMapformatDate(monthMap, day),
                                      payload: dayEvent,
                                      isOld,
                                  })
                            : null,
                        date: monthMapformatDate(monthMap, day),
                    }
                }
            }
        }
        return {
            number: day,
            oldDay: isOld,
            today: isToday,
            choose,
            ...result,
        }
    }

    const _onDayPress = item => {
        if (onDayPress) {
            onDayPress(item)
        }
    }
    const _onDayLongPress = item => {
        if (onDayLongPress) {
            onDayLongPress(item)
        }
    }

    return (
        <Container>
            <HeaderCalendar>
                <Arrow name="ChevronLeft" onClick={_prevMonth} />
                <MonthName>{getMonthName()}</MonthName>
                <Arrow name="ChevronRight" onClick={_nextMonth} />
            </HeaderCalendar>
            <Slider onChangeMonth={_isSameMonth} scrollEnabled={!freeze} width={width}>
                {mapMonths.map((month, index) => (
                    <BodyCalendar key={`month_${index}`} width={width}>
                        {month.matrix.map((week, row) => (
                            <ContainerCalendar key={`week_${row}`}>
                                {week.map((day, column) =>
                                    row === 0 ? (
                                        <Week key={`week_${column}`} label={day} />
                                    ) : (
                                        <Day
                                            square={square}
                                            key={`day_${column}`}
                                            {..._verifyDay(month, day)}
                                        />
                                    ),
                                )}
                            </ContainerCalendar>
                        ))}
                    </BodyCalendar>
                ))}
            </Slider>
            <Loading show={loading} />
        </Container>
    )
}

const Slider = memo(({ children, onChangeMonth, scrollEnabled, width }) => {
    return <div className="scroll">{children}</div>
})

const Day = memo(
    ({
        number,
        colors,
        hasEvent,
        hasAlert,
        hasInfo,
        hasVacancy,
        today,
        oldDay,
        onClick,
        opacity,
        square,
    }) => {
        return (
            <ContainerBox noneDay={number == null}>
                {number != null && (
                    <>
                        {today && <Today square={square} />}
                        <div onClick={onClick}>
                            {hasEvent ? (
                                <DayBoxDefault
                                    square={square}
                                    oldDay={oldDay}
                                    colors={colors}
                                    style={{ opacity: opacity ? 0.5 : 1 }}
                                >
                                    <NumberDay oldDay={oldDay} hasEvent>
                                        {number}
                                    </NumberDay>
                                    {hasInfo && <Circle />}
                                </DayBoxDefault>
                            ) : (
                                <DayBoxDefault square={square}>
                                    <NumberDay oldDay={oldDay}>{number}</NumberDay>
                                </DayBoxDefault>
                            )}
                        </div>

                        {hasAlert && <ContainerConflict>!</ContainerConflict>}
                        {hasVacancy && colors?.length > 0 && <IconVacancy color={colors[0]} />}
                    </>
                )}
            </ContainerBox>
        )
    },
)

const Week = memo(({ label }) => {
    return (
        <ContainerBox>
            <WeekBox>
                <Label>{label}</Label>
            </WeekBox>
        </ContainerBox>
    )
})

export default memo(Calendar)
