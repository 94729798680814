import styled, { css } from 'styled-components'

const Bold = styled.span`
    font-weight: bold;
    cursor: pointer;
    ${({ primary }) =>
        primary &&
        css`
            color: ${({ theme }) => theme.colors.primary};
        `}
`

export { Bold }
