import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    align-items: flex-end;
    @media (max-width: 992px) {
        flex-direction: column;
    }

    .input {
        &:first-child {
            margin-right: 20px;
            @media (max-width: 992px) {
                margin-right: 0px;
            }
        }
        &.small-field {
            width: 180px;
            @media (max-width: 992px) {
                width: 100%;
            }
        }
    }

    .select {
        &:first-child {
            margin-right: 20px;
            @media (max-width: 992px) {
                margin-right: 0px;
            }
        }
        &.small-field {
            width: 180px;
            @media (max-width: 992px) {
                width: 100%;
            }
        }
    }
`

export { Container }
