import React, {
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useImperativeHandle,
    useReducer,
    useState,
} from 'react'

import { OpportunityAPI } from '#/api'
import { List } from '#/components'
import { ItemOpportunity } from '#/components/items'
import { Loading } from '#/modals'

const initialStateParams = {
    page: 1,
    limit: 20,
    total: 0,
    status: 'inactive',
}

function reducerParams(state, newParams) {
    return {
        ...state,
        ...newParams,
    }
}

function TabInactiveOpportunity({ refreshActive }, ref) {
    const [params, setParams] = useReducer(reducerParams, initialStateParams)
    const [loading, setLoading] = useState(true)
    const [loadingMore, setLoadingMore] = useState(false)
    const [opportunities, setOpportunities] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    const _loadOpportunities = useCallback(async () => {
        setLoading(true)
        try {
            const result = await OpportunityAPI.myOpportunities(params)
            setParams({
                page: result.current_page,
                limit: result.per_page,
                total: result.total,
            })
            setOpportunities(result.items)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }, [params])

    const _loadMore = useCallback(async () => {
        if (params.total <= opportunities.length) {
            return
        }

        if (loadingMore) {
            return
        }

        if (params.page + 1 === currentPage) {
            return
        }
        setLoadingMore(true)
        setCurrentPage(currentPage + 1)
        try {
            const result = await OpportunityAPI.list({ ...params, page: params.page + 1 })
            setParams({
                page: result.current_page,
                total: result.total,
            })
            setOpportunities([...opportunities, ...result.items])
        } catch (err) {
            setCurrentPage(params.page)
        } finally {
            setLoadingMore(false)
        }
    }, [params, loadingMore, opportunities, currentPage])

    useImperativeHandle(
        ref,
        () => ({
            refresh: () => {
                _loadOpportunities()
            },
        }),
        [_loadOpportunities],
    )

    useEffect(() => {
        _loadOpportunities()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <List
                data={opportunities}
                onRenderItem={(item, i) => (
                    <ItemOpportunity
                        opportunity={item}
                        key={`opportunity_${i}`}
                        onStatusUpdate={refreshActive}
                    />
                )}
                loadMore={opportunities.length < params.total}
                onLoadMoreClick={_loadMore}
                isLoading={loadingMore}
                // infoEmptyProps={{
                //     iconName: 'Users',
                //     title: t('empty.groups.title'),
                //     message: t('empty.groups.message'),
                //     buttonText: t('create-group'),
                //     onButtonClick: () => history.push('/criar-grupo'),
                // }}
            />
            <Loading show={loading} />
        </>
    )
}

export default memo(forwardRef(TabInactiveOpportunity))
