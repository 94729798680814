import React, { forwardRef, memo, useCallback, useImperativeHandle, useReducer, useState } from 'react'

import { ModalBase } from '../shared'
import ChooseRecoveryOption from './choose-recovery-option/choose-recovery-option'
import FindAccount from './find-account/find-account'
import NewPassword from './new-password/new-password'
import SendToken from './send-token/send-token'
import { RecoveryContext } from './shared'

const initialState = {
    title: 'Recuperar Senha',
    message: null,
    icon: null,
    buttonText: null,
    showButton: false,
    step: 1,
}

function reducer(state, props) {
    return { ...state, ...props }
}

function RecoveryPassword(props, ref) {
    const [show, setShow] = useState(false)
    const [modalProps, dispatchProps] = useReducer(reducer, initialState)

    useImperativeHandle(ref, () => ({
        show: () => {
            setShow(true)
        },
    }))

    const _changeProps = useCallback(props => {
        dispatchProps(props)
    }, [])
    const _finished = useCallback(props => {
        setShow(false)
    }, [])

    const _onClose = useCallback(() => {
        setShow(false)
    }, [])

    return (
        <RecoveryContext.Provider
            value={{ recoveryProps: modalProps, changeProps: _changeProps, finished: _finished }}
        >
            <ModalBase {...modalProps} visible={show} onClose={_onClose}>
                {modalProps.step === 1 && <FindAccount />}
                {modalProps.step === 2 && <ChooseRecoveryOption />}
                {modalProps.step === 3 && <SendToken />}
                {modalProps.step === 4 && <NewPassword />}
            </ModalBase>
        </RecoveryContext.Provider>
    )
}

export default memo(forwardRef(RecoveryPassword))
