import styled from 'styled-components'

import { FontPA } from '#/components/icons'

const Icon = styled(FontPA).attrs({ name: 'vacancy' })`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 90px;
    margin-bottom: 16px;
`

export { Icon }
