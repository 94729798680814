import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { Badge } from '#/components'
import { adsUrl } from '#/utils'

import { WidgetContainer } from '../shared'
import { ContainerItem, FeatherIcon, FontPAIcon, Title } from './widget-navigator.styled'
function WidgetGroups() {
    const auth = useSelector(state => state?.auth)

    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()

    const activeIndex = useMemo(() => {
        if (location) {
            const { pathname } = location

            if (pathname.includes('oportunidades')) {
                return 2
            }
            if (pathname.includes('pendentes')) {
                return 1
            }

            return 0
        }
    }, [location])

    return (
        <WidgetContainer>
            <ContainerItem onClick={() => history.push('/')}>
                <FeatherIcon name="Home" active={activeIndex === 0} />
                <Title active={activeIndex === 0}>{t('feed')}</Title>
            </ContainerItem>
            {/* <ContainerItem onClick={() => history.push('/pendentes')}>
                <FeatherIcon name="FileText" active={activeIndex === 1} />
                <Title active={activeIndex === 1}>{t('pending')}</Title>
            </ContainerItem> */}
            <ContainerItem onClick={() => history.push('/oportunidades')}>
                <FontPAIcon name="opportunity" active={activeIndex === 2} />
                <Title active={activeIndex === 2}>{t('opportunities')}</Title>
            </ContainerItem>
            <ContainerItem onClick={() => (window.location.href = `${adsUrl}`)}>
                <FeatherIcon name="Monitor" />
                <Title active={activeIndex === 3}>{t('ads')}</Title>
                <Badge title={t('new')} />
            </ContainerItem>
        </WidgetContainer>
    )
}

export default memo(WidgetGroups)
