import { format } from 'date-fns'
import _ from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ScheduleAPI } from '#/api'
import { ContentSection, ItemSchedule, TitleBar } from '#/components'
import { useLoadGroup } from '#/hooks'

function GroupSchedule() {
    useLoadGroup()
    const group_id = useSelector(state => state.group?.id)

    const [schedules, setSchedules] = useState([])
    const [currentMonth, setCurrentMonth] = useState(0)
    const [loading, setLoading] = useState(true)

    const _loadSchedules = async group_id => {
        try {
            setLoading(true)
            const result = await ScheduleAPI.list(group_id)

            const schedules = _.chain(result.items)
                .groupBy('year')
                .map((value, key) => ({ title: key, items: value }))
                .orderBy('title', ['desc'])
                .value()
            setSchedules(schedules)
        } catch (error) {
            setSchedules([])
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setCurrentMonth(Number(format(new Date(), 'yyyyMM')))
        if (group_id) {
            _loadSchedules(group_id)
        }
    }, [group_id])

    return (
        <ContentSection loading={loading} withoutPadding>
            {schedules.map(({ title, items }, i) => (
                <div key={`year_${i}`}>
                    <TitleBar title={title} />
                    {items.map((item, i) => (
                        <ItemSchedule currentMonth={currentMonth} schedule={item} key={`schedule_${i}`} />
                    ))}
                </div>
            ))}
        </ContentSection>
    )
}

export default memo(GroupSchedule)
