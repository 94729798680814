import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    border-width: 1px;
    border-color: ${({ theme }) => theme.colors.backgroundOpacity};
    border-radius: ${({ theme }) => theme.sizes.radius}px;
    border-style: solid;
    margin-top: 10px;
    cursor: pointer;
    position: relative;

    &:hover {
        box-shadow: 3px 2px 0px 1px ${({ theme }) => theme.colors.backgroundOpacity};
    }
`

const Icon = styled.img`
    height: 15px;
    width: 15px;
    margin-left: 8px;
    position: absolute;
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: 500;
    text-align: center;
    flex: 1;
`

export { Container, Icon, Title }
