import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import {
    Home,
    Login,
    ManagerSchedule,
    Manutence,
    NotFound,
    PrintReports,
    PrintSchedule,
    Profile,
    ReviewSchedule,
} from '#/pages'
import {
    RegisterActive,
    RegisterComplete,
    RegisterEmail,
    RegisterPassword,
    RegisterPersonal,
    RegisterProfession,
} from '#/pages/register'
import { validateUserData } from '#/utils'

function MainRoutes(params) {
    const auth = useSelector(state => state.auth)
    let isAuthorization = false
    if (auth?.pending_stages !== null && auth?.pending_stages !== undefined) {
        isAuthorization = auth && auth?.pending_stages?.length === 0
    } else {
        isAuthorization = !!auth
    }

    return (
        <Switch>
            <Route
                path="/login"
                render={props => (isAuthorization ? <Redirect to="/" /> : <Login {...props} />)}
            />

            <Route path="/manutencao" component={Manutence} />

            <Route
                path="/criar-conta"
                render={props => (isAuthorization ? <Redirect to="/" /> : <RegisterEmail {...props} />)}
            />

            <Route
                path="/ativar-conta"
                render={props => (auth?.status === 3 ? <RegisterActive {...props} /> : <Redirect to="/" />)}
            />
            <PrivateRoute path="/email-pessoal" component={RegisterEmail} isRegister />
            <PrivateRoute path="/adicionar-senha" component={RegisterPassword} isRegister />
            <PrivateRoute path="/informacoes-pessoais" component={RegisterPersonal} isRegister />
            <PrivateRoute path="/informacoes-profissionais" component={RegisterProfession} isRegister />
            <PrivateRoute path="/cadastro-concluido" component={RegisterComplete} />

            <PrivateRoute path="/gerenciar-escala/:group_id/:period" component={ManagerSchedule} />
            <PrivateRoute path="/imprimir-escala/:group_id/:period" component={PrintSchedule} />
            <PrivateRoute
                path="/imprimir-relatorio/:group_id/:start_date/:end_date/:user"
                component={PrintReports}
            />
            <PrivateRoute path="/revisar-escala/:group_id/:period" component={ReviewSchedule} />
            {/* <PrivateRoute exact path="/valores-especiais/:group_id" component={SpecialValues} />
            <PrivateRoute path="/valores-especiais/:group_id/:special_id" component={SpecialValues} /> */}
            <PrivateRoute path="/configuracoes" component={Profile} isRegister />
            <PrivateRoute path="/perfil/:user_id" component={Profile} />
            <PrivateRoute path="/" component={Home} />
            <Route component={NotFound} />
        </Switch>
    )
}

function PrivateRoute({ component: Component, isRegister, ...rest }) {
    const auth = useSelector(state => state.auth)
    const user = useSelector(state => state.user)

    const isIncomplete = useMemo(() => {
        if (user && !isRegister) {
            return validateUserData(user)
        }
        return false
    }, [user, isRegister])

    if (isIncomplete) {
        return (
            <Route
                {...rest}
                render={props => (isRegister ? <Component {...props} /> : <Redirect to={isIncomplete} />)}
            />
        )
    }

    return (
        <Route
            {...rest}
            render={props =>
                auth ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location.pathname },
                        }}
                    />
                )
            }
        />
    )
}

export { MainRoutes }
