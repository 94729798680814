import styled, { css } from 'styled-components'

import { Feather } from '#/components/icons'
const Container = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    table {
        width: 100%;
        text-align: center;
        border-collapse: separate; /* Don't collapse */
        border-spacing: 0;
        color: ${({ theme }) => theme.colors.text};

        th {
            border-top: 1px solid ${({ theme }) => theme.colors.borderGrid};
            border-bottom: 1px solid ${({ theme }) => theme.colors.borderGrid};
            border-right: 1px solid ${({ theme }) => theme.colors.borderGrid};
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 2;
            background-color: ${({ theme }) => theme.colors.background};
            :first-child {
                border-left: 1px solid ${({ theme }) => theme.colors.borderGrid};
            }
        }

        td {
            border-top: 1px solid ${({ theme }) => theme.colors.borderGrid};
            border-right: 1px solid ${({ theme }) => theme.colors.borderGrid};
            font-size: 12px;
            overflow: hidden;
            word-break: normal;
            :first-child {
                border-left: 1px solid ${({ theme }) => theme.colors.borderGrid};
            }
        }

        .tr-last {
            td {
                border-bottom: 2px solid ${({ theme }) => theme.colors.borderSeparator};
            }
        }

        .title {
            font-weight: bold;
        }

        .td-user {
            width: 1%;
            white-space: nowrap;
            font-size: 10px;
            border-bottom: 2px solid ${({ theme }) => theme.colors.borderSeparator};
            border-left: 1px solid ${({ theme }) => theme.colors.borderGrid};
        }

        .working-time {
            text-align: center;
            width: 1%;
            min-height: 40px;
            white-space: nowrap;
            padding: 0px 10px;
            .wt-title {
                font-weight: bold;
            }
        }
    }
`

const BoxHeader = styled.th`
    font-size: 12px;
    font-weight: normal;
    overflow: hidden;
    word-break: normal;
    text-align: center;
    vertical-align: top;
    user-select: none;

    ${({ theme, isWeekend }) => {
        if (isWeekend) {
            return css`
                background-color: ${theme.colors.backgroundBox} !important;
            `
        }
    }}
`

const ConainerHeader = styled.div`
    display: flex;

    flex-direction: column;
    align-items: center;
`

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.borderGrid};
`

const Box = styled.td`
    padding: 0px;
    text-align: left;
    cursor: pointer;

    ${({ isOld, theme, isWeekend }) => {
        if (isWeekend && isOld) {
            return css`
                pointer-events: none;
                background-color: ${theme.colors.backgroundBox};
            `
        }
        if (isWeekend) {
            return css`
                background-color: ${theme.colors.backgroundBox};
            `
        }
        if (isOld) {
            return css`
                background-color: ${theme.colors.backgroundBox + '4D'};
                pointer-events: none;
            `
        }
    }}
`

const ContainerShift = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    .avatar {
        .container-avatar {
            border-width: 1px;
            cursor: pointer;
        }
    }
`

const ContainerUser = styled.div`
    width: 150px;
    max-height: 70px;
    text-align: start;
    padding: 2px;
`

const NameUser = styled.div`
    width: 100%;
    font-weight: bold;
    font-size: 12px;
    white-space: normal;
    line-height: 1.4;
`

const Circle = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 14.5px;
    overflow: hidden;
    background-color: ${({ theme, color }) => (color ? color : theme.colors.primary)};
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    user-select: none;
`
const MoreIcon = styled(Feather).attrs({ name: 'PlusCircle', size: 30 })`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 4px;
    align-self: center;
    margin-top: 20px;
`

export {
    Box,
    BoxHeader,
    ConainerHeader,
    Container,
    ContainerShift,
    ContainerUser,
    Divider,
    NameUser,
    Circle,
    MoreIcon,
}
