import React, { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '#/components'

import {
    Box,
    ButtonContainer,
    Close,
    Container,
    ContainerTitle,
    Content,
    Divider,
    Footer,
    Icon,
    LinkButton,
    Message,
    Title,
} from './modal-base.styled'
function ModalBase({
    children,
    title,
    message,
    icon = 'CheckCircle',
    type,
    showButton = true,
    isTwoOption,
    firstOptionText,
    firstOptionClick,
    SecondlyOptionText,
    SecondlyOptionClick,
    withoutPadding,
    headerPrimary,
    linkText,
    linkTextType,
    onLinkClick,
    visible,
    onClose,
    buttonText,
    onButtonClick,
    style,
}) {
    const { t } = useTranslation()

    const _onClick = useCallback(() => {
        if (onClose) {
            onClose()
        }
    }, [onClose])

    const _onSingleClick = useCallback(() => {
        if (onButtonClick) {
            onButtonClick()
        }
        _onClick()
    }, [_onClick, onButtonClick])

    const _onFirstOptionClick = useCallback(() => {
        if (firstOptionClick) {
            firstOptionClick()
        }
        _onClick()
    }, [_onClick, firstOptionClick])

    const _onSecondlyOptionClick = useCallback(() => {
        if (SecondlyOptionClick) {
            SecondlyOptionClick()
        }
        _onClick()
    }, [_onClick, SecondlyOptionClick])

    const _onLinkClick = useCallback(() => {
        if (onLinkClick) {
            onLinkClick()
        }
        _onClick()
    }, [_onClick, onLinkClick])
    if (!visible) {
        return null
    }
    return (
        <Container className="w3-animate-opacity">
            <Box style={style} className="w3-animate-bottom">
                <ContainerTitle headerPrimary={headerPrimary}>
                    {title && <Title headerPrimary={headerPrimary}>{title}</Title>}
                    <Close headerPrimary={headerPrimary} onClick={_onClick} />
                </ContainerTitle>
                <Content withoutPadding={withoutPadding}>
                    {icon && <Icon type={type} name={icon} />}
                    {message && <Message dangerouslySetInnerHTML={{ __html: message }} />}
                    {children}
                </Content>
                {(!!showButton || !!linkText) && (
                    <Footer>
                        {showButton && (
                            <ButtonContainer>
                                {isTwoOption ? (
                                    <>
                                        <Button
                                            onClick={_onFirstOptionClick}
                                            variant={`outline ${type}`}
                                            title={firstOptionText || t('no')}
                                        />
                                        <Divider />
                                        <Button
                                            onClick={_onSecondlyOptionClick}
                                            variant={type}
                                            title={SecondlyOptionText || t('yes')}
                                        />
                                    </>
                                ) : (
                                    <Button
                                        onClick={_onSingleClick}
                                        variant={type}
                                        title={buttonText || 'OK'}
                                    />
                                )}
                            </ButtonContainer>
                        )}
                        {!!linkText && (
                            <LinkButton onClick={_onLinkClick} type={linkTextType}>
                                {linkText}
                            </LinkButton>
                        )}
                    </Footer>
                )}
            </Box>
        </Container>
    )
}

export default memo(ModalBase)
