import axios from 'axios'

import { Store } from '../redux'
import { transformError } from '../utils'
let Token

//let baseURL = 'https://api.beta.plantaoativo.com/'
//let baseURL = 'https://api.plantaoativo.com.br/'
let baseURL = 'https://api.plantaoativo.com/'
//let baseURL = 'http://192.168.27.229:80/'

Store.subscribe(() => {
    const auth = Store.getState().auth

    if (auth) {
        Token = auth.token
    } else {
        Token = null
    }
})

const Axios = () => {
    const headers = {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-version': '2.0',
    }

    if (Token) {
        headers.Authorization = `Bearer ${Token}`
    }
    const Axios = axios.create({
        baseURL: baseURL,
        timeout: 32000,
        headers,
    })

    Axios.interceptors.response.use(
        response => {
            const message = response.data?.message
            if (message) {
                return { ...response.data.data, message }
            }
            return response.data.data
        },
        error => {
            const status = error?.response?.status || 0
            return Promise.reject({ message: transformError(error), status })
        },
    )

    return Axios
}

const status = async () => {
    return Axios().get('/status')
}

export { Axios, baseURL, status }
