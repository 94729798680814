import React, { memo, useContext } from 'react'
import ToogleSwitch from 'react-switch'
import { ThemeContext } from 'styled-components'

import { Container, Title } from './switch.styled'
function Switch({ title, onChange, checked }) {
    const { colors } = useContext(ThemeContext)
    return (
        <Container>
            <Title>{title}</Title>
            <ToogleSwitch
                onChange={onChange}
                checked={checked}
                onColor={colors.light}
                onHandleColor={colors.primary}
                handleDiameter={25}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={43}
                className="react-switch"
                id="material-switch"
            />
        </Container>
    )
}

export default memo(Switch)
