import { Axios } from './axios-config'

const UFlist = () => {
    return Axios().get('locations/br/states')
}

const listCitiesByUf = uf => {
    return Axios().get(`locations/br/states/${uf}/cities`)
}

const getInfo = () => {
    return Axios().get('info')
}

const getTermsUse = () => {
    return Axios().get('accounts/terms')
}

const checkEmail = email => {
    return Axios().post('status/email', { email })
}

export default {
    UFlist,
    listCitiesByUf,
    getInfo,
    getTermsUse,
    checkEmail,
}
