import styled from 'styled-components'

const Container = styled.div`
    border-radius: ${({ theme }) => theme.sizes.radius}px;
    border: 2px solid transparent;
    text-align: center;
    width: 100%;
    height: fit-content;
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    transition: 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently*/

    &:hover {
        box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.primary};
    }

    &.white {
        background-color: #fff;
        color: ${({ theme }) => theme.colors.primary};
        box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.shadow};
        &:hover {
            background-color: ${({ theme }) => theme.colors.primary};
            box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.primary};
            color: #fff;
        }
    }

    &.outline {
        color: #fff;
        border: 2px solid ${({ theme }) => theme.colors.primary};

        &:hover {
            background-color: ${({ theme }) => theme.colors.primary};
            box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.primary};
            color: white;
        }
    }

    &.disabled {
        background-color: ${({ theme }) => theme.colors.grey};
        color: ${({ theme }) => theme.colors.greyMedium};
        box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.shadow};
    }

    &.success {
        background-color: ${({ theme }) => theme.colors.success};
        color: $white;
        &:hover {
            box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.success};
        }
    }

    &.danger {
        background-color: ${({ theme }) => theme.colors.danger};
        color: $white;
        &:hover {
            box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.danger};
        }
    }
    &.danger-o {
        background-color: #fff;
        border-color: ${({ theme }) => theme.colors.danger};
        color: ${({ theme }) => theme.colors.danger};
        &:hover {
            background-color: ${({ theme }) => theme.colors.danger};
            color: #fff;
            box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.danger};
        }
    }

    &.yellow {
        background-color: ${({ theme }) => theme.colors.yellow};
        color: $white;
        &:hover {
            box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.yellow};
        }
    }

    &.white-border {
        background-color: #fff;
        border-color: ${({ theme }) => theme.colors.text};
        color: ${({ theme }) => theme.colors.text};
        &:hover {
            background-color: ${({ theme }) => theme.colors.text};
            color: #fff;
            box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.text};
        }
    }

    &.text-only {
        background-color: transparent;
        padding: 0;
        color: ${({ theme }) => theme.colors.primary};
        text-align: left;
        width: unset;
        border: 0;
        font-size: 21px/28px;
        font-weight: bold;
        &:hover {
            box-shadow: 0px 0px 0px 0px !important;
            text-decoration: underline;
        }
    }

    &.auto {
        width: fit-content;
        font-size: 14px;
    }
    &.small {
        @media (min-width: 1200px) {
            width: 180px;
            font-size: 14px;
        }
    }

    &.medium {
        @media (min-width: 1200px) {
            width: 250px;
            font-size: 14px;
        }
    }

    &.big {
        @media (min-width: 1200px) {
            width: 420px;
            font-size: 20px;
        }
    }
`

export { Container }
