import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Name = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
`

const Description = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 12px;
`

export { Container, Name, Description }
