import { useFormik } from 'formik'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { AmountBlockAPI } from '#/api'
import { Button, Calendar, Card, HeaderGroup, List, PageContainer, Warning } from '#/components'
import { ErrorMessage, Input, InputCurrency } from '#/components/forms'
import { ItemSpecialValue } from '#/components/items'
import { useLoadGroup } from '#/hooks'
import { Alert, AlertError, Confirmation } from '#/modals'
import { transformWorkingTime } from '#/utils'
import { makeEvent } from '#/utils/calendar-tools'

import {
    BackIcon,
    Container,
    ContainerCalendar,
    ContainerCards,
    ContainerForms,
    ContainerTitle,
    ContainerValues,
    ContentForms,
    Manager,
    Title,
} from './special-values.styled'

function SpecialValues() {
    useLoadGroup()

    const { t } = useTranslation()

    const history = useHistory()
    const confirmationRef = useRef()
    const alertRef = useRef()
    const alertErrorRef = useRef()

    const { special_id } = useParams()

    const group_id = useSelector(state => state.group?.id)
    const is_admin = useSelector(state => state.group?.is_admin)
    const group_color = useSelector(state => state.group?.color)
    const manager = useSelector(state => state.group?.manager)

    const [specialValues, setSpecialValues] = useState([])
    const [loading, setLoading] = useState(true)

    const { isSubmitting, values, errors, touched, setValues, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            date: '',
            label: '',
            variations: [],
        },
        validationSchema: Yup.object().shape({
            date: Yup.string().nullable().required(t('calendar.special-values.required')),
            label: Yup.string().trim().required(t('input.special-value.required')),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true)
                const body = {
                    date: values.date.date,
                    label: values.label,
                    variations: values.variations.map(item => ({
                        amount: item.amount,
                        workingtime_id: item.workingtime.id,
                    })),
                }
                if (special_id) {
                    const result = await AmountBlockAPI.updateSpecialAmount(group_id, special_id, body)
                    setSpecialValues(
                        specialValues.map(item => {
                            if (item.id === special_id) {
                                return result
                            } else {
                                return item
                            }
                        }),
                    )
                } else {
                    const result = await AmountBlockAPI.createSpecialAmount(group_id, body)
                    setSpecialValues([...specialValues, result])
                    resetForm()
                }

                alertRef.current && alertRef.current.show({ message: 'Plantão especial criado com sucesso!' })
            } catch ({ message }) {
                alertErrorRef.current && alertErrorRef.current.show({ message })
            }
        },
    })

    const _goBack = useCallback(() => {
        history.goBack()
    }, [history])

    const _loadWorkingTimes = useCallback(
        async group_id => {
            try {
                const result = await AmountBlockAPI.listWorkingTimes(group_id)
                setFieldValue(
                    'variations',
                    result.items.map(item => ({ amount: 0, workingtime: item })),
                )
            } catch (error) {
            } finally {
            }
        },
        [setFieldValue],
    )

    const _loadSpecialValues = useCallback(async group_id => {
        try {
            const result = await AmountBlockAPI.listSpecialAmounts(group_id)
            setSpecialValues(result.items)
        } catch (err) {}
    }, [])

    const _getSpecialValues = useCallback(
        async (group_id, special_id) => {
            try {
                const result = await AmountBlockAPI.getSpecialAmounts(group_id, special_id)

                setValues({
                    ...result,
                    date: makeEvent({ date: result.date, colors: [group_color] }),
                })
            } catch ({ message }) {
                console.error(message)
            }
        },
        [setValues, group_color],
    )

    const _onDelete = useCallback(
        async id => {
            try {
                setLoading(true)
                await AmountBlockAPI.removeSpecialAmount(group_id, id)
                setSpecialValues(specialValues.filter(item => item.id !== id))
            } catch ({ message }) {
                if (alertErrorRef.current) {
                    alertErrorRef.current.show({ message })
                }
            } finally {
                setLoading(false)
            }
        },
        [group_id, specialValues],
        alertErrorRef,
    )

    const _onRemove = useCallback(
        special_id => {
            if (confirmationRef.current) {
                confirmationRef.current.show({
                    title: 'Excluir plantão especial',
                    message: 'Tem certeza que deseja excluir este plantão especial?',
                    icon: 'Trash2',
                    onYesClick: () => {
                        _onDelete(special_id)
                    },
                })
            }
        },
        [confirmationRef, _onDelete],
    )

    const _updateVariation = useCallback(
        (workingtime_id, value) => {
            setFieldValue(
                'variations',
                values.variations.map(item =>
                    item.workingtime.id === workingtime_id ? { ...item, amount: value } : item,
                ),
            )
        },
        [setFieldValue, values],
    )

    useEffect(() => {
        if (group_id) {
            setTimeout(async () => {
                setLoading(true)
                await _loadSpecialValues(group_id)
                if (special_id) {
                    _getSpecialValues(group_id, special_id)
                } else {
                    await _loadWorkingTimes(group_id)
                }
                setLoading(false)
            }, 200)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group_id, special_id])

    return (
        <>
            <PageContainer backgroundContrast loading={loading || isSubmitting}>
                <Container>
                    <ContainerTitle>
                        <BackIcon onClick={_goBack} size={30} />
                        <Title>{t('special-values')}</Title>
                    </ContainerTitle>
                    <Card withoutPadding overflowHidden>
                        <HeaderGroup
                            leftComponent={
                                manager && (
                                    <>
                                        <Manager>
                                            {t('manager-schedule.manager', { name: manager.name })}
                                        </Manager>
                                    </>
                                )
                            }
                        />

                        <ContainerCards>
                            <ContainerCalendar>
                                <Warning>{t('group-settings.warning.calendar')}</Warning>
                                <Calendar
                                    events={values.date ? [values.date] : []}
                                    onDayPress={e =>
                                        setFieldValue(
                                            'date',
                                            makeEvent({ date: e.date, colors: [group_color] }),
                                        )
                                    }
                                />
                                {touched.date && errors.date && <ErrorMessage>{errors.date}</ErrorMessage>}
                            </ContainerCalendar>
                            <ContainerForms>
                                <Warning>{t('group-settings.warning.forms')}</Warning>
                                <ContentForms>
                                    <Input
                                        label={t('input.special-value.label')}
                                        value={values.label}
                                        onChangeText={text =>
                                            setFieldValue('label', (text || '').toUpperCase())
                                        }
                                        errorMessage={touched.label && errors.label}
                                    />

                                    {values.variations.map((item, i) => (
                                        <InputCurrency
                                            key={`currency_${i}`}
                                            label={transformWorkingTime(item.workingtime)}
                                            value={item.amount}
                                            onChangeText={e => _updateVariation(item.workingtime.id, e)}
                                        />
                                    ))}
                                </ContentForms>
                                <Button
                                    disabled={isSubmitting}
                                    title={special_id ? t('update') : t('add')}
                                    onClick={handleSubmit}
                                />
                            </ContainerForms>
                            <ContainerValues>
                                <Warning>{t('group-settings.warning.values')}</Warning>

                                <List
                                    data={specialValues}
                                    onRenderItem={(item, i) => (
                                        <ItemSpecialValue
                                            key={`block_${i}`}
                                            {...item}
                                            onDelete={is_admin ? () => _onRemove(item.id) : null}
                                            onUpdate={
                                                is_admin
                                                    ? () => {
                                                          history.replace(
                                                              `/valores-especiais/${group_id}/${item.id}`,
                                                          )
                                                      }
                                                    : null
                                            }
                                        />
                                    )}
                                />
                            </ContainerValues>
                        </ContainerCards>
                    </Card>
                </Container>
            </PageContainer>

            <Confirmation ref={confirmationRef} />
            <Alert ref={alertRef} />
            <AlertError ref={alertErrorRef} />
        </>
    )
}

export default SpecialValues
