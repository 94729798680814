import styled from 'styled-components'

const ContainerForm = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    .input {
        margin-left: 10px;
        :first-child {
            margin-left: 0px;
        }
    }
`

const ContainerButtons = styled.div`
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: flex-end;
    .button-text {
        margin-left: 20px;
    }
`

export { ContainerForm, ContainerButtons }
