import styled from 'styled-components'

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    text-align: start;
`
const Message = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: start;
    font-size: 14px;
`

export { Title, Message }
