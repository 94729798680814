import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import {
    Category,
    Circle,
    Container,
    ContainerInfo,
    Institution,
    LeftComponent,
    Title,
} from './header-group.styled'

function HeaderGroup({ leftComponent }) {
    const { name, institution, color, category } = useSelector(state => state.group || {})
    if (!name) {
        return null
    }
    return (
        <Container className="header-group" color={color && `#${color}`}>
            <Circle hasColor={!!color} />
            <ContainerInfo>
                <Title hasColor={!!color}>{name}</Title>
                <Institution hasColor={!!color}>{institution?.name}</Institution>
                <Category hasColor={!!color}>{category?.name}</Category>
            </ContainerInfo>
            {!!leftComponent && <LeftComponent>{leftComponent}</LeftComponent>}
        </Container>
    )
}

export default memo(HeaderGroup)
