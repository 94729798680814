import React, { memo, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import { Menu, SectionControl } from '#/components'
import { OpportunityContext } from '#/contexts'
import { Alert, Confirmation, IndicateUsers, InterestedUsers, ModalMenu } from '#/modals'
import { shareOpportunity } from '#/utils'

import OpportunityCreate from './opportunity-create/opportunity-create'
import OpportunityDetails from './opportunity-details/opportunity-details'
import OpportunityInterests from './opportunity-interests/opportunity-interests'
import OpportunityList from './opportunity-list/opportunity-list'
import OpportunityMy from './opportunity-my/opportunity-my'
import OpportunityRecommendations from './opportunity-recommendations/opportunity-recommendations'
import OpportunitySaved from './opportunity-saved/opportunity-saved'
import OpportunityUpdate from './opportunity-update/opportunity-update'
function SectionCreateGroup() {
    const { t } = useTranslation()
    const history = useHistory()
    const menuRef = useRef()
    const alertRef = useRef()
    const confirmationRef = useRef()
    const interestedUsersRef = useRef()
    const indicateUsersRef = useRef()
    const location = useLocation()

    const activeIndex = useMemo(() => {
        if (location) {
            const { pathname } = location

            // if (pathname.includes('recomendacoes')) {
            //     return 4
            // }
            if (pathname.includes('criar')) {
                return 2
            }
            if (pathname.includes('meus-anuncios')) {
                return 1
            }

            // if (pathname.includes('meus-interesses')) {
            //     return 2
            // }

            // if (pathname.includes('salvas')) {
            //     return 1
            // }

            if (pathname.includes('oportunidades')) {
                return 0
            }
        }
    }, [location])

    const menuItems = useMemo(() => {
        return [
            { title: t('for-you'), icon: 'User', router: '/oportunidades' },
            // { title: t('opportunities-saved'), icon: 'Bookmark', router: '/oportunidades/salvas' },
            // {
            //     title: t('my-interests'),
            //     icon: 'Heart',

            //     router: '/oportunidades/meus-interesses',
            // },
            { title: t('my-opportunities'), icon: 'Folder', router: '/oportunidades/meus-anuncios' },
            { title: t('opportunity-create.title'), icon: 'FolderPlus', router: '/oportunidades/criar' },
            // { title: t('recommendations'), icon: 'Send', router: '/oportunidades/recomendacoes' },
        ]
    }, [t])

    const _openMenu = useCallback(
        (opportunity, { onRemove, onClose, onReactivate }) => {
            if (!opportunity) {
                return
            }
            let options = []

            options.push({
                title: t('menu.opportunity.use-model'),
                onClick: () => {
                    history.push(`/oportunidades/criar?model=${opportunity.id}`)
                },
            })

            if (opportunity.status === 'finished') {
                options.push({ title: t('menu.opportunity.reactivate'), onClick: onReactivate })
            }

            options.push({
                title: t('menu.opportunity.update'),
                onClick: () => {
                    history.push(`/oportunidades/atualizar/${opportunity.id}`)
                },
            })

            if (opportunity.status === 'published') {
                options.push({ title: t('menu.opportunity.close'), onClick: onClose })
            }

            if (opportunity.status !== 'published') {
                options.push({ title: t('menu.opportunity.delete'), onClick: onRemove })
            }

            menuRef.current && menuRef.current.show({ title: t('menu.opportunity.title'), options })
        },
        [menuRef, t, history],
    )

    const _confirmInterest = useCallback(
        callback => {
            if (confirmationRef.current) {
                confirmationRef.current.show({
                    title: t('modal.confirm-interest.title'),
                    message: t('modal.confirm-interest.message'),
                    icon: 'AlertCircle',
                    onYesClick: callback,
                })
            }
        },
        [confirmationRef, t],
    )
    const _showInterestedUsers = useCallback(
        opportunity_id => {
            if (interestedUsersRef.current) {
                interestedUsersRef.current.show({ opportunity_id })
            }
        },
        [interestedUsersRef],
    )
    const _showReferralsUsers = useCallback(
        opportunity_id => {
            if (indicateUsersRef.current) {
                indicateUsersRef.current.show({ opportunity_id })
            }
        },
        [indicateUsersRef],
    )
    const _cancelInterest = useCallback(
        callback => {
            if (confirmationRef.current) {
                confirmationRef.current.show({
                    title: t('modal.cancel-interest.title'),
                    message: t('modal.cancel-interest.message'),
                    icon: 'AlertCircle',
                    type: 'danger',
                    onYesClick: callback,
                })
            }
        },
        [confirmationRef, t],
    )

    const _alertSuccess = useCallback(
        ({ title, message, opportunity, onComplete }) => {
            if (alertRef.current) {
                alertRef.current.show({
                    title,
                    message,
                    buttonText: t('share-opportunity'),
                    onPress: async () => {
                        try {
                            if (opportunity) {
                                await shareOpportunity(opportunity)

                                if (onComplete) {
                                    onComplete()
                                }
                            }
                        } catch ({ message }) {
                            // setMessageError(err)
                        }
                    },
                    icon: 'CheckCircle',
                    linkText: t('close'),
                })
            }
        },
        [alertRef, t],
    )

    return (
        <>
            <SectionControl
                contentControl={
                    <>
                        <Menu activeIndex={activeIndex} items={menuItems} />
                    </>
                }
            >
                <OpportunityContext.Provider
                    value={{
                        openMenu: _openMenu,
                        confirmInterest: _confirmInterest,
                        cancelInterest: _cancelInterest,
                        showInterestedUsers: _showInterestedUsers,
                        showReferralsUsers: _showReferralsUsers,
                        alertSuccess: _alertSuccess,
                    }}
                >
                    <Switch>
                        <Route exact path="/oportunidades" component={OpportunityList} />
                        <Route path="/oportunidades/criar" component={OpportunityCreate} />
                        <Route
                            path="/oportunidades/atualizar/:opportunity_id"
                            component={OpportunityUpdate}
                        />
                        <Route path="/oportunidades/salvas" component={OpportunitySaved} />
                        <Route path="/oportunidades/meus-interesses" component={OpportunityInterests} />
                        <Route path="/oportunidades/meus-anuncios" component={OpportunityMy} />
                        <Route path="/oportunidades/recomendacoes" component={OpportunityRecommendations} />
                        <Route path="/oportunidades/:opportunity_id" component={OpportunityDetails} />
                    </Switch>
                </OpportunityContext.Provider>
            </SectionControl>
            <ModalMenu ref={menuRef} />
            <Confirmation ref={confirmationRef} />
            <InterestedUsers ref={interestedUsersRef} />
            <IndicateUsers ref={indicateUsersRef} />
            <Alert ref={alertRef} />
        </>
    )
}

export default memo(SectionCreateGroup)
