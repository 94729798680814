import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex: 1;

    .section-container {
        min-width: 300px;
    }
`

export { Container }
