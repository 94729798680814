import styled from 'styled-components'

const Container = styled.div`
    min-height: 300px;
    padding: 16px;
    display: flex;
    flex-direction: column;
`

const ContainerForms = styled.div`
    flex: 1;
`

export { Container, ContainerForms }
