import { GroupAPI } from '../../api'
import { setGroup } from '../reducers/group'
import { showLoading } from '../reducers/loading'

export const loadGroup = group_id => {
    return async dispatch => {
        if (!group_id) {
            return
        }
        try {
            dispatch(showLoading(true))
            const result = await GroupAPI.view(group_id)

            dispatch(setGroup(result))
        } catch (err) {
        } finally {
            dispatch(showLoading(false))
        }
    }
}
