import React, { memo } from 'react'

import { Container, Icon, Title } from './item-menu.styled'

function ItemMenu({ title, onClick }) {
    return (
        <Container onClick={onClick}>
            <Title>{title}</Title>
            <Icon name="ChevronRight" />
        </Container>
    )
}

export default memo(ItemMenu)
