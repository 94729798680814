import { format, getDaysInMonth, parseISO } from 'date-fns'
import localePT from 'date-fns/locale/pt-BR'
import { saveAs } from 'file-saver'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ShiftAPI } from '#/api'
import { Button, CheckBox, PrintGridSchedule } from '#/components'
import { useLoadGroup } from '#/hooks'
import { Alert, Loading } from '#/modals'

import {
    Container,
    ContainerButtons,
    ContainerGrupoInfo,
    ContainerOptions,
    ContainerPageLogo,
    ContainerPageManager,
    ContainerPageTitle,
    ContainerTitle,
    ContainerTool,
    ContainerWorkingTimeInfo,
    Emission,
    GroupInstitution,
    GroupTitle,
    HeaderBottom,
    HeaderPage,
    HeaderTop,
    Line,
    Logo,
    ManagerName,
    PageA4,
    PageTitle,
    Profession,
    Subtitle,
    TitleTool,
    Tool,
    WorkingTime,
} from './print-schedule.styled'

function PrintSchedule() {
    useLoadGroup()
    const alertRef = useRef()
    const group = useSelector(state => state.group)
    const [loading, setLoading] = useState(true)
    const [shedules, setShedules] = useState([])
    const [vacancies, setVacancies] = useState(null)
    const [workingtimes, setWorkingtimes] = useState([])
    const { period } = useParams()
    const [displayName, setDisplayName] = useState('full_name')
    const [displayVacancy, setDisplayVacancy] = useState(false)
    const [typeSchedule, setTypeSchedule] = useState('fixed')
    const [extras, setExtras] = useState(['council'])

    const schedulePeriod = useMemo(() => {
        if (period) {
            try {
                return format(parseISO(period), "'Escala de' MMMM 'de' yyyy", { locale: localePT })
            } catch (err) {
                return null
            }
        }
    }, [period])

    const emission = useMemo(() => {
        return format(new Date(), "'Emissão em:' HH'h'mm 'de' dd/MM/yyyy", { locale: localePT })
    }, [])

    const _loadShifts = useCallback(
        async (group_id, params) => {
            try {
                setLoading(true)
                const result = await ShiftAPI.print(group_id, period, params)
                setShedules(result.users)
                setWorkingtimes(result.working_times)
            } catch (error) {
            } finally {
                setLoading(false)
            }
        },
        [period],
    )

    const _loadVacancies = useCallback(
        async group_id => {
            try {
                const result = await ShiftAPI.listVacancies(group_id, period)

                setVacancies(result)
            } catch (error) {}
        },
        [period],
    )

    const _changeExtra = useCallback(
        extra => {
            if (extras.includes(extra)) {
                setExtras(extras.filter(item => item !== extra))
            } else {
                setExtras([...extras, extra])
            }
        },
        [extras],
    )

    const _exportCSV = useCallback(() => {
        let days = []
        const lastDay = getDaysInMonth(parseISO(period))
        for (let i = 1; i <= lastDay; i++) {
            days.push(format(parseISO(period + '-' + (i < 10 ? '0' + i : i)), 'dd/MM/yyyy'))
        }
        const fields = `NOME,CATEGORIA,TURNO,${days.join(',')}\n`
        const values = shedules.map(({ user, items }) => {
            return workingtimes
                .filter((wt, index) => {
                    let hasShift

                    for (let i = 0; i < items.length; i++) {
                        const e = items[i].items[index]
                        if (e?.has_shift) {
                            hasShift = true
                            break
                        }
                    }

                    return hasShift
                })
                .map((wt, i) => {
                    return `${user.full_name},${user.description.replace('/', '-')},${wt.label} (${
                        wt.start_hour
                    } - ${wt.end_hour}),${items
                        .map(({ items }) => {
                            const shift = items[i]
                            return shift?.has_shift ? shift.label : ''
                        })
                        .join(',')}`
                })
                .join('\n')
        })
        var blob = new Blob([fields + values.join('\n')], { type: 'text/csv;charset=utf-8;' })
        saveAs(blob, `escala-${period}-${new Date().getTime()}.csv`)
    }, [shedules, period, workingtimes])

    const _alertExport = useCallback(() => {
        if (alertRef.current) {
            alertRef.current.show({
                icon: 'FileText',
                title: 'Exportar Escala',
                message: 'O arquivo será exportado como um CSV',
                onPress: () => {
                    setTimeout(() => {
                        _exportCSV()
                    }, 1000)
                },
            })
        }
    }, [alertRef, _exportCSV])

    const _alert = useCallback(() => {
        if (alertRef.current) {
            alertRef.current.show({
                icon: 'Printer',
                title: 'Aviso',
                message:
                    'Para uma melhor qualidade de impressão recomendamos que utilize os navegadores: <b>Chrome</b>, <b>Microsoft Edge</b> ou <b>Chromium<b/>',
                onPress: () => {
                    setTimeout(() => {
                        window.print()
                    }, 1000)
                },
            })
        }
    }, [alertRef])

    useEffect(() => {
        if (group) {
            _loadShifts(group.id, { type: typeSchedule })
            _loadVacancies(group.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group, typeSchedule])

    useEffect(() => {
        if (group) {
            document.title = `${group.name.toUpperCase()} - ${schedulePeriod || 'escala'}`
        }
    }, [schedulePeriod, group])

    if (!group) {
        return null
    }

    return (
        <>
            <Container>
                <ContainerTitle className="tools">
                    <ContainerTool>
                        <Tool>
                            <TitleTool>Escala</TitleTool>
                            <ContainerOptions>
                                <CheckBox
                                    label="Fixa"
                                    checked={typeSchedule === 'fixed'}
                                    onClick={() => setTypeSchedule('fixed')}
                                />
                                <CheckBox
                                    label="Dinâmica"
                                    checked={typeSchedule === 'dynamic'}
                                    onClick={() => setTypeSchedule('dynamic')}
                                />
                            </ContainerOptions>
                        </Tool>
                        <Tool>
                            <TitleTool>Nome de exibição</TitleTool>
                            <ContainerOptions>
                                <CheckBox
                                    label="Nome Completo"
                                    checked={displayName === 'full_name'}
                                    onClick={() => setDisplayName('full_name')}
                                />
                                <CheckBox
                                    label="Nome de Tratamento"
                                    checked={displayName === 'name'}
                                    onClick={() => setDisplayName('name')}
                                />
                            </ContainerOptions>
                        </Tool>
                        <Tool>
                            <TitleTool>Extras</TitleTool>
                            <ContainerOptions>
                                <CheckBox
                                    label="Conselho"
                                    square
                                    checked={extras.includes('council')}
                                    onClick={() => _changeExtra('council')}
                                />
                                <CheckBox
                                    label="Telefone"
                                    square
                                    checked={extras.includes('phone')}
                                    onClick={() => _changeExtra('phone')}
                                />
                            </ContainerOptions>
                        </Tool>
                        <Tool>
                            <TitleTool>Plantão Vago</TitleTool>
                            <ContainerOptions>
                                <CheckBox
                                    label="Exibir"
                                    square
                                    checked={displayVacancy}
                                    onClick={() => setDisplayVacancy(!displayVacancy)}
                                />
                            </ContainerOptions>
                        </Tool>
                    </ContainerTool>
                    {!loading && !!schedulePeriod && (
                        <ContainerButtons>
                            <Button
                                variant="white"
                                title="Exportar"
                                dimension="auto"
                                onClick={_alertExport}
                            />
                            <Button title="Imprimir" dimension="auto" onClick={_alert} />
                        </ContainerButtons>
                    )}
                </ContainerTitle>

                {schedulePeriod ? (
                    <>
                        {/* <PageA4> */}
                        {shedules.length > 0 ? (
                            <PrintGridSchedule
                                displayName={displayName || 'name'}
                                extras={extras}
                                groupID={group.id}
                                period={period}
                                vacancies={displayVacancy && vacancies}
                                shedules={shedules}
                                isDynamic={typeSchedule === 'dynamic'}
                                header={
                                    <HeaderPage>
                                        <HeaderTop>
                                            <ContainerPageLogo>
                                                <Logo />
                                            </ContainerPageLogo>
                                            <ContainerPageTitle>
                                                <PageTitle>{schedulePeriod}</PageTitle>
                                                <Subtitle>{`Escala ${
                                                    typeSchedule === 'fixed' ? 'Fixa' : 'Dinâmica'
                                                }`}</Subtitle>
                                                <Emission>{emission}</Emission>
                                            </ContainerPageTitle>
                                            <ContainerPageManager>
                                                <Line />
                                                <ManagerName>{group.manager.full_name}</ManagerName>
                                                <Profession>
                                                    {group.manager.description} - {group.manager.council}
                                                </Profession>
                                            </ContainerPageManager>
                                        </HeaderTop>
                                        <HeaderBottom>
                                            <ContainerGrupoInfo>
                                                <GroupTitle>{`${group.name} (${group.category.name})`}</GroupTitle>
                                                <GroupInstitution>{group.institution?.name}</GroupInstitution>
                                            </ContainerGrupoInfo>
                                            <ContainerWorkingTimeInfo>
                                                {workingtimes.map((item, i) => (
                                                    <WorkingTime key={`work_${i}`}>
                                                        {`(${item.label}) ${item.start_hour} - ${item.end_hour}`}
                                                    </WorkingTime>
                                                ))}
                                            </ContainerWorkingTimeInfo>
                                        </HeaderBottom>
                                    </HeaderPage>
                                }
                            />
                        ) : (
                            <>{!loading && <div>Escala vazia</div>}</>
                        )}
                        {/* </PageA4> */}
                    </>
                ) : (
                    <div>Data Inválido</div>
                )}
            </Container>
            <Alert ref={alertRef} />
            <Loading show={loading} />
        </>
    )
}

export default PrintSchedule
