import { Axios } from './axios-config'

const view = (groupId, shiftId) => {
    return Axios().get(`groups/${groupId}/shifts/${shiftId}`)
}

const list = (group_id, period, params) => {
    return Axios().get(`groups/${group_id}/schedules/${period}/users`, { params })
}
const print = (group_id, period, params) => {
    return Axios().get(`groups/${group_id}/schedules/${period}/print`, { params })
}
const listShiftsByUser = (group_id, period, user_id) => {
    return Axios().get(`groups/${group_id}/schedules/${period}/shifts/${user_id}`)
}

const listVacancies = (group_id, period) => {
    return Axios().get(`groups/${group_id}/schedules/${period}/vacancies`)
}

const getVacancy = (group_id, vacancy) => {
    return Axios().get(`groups/${group_id}/vacancies/${vacancy}`)
}

const createVacancy = (group_id, body) => {
    return Axios().post(`groups/${group_id}/vacancies`, body)
}

const create = (group_id, body) => {
    return Axios().post(`groups/${group_id}/shifts`, body)
}

const updateVacancy = (group_id, vacancy_id, body) => {
    return Axios().patch(`groups/${group_id}/vacancies/${vacancy_id}`, body)
}

const remove = (group_id, shift_id) => {
    return Axios().delete(`groups/${group_id}/shifts/${shift_id}`, {})
}

const removeVacancy = (group_id, vacancy_id) => {
    return Axios().delete(`groups/${group_id}/vacancies/${vacancy_id}`, {
        data: {
            type: 'all',
        },
    })
}

export default {
    view,
    create,
    createVacancy,
    print,
    remove,
    removeVacancy,
    list,
    listShiftsByUser,
    listVacancies,
    getVacancy,
    updateVacancy,
}
