import styled from 'styled-components'

const Text = styled.div`
    font-size: 16px;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.text};
    user-select: none;
`

const Required = styled.span`
    color: ${({ theme }) => theme.colors.text};
`

export { Text, Required }
