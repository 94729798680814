import {
    differenceInMinutes,
    eachDayOfInterval,
    endOfMonth,
    isBefore,
    isWeekend,
    parseISO,
    startOfMonth,
    subMonths,
} from 'date-fns'
import React, { memo, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import { useHistory } from 'react-router-dom'

import { ShiftAPI } from '#/api'
import loadingData from '#/assets/animation/loading.json'
import Avatar from '#/components/avatar/avatar'
import { GridScheduleContext } from '#/contexts'
import { AlertError, ManagerVacancy } from '#/modals'
import { minutesToHours, parseQuery } from '#/utils'

import {
    Box,
    BoxHeader,
    Circle,
    ConainerHeader,
    Container,
    ContainerShift,
    ContainerUser,
    Divider,
    MoreIcon,
    NameUser,
} from './grid-schedule.styled'

function GridSchedule({ period, shedules, vacancies, loadMore, onMoreClick }) {
    const { group_id, schedule_status, onAddShift, onRemoveShift, onChangeVacancy, ignoreOld } =
        useContext(GridScheduleContext)

    const history = useHistory()
    const alertErrorRef = useRef()
    const managerScheduleRef = useRef()
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)

    const minDate = useMemo(() => {
        return startOfMonth(subMonths(new Date(), 1))
    }, [])

    const days = useMemo(() => {
        if (period) {
            return eachDayOfInterval({
                start: startOfMonth(parseISO(period)),
                end: endOfMonth(parseISO(period)),
            })
        }
        return []
    }, [period])

    const _addShift = useCallback(
        async (user, workingtime, shift, minutes) => {
            if (loading) {
                return
            }
            try {
                setLoading(true)
                const result = await ShiftAPI.create(group_id, {
                    user_id: user.id,
                    workingtime_id: workingtime.id,
                    date: shift.date,
                })
                if (schedule_status === 'not_created') {
                    history.replace({
                        pathname: `/gerenciar-escala/${group_id}/${period}`,
                        search: parseQuery({ status: 'draft' }),
                    })
                }

                const newMinutes = calcWorkingTimes('increment', workingtime, minutes)
                const newShift = { date: shift.date, id: result.id }
                onAddShift(user.id, workingtime.id, newShift, newMinutes)
            } catch ({ message }) {
                alert(message)
            } finally {
                setLoading(false)
            }
        },
        [group_id, schedule_status, onAddShift, loading, history, period],
    )

    const _removeShift = useCallback(
        async (user, workingtime, shift, minutes) => {
            if (loading) {
                return
            }
            try {
                setLoading(true)

                await ShiftAPI.remove(group_id, shift.id)

                const newMinutes = calcWorkingTimes('decrement', workingtime, minutes)
                onRemoveShift(user.id, workingtime.id, shift.id, newMinutes)
            } catch ({ message }) {
                alert(message)
            } finally {
                setLoading(false)
            }
        },
        [group_id, onRemoveShift, loading],
    )

    const _removeVacancy = useCallback(
        async (workingtime, vacancy) => {
            try {
                setLoading(true)

                await ShiftAPI.removeVacancy(group_id, vacancy.id)

                const newVacancy = { date: vacancy.date, id: null, available: 0, total: 0 }
                onChangeVacancy(workingtime.id, newVacancy, 0, 0)
            } catch ({ message }) {
                alert(message)
            } finally {
                setLoading(false)
            }
        },
        [group_id, onChangeVacancy],
    )

    const _updateVacancy = useCallback(
        async (workingtime, vacancy, minutes) => {
            if (loading) {
                return
            }
            const callback = async slots => {
                if (vacancy.id === null && slots === 0) {
                    return
                }
                if (vacancy.id && slots === 0) {
                    _removeVacancy(workingtime, vacancy)
                    return
                }
                try {
                    setLoading(true)
                    const result = await ShiftAPI.createVacancy(group_id, {
                        workingtime_id: workingtime.id,
                        date: vacancy.date,
                        slots: slots,
                    })
                    if (schedule_status === 'not_created') {
                        history.replace({
                            pathname: `/gerenciar-escala/${group_id}/${period}`,
                            search: parseQuery({ status: 'draft' }),
                        })
                    }

                    const newMinutes = calcWorkingTimes('increment', workingtime, minutes * slots)
                    const newVacancy = { date: vacancy.date, id: result.id }
                    onChangeVacancy(workingtime.id, newVacancy, newMinutes, slots)
                } catch ({ message }) {
                    alert(message)
                } finally {
                    setLoading(false)
                }
            }
            if (managerScheduleRef.current) {
                managerScheduleRef.current.show(vacancy.total, callback)
            }
        },
        [group_id, history, schedule_status, onChangeVacancy, loading, _removeVacancy, period],
    )

    return (
        <>
            <Container className="grid-schedule">
                <table>
                    <thead>
                        <tr>
                            <BoxHeader className="title" rowSpan="2">
                                {t('name')}
                            </BoxHeader>
                            <BoxHeader className="title" rowSpan="2">
                                {t('workingtime')}
                            </BoxHeader>
                            {days.map((day, i) => (
                                <BoxHeader key={`week_${i}`} isWeekend={isWeekend(day)} className="title">
                                    <ConainerHeader>
                                        <span>{getSymbolOfWeek(day.getDay())}</span>
                                        <Divider />
                                        <span>{transformDay(day.getDate())}</span>
                                    </ConainerHeader>
                                </BoxHeader>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {vacancies && (
                            <>
                                <tr className={vacancies.items.length === 1 ? 'tr-last' : null}>
                                    <td className="td-user" rowSpan={vacancies.items.length}>
                                        <ContainerUser>
                                            <NameUser>{t('vacancy')}</NameUser>
                                            <div>{minutesToHours(vacancies.minutes)}</div>
                                        </ContainerUser>
                                    </td>
                                    <RenderFirstVacancyWorkingTime
                                        items={vacancies.items}
                                        minutes={vacancies.minutes}
                                        updateVacancy={_updateVacancy}
                                        ignoreOld={ignoreOld}
                                        minDate={minDate}
                                    />
                                </tr>
                                <RenderOthersVacancyWorkingTime
                                    items={vacancies.items}
                                    minutes={vacancies.minutes}
                                    updateVacancy={_updateVacancy}
                                    minDate={minDate}
                                />
                            </>
                        )}
                        {shedules.map(
                            ({ user, items, minutes }, i) =>
                                user?.role !== 'analyst' &&
                                user?.role !== 'observer' && (
                                    <React.Fragment key={`item_${i}`}>
                                        <tr className={items.length === 1 ? 'tr-last' : null}>
                                            <td className="td-user" rowSpan={items.length}>
                                                <ContainerUser>
                                                    <NameUser>{user.full_name}</NameUser>
                                                    <div>{minutesToHours(minutes)}</div>
                                                </ContainerUser>
                                            </td>
                                            <RenderFirstShiftWorkingTime
                                                items={items}
                                                user={user}
                                                addShift={_addShift}
                                                removeShift={_removeShift}
                                                minutes={minutes}
                                                ignoreOld={ignoreOld}
                                                minDate={minDate}
                                            />
                                        </tr>
                                        <RenderOthersShiftWorkingTime
                                            items={items}
                                            user={user}
                                            addShift={_addShift}
                                            removeShift={_removeShift}
                                            minutes={minutes}
                                            ignoreOld={ignoreOld}
                                            minDate={minDate}
                                        />
                                    </React.Fragment>
                                ),
                        )}
                    </tbody>
                </table>
                {loadMore && <MoreIcon onClick={onMoreClick} />}
            </Container>
            <ManagerVacancy ref={managerScheduleRef} />
            <AlertError ref={alertErrorRef} />
        </>
    )
}

const RenderFirstShiftWorkingTime = memo(
    ({ items, user, addShift, removeShift, minutes, ignoreOld, minDate }) => {
        const { t } = useTranslation()
        const { color } = useContext(GridScheduleContext)
        const [loadingIndex, setLoadingIndex] = useState(null)

        const _addOrRemoveShift = useCallback(
            async (workingtime, shift, index) => {
                setLoadingIndex(index)
                if (!shift.id) {
                    await addShift(user, workingtime, shift, minutes)
                } else {
                    await removeShift(user, workingtime, shift, minutes)
                }
                setLoadingIndex(null)
            },
            [user, addShift, removeShift, minutes],
        )
        if (items.length <= 0) {
            return null
        }
        const { workingtime, shifts } = items[0]
        return (
            <>
                <td className="working-time">
                    <span className="wt-title">{workingtime.label}</span>
                    {` - ${workingtime.start_hour} ${t('at')} ${workingtime.end_hour}`}
                </td>
                {shifts.map((shift, i) => {
                    const date = parseISO(shift.date)
                    return (
                        <Box
                            key={`box_${i}`}
                            isWeekend={isWeekend(date)}
                            isOld={!ignoreOld && checkIsOld(date, minDate)}
                            onClick={() => _addOrRemoveShift(workingtime, shift, i)}
                        >
                            <ContainerShift>
                                {loadingIndex === i ? (
                                    <Await />
                                ) : (
                                    <>
                                        {!!shift.id && (
                                            <Avatar
                                                color={color}
                                                border
                                                name={user.name}
                                                source={user.photo}
                                                size={25}
                                            />
                                        )}
                                    </>
                                )}
                            </ContainerShift>
                        </Box>
                    )
                })}
            </>
        )
    },
)

const RenderOthersShiftWorkingTime = memo(
    ({ items, user, minutes, addShift, removeShift, ignoreOld, minDate }) => {
        const { t } = useTranslation()
        const { color } = useContext(GridScheduleContext)
        const [loadingIndex, setLoadingIndex] = useState(null)

        const _addOrRemoveShift = useCallback(
            async (workingtime, shift, index) => {
                setLoadingIndex(index)
                if (!shift.id) {
                    await addShift(user, workingtime, shift, minutes)
                } else {
                    await removeShift(user, workingtime, shift, minutes)
                }
                setLoadingIndex(null)
            },
            [user, addShift, removeShift, minutes],
        )

        if (items.length <= 1) {
            return null
        }
        return (
            <>
                {items.map(({ workingtime, shifts }, i) => {
                    if (i === 0) {
                        return null
                    }

                    return (
                        <tr key={`wt_${i}`} className={items.length === i + 1 ? 'tr-last' : null}>
                            <td className="working-time">
                                <span className="wt-title">{workingtime.label}</span>
                                {` - ${workingtime.start_hour} ${t('at')} ${workingtime.end_hour}`}
                            </td>
                            {shifts.map((shift, i) => {
                                const date = parseISO(shift.date)
                                return (
                                    <Box
                                        key={`box_${i}`}
                                        isWeekend={isWeekend(date)}
                                        isOld={!ignoreOld && checkIsOld(date, minDate)}
                                        onClick={() => _addOrRemoveShift(workingtime, shift, i)}
                                    >
                                        <ContainerShift>
                                            {loadingIndex === i ? (
                                                <Await />
                                            ) : (
                                                <>
                                                    {!!shift.id && (
                                                        <Avatar
                                                            color={color}
                                                            border
                                                            name={user.name}
                                                            source={user.photo}
                                                            size={25}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </ContainerShift>
                                    </Box>
                                )
                            })}
                        </tr>
                    )
                })}
            </>
        )
    },
)

const RenderFirstVacancyWorkingTime = memo(({ items, minutes, updateVacancy, ignoreOld, minDate }) => {
    const { t } = useTranslation()
    const { color } = useContext(GridScheduleContext)

    const _updateVacancy = useCallback(
        async (workingtime, vacancy, index) => {
            if (updateVacancy) {
                await updateVacancy(workingtime, vacancy, minutes)
            }
        },
        [updateVacancy, minutes],
    )
    if (items.length <= 0) {
        return null
    }
    const { workingtime, vacancies } = items[0]
    return (
        <>
            <td className="working-time">
                <span className="wt-title">{workingtime.label}</span>
                {` - ${workingtime.start_hour} ${t('at')} ${workingtime.end_hour}`}
            </td>
            {vacancies.map((vacancy, i) => {
                const date = parseISO(vacancy.date)
                return (
                    <Box
                        key={`box_${i}`}
                        isWeekend={isWeekend(date)}
                        isOld={!ignoreOld && checkIsOld(date, minDate)}
                        onClick={() => _updateVacancy(workingtime, vacancy, i)}
                    >
                        <ContainerShift>
                            {!!vacancy.id && <Circle color={color}>{vacancy.total}</Circle>}
                        </ContainerShift>
                    </Box>
                )
            })}
        </>
    )
})

const RenderOthersVacancyWorkingTime = memo(({ items, minutes, updateVacancy, minDate }) => {
    const { t } = useTranslation()
    const { color } = useContext(GridScheduleContext)

    const _updateVacancy = useCallback(
        async (workingtime, vacancy, index) => {
            if (updateVacancy) {
                await updateVacancy(workingtime, vacancy, minutes)
            }
        },
        [updateVacancy, minutes],
    )
    if (items.length <= 1) {
        return null
    }
    return (
        <>
            {items.map(({ workingtime, vacancies }, i) => {
                if (i === 0) {
                    return null
                }

                return (
                    <tr key={`wt_${i}`} className={items.length === i + 1 ? 'tr-last' : null}>
                        <td className="working-time">
                            <span className="wt-title">{workingtime.label}</span>
                            {` - ${workingtime.start_hour} ${t('at')} ${workingtime.end_hour}`}
                        </td>
                        {vacancies.map((vacancy, i) => {
                            const date = parseISO(vacancy.date)
                            return (
                                <Box
                                    key={`box_${i}`}
                                    isWeekend={isWeekend(date)}
                                    isOld={checkIsOld(date, minDate)}
                                    onClick={() => _updateVacancy(workingtime, vacancy, i)}
                                >
                                    <ContainerShift>
                                        {!!vacancy.id && <Circle color={color}>{vacancy.total}</Circle>}
                                    </ContainerShift>
                                </Box>
                            )
                        })}
                    </tr>
                )
            })}
        </>
    )
})

const Await = memo(() => {
    return (
        <Lottie
            options={{
                loop: true,
                autoplay: true,
                animationData: loadingData,
            }}
            height={20}
            width={20}
        />
    )
})

function getSymbolOfWeek(day) {
    const weeks = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']

    if (day >= 0 && day < 7) {
        return weeks[day]
    }
    return ''
}

function transformDay(day) {
    if (day < 10) {
        return `0${day}`
    } else {
        return day.toString()
    }
}

const getDateFromSchedule = schedule => {
    const currentDate = new Date()
    const [hours, minutes] = schedule.split(':')

    currentDate.setHours(hours, minutes, 0, 0)
    return currentDate
}

function calcWorkingTimes(action, workingTime, user_minutes) {
    const startDate = getDateFromSchedule(workingTime.start_hour)
    const endDate = getDateFromSchedule(workingTime.end_hour)

    if (endDate < startDate) {
        endDate.setDate(endDate.getDate() + 1)
    }

    const totalMinutes = differenceInMinutes(endDate, startDate) || 0

    if (action === 'increment') {
        const newMinutes = user_minutes + totalMinutes
        return newMinutes || 0
    }

    if (action === 'decrement') {
        const newMinutes = user_minutes - totalMinutes
        if (newMinutes < 0) {
            return 0
        }

        return newMinutes || 0
    }
}

function checkIsOld(date, minDate) {
    let isOld = false

    if (minDate && isBefore(date, minDate)) {
        isOld = true
    }

    return isOld
}
export default memo(GridSchedule)
