import React from 'react'

import { PageContainer } from '#/components'

import MainDesktop from './main-desktop/main-desktop'
function Home() {
    return (
        <PageContainer backgroundContrast>
            <MainDesktop />
        </PageContainer>
    )
}

export default Home
