import { useFormik } from 'formik'
import React, { memo, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

import { AuthAPI } from '../../../api'
import { Button, Input } from '../../../components'
import { showLoading } from '../../../redux/reducers/loading'
import AlertError from '../../alert-error/alert-error'
import { RecoveryContext } from '../shared'

const FindAccount = () => {
    const { changeProps } = useContext(RecoveryContext)
    const dispatch = useDispatch()
    const alertErrorRef = useRef()

    const { t } = useTranslation()

    const {
        isValid,
        dirty,
        isSubmitting,
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        handleBlur,
    } = useFormik({
        initialValues: { email: '' },
        validationSchema: Yup.object().shape({
            email: Yup.string().trim().email(t('input.email.invalid')).required(t('input.email.required')),
        }),
        onSubmit: async values => {
            try {
                dispatch(showLoading(true))

                const result = await AuthAPI.recoverOptions({ ...values })
                changeProps({ step: 2, options: result.items, ...values })
            } catch ({ message }) {
                alertErrorRef.current && alertErrorRef.current.show({ message })
            } finally {
                dispatch(showLoading(false))
            }
        },
    })

    useEffect(() => {
        changeProps({
            title: t('recovery.find.title'),
            message: t('recovery.find.message'),
            icon: 'Key',
        })
        return () => {
            changeProps({
                message: null,
                icon: null,
            })
        }
    }, [changeProps, t])

    return (
        <>
            <div>
                <Input
                    name="email"
                    label={t('input.email.label')}
                    type="email"
                    value={values.email}
                    onChangeText={e => setFieldValue('email', e)}
                    onBlur={handleBlur('email')}
                    errorMessage={touched.email && errors.email}
                />
                <Button
                    disabled={!isValid || !dirty || isSubmitting}
                    title={t('recovery.find')}
                    onClick={handleSubmit}
                />
            </div>
            <AlertError ref={alertErrorRef} />
        </>
    )
}

export default memo(FindAccount)
