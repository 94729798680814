import React, { memo, useCallback, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import { GroupAPI } from '#/api'
import { ItemGroup, List, Search } from '#/components'
import { GroupChangeBehavior, RemoveGroupBehavior } from '#/subjects'

import { WidgetContainer } from '../shared'
import { ContainerSearch } from './widget-groups.styled'
const initialStateParams = {
    page: 1,
    total: 0,
    type: 'public',
}
function reducerParams(state, newParams) {
    return {
        ...state,
        ...newParams,
    }
}

function WidgetGroups() {
    const { t } = useTranslation()
    const location = useLocation()

    const [params, setParams] = useReducer(reducerParams, initialStateParams)
    const [loading, setLoading] = useState(true)
    const [groups, setGroups] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const history = useHistory()

    const isActive = useCallback(
        group_id => {
            const { pathname } = location
            return pathname.includes(group_id)
        },
        [location],
    )

    const _checkRole = useCallback(group => {
        const roles = group.filter(group => group.role !== null)
        const members = group.filter(group => group.role === null)

        return [...roles, ...members]
    }, [])

    const _loadGroup = useCallback(
        async search => {
            setLoading(true)
            try {
                const result = await GroupAPI.listGroups({ ...initialStateParams, search })
                setParams({
                    page: result.current_page,
                    total: result.total,
                })
                setGroups(_checkRole(result.items))
            } catch (err) {
                setParams()
            } finally {
                setLoading(false)
            }
        },
        [_checkRole],
    )

    const _loadMore = useCallback(async () => {
        if (params.total <= groups.length) {
            return
        }

        if (loading) {
            return
        }

        if (params.page + 1 === currentPage) {
            return
        }

        setCurrentPage(currentPage + 1)
        try {
            const result = await GroupAPI.listGroups({ ...params, page: params.page + 1 })
            setParams({
                page: result.current_page,
                total: result.total,
            })
            setGroups(_checkRole([...groups, ...result.items]))
        } catch (err) {
            setCurrentPage(params.page)
        } finally {
            setLoading(false)
        }
    }, [params, groups, loading, currentPage, _checkRole])

    const _onSearch = useCallback(
        search => {
            _loadGroup(search)
        },
        [_loadGroup],
    )

    useEffect(() => {
        const behaviorChange = GroupChangeBehavior.subscribe(group => {
            if (group) {
                const cloneGroups = [..._checkRole(group)]
                const index = groups.findIndex(item => item.id === group.id)
                if (index !== -1) {
                    if (groups[index]) {
                        Object.keys(groups[index]).forEach(key => {
                            cloneGroups[index][key] = group[key]
                        })
                    }
                }
                setGroups(cloneGroups)
            }
        })

        const behaviorRemove = RemoveGroupBehavior.subscribe(group_id => {
            if (group_id) {
                setGroups(groups.filter(item => item.id !== group_id))
            }
        })

        return () => {
            behaviorChange.unsubscribe()
            behaviorRemove.unsubscribe()
        }
    }, [_checkRole, groups])

    useEffect(() => {
        _loadGroup()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <WidgetContainer loading={loading}>
                <ContainerSearch>
                    <Search onSearch={_onSearch} />
                </ContainerSearch>
                <List
                    data={groups}
                    onRenderItem={(item, i) => (
                        <ItemGroup key={`group_${i}`} group={item} hover active={isActive(item.id)} />
                    )}
                    loadMore={groups.length < params.total}
                    onLoadMoreClick={_loadMore}
                    isLoading={loading}
                    infoEmptyProps={{
                        iconName: 'Users',
                        title: t('empty.groups.title'),
                        message: t('empty.groups.message'),
                        buttonText: t('create-group'),
                        onButtonClick: () => history.push('/criar-grupo'),
                    }}
                />
            </WidgetContainer>
        </>
    )
}

export default memo(WidgetGroups)
