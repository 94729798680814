import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalBase } from '../shared'

function AlertError({ onClose }, ref) {
    const [show, setShow] = useState(false)
    const [message, setMessage] = useState(null)
    const [title, setTitle] = useState(null)
    const [onPress, setOnPress] = useState(null)
    const [buttonText, setButtonText] = useState(null)

    const { t } = useTranslation()

    useImperativeHandle(ref, () => ({
        show: (props = {}) => {
            const { message, title, onPress, buttonText } = props
            if (onPress) {
                setOnPress(() => onPress)
            }
            setButtonText(buttonText)
            setShow(true)
            setMessage(message)
            setTitle(title != null ? title : t('error'))
        },
    }))

    const _onClose = useCallback(
        (refresh = false) => {
            setShow(false)
            setTimeout(() => {
                setMessage(null)
                setTitle(null)
            }, 300)
            if (onClose) {
                onClose(refresh)
            }
        },
        [onClose],
    )

    return (
        <ModalBase
            type="danger"
            icon="Slash"
            onButtonClick={onPress}
            buttonText={buttonText}
            title={title || t('error')}
            message={message}
            visible={show}
            onClose={_onClose}
        />
    )
}
export default memo(forwardRef(AlertError))
