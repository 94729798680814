import { useFormik } from 'formik'
import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

import { AuthAPI } from '#/api'
import Ilustration from '#/assets/images/ilustration-register.svg'
import { Input } from '#/components'
import { Alert, AlertError, ModalTerms } from '#/modals'
import { checkAccount } from '#/redux/thunk'

import { PageRegister } from '../shared'

function RegisterPassword() {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const alertErrorRef = useRef()
    const alertRef = useRef()
    const modalTermsRef = useRef()

    const {
        isSubmitting,
        values,
        errors,
        touched,
        isValid,
        dirty,
        handleSubmit,
        setFieldValue,
        handleBlur,
    } = useFormik({
        initialValues: {
            password: '',
            password_confirmation: '',
        },
        validationSchema: Yup.object().shape({
            password: Yup.string()
                .trim()
                .min(8, t('input.password.min'))
                .required(t('input.password.required')),
            password_confirmation: Yup.string()
                .trim()
                .required(t('input.password_confirmation.required'))
                .oneOf([Yup.ref('password'), null], t('input.password_confirmation.not-corresponding')),
        }),
        onSubmit: async values => {
            try {
                const result = await AuthAPI.registerPassword(values)
                dispatch(checkAccount(result))
            } catch ({ message }) {
                _showAlertErro(message)
            } finally {
            }
        },
    })

    const _showAlertErro = useCallback(
        message => {
            try {
                alertErrorRef.current && alertErrorRef.current.show({ message })
            } catch (error) {}
        },
        [alertErrorRef],
    )

    return (
        <>
            <PageRegister
                loading={isSubmitting}
                hasAccount
                title={t('create-account.title')}
                message={t('create-account.message')}
                disabled={!isValid || !dirty || isSubmitting}
                ilustration={Ilustration}
                onNextClick={handleSubmit}
                stepsComplete={2}
            >
                <Input
                    name="password"
                    label={t('input.create-password.label')}
                    type="password"
                    value={values.password}
                    onChangeText={e => setFieldValue('password', e)}
                    onBlur={handleBlur('password')}
                    errorMessage={touched.password && errors.password}
                />
                <Input
                    name="password_confirmation"
                    label={t('input.password_confirmation.label')}
                    type="password"
                    value={values.password_confirmation}
                    onChangeText={e => setFieldValue('password_confirmation', e)}
                    onBlur={handleBlur('password_confirmation')}
                    errorMessage={touched.password_confirmation && errors.password_confirmation}
                />
            </PageRegister>
            <AlertError ref={alertErrorRef} />
            <Alert ref={alertRef} />
            <ModalTerms ref={modalTermsRef} />
        </>
    )
}

export default RegisterPassword
