import styled from 'styled-components'

const ContainerPicture = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
`

const LabelPicture = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    margin-top: 10px;
`

const Message = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
`

export { ContainerPicture, LabelPicture, Message }
