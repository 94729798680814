import styled from 'styled-components'

const Container = styled.div`
    min-height: 300px;
    .item-member {
        padding: 5px 10px;
    }
`

const ContainerItem = styled.div`
    cursor: pointer;
`

export { Container, ContainerItem }
