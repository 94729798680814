import { push } from 'connected-react-router'

import { Store } from '../redux'
import { logout } from '../redux/thunk'
function validateError(err) {
    let msg = ''

    try {
        if (err?.response) {
            try {
                const {
                    data,
                    status,
                    config: { headers },
                } = err.response

                if (status === 501) {
                    return 'Funcionalidade não implementada.'
                }

                if (status === 503) {
                    // NavigationHelper.navigate('Maintenance')
                    return ''
                }

                if (status > 500) {
                    return 'Algo estranho aconteceu! Mas não se preocupe pois nossa equipe já está verificando.'
                }

                if (status === 401 && headers.Authorization) {
                    Store.dispatch(push('/login', { expired: true }))
                    Store.dispatch(logout())
                    return 'Sem autorização'
                }

                try {
                } catch (error) {}

                if (data.errors) {
                    let errors = Object.keys(data.errors)

                    errors.forEach(element => {
                        msg += `*${data.errors[element][0]} <br>`
                    })
                } else if (data.message) {
                    msg = data.message
                } else if (data.data) {
                    msg = data.data
                } else {
                    let errors = Object.keys(data)
                    errors.forEach(element => {
                        data[element].forEach(e => {
                            msg += `*${e} <br>`
                        })
                    })
                }
            } catch (error) {
                console.log(err)
            }
        }
    } catch (err) {}

    return msg || 'Ocorreu um erro inesperado'
}

export default validateError
