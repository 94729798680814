import { format } from 'date-fns'
import localePT from 'date-fns/locale/pt-BR'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { GroupAPI } from '#/api'
import { Badge, Button, Calendar, ContentSection } from '#/components'
import { useLoadGroup } from '#/hooks'
import { AlertError, ListShiftsByGroup } from '#/modals'
import { parseQuery } from '#/utils'
import { makeEvent } from '#/utils/calendar-tools'

import {
    ContainerCalendar,
    ContainerDetails,
    ContainerInfo,
    ContainerToday,
    LabelToday,
    Month,
    Today,
    WeekDay,
} from './group-details.styled'

function GroupDetails() {
    useLoadGroup()
    const modalShiftsRef = useRef()
    const history = useHistory()
    const location = useLocation()
    const { t } = useTranslation()
    const alertError = useRef()
    const [today] = useState(new Date())
    const group = useSelector(state => state.group)
    const color = useSelector(state => state.group?.color)

    const [events, setEvents] = useState([])
    const [shifts, setShifts] = useState([])
    const [monthsLoaded, setMonthsLoaded] = useState([])
    const [loadingCalendar, setLoadingCalendar] = useState(false)
    const [monthStatus, setMonthStatus] = useState([])
    const [loadedParams, setLoadedParams] = useState(false)
    const [currentPeriod, setCurrentPeriod] = useState([])
    const [initialDate, setInitialDate] = useState(null)
    const [currentStatus, setCurrentStatus] = useState(null)
    const _mergeMonths = useCallback(
        periods => {
            let months = [...monthsLoaded]
            const { previous, current, next } = periods

            if (!monthsLoaded.length) {
                months = [previous, current, next]
            } else {
                if (monthsLoaded[0].month === current.month) {
                    months = [previous, ...monthsLoaded]
                }
                if (monthsLoaded[monthsLoaded.length - 1].month === current.month) {
                    months = [...monthsLoaded, next]
                }
            }

            return months
        },
        [monthsLoaded],
    )

    const _checkRefresh = useCallback(
        date => {
            if (!monthsLoaded.length) {
                return true
            } else {
                if (monthsLoaded[0].month === date) {
                    return true
                }
                if (monthsLoaded[monthsLoaded.length - 1].month === date) {
                    return true
                }
            }

            return false
        },
        [monthsLoaded],
    )

    const _loadShifts = useCallback(
        async (group, date) => {
            if (!group) {
                return
            }

            setLoadingCalendar(true)
            try {
                const result = await GroupAPI.getGroupCalendar(group.id, date)

                const months = _mergeMonths(result)

                let shifts = []
                let monthStatus = []
                months.forEach(item => {
                    shifts = [...shifts, ...item.days]
                })
                months.forEach(({ status, month }) => {
                    monthStatus = [...monthStatus, { status, month }]
                })
                setMonthStatus(monthStatus)
                setShifts(shifts)

                setMonthsLoaded(months)
            } catch (error) {
            } finally {
                setLoadingCalendar(false)
            }
        },
        [_mergeMonths],
    )

    const _onMonthChange = useCallback(
        async period => {
            if (!loadedParams) {
                return
            }

            if (initialDate !== period) {
                setInitialDate(null)
            }

            if (currentPeriod !== period) {
                setCurrentPeriod(period)

                if (_checkRefresh(period)) {
                    await _loadShifts(group, period)
                }
            }
        },
        [currentPeriod, initialDate, _checkRefresh, _loadShifts, group, loadedParams],
    )

    const _openListModal = useCallback(
        ({ date }) => {
            modalShiftsRef.current && modalShiftsRef.current.show({ date })
        },
        [modalShiftsRef],
    )

    // useEffect(() => {
    //     if (color) {
    //         const events = shifts.map(item =>
    //             makeEvent({
    //                 date: item.date,
    //                 colors: [color],
    //                 opacity: !item.user_has_shifts,
    //                 hasInfo: item.user_has_transaction,
    //                 hasAlert: item.has_conflicts,
    //                 hasVacancy: item.has_vacancies,
    //             }),
    //         )
    //         setEvents(events)
    //     }
    // }, [color, shifts])

    useEffect(() => {
        if (currentPeriod && group) {
            history.push({
                pathname: `/grupo/${group.id}`,
                search: parseQuery({ period: currentPeriod }),
            })
            const schedule = monthStatus.find(item => item.month === currentPeriod)

            if (schedule) {
                setCurrentStatus(schedule.status)
            }
        }
    }, [currentPeriod, history, group, monthStatus])

    useEffect(() => {
        if (shifts && color) {
            const events = shifts.map(item =>
                makeEvent({
                    date: item.date,
                    colors: [color],
                    opacity: !item.user_has_shifts,
                    hasInfo: item.user_has_transaction,
                    hasAlert: item.has_conflicts,
                    hasVacancy: item.has_vacancies,
                }),
            )
            setEvents(events)
        }
    }, [color, shifts])

    useEffect(() => {
        const queryString = require('query-string')
        var parsed = queryString.parse(location.search)
        if (parsed?.period) {
            setInitialDate(parsed.period)
        }
        setTimeout(() => {
            setLoadedParams(true)
        }, 300)

        return () => {
            setMonthsLoaded([])
            setEvents([])
            setShifts([])
            setMonthStatus([])
            setCurrentPeriod(null)
            setInitialDate(null)
            setCurrentStatus(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group])

    return (
        <>
            <ContentSection withoutPadding>
                {!!group && (
                    <>
                        <ContainerDetails>
                            <ContainerCalendar>
                                <Calendar
                                    events={events}
                                    initialDate={initialDate}
                                    onMonthChange={_onMonthChange}
                                    loading={loadingCalendar}
                                    onDayPress={_openListModal}
                                />
                            </ContainerCalendar>
                            <ContainerInfo>
                                <ContainerToday>
                                    <LabelToday>{t('today')}</LabelToday>
                                    <Today color={`#${color}`}>{today.getDate()}</Today>
                                    <WeekDay>{format(today, 'EEEE', { locale: localePT })}</WeekDay>
                                    <Month>{format(today, 'MMMM, yyyy', { locale: localePT })}</Month>
                                </ContainerToday>
                                {group.is_admin ? (
                                    <>
                                        {currentStatus === 'draft' && (
                                            <Badge title={t('draft')} variant="danger" size={14} />
                                        )}
                                        <Button
                                            title={t('scale-management')}
                                            dimension="medium"
                                            onClick={() =>
                                                history.push({
                                                    pathname: `/gerenciar-escala/${group.id}/${currentPeriod}`,
                                                    search: parseQuery({ status: currentStatus }),
                                                })
                                            }
                                        />
                                    </>
                                ) : (
                                    <div style={{ width: 200 }} />
                                )}
                            </ContainerInfo>
                        </ContainerDetails>
                        {/* <ContainerNotices>
                            <TitleBar title={t('notices')} />
                        </ContainerNotices> */}
                    </>
                )}
            </ContentSection>
            <AlertError ref={alertError} />
            <ListShiftsByGroup ref={modalShiftsRef} />
        </>
    )
}

export default memo(GroupDetails)
