import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '#/components'
import { Input } from '#/components/forms'

import { ModalBase } from '../shared'
import { ContainerButton } from './confirmation.styled'
function Confirmation({ onClose }, ref) {
    const [show, setShow] = useState(false)
    const [message, setMessage] = useState(null)
    const [title, setTitle] = useState(null)
    const [iconName, setIconName] = useState(null)
    const [onYesClick, setOnYesClick] = useState(null)
    const [buttonText, setButtonText] = useState(null)
    const [type, setType] = useState('primary')
    const [inputText, setInputText] = useState(false)
    const [text, setText] = useState(null)
    const [label, setLabel] = useState(null)
    const { t } = useTranslation()

    useImperativeHandle(ref, () => ({
        show: (props = {}) => {
            const { message, title, onYesClick, buttonText, icon, type, inputText, label } = props
            if (type) {
                setType(type)
            }
            if (onYesClick) {
                setOnYesClick(() => onYesClick)
            }
            setLabel(label)
            setInputText(inputText)
            setButtonText(buttonText)
            setShow(true)
            setMessage(message)
            setIconName(icon)
            setTitle(title != null ? title : t('warning'))
        },
    }))

    const _onClose = useCallback(
        (refresh = false) => {
            setShow(false)
            setTimeout(() => {
                setType('primary')
                setOnYesClick(null)
                setInputText(false)
                setButtonText(null)
                setMessage(null)
                setIconName(null)
                setLabel(null)
                setTitle(null)
                setText(null)
            }, 300)
            if (onClose) {
                onClose(refresh)
            }
        },
        [onClose],
    )

    const _onYespress = useCallback(() => {
        if (onYesClick) {
            onYesClick(text)
        }
        _onClose()
    }, [_onClose, onYesClick, text])

    return (
        <ModalBase
            onButtonClick={onYesClick}
            buttonText={buttonText}
            title={title || t('warning')}
            message={message}
            icon={iconName}
            visible={show}
            type={type}
            showButton={false}
            onClose={_onClose}
        >
            {inputText && <Input label={label} value={text} onChangeText={e => setText(e)} />}
            <ContainerButton>
                <Button title="Não" variant="outline" dimension="small" onClick={_onClose} />
                <Button title={buttonText || 'Sim'} dimension="small" onClick={_onYespress} />
            </ContainerButton>
        </ModalBase>
    )
}
export default memo(forwardRef(Confirmation))
