import React, { memo, useEffect, useState } from 'react'

import { useWindowSize } from '../../utils'
import { Circle, Container, Divider, Icon, Step } from './stepper.styled'
function Stepper({ stepsComplete = 0, steps = 0 }) {
    const [width] = useWindowSize()
    const [horizontal, setHorizontal] = useState(false)

    useEffect(() => {
        setHorizontal(width <= 992)
    }, [width])

    return (
        <Container horizontal={horizontal}>
            {[...Array(steps)].map((e, i) => (
                <Step key={`step_${i}`} active={stepsComplete === i + 1} complete={stepsComplete > i + 1}>
                    {i !== 0 && <Divider />}
                    <Circle>
                        <Icon
                            horizontal={horizontal}
                            active={stepsComplete === i + 1}
                            complete={stepsComplete > i + 1}
                        />
                    </Circle>
                </Step>
            ))}
        </Container>
    )
}

export default memo(Stepper)
