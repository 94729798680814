import styled from 'styled-components'

const Container = styled.div`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    .ReactCrop__image {
        user-select: none;
        user-drag: none;
    }
`

export { Container }
