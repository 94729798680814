import styled from 'styled-components'

const Container = styled.div``

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
`

const Message = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 14px;
    margin-bottom: 10px;
`

const ContainerButton = styled.div`
    display: flex;
    justify-content: space-between;
`

const ButtonText = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;
    align-self: flex-end;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
`

export { Container, ButtonText, Message, ContainerButton, Title }
