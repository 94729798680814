import { format, parseISO } from 'date-fns'
import pt from 'date-fns/locale/pt'
import React, { memo } from 'react'

import Avatar from '../avatar/avatar'
import { FontPA } from '../icons'
import List from '../list/list'
import {
    Bold,
    Card,
    Column,
    Container,
    ContainerCard,
    ContainerMembers,
    HeaderCard,
    HeaderColumn,
    HeaderTitle,
    Label,
    WorkingTime,
} from './column-schedule.style'

const ColumnSchedule = ({ schedules, color }) => {
    const _header = date => {
        const day = parseISO(date)
        const formated = format(day, 'dd:EEEE', { locale: pt })
        const splitDate = formated.split(':')

        return (
            <HeaderColumn weekend={_checkIsWeekend(splitDate[1])}>
                <HeaderTitle>
                    <Bold>{`${splitDate[0]} `}</Bold>
                    {splitDate[1]}
                </HeaderTitle>
            </HeaderColumn>
        )
    }

    const _checkIsWeekend = day => {
        if (day.toLowerCase() === 'sábado' || day.toLowerCase() === 'domingo') {
            return true
        }

        return false
    }

    return (
        <Container>
            <Column>
                {_header(schedules.date)}
                <ContainerCard>
                    <List
                        data={schedules.workingtimes || []}
                        onRenderItem={(item, i) => (
                            <RenderCard key={`item_${i}`} workingTime={item} color={color} />
                        )}
                    />
                </ContainerCard>
            </Column>
        </Container>
    )
}

const RenderCard = memo(({ workingTime, color }) => {
    const { label, time, coworkers, vacancies } = workingTime
    return (
        <Card>
            <HeaderCard>
                <Label>{label}</Label>
                <WorkingTime>{time}</WorkingTime>
            </HeaderCard>
            <ContainerMembers>
                {coworkers
                    ? coworkers.map((e, i) => (
                          <Avatar
                              name={e.name}
                              key={`avatar_${e.id}`}
                              size={45}
                              userId={e.id}
                              source={e.photo}
                              style={{ margin: 2 }}
                          />
                      ))
                    : null}
                {vacancies > 0 ? <FontPA name="vacancy" amount={vacancies} size={45} color={color} /> : null}
                <div style={{ width: 45 }} />
            </ContainerMembers>
        </Card>
    )
})

export default memo(ColumnSchedule)
