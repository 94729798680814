import React, { memo } from 'react'

import { Required, Text } from './label.styled'

function Label({ children, required }) {
    return (
        <Text>
            {required && <Required>*</Required>}
            {children}
        </Text>
    )
}

export default memo(Label)
