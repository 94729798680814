import { addMonths, format, parseISO, subMonths } from 'date-fns'
import localePT from 'date-fns/locale/pt-BR'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { ScheduleAPI } from '#/api'
import {
    Badge,
    Button,
    ButtonText,
    Card,
    CheckBox,
    ColumnSchedule,
    HeaderGroup,
    NavLink,
    PageContainer,
    Warning,
} from '#/components'
import { Input } from '#/components/forms'
import { useLoadGroup, useQuery } from '#/hooks'
import { ActiveEmail, Alert, AlertError, Confirmation, CustomModal } from '#/modals'
import { parseQuery } from '#/utils'

import {
    BackIcon,
    BannerWarning,
    Bold,
    Container,
    ContainerButtons,
    ContainerCards,
    ContainerOptions,
    ContainerTitle,
    ContainerTools,
    IconWarning,
    Manager,
    Period,
    TextWarning,
    Title,
} from './review-schedule.styled'

function ManagerSchedule() {
    useLoadGroup()

    const { t } = useTranslation()
    const { status } = useQuery()
    const history = useHistory()
    const confirmationRef = useRef()
    const alertRef = useRef()
    const alertErrorRef = useRef()
    const customModalRef = useRef()
    const activeEmailRef = useRef()

    const unconfirmed = useSelector(state => state.user?.unconfirmed_data || [])

    const group_id = useSelector(state => state.group?.id)
    const groupColor = useSelector(state => state.group?.color)
    const manager = useSelector(state => state.group?.manager)
    const is_admin = useSelector(state => state.group?.is_admin)

    const [shedules, setShedules] = useState([])
    const [loading, setLoading] = useState(true)
    const { period } = useParams()
    const [typeSchedule, setTypeSchedule] = useState('fixed')

    const schedulePeriod = useMemo(() => {
        if (period) {
            return format(parseISO(period), 'MMMM yyyy', { locale: localePT })
        }
    }, [period])

    const isOldMonth = useMemo(() => {
        const date = Number(format(new Date(), 'yyyyMM'))
        const scheduleDate = Number(period.replace(/[^0-9]/g, ''))
        return scheduleDate >= date
    }, [period])

    const hideTools = useMemo(() => {
        const scheduleDate = Number(period.replace(/[^0-9]/g, ''))
        const previousMonth = Number(format(subMonths(new Date(), 3), 'yyyyMM'))

        return scheduleDate >= previousMonth
    }, [period])

    const _goBack = useCallback(() => {
        history.goBack()
    }, [history])

    const _loadShifts = useCallback(
        async (group_id, params) => {
            setLoading(true)
            try {
                const result = await ScheduleAPI.review(group_id, period, params)
                setShedules(result.items)
            } catch (error) {
            } finally {
                setLoading(false)
            }
        },
        [period],
    )

    const _publishSchedule = useCallback(async () => {
        try {
            setLoading(true)
            const body = {
                status: 'publi',
            }
            await ScheduleAPI.update(group_id, period, body)
            if (alertRef.current) {
                alertRef.current.show({
                    title: 'Plantões Especiais',
                    message: 'Adicione um valor para plantões especiais caso haja feriados neste mês.',
                    buttonText: 'Adicionar agora',
                    icon: 'Calendar',
                    linkText: 'Agora não',
                })
            }
            history.replace({
                pathname: `/gerenciar-escala/${group_id}/${period}`,
                search: parseQuery({ status: 'publi' }),
            })
        } catch ({ message }) {
            alertErrorRef.current && alertErrorRef.current.show({ message })
        } finally {
            setLoading(false)
        }
    }, [alertErrorRef, alertRef, period, group_id, history])

    const _confirmPublish = useCallback(async () => {
        if (confirmationRef.current) {
            confirmationRef.current.show({
                title: 'Publicar Escala',
                message:
                    'Ao publicar a escala, todos os membros do grupo poderão visualizá-la. Tem certeza de que deseja publicá-la agora?',
                onYesClick: _publishSchedule,
            })
        }
    }, [confirmationRef, _publishSchedule])

    const _replicate = useCallback(async () => {
        try {
            setLoading(true)
            const month = parseISO(period)
            let body = {
                from: period,
                to: format(addMonths(month, 1), 'yyyy-MM', { locale: localePT }).toUpperCase(),
            }
            const result = await ScheduleAPI.replicate(group_id, body)
            alertRef.current &&
                alertRef.current.show({ title: 'Replicação de Escala', message: result.message })
        } catch ({ message }) {
            alertErrorRef.current && alertErrorRef.current.show({ message })
        } finally {
            setLoading(false)
        }
    }, [group_id, alertErrorRef, period])

    const _ShowModalReplicate = useCallback(() => {
        if (customModalRef.current) {
            customModalRef.current.show({ show: true, title: t('replicate-schedule'), onClick: _replicate })
        }
    }, [customModalRef, t, _replicate])

    useEffect(() => {
        if (group_id) {
            _loadShifts(group_id, { type: typeSchedule })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group_id, typeSchedule])

    return (
        <>
            <PageContainer backgroundContrast loading={loading}>
                <Container>
                    <ContainerTitle>
                        <BackIcon onClick={_goBack} size={30} />
                        <Title>{t('review')}</Title>
                    </ContainerTitle>
                    <Card withoutPadding overflowHidden>
                        <HeaderGroup
                            leftComponent={
                                manager && (
                                    <>
                                        <Period>{schedulePeriod}</Period>
                                        <Manager>
                                            {t('manager-schedule.manager', { name: manager.name })}
                                        </Manager>
                                    </>
                                )
                            }
                        />
                        <ContainerTools>
                            <ContainerOptions>
                                <CheckBox
                                    label={t('fixed')}
                                    checked={typeSchedule === 'fixed'}
                                    onClick={() => setTypeSchedule('fixed')}
                                />
                                <CheckBox
                                    label={t('dynamic')}
                                    checked={typeSchedule === 'dynamic'}
                                    onClick={() => setTypeSchedule('dynamic')}
                                />
                            </ContainerOptions>

                            {status === 'draft' && <Badge variant="danger" title={t('draft-scale')} />}

                            {unconfirmed.includes('email') ? (
                                <BannerWarning>
                                    <IconWarning />
                                    <TextWarning>
                                        {t('warning.active-email.message')}
                                        <Bold
                                            onClick={() =>
                                                activeEmailRef.current && activeEmailRef.current.show()
                                            }
                                        >
                                            {t('warning.active-email.click')}
                                        </Bold>
                                    </TextWarning>
                                </BannerWarning>
                            ) : (
                                <ContainerButtons>
                                    <NavLink to={`/imprimir-escala/${group_id}/${period}`} target="_blank">
                                        <ButtonText iconName="Printer">{t('print')}</ButtonText>
                                    </NavLink>

                                    {is_admin && hideTools && (
                                        <>
                                            {typeSchedule === 'fixed' && (
                                                <ButtonText iconName="Copy" onClick={_ShowModalReplicate}>
                                                    {t('replicate')}
                                                </ButtonText>
                                            )}
                                            {status !== 'publi' && isOldMonth && (
                                                <Button
                                                    title={t('publish')}
                                                    dimension="auto"
                                                    onClick={_confirmPublish}
                                                />
                                            )}
                                        </>
                                    )}
                                </ContainerButtons>
                            )}
                        </ContainerTools>
                        <ContainerCards horizontal hideScrollbars={false}>
                            {shedules.map((item, i) => (
                                <ColumnSchedule
                                    key={`column_${i}`}
                                    schedules={item}
                                    color={groupColor && `#${groupColor}`}
                                />
                            ))}
                        </ContainerCards>
                    </Card>
                </Container>
            </PageContainer>
            <CustomModal ref={customModalRef}>
                <>
                    <Warning>{t('replication-schedule.warning')}</Warning>
                    <Input value={transformPeriod(period)} disabled label={t('replication-schedule.from')} />
                    <Input value={transformPeriod(period, 1)} disabled label={t('replication-schedule.to')} />
                </>
            </CustomModal>
            <Confirmation ref={confirmationRef} />
            <Alert ref={alertRef} />
            <AlertError ref={alertErrorRef} />
            <ActiveEmail ref={activeEmailRef} />
        </>
    )
}

function transformPeriod(period, add) {
    if (period) {
        const month = parseISO(period)
        if (add) {
            return format(addMonths(month, 1), 'MMMM yyyy', { locale: localePT }).toUpperCase()
        } else {
            return format(month, 'MMMM yyyy', { locale: localePT }).toUpperCase()
        }
    }
}

export default ManagerSchedule
