function timeout(time) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve('timeout complete')
        }, time || 300)
    })
}

function format(value, pattern) {
    var i = 0,
        v = value.toString()
    return pattern.replace(/#/g, _ => v[i++])
}

export { timeout, format }
