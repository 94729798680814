import styled from 'styled-components'

const ContainerForm = styled.div`
    padding: 16px;
`
const ContainerButton = styled.div`
    padding: 16px;
    flex: 1;
    display: flex;
    align-items: flex-end;
`

export { ContainerForm, ContainerButton }
