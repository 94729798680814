import 'react-image-crop/dist/ReactCrop.css'

import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactCrop from 'react-image-crop'

import { ModalBase } from '../shared'
import { Container } from './crop-image.styled'

function CropImage({ onClose, onCrop }, ref) {
    const { t } = useTranslation()
    const [show, setShow] = useState(false)
    const [source, setSource] = useState(null)
    const imageRef = useRef(null)
    const [crop, setCrop] = useState({
        unit: '%',
        aspect: 1,
        width: 100,
    })
    const [completedCrop, setCompletedCrop] = useState(false)

    useImperativeHandle(ref, () => ({
        show: (props = {}) => {
            setSource(props?.source)
            setShow(!!props?.source)
        },
    }))

    const _onClose = useCallback(
        (refresh = false) => {
            setShow(false)
            setSource(null)
            setCrop({
                unit: '%',
                aspect: 1,
                width: 100,
            })
            setCompletedCrop(false)
            if (onClose) {
                onClose(refresh)
            }
        },
        [onClose],
    )

    const _onLoad = useCallback(img => {
        imageRef.current = img
    }, [])

    const _cropImage = useCallback(async () => {
        if (completedCrop) {
            if (imageRef.current && crop.width && crop.height) {
                const file = await getCroppedImg(imageRef.current, crop)

                if (onCrop) {
                    onCrop(file)
                }
                _onClose()
            }
        }
    }, [imageRef, crop, completedCrop, onCrop, _onClose])

    return (
        <ModalBase
            style={{ maxWidth: 480, maxHeight: 580 }}
            title={t('cop-image')}
            visible={show}
            onClose={_onClose}
            icon={null}
            onButtonClick={_cropImage}
        >
            <Container>
                <ReactCrop
                    src={source}
                    onImageLoaded={_onLoad}
                    crop={crop}
                    circularCrop
                    keepSelection
                    minWidth={100}
                    minHeight={100}
                    onChange={c => setCrop(c)}
                    onComplete={() => setCompletedCrop(true)}
                />
            </Container>
        </ModalBase>
    )
}

function getCroppedImg(image, crop) {
    return new Promise((resolve, reject) => {
        // condition
        try {
            const canvas = document.createElement('canvas')
            const scaleX = image.naturalWidth / image.width
            const scaleY = image.naturalHeight / image.height
            canvas.width = crop.width
            canvas.height = crop.height
            const ctx = canvas.getContext('2d')

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height,
            )

            const reader = new FileReader()
            canvas.toBlob(blob => {
                reader.readAsDataURL(blob)
                reader.onloadend = () => {
                    resolve(dataURLtoFile(reader.result, 'profile-cropped.jpg'))
                }
            })
        } catch (err) {
            reject(err)
        }
    })
}

function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
}
export default memo(forwardRef(CropImage))
