import { Axios } from './axios-config'

const list = (group_id, params) => {
    return Axios().get(`groups/${group_id}/schedules`, {
        params: params,
    })
}

const review = (group_id, period, params) => {
    return Axios().get(`groups/${group_id}/schedules/${period}/shifts`, {
        params: params,
    })
}

const update = (group_id, period, body, params) => {
    return Axios().patch(`groups/${group_id}/schedules/${period}`, body, {
        params: params,
    })
}

const replicate = (group_id, body, params) => {
    return Axios().post(`groups/${group_id}/schedules/replications`, body, {
        params: params,
    })
}

const members = (group_id, period, params) => {
    return Axios().get(`groups/${group_id}/schedules/${period}/hours`, {
        params: params,
    })
}

const shifts = (group_id, period, user_id, params) => {
    return Axios().get(`groups/${group_id}/schedules/${period}/shifts/${user_id}`, {
        params: params,
    })
}

export default {
    list,
    members,
    shifts,
    review,
    update,
    replicate,
}
