import './i18n'

import { ConnectedRouter } from 'connected-react-router'
import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import Main from './pages/main'
import { history, persistor, Store } from './redux'

export default function App() {
    return (
        <Suspense fallback={null}>
            <Provider store={Store}>
                <ConnectedRouter history={history}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Main />
                    </PersistGate>
                </ConnectedRouter>
            </Provider>
        </Suspense>
    )
}
