import { useFormik } from 'formik'
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AccountsAPI } from '#/api'
import { Avatar, ButtonText } from '#/components'
import { TextArea } from '#/components/forms'
import { ProfileContext } from '#/contexts'
import { AlertError, CropImage } from '#/modals'
import { setUser } from '#/redux/reducers/user'
import { logout } from '#/redux/thunk/logout'

import {
    CallName,
    Container,
    ContainerInfo,
    ContainerPicture,
    ContentCard,
    Council,
    Description,
    Icon,
    Info,
    Name,
    Row,
    Title,
    TopBarCard,
} from './widget-profile-info.styled'

function WidgetProfileInfo() {
    const profile = useContext(ProfileContext)
    const user = useSelector(state => state.user)

    const [profileUser, setProfileUser] = useState(null)
    const [isMyProfile, setIsMyProfile] = useState(true)
    const { t } = useTranslation()

    const alertErrorRef = useRef()
    const inputFileRef = useRef()
    const cropModalRef = useRef()

    const dispatch = useDispatch()

    const [isEdit, setIsEdit] = useState(false)
    const [profileImage, setProfileImage] = useState(null)
    const [profileURL, setProfileURL] = useState(null)

    const description = useMemo(() => {
        let job = ''
        if (profileUser?.profession?.name) {
            job = profileUser.profession.name
        }

        if (profileUser?.specialization?.name) {
            job += ` - ${profileUser.specialization.name}`
        }

        return job
    }, [profileUser])

    const council = useMemo(() => {
        let council = ''
        if (profileUser?.council) {
            council = `${profileUser.council.id} / ${profileUser.council.state}`
        }

        return council
    }, [profileUser])

    const { isSubmitting, values, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            about: null,
        },

        onSubmit: async values => {
            try {
                setIsEdit(false)
                const result = await AccountsAPI.update({ ...values })
                dispatch(setUser(result))
            } catch ({ message }) {
                setIsEdit(true)
                alertErrorRef.current && alertErrorRef.current.show({ message })
            }
        },
    })

    const _openFile = useCallback(() => {
        if (inputFileRef.current) {
            inputFileRef.current.click()
        }
    }, [inputFileRef])

    const _onCropImage = useCallback(file => {
        setProfileImage(file)
        let URL = (window.URL || window.webkitURL).createObjectURL(file)

        setProfileURL(URL)
    }, [])

    const _onSelectFile = useCallback(e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                if (cropModalRef.current) {
                    cropModalRef.current.show({ source: reader.result })
                }
            })
            reader.readAsDataURL(e.target.files[0])
        }
    }, [])

    const _uploadImage = useCallback(async () => {
        if (profileImage) {
            try {
                const result = await AccountsAPI.photo(profileImage)

                dispatch(setUser(result))
            } catch ({ message }) {
                alertErrorRef.current && alertErrorRef.current.show({ message })
            } finally {
            }
        }
    }, [profileImage, dispatch])

    const _logout = useCallback(() => {
        dispatch(logout())
    }, [dispatch])

    useEffect(() => {
        if (profileUser) {
            setFieldValue('about', profileUser.about)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileUser])

    useEffect(() => {
        if (profileURL) {
            _uploadImage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileURL])

    useEffect(() => {
        if (profile) {
            setProfileUser(profile)
            setIsMyProfile(false)
            return
        }

        if (user) {
            setProfileUser(user)
            setIsMyProfile(true)
            return
        }
    }, [user, profile])

    if (!profileUser) {
        return null
    }
    return (
        <>
            <ContentCard>
                <TopBarCard />
                <ContainerPicture>
                    <Avatar
                        size={100}
                        name={profileUser?.name}
                        onClick={isMyProfile && _openFile}
                        source={profileURL || profileUser?.photo}
                        showIconPicker={isMyProfile}
                    />
                </ContainerPicture>

                <CallName>{profileUser.call_name}</CallName>
                <Name>{profileUser.name}</Name>
                <Description>{description}</Description>
                <Council>{council}</Council>
            </ContentCard>
            <Container>
                {isMyProfile && (
                    <Row>
                        <Title>{t('about')}</Title>
                        <Icon name={isEdit ? 'X' : 'Edit2'} pointer onClick={() => setIsEdit(!isEdit)} />
                    </Row>
                )}
                <TextArea
                    value={values.about}
                    onChangeText={e => setFieldValue('about', e)}
                    readOnly={!isEdit}
                    disabled={!isEdit}
                />
                {isEdit && (
                    <ButtonText onClick={handleSubmit} disabled={isSubmitting}>
                        {t('save')}
                    </ButtonText>
                )}
                <Row>
                    <Title>{t('contact')}</Title>
                </Row>
                <ContainerInfo>
                    <Icon name="Mail" />
                    <Info>{profileUser.email}</Info>
                </ContainerInfo>
                <ContainerInfo>
                    <Icon name="Phone" />
                    <Info>{profileUser.phone}</Info>
                </ContainerInfo>
                {isMobile && <ButtonText onClick={_logout}>{t('exit')}</ButtonText>}
            </Container>
            <AlertError ref={alertErrorRef} />
            <CropImage ref={cropModalRef} onCrop={_onCropImage} />
            <input
                ref={inputFileRef}
                type="file"
                accept="image/*"
                onChange={_onSelectFile}
                style={{ display: 'none' }}
            />
        </>
    )
}

export default memo(WidgetProfileInfo)
