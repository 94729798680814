import classNames from 'classnames'
import React, { memo, useMemo } from 'react'

import {
    Container,
    ContainerTitle,
    Content,
    HeaderCard,
    LeftIconFeather,
    LeftIconFontPA,
    RightIcon,
    RightText,
    SubTitle,
    Title,
} from './card.styled'
function Card({
    children,
    withoutPadding = false,
    overflowHidden = true,
    className,
    leftIcon,
    rightIcon,
    title,
    subTitle,
    rightText,
    onRightIconClick,
    ...props
}) {
    const LeftIcon = useMemo(() => {
        if (leftIcon) {
            if (['arrow-left', 'exchange', 'vacancy', 'manager'].includes(leftIcon)) {
                return LeftIconFontPA
            } else {
                return LeftIconFeather
            }
        } else {
            return null
        }
    }, [leftIcon])

    return (
        <Container overflowHidden={overflowHidden} className={classNames('card', className)} {...props}>
            {(leftIcon || title || rightIcon) && (
                <HeaderCard>
                    {!!leftIcon && <LeftIcon name={leftIcon} />}
                    {(title || subTitle) && (
                        <ContainerTitle>
                            {!!title && <Title>{title}</Title>}
                            {!!subTitle && <SubTitle>{subTitle}</SubTitle>}
                        </ContainerTitle>
                    )}
                    {!!rightText && <RightText>{rightText}</RightText>}
                    {!!rightIcon && <RightIcon name={rightIcon} onClick={onRightIconClick} />}
                </HeaderCard>
            )}
            <Content className="card-content" withoutPadding={withoutPadding}>
                {children}
            </Content>
        </Container>
    )
}

export default memo(Card)
