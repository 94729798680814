import Dimensions from './dimensions'

const DarkTheme = {
    isDark: true,
    colors: {
        primary: '#1592e6',
        light: '#5e72a9',
        header: '#2E477A',
        menuInativeColor: '#707070',

        background: '#22283A',
        backgroundAccent: '#161e31',
        backgroundContrast: '#232E48',
        backgroundOpacity: 'rgba(255, 255, 255, 0.3)',
        backgroundBox: '#232016',
        backgroundBoxDisabled: 'rgba(255,255,255,0.05)',
        backgroundAvatar: '#EFEFEF',

        hover: 'rgba(255, 255, 255, 0.05)',

        border: 'rgba(255, 255, 255, 0.1)',
        borderGrid: '#8D8DAE',
        borderSeparator: '#FFFFFF',

        shadow: 'rgba(0,0,0,0.29)',

        input: '#273759',
        placeholder: '#bdbdbd',

        grey: '#E4E4E4',
        greyMedium: '#bdbdbd',
        greyDark: '#5a5a5a',

        textOpacity: 'rgba(255, 255, 255, 0.6)',
        text: '#FFFFFF',
        textLight: '#8D8DAE',

        success: '#00CE96',
        info: '#1594E5',
        danger: '#FF486D',
        yellow: '#FFCE48',

        calendarToday: '#bdbdbd',
        calendarBackground: '#22304F',
        calendarAccentColor: '#67c2ff',
        calendarDaysColor: '#ffffff',
        calendarOldDaysColor: 'rgba(255,255,255,0.25)',
        calendarOldDayBackground: 'rgba(255,255,255,0.15)',
        calendarBorderDayColor: '#ffffff',
    },
    sizes: Dimensions,
}

export default DarkTheme
