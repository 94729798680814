import styled from 'styled-components'

const ContainerButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1200px) {
        flex-direction: column;
        .button {
            :first-child {
                margin-bottom: 5px;
            }
        }
    }
    @media (min-width: 1200px) {
        .button {
            :first-child {
                margin-right: 5px;
            }
        }
    }
`

export { ContainerButton }
