import React, { memo } from 'react'

import Button from '../button/button'
import { Container, ContainerIcon, Icon, Message, Title } from './info-empty.styled'

const InfoEmpty = memo(({ title, message, buttonText, onButtonClick, iconName }) => {
    return (
        <Container>
            {!!iconName && (
                <ContainerIcon>
                    <Icon name={iconName} />
                </ContainerIcon>
            )}
            <Title>{title}</Title>
            <Message>{message}</Message>
            {!!buttonText && <Button title={buttonText} dimension="small" onClick={onButtonClick} />}
        </Container>
    )
})

export { InfoEmpty }
