import styled from 'styled-components'

import { Feather } from '#/components/icons'
import Link from '#/components/link/link'

const Container = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000080;
    align-items: center;
    justify-content: center;
    z-index: 10;
`

const Box = styled.div`
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: ${({ theme }) => theme.sizes.radius}px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    overflow: hidden;
    width: 420px;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    @media (max-width: 992px) {
        max-width: 80%;
    }
`

const Content = styled.div`
    flex: 1;
    position: relative;
    padding: ${({ withoutPadding }) => (withoutPadding ? 0 : 16)}px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
`

const ContainerTitle = styled.div`
    font-weight: bold;
    width: 100%;
    padding: 8px;
    background-color: ${({ theme, headerPrimary }) =>
        headerPrimary ? theme.colors.primary : theme.colors.backgroundAccent};
    color: ${({ theme }) => theme.colors.text};
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
`
const Title = styled.div`
    font-weight: bold;
    color: ${({ theme, headerPrimary }) => (headerPrimary ? '#fff' : theme.colors.text)};
    flex: 1;
`

const Icon = styled(Feather).attrs({ size: 80 })`
    color: ${({ theme, type }) => (type ? theme.colors[type] : theme.colors.primary)};
    margin-bottom: 16px;
    align-self: center;
`

const Close = styled(Feather).attrs({ size: 18, name: 'X' })`
    color: ${({ theme, headerPrimary }) => (headerPrimary ? '#fff' : theme.colors.textOpacity)};
    position: absolute;
    right: 8px;
    cursor: pointer;
`

const Message = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    text-align: center;
    padding: 0px 0px 16px 0px;
`

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    align-content: space-between;
`
const Divider = styled.div`
    width: 20px;
    height: 100%;
`
const LinkButton = styled(Link)`
    margin-top: 10px;
    text-decoration: underline;
    user-select: none;
`

const Footer = styled.div`
    padding: 10px;
`
export {
    Container,
    Box,
    ButtonContainer,
    Title,
    Content,
    Message,
    Icon,
    Divider,
    LinkButton,
    Close,
    ContainerTitle,
    Footer,
}
