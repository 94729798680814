import { Axios } from './axios-config'

const getFeed = data => {
    const params = {
        page: data?.page,
    }
    return Axios().get('/users/feed', { params })
}

const getFeedHorizontal = () => {
    return Axios().get('/users/feed/horizontal')
}

const getNews = params => {
    return Axios().get('news', { params })
}

const getNewsPick = params => {
    return Axios().get('news/pick', { params })
}

const getClickNews = id => {
    return Axios().patch(`news/${id}/click`)
}

const getLikeNews = id => {
    return Axios().patch(`news/${id}`)
}

const getCalendarByMonth = month => {
    return Axios().get(`users/calendar/monthly/${month}`)
}

const getDayShifts = date => {
    return Axios().get(`users/calendar/daily/${date}`)
}

export default {
    getFeed,
    getFeedHorizontal,
    getCalendarByMonth,
    getNewsPick,
    getDayShifts,
    getClickNews,
    getLikeNews,
    getNews,
}
