import { useFormik } from 'formik'
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { AccountsAPI, AppAPI, InstitutionAPI, OpportunityAPI } from '#/api'
import { AutoComplete, Button, Calendar, CheckBox, ContentSection, InputCurrency, List } from '#/components'
import { Input, Label, Select, TextArea } from '#/components/forms'
import { ItemMultiSelect } from '#/components/items'
import { OpportunityContext } from '#/contexts'
import { AlertError } from '#/modals'
import { makeEvent } from '#/utils/calendar-tools'

import { ContentOpportunity } from '../shared'
import {
    ActionContainer,
    ActionIconLeft,
    ActionIconRight,
    Close,
    Container,
    ContainerForm,
    ContainerTitleSpecializations,
    Row,
    VacanciesCounter,
    Value,
} from './opportunity-update.styled'
function OpportunityUpdate() {
    const { t } = useTranslation()
    const history = useHistory()
    const { opportunity_id } = useParams()
    const alertErrorRef = useRef()

    const [loading, setLoading] = useState(false)
    const opportunityContext = useContext(OpportunityContext)
    const [professions, setProfessions] = useState([])
    const [specializations, setSpecializations] = useState([])
    const [assistances, setAssistances] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [institutionName, setInstitutionName] = useState(null)

    const [showSpecializations, setShowSpecializations] = useState(false)
    const [hasSpecialization, setHasSpecialization] = useState(false)
    const [hasInstitution, setHasInstitution] = useState(false)

    const [hasValue, setHasValue] = useState(true)

    const [opportunity, setOpportunity] = useState(null)
    const {
        isSubmitting,
        values,
        errors,
        handleSubmit,
        setTouched,
        isValid,
        setFieldValue,
        touched,
        handleBlur,
    } = useFormik({
        initialValues: {
            description: null,
            available_days: [],
            qty: 0,
            accepted: true,
            value: null,
            sector: null,
            value_type: null,
            payment_type: null,
            profession: null,
            specializations: null,
            assistance: null,
            state: null,
            city: null,
            expires_at_days: null,
            institution: null,
        },
        validationSchema: Yup.object().shape({
            assistance: Yup.string().nullable().required(t('select.assistance.required')),
            institution: Yup.string()
                .nullable()
                .test('institution-valid', t('autocomplete.institution.required'), value => {
                    if (hasInstitution) {
                        return !!value
                    }
                    return true
                }),
            city: Yup.string()
                .nullable()
                .test('city-valid', t('select.city.required'), value => {
                    if (!hasInstitution) {
                        return !!value
                    }
                    return true
                }),
            state: Yup.string()
                .nullable()
                .test('state-valid', t('select.uf.required'), value => {
                    if (!hasInstitution) {
                        return !!value
                    }
                    return true
                }),
            qty: Yup.number()
                .required(t('qty.required'))
                .test('qty-valid', t('qty.required'), value => (value || 0) > 0),
            sector: Yup.string().nullable().max(20, t('input.sector-value.max')),
            profession: Yup.object().nullable().required(t('select.profession.required')),
            accepted: Yup.bool().oneOf([true], t('opportunity-create.required')),
        }),
        onSubmit: async (values, { setErrors }) => {
            try {
                const body = {
                    ...values,
                    assistance_id: values.assistance.id,
                    profession_id: values.profession.id,
                }

                if (body.institution) {
                    body.institution_id = values.institution.value?.id

                    delete body.city
                    delete body.state
                }
                delete body.profession
                delete body.assistance
                delete body.institution
                await OpportunityAPI.update(opportunity_id, body)

                _alertSuccess()
            } catch ({ message }) {
                _alertError(message)
            }
        },
        enableReinitialize: true,
    })

    const events = useMemo(() => {
        return values.available_days.map(item => makeEvent({ date: item }))
    }, [values.available_days])

    const deadlines = useMemo(() => {
        let deadlines = [
            { label: t('today'), value: 0, key: 0 },
            { label: t('in-day', { day: 1 }), value: 1, key: 1 },
        ]
        for (let i = 2; i <= 45; i++) {
            deadlines.push({ label: t('in-days', { day: i }), value: i, key: i })
        }
        return deadlines
    }, [t])

    const _onSearch = useCallback(async value => {
        if (value) {
            try {
                const result = await InstitutionAPI.list(value)
                return result.items.map(item => ({
                    title: item.name,
                    subTitle: `${item.city} - ${item.uf}`,
                    value: item,
                }))
            } catch (err) {
                return 0
            }
        } else {
            return []
        }
    }, [])

    const _loadUf = useCallback(async () => {
        setLoading(true)
        try {
            const result = await AppAPI.UFlist()

            const states = result.items.map(item => {
                return { label: item.code, value: { uf: item.code, name: item.name }, key: item.code }
            })

            setStates(states)
        } catch (err) {
        } finally {
            setLoading(false)
        }
    }, [])

    const _loadCities = useCallback(async uf => {
        setLoading(true)
        try {
            const result = await AppAPI.listCitiesByUf(uf)

            const cities = result.items.map(item => {
                return { label: item.name, value: { id: item.id, name: item.name }, key: item.id }
            })
            setCities(cities)
        } catch (err) {
        } finally {
            setLoading(false)
        }
    }, [])

    const _loadProfessions = useCallback(async () => {
        try {
            setLoading(true)
            const responseSpec = await AccountsAPI.professionalList()

            const professions = responseSpec.items.map(item => {
                return { label: item.name, value: item, key: item.id }
            })
            setProfessions(professions)
        } catch (error) {
            setProfessions([])
        } finally {
            setLoading(false)
        }
    }, [])

    const _loadTypes = useCallback(async () => {
        try {
            const result = await OpportunityAPI.listAssistanceTypes()

            setAssistances(
                result.items.map(item => ({
                    label: item.title,
                    value: item,
                    key: item.id,
                })),
            )
        } catch (error) {}
    }, [])

    const _loadSpecializations = useCallback(async () => {
        if (!values.profession) {
            return
        }
        try {
            setLoading(true)
            const result = await AccountsAPI.specializationList(values.profession.id, true)
            const specializations = result.items.map(item => ({
                title: item.name,
                value: item.id,
            }))

            setSpecializations(specializations)
        } catch (err) {
        } finally {
            setLoading(false)
        }
    }, [values])

    const _setUf = useCallback(
        state => {
            if (state) {
                _loadCities(state.uf)
            } else {
                setCities([])
            }
            setFieldValue('state', state)
        },
        [_loadCities, setFieldValue],
    )

    const _setValues = useCallback(
        async opportunity => {
            if (opportunity.assistance) {
                const { assistance } = opportunity

                setFieldValue('assistance', assistance)
                setHasInstitution(assistance.has_institution)
                if (!assistance.has_institution) {
                    setFieldValue('state', opportunity.city.state.code)
                    await _loadCities(opportunity.city.state.code)
                    setFieldValue('city', opportunity.city.id)
                }
            }

            if (opportunity.institution) {
                const { institution } = opportunity
                setFieldValue('institution', {
                    title: institution.name,
                    subTitle: `${institution.city} - ${institution.uf}`,
                    value: institution,
                })

                setInstitutionName(
                    `${institution.name || institution.popular_name} | ${institution.city} - ${
                        institution.uf
                    }`,
                )
            }

            if (opportunity.profession) {
                const { profession } = opportunity
                setFieldValue('profession', profession)
                setHasSpecialization(profession.has_specializations)
            }

            if (opportunity.value === null) {
                setHasValue(false)
            } else {
                setHasValue(true)
                setFieldValue('value', Number(opportunity.value?.amount))
                setFieldValue('value_type', opportunity.value?.value_type)
                setFieldValue('payment_type', opportunity.value?.payment_type)
            }

            setFieldValue('sector', opportunity.sector)
            setFieldValue('qty', Number(opportunity.qty || 0))
            setFieldValue('available_days', opportunity.available_days || [])
            setFieldValue('description', opportunity.description)

            if (opportunity.specializations.length > 0) {
                const { specializations } = opportunity
                setShowSpecializations(true)
                setFieldValue(
                    'specializations',
                    specializations.map(item => item.id),
                )
            }
        },
        [setFieldValue, _loadCities],
    )

    const _setFieldValue = useCallback(
        (name, value) => {
            if (name === 'profession') {
                setShowSpecializations(false)
                if (value) {
                    setHasSpecialization(value?.has_specializations)
                    setFieldValue('profession', value)
                } else {
                    setFieldValue('profession', null)
                    setHasSpecialization(false)
                }
            }

            if (name === 'assistance') {
                if (value) {
                    setHasInstitution(value.has_institution)
                    setFieldValue('assistance', value)
                } else {
                    setFieldValue('assistance', null)
                    setHasInstitution(false)
                }
            }

            if (name === 'specialization') {
                if (values.specializations?.includes(value)) {
                    let selecteds = values.specializations.filter(item => item !== value)
                    setFieldValue('specializations', selecteds.length > 0 ? selecteds : null)
                } else {
                    if (values.specializations?.length > 0) {
                        setFieldValue('specializations', [...values.specializations, value])
                    } else {
                        setFieldValue('specializations', [value])
                    }
                }
            }
        },
        [setFieldValue, values],
    )

    const _setValueType = useCallback(
        type => {
            if (type === values.value_type) {
                setFieldValue('value_type', null)
            } else {
                setFieldValue('value_type', type)
            }
        },
        [setFieldValue, values],
    )

    const _setPaymentType = useCallback(
        type => {
            if (type === values.payment_type) {
                setFieldValue('payment_type', null)
            } else {
                setFieldValue('payment_type', type)
            }
        },
        [setFieldValue, values],
    )

    const _setDate = useCallback(
        day => {
            if (values.available_days.includes(day.date)) {
                setFieldValue(
                    'available_days',
                    values.available_days.filter(e => e !== day.date),
                )
            } else {
                setFieldValue('available_days', [...values.available_days, day.date])
            }
        },
        [values, setFieldValue],
    )

    const _alertSuccess = useCallback(() => {
        if (opportunityContext) {
            opportunityContext.alertSuccess({
                title: t('modal.update-opportunity.title'),
                message: t('modal.create-opportunity.shared-message'),
                opportunity,
                onComplete: () => {
                    history.back()
                },
            })
        }
    }, [opportunityContext, opportunity, t, history])

    const _alertError = useCallback(
        message => {
            alertErrorRef.current && alertErrorRef.current.show({ message })
        },
        [alertErrorRef],
    )

    const _init = useCallback(
        async opportunity_id => {
            if (opportunity_id) {
                setLoading(true)
                try {
                    const result = await OpportunityAPI.view(opportunity_id)
                    setOpportunity(result)
                    await _loadProfessions()
                    await _loadTypes()
                    await _setValues(result)
                } catch ({ message }) {
                    _alertError(message)
                } finally {
                }
                setLoading(false)
            } else {
                _alertError(t('not-found-opportunity'))
            }
        },
        [_loadProfessions, _loadTypes, _alertError, _setValues, t],
    )

    useEffect(() => {
        if (showSpecializations && hasSpecialization) {
            _loadSpecializations()
        } else {
            setFieldValue('specializations', null)
            setSpecializations([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSpecialization, values.profession, showSpecializations])

    useEffect(() => {
        if (hasInstitution) {
            setStates([])
            setCities([])
            setFieldValue('city', null)
            setFieldValue('state', null)
        } else {
            setFieldValue('institution', null)
            _loadUf()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasInstitution])

    useEffect(() => {
        if (!hasValue) {
            setFieldValue('value', null)
            setFieldValue('value_type', null)
            setFieldValue('payment_type', null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasValue])

    useEffect(() => {
        _init(opportunity_id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opportunity_id])

    return (
        <>
            <Container>
                <ContentSection loading={loading || isSubmitting} withoutPadding>
                    <ContentOpportunity title={t('opportunity-update.title')} arrowBack>
                        <ContainerForm>
                            <Select
                                required
                                name="assistance"
                                label={t('select.assistance.label')}
                                placeholder={t('select.assistance.placeholder')}
                                options={assistances}
                                onChange={value => _setFieldValue('assistance', value)}
                                selectedKey={values.assistance?.id}
                                errorMessage={touched.assistance && errors.assistance}
                            />
                            <Input
                                name="sector"
                                label={t('input.sector-value.label')}
                                placeholder={t('input.sector-value.placeholder')}
                                value={values.sector}
                                onChangeText={e => setFieldValue('sector', e)}
                                onBlur={handleBlur('sector')}
                                errorMessage={touched.sector && errors.sector}
                            />
                            {!!values.assistance && (
                                <>
                                    {hasInstitution ? (
                                        <AutoComplete
                                            required
                                            label={t('autocomplete.institution.label')}
                                            placeholder={t('autocomplete.institution.placeholder')}
                                            onSearching={_onSearch}
                                            value={values.institution}
                                            stringValue={institutionName}
                                            onSelected={e => {
                                                setFieldValue('institution', e || null)

                                                if (e) {
                                                    const { value } = e
                                                    setInstitutionName(
                                                        `${value.name || value.popular_name} | ${
                                                            value.city
                                                        } - ${value.uf}`,
                                                    )
                                                } else {
                                                    setInstitutionName(null)
                                                }
                                                if (!touched.institution) {
                                                    setTouched({ ...touched, institution: true })
                                                }
                                            }}
                                            errorMessage={touched.institution && errors.institution}
                                        />
                                    ) : (
                                        <>
                                            <Select
                                                name="state"
                                                className="small-field"
                                                required
                                                options={states}
                                                label={t('select.uf.label')}
                                                placeholder={t('select.uf.placeholder')}
                                                selectedKey={values.state?.uf}
                                                onChange={_setUf}
                                                onBlur={handleBlur('state')}
                                                errorMessage={touched.state && errors.state}
                                            />
                                            <Select
                                                required
                                                options={cities}
                                                label={t('select.city.label')}
                                                placeholder={t('select.city.placeholder')}
                                                name="city"
                                                selectedKey={values.city?.id}
                                                onChange={e => setFieldValue('city', e)}
                                                onBlur={handleBlur('city')}
                                                errorMessage={touched.city && errors.city}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                            <Label>{t('input.value.label')}:</Label>
                            <CheckBox
                                label={t('to-match')}
                                checked={!hasValue}
                                onClick={() => setHasValue(!hasValue)}
                                className="check-box-value"
                            />
                            {hasValue && (
                                <>
                                    <InputCurrency
                                        required
                                        name="value"
                                        value={values.value}
                                        onChangeText={e => setFieldValue('value', e)}
                                        onBlur={handleBlur('value')}
                                        errorMessage={touched.value && errors.value}
                                        maxLength={12}
                                    />
                                    <Row>
                                        <CheckBox
                                            label={t('liquid')}
                                            checked={values.value_type === 0}
                                            onClick={() => _setValueType(0)}
                                        />
                                        <CheckBox
                                            label={t('gross')}
                                            checked={values.value_type === 1}
                                            onClick={() => _setValueType(1)}
                                        />
                                    </Row>
                                    <Label>{t('pay-day')}</Label>
                                    <Row>
                                        <CheckBox
                                            label={t('in-cash')}
                                            checked={values.payment_type === 0}
                                            onClick={() => _setPaymentType(0)}
                                        />
                                        <CheckBox
                                            label={t('to-match')}
                                            checked={values.payment_type === 1}
                                            onClick={() => _setPaymentType(1)}
                                        />
                                    </Row>
                                </>
                            )}
                            <VacanciesCounter>
                                <Label>{t('qty.label')}:</Label>
                                <ActionContainer>
                                    <ActionIconLeft
                                        onClick={() =>
                                            setFieldValue(
                                                'qty',
                                                (values.qty || 0) <= 0 ? 0 : (values.qty || 0) - 1,
                                            )
                                        }
                                    />
                                    <Value>{values.qty || 0}</Value>
                                    <ActionIconRight
                                        onClick={() => setFieldValue('qty', (values.qty || 0) + 1)}
                                    />
                                </ActionContainer>
                            </VacanciesCounter>
                            <Select
                                name="expires_at_days"
                                options={deadlines}
                                label={t('select.expires_at_days.label')}
                                selectedKey={values.expires_at_days}
                                onChange={e => setFieldValue('expires_at_days', e)}
                                onBlur={handleBlur('expires_at_days')}
                                errorMessage={touched.expires_at_days && errors.expires_at_days}
                            />

                            <Select
                                required
                                name="profession"
                                label={t('profession')}
                                options={professions}
                                placeholder={t('select.profession.placeholder')}
                                onChange={value => _setFieldValue('profession', value)}
                                selectedKey={values.profession?.id}
                                errorMessage={touched.profession && errors.profession}
                            />
                            {hasSpecialization && (
                                <>
                                    <ContainerTitleSpecializations>
                                        <Label>{t('specializations')}:</Label>
                                        {showSpecializations && (
                                            <Close onClick={() => setShowSpecializations(false)} />
                                        )}
                                    </ContainerTitleSpecializations>
                                    {showSpecializations ? (
                                        <>
                                            <List
                                                data={specializations}
                                                onRenderItem={(e, i) => (
                                                    <ItemMultiSelect
                                                        key={`opp_${i}`}
                                                        title={e.title}
                                                        selected={values.specializations?.includes(e.value)}
                                                        onClick={() =>
                                                            _setFieldValue('specialization', e.value)
                                                        }
                                                    />
                                                )}
                                            />
                                        </>
                                    ) : (
                                        <Button
                                            title={t('add-specializations')}
                                            onClick={() => setShowSpecializations(true)}
                                        />
                                    )}
                                </>
                            )}
                            <Label>{t('available-days')}:</Label>
                            <Calendar square onDayPress={_setDate} events={events} />

                            <TextArea
                                label={t('description')}
                                value={values.description}
                                onChangeText={e => setFieldValue('description', e)}
                            />

                            <Button disabled={!isValid} title={t('update')} onClick={handleSubmit} />
                        </ContainerForm>
                    </ContentOpportunity>
                </ContentSection>
            </Container>
            <AlertError ref={alertErrorRef} />
        </>
    )
}

export default memo(OpportunityUpdate)
