import React, { forwardRef, memo, useCallback, useImperativeHandle, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalBase } from '../shared'

const initialState = {
    show: false,
    title: null,
    onClick: null,
    onClose: null,
    buttonText: null,
    linkText: null,
    onLinkClick: null,
    icon: null,
}

function reducer(state, props) {
    return { ...state, ...props }
}

function CustomModal({ children }, ref) {
    const [{ show, title, onClick, onClose, buttonText, linkText, onLinkClick, icon }, setModalProps] =
        useReducer(reducer, initialState)
    const { t } = useTranslation()

    useImperativeHandle(ref, () => ({
        show: (props = {}) => {
            setModalProps(props)
        },
    }))

    const _onClose = useCallback(
        (refresh = false) => {
            setModalProps({ show: false })
            setTimeout(() => {
                setModalProps(initialState)
            }, 300)
            if (onClose) {
                onClose(refresh)
            }
        },
        [onClose],
    )

    return (
        <ModalBase
            onButtonClick={onClick}
            buttonText={buttonText}
            title={title || t('warning')}
            linkText={linkText}
            onLinkClick={onLinkClick}
            visible={show}
            onClose={_onClose}
            icon={icon}
        >
            {children}
        </ModalBase>
    )
}

export default memo(forwardRef(CustomModal))
