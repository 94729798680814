import React, { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Container, ContainerInfo, Icon, Label, Time } from './item-working-time.styled'

function ItemWorkingTime({ selectedWorkingTime, workingTime, onItemClick, onDelete }) {
    const [isActive, setActive] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        if (selectedWorkingTime) {
            setActive(selectedWorkingTime?.id === workingTime?.id)
        } else {
            setActive(false)
        }
    }, [selectedWorkingTime, workingTime])

    const _onClick = useCallback(() => {
        if (onItemClick) {
            if (isActive) {
                onItemClick(null)
            } else {
                onItemClick(workingTime)
            }
        }
    }, [workingTime, onItemClick, isActive])

    const _onDelete = useCallback(() => {
        if (workingTime?.id) {
            if (onDelete) {
                onDelete(workingTime.id)
            }
        }
    }, [workingTime, onDelete])

    if (!workingTime) {
        return null
    }

    const { start_hour, end_hour, label } = workingTime
    return (
        <Container isActive={isActive}>
            <ContainerInfo onClick={_onClick}>
                <Label isActive={isActive}>{label}</Label>
                <Time isActive={isActive}>
                    {formatTime(start_hour)} {t('at')} {formatTime(end_hour)}
                </Time>
            </ContainerInfo>
            {isActive ? (
                <Icon isActive name="X" size={20} onClick={_onClick} />
            ) : (
                <Icon name="Trash2" size={20} onClick={_onDelete} />
            )}
        </Container>
    )
}

function formatTime(time) {
    if (time) {
        return time.replace(':', 'h')
    }
}

export default memo(ItemWorkingTime)
