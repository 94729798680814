import React, { forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { VacancyAPI } from '#/api'
import {
    Avatar,
    Button,
    ButtonText,
    CheckBox,
    InfoGroup,
    List,
    Separator,
    ShiftInfo,
    TabsValue,
} from '#/components'
import { Vacancy } from '#/components/icons'
import { ItemMember } from '#/components/items'
import { ActiveEmail, Alert, AlertError, Confirmation, Loading } from '#/modals'

import {
    Amount,
    BannerWarning,
    Bold,
    Container,
    ContainerAvatar,
    ContainerButtons,
    ContainerCoworkers,
    ContainerGroup,
    ContainerMember,
    ContainerVancancy,
    IconWarning,
    LabelVacancy,
    Segment,
    SegmentTitle,
    TextWarning,
} from './vacancy-details.styled'

function VacancyDetails({ vacancy }) {
    const { t } = useTranslation()

    const alertRef = useRef()
    const alertErrorRef = useRef()
    const confirmationRef = useRef()
    const activeEmailRef = useRef()
    const unconfirmed = useSelector(state => state.user?.unconfirmed_data || [])

    const [vacancyComplete, setVacancyComplete] = useState(null)
    const [loading, setLoading] = useState(false)
    const [seeInterests, setSeeInterests] = useState(false)
    const [selectedMembers, setSelectedMembers] = useState([])
    const [tabActive, setTabActive] = useState(1)

    const _loadVacancy = useCallback(async vacancy => {
        try {
            setLoading(true)
            const result = await VacancyAPI.view(vacancy.group.id, vacancy.id)
            setVacancyComplete(result)
        } catch (err) {
        } finally {
            setLoading(false)
        }
    }, [])

    const _onSelectMember = useCallback(
        id => {
            if (selectedMembers.includes(id)) {
                setSelectedMembers(selectedMembers.filter(item => item !== id))
            } else {
                setSelectedMembers([...selectedMembers, id])
            }
        },
        [selectedMembers],
    )

    const _designateVacancy = useCallback(async () => {
        try {
            setLoading(true)

            await VacancyAPI.designate(vacancy.group.id, vacancy.id, {
                interests: selectedMembers,
            })

            alertRef.current && alertRef.current.show({ message: 'Vaga atribuída com sucesso' })
            setSelectedMembers([])
            _loadVacancy(vacancy)
        } catch ({ error, status }) {
            alertErrorRef.current && alertErrorRef.current.show({ message: error })
        } finally {
            setLoading(false)
        }
    }, [vacancy, selectedMembers, alertRef, alertErrorRef, _loadVacancy])

    const _deleteVacancy = useCallback(
        async ({ force = false, shift_id = null, all = false, motive = null }) => {
            setLoading(true)

            let body = {
                type: all ? 'all' : 'allocated',
                motive,
                shift_id: shift_id,
                force,
            }

            try {
                await VacancyAPI.remove(vacancy.group.id, vacancy.id, body)
                if (!all) {
                    _loadVacancy(vacancy)
                }
            } catch ({ message, status }) {
                if (status === 409) {
                    confirmationRef.current &&
                        confirmationRef.current.show({
                            message,
                            type: 'danger',
                            onYesClick: () => {
                                _deleteVacancy({ force: true, shift_id, all, motive })
                            },
                        })
                } else {
                    alertErrorRef.current && alertErrorRef.current.show({ message })
                }
            } finally {
                setLoading(false)
            }
        },
        [vacancy, _loadVacancy, alertErrorRef, confirmationRef],
    )

    // const _modalDeleteVacancy = useCallback(() => {
    //     confirmationRef.current &&
    //         confirmationRef.current.show({
    //             title: 'Cancelar Vaga',
    //             message: 'Deseja cancelar esta vaga?',
    //             icon: 'AlertCircle',
    //             type: 'danger',
    //             inputText: true,
    //             label: 'Motivo',
    //             onYesClick: message => {
    //                 _deleteVacancy({ all: true, motive: message })
    //             },
    //         })
    // }, [_deleteVacancy])

    const _modalCancelInterest = useCallback(
        shift_id => {
            confirmationRef.current &&
                confirmationRef.current.show({
                    title: 'Cancelar Designação',
                    message: 'Deseja realmente esta Designação?',
                    icon: 'AlertCircle',
                    type: 'danger',
                    inputText: true,
                    label: 'Motivo',
                    onYesClick: message => {
                        _deleteVacancy({ motive: message, shift_id })
                    },
                })
        },
        [_deleteVacancy],
    )

    const _modalDesignateVacancy = useCallback(() => {
        confirmationRef.current &&
            confirmationRef.current.show({
                title: 'Designar Vaga',
                message: 'Deseja realmente designar essa vaga?',
                icon: 'AlertCircle',
                onYesClick: () => {
                    _designateVacancy()
                },
            })
    }, [_designateVacancy])

    useEffect(() => {
        if (vacancy) {
            setVacancyComplete(vacancy)
            _loadVacancy(vacancy)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vacancy])

    if (!vacancyComplete) {
        return null
    }
    const { group, coworkers, interests, allocated } = vacancyComplete
    return (
        <>
            <Container>
                <ContainerGroup>
                    <InfoGroup group={group} />
                </ContainerGroup>
                <Segment>
                    <ContainerVancancy>
                        <Vacancy color={group.color && `#${group.color}`} size={55} />
                        <LabelVacancy>{t('vacancy')}</LabelVacancy>
                    </ContainerVancancy>
                </Segment>
                <Separator />
                <Segment>
                    <SegmentTitle>{t('shift-details')}</SegmentTitle>
                    <ShiftInfo shift={vacancyComplete} />
                    {!seeInterests && (
                        <ButtonText onClick={() => setSeeInterests(true)}>
                            {t('see-interest-vacancy')}
                            {interests && <Amount>{interests.length}</Amount>}
                        </ButtonText>
                    )}
                </Segment>
                <Separator />
                {!seeInterests && (
                    <>
                        {coworkers?.length > 0 && (
                            <>
                                <Segment>
                                    <SegmentTitle>{t('who-more')}</SegmentTitle>
                                    <ContainerCoworkers>
                                        {coworkers.map((item, i) => (
                                            <ContainerAvatar key={`member_${i}`}>
                                                <Avatar
                                                    name={item.name}
                                                    userId={item.id}
                                                    source={item.photo}
                                                />
                                            </ContainerAvatar>
                                        ))}
                                    </ContainerCoworkers>
                                </Segment>
                            </>
                        )}
                    </>
                )}
                <Segment>
                    {unconfirmed.includes('email') && (
                        <BannerWarning>
                            <IconWarning />
                            <TextWarning>
                                {t('warning.active-email.message')}
                                <Bold onClick={() => activeEmailRef.current && activeEmailRef.current.show()}>
                                    {t('warning.active-email.click')}
                                </Bold>
                            </TextWarning>
                        </BannerWarning>
                    )}
                </Segment>

                {seeInterests && (interests || allocated) && (
                    <Segment>
                        <SegmentTitle>{t('interest-vacancy')}</SegmentTitle>
                        <TabsValue
                            onChange={e => setTabActive(e)}
                            leftText={t('interest')}
                            rightText={t('designated')}
                        />
                        <List
                            data={tabActive === 1 ? interests : allocated}
                            infoEmptyProps={{
                                iconName: 'Users',
                                message: tabActive === 1 ? t('no-interested') : t('no-designated'),
                            }}
                            onRenderItem={({ user, id }, i) => (
                                <ContainerMember onClick={() => _onSelectMember(id)}>
                                    <ItemMember
                                        key={`m_${i}`}
                                        name={user.name}
                                        description={user.description}
                                        photo={user.photo}
                                        userId={user.id}
                                        isRemovable={tabActive === 2}
                                        onRemove={() => _modalCancelInterest(id)}
                                    />
                                    {tabActive === 1 && <CheckBox checked={selectedMembers.includes(id)} />}
                                </ContainerMember>
                            )}
                        />
                    </Segment>
                )}
            </Container>
            {seeInterests && (
                <>
                    <Separator />
                    <ContainerButtons>
                        <Button
                            title={t('designate')}
                            disabled={selectedMembers?.length < 1}
                            onClick={_modalDesignateVacancy}
                        />
                    </ContainerButtons>
                </>
            )}
            <Loading show={loading} />
            <Alert ref={alertRef} />
            <AlertError ref={alertErrorRef} />
            <Confirmation ref={confirmationRef} />
            <ActiveEmail ref={activeEmailRef} />
        </>
    )
}

export default memo(forwardRef(VacancyDetails))
