import React from 'react'
import { useParams } from 'react-router-dom'

import { PageContainer } from '#/components'

import MyProfile from './my-profile/my-profile'
import UserProfile from './user-profile/user-profile'

function Profile() {
    const { user_id } = useParams()
    return (
        <PageContainer backgroundContrast>
            {user_id ? <UserProfile userID={user_id} /> : <MyProfile />}
        </PageContainer>
    )
}

export default Profile
