import React, { memo } from 'react'

import { Container, Icon, Text } from './error-message.styled'

function ErrorMessage({ children }) {
    return (
        <Container>
            <Icon />
            <Text>{children}</Text>
        </Container>
    )
}

export default memo(ErrorMessage)
