import React, { memo, useCallback, useEffect, useState } from 'react'

import { AccountsAPI } from '#/api'
import { Card } from '#/components'
import { ProfileContext } from '#/contexts'
import { Loading } from '#/modals'

import { WidgetProfileInfo } from '../widgets'
import { Container, ContainerSection, ContainerWidget } from './user-profile.styled'
function UserProfile({ userID }) {
    const [profile, setProfile] = useState(null)
    const [loading, setLoading] = useState(true)
    const _getUserProfile = useCallback(async id => {
        try {
            setLoading(true)
            const result = await AccountsAPI.viewAccount(id)
            setProfile(result)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if (userID) {
            _getUserProfile(userID)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID])

    if (!profile) {
        return null
    }
    return (
        <ProfileContext.Provider value={profile}>
            <Container>
                <ContainerWidget>
                    <Card withoutPadding>
                        <WidgetProfileInfo profile={profile} />
                    </Card>
                </ContainerWidget>

                <ContainerSection />
                <Loading show={loading} />
            </Container>
        </ProfileContext.Provider>
    )
}

export default memo(UserProfile)
