import Dimensions from './dimensions'

const DefaultTheme = {
    isDark: false,
    colors: {
        primary: '#1592e6',
        light: '#67c2ff',
        header: '#1592e6',
        menuInativeColor: '#707070',

        backgroundInput: '#F2F6FB',
        colorTextInput: '#CEDOD2',

        background: '#ffffff',
        backgroundAccent: '#f2f2f2',
        backgroundContrast: '#D9DDE8',
        backgroundOpacity: 'rgba(0, 0, 0, 0.1)',
        backgroundBox: '#DCDFE9',
        backgroundBoxDisabled: 'rgba(0,0,0,0.08)',
        backgroundAvatar: '#EFEFEF',

        hover: 'rgba(0, 0, 0, 0.05)',

        border: 'rgba(0, 0, 0, 0.1)',
        borderGrid: 'rgba(0, 0, 0, 0.15)',
        borderSeparator: '#888888',

        shadow: 'rgba(0,0,0,0.29)',

        input: '#e8e8e8',
        placeholder: '#bdbdbd',

        grey: '#EFEFEF',
        greyMedium: '#bdbdbd',
        greyDark: '#5a5a5a',

        textOpacity: 'rgba(0, 0, 0, 0.5)',
        text: '#6F6F6F',
        textLight: '#888888',

        success: '#00CE96',
        info: '#1594E5',
        danger: '#FF486D',
        yellow: '#FFCE48',

        calendarToday: '#bdbdbd',
        calendarBackground: '#ffffff',
        calendarAccentColor: '#1592e6',
        calendarDaysColor: '#6B6B6B',
        calendarOldDaysColor: 'rgba(0,0,0,0.25)',
        calendarOldDayBackground: 'rgba(0,0,0,0.15)',
        calendarBorderDayColor: '#000000',
    },
    sizes: Dimensions,
}

export default DefaultTheme
