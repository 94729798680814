import styled from 'styled-components'

const Container = styled.div`
    margin-bottom: 10px;
`

const ContainerBoard = styled.div`
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    user-select: none;
`
const ContainerTitleBox = styled.div``

const TitleBox = styled.div`
    width: 150px;
    height: 45px;
    background-color: ${({ theme }) => theme.colors.backgroundBoxDisabled};
    color: ${({ theme }) => theme.colors.text};
    border-radius: 8px;
    overflow: hidden;
    margin: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-weight: bold;
`

const ContainerLabel = styled.div`
    width: 150px;
    height: 45px;
    overflow: hidden;
    margin: 1px;
    padding: 5px;
`

const Label = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    flex-wrap: nowrap;
    display: flex;
`

const Amount = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 12px;
`

const ContainerBox = styled.div`
    overflow-x: auto;
`

const ContainerBoxDay = styled.div`
    display: flex;
`

const Content = styled.div`
    overflow-y: visible;
    overflow-x: hidden;
    width: calc(100% - 152px);
    position: relative;
`

const Box = styled.div`
    min-width: 45px;
    min-height: 45px;
    background-color: ${({ theme: { colors }, disabled }) =>
        disabled ? colors.backgroundBoxDisabled : colors.backgroundBox};
    color: ${({ theme }) => theme.colors.text};
    border-radius: 8px;
    overflow: hidden;
    margin: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
    padding: 4px;
    &.title {
        background-color: ${({ theme }) => theme.colors.backgroundBoxDisabled};
    }
`

const Circle = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: ${({ theme, color }) => (color ? color : theme.colors.primary)};
`

export {
    Container,
    ContainerBoard,
    Box,
    TitleBox,
    ContainerBoxDay,
    ContainerBox,
    ContainerTitleBox,
    Content,
    Circle,
    ContainerLabel,
    Label,
    Amount,
}
