import {
    eachDayOfInterval,
    endOfMonth,
    format,
    isSameDay,
    isToday,
    isWeekend,
    parseISO,
    startOfMonth,
} from 'date-fns'
import React, { memo, useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { scroller } from 'react-scroll'

import {
    Arrow,
    Border,
    BoxDay,
    Circle,
    Container,
    ContainerDay,
    Day,
    DayWeek,
    Scroll,
} from './select-day.styled'

const WEEK = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
function SelectDay({ initialDate, onSelectedDay }) {
    const [currentStep, setCurrentStep] = useState(0)
    const [daySelected, setDaySelected] = useState(null)

    const month = useMemo(() => {
        if (initialDate) {
            let date
            try {
                date = parseISO(initialDate)
            } catch (error) {
                date = new Date()
            }
            if (date) {
                return eachDayOfInterval({ start: startOfMonth(date), end: endOfMonth(date) })
            }
        }
        return []
    }, [initialDate])

    const scrollSteps = useMemo(() => {
        return Math.trunc(month.length / 7)
    }, [month])

    const _openDay = useCallback(
        day => {
            setDaySelected(day)
            if (onSelectedDay && day) {
                onSelectedDay(format(day, 'yyyy-MM-dd'))
            }
        },
        [onSelectedDay],
    )

    const _previus = useCallback(() => {
        const hackStep = currentStep - 1
        const newStep = hackStep <= 0 ? 0 : hackStep
        const day = newStep * 7
        setCurrentStep(newStep)

        scroller.scrollTo(`day_${day <= 0 ? 1 : day}`, {
            horizontal: true,
            spy: true,
            smooth: true,
            duration: 500,
            containerId: 'containerDays',
        })
    }, [currentStep])

    const _next = useCallback(() => {
        const hackStep = currentStep + 1
        const newStep = hackStep >= scrollSteps ? scrollSteps : hackStep
        const day = newStep * 7

        setCurrentStep(newStep)
        scroller.scrollTo(`day_${day >= 28 ? 28 : day}`, {
            horizontal: true,
            spy: true,
            smooth: true,
            duration: 500,
            containerId: 'containerDays',
        })
    }, [currentStep, scrollSteps])

    useLayoutEffect(() => {
        let parseDate
        let day

        try {
            parseDate = initialDate ? parseISO(initialDate) : new Date()
            day = parseDate.getDate()
        } catch (err) {}

        if (day) {
            setTimeout(() => {
                const step = Math.trunc(day / 7)
                setCurrentStep(step)

                scroller.scrollTo(`day_${step * 7}`, {
                    horizontal: true,
                    spy: true,
                    smooth: true,
                    duration: 500,
                    containerId: 'containerDays',
                })
            }, 500)
        }
        setDaySelected(parseDate)
    }, [initialDate])

    return (
        <Container>
            <Arrow name="ChevronLeft" onClick={_previus} />

            <Scroll id="containerDays">
                {month.map((item, i) => (
                    <ContainerDay name={`day_${i + 1}`} key={`day_${i}`}>
                        <DayWeek bold={isWeekend(item)}>{WEEK[item.getDay()]}</DayWeek>
                        <BoxDay onClick={() => _openDay(item)}>
                            <Border isToday={isToday(item)}>
                                <Circle selected={isSameDay(daySelected, item)}>
                                    <Day bold={isWeekend(item)} selected={isSameDay(daySelected, item)}>
                                        {item.getDate()}
                                    </Day>
                                </Circle>
                            </Border>
                        </BoxDay>
                    </ContainerDay>
                ))}
            </Scroll>
            <Arrow name="ChevronRight" onClick={_next} />
        </Container>
    )
}

export default memo(SelectDay)
