import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AuthAPI } from '../../../api'
import Ilustration from '../../../assets/images/ilustration-phone.svg'
import { InputCode } from '../../../components'
import { Alert, AlertError } from '../../../modals'
import { checkAccount } from '../../../redux/thunk'
import { PageRegister } from '../shared'
import { Bold, ContainerInputCode, Message } from './register-active.styled'
function RegisterActive() {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const alertErrorRef = useRef()
    const alertRef = useRef()

    const [code, setCode] = useState(null)
    const [valid, setValid] = useState(false)
    const [loading, setLoading] = useState(false)

    const _activeToken = useCallback(async () => {
        if (!valid || !code) {
            return
        }
        const { email } = user
        setLoading(true)
        try {
            const result = await AuthAPI.activateAccount({
                token: code,
                email,
            })

            dispatch(checkAccount(result))
        } catch ({ message }) {
            alertErrorRef.current && alertErrorRef.current.show({ message })
        } finally {
            setLoading(false)
        }
    }, [code, dispatch, user, valid])

    const _resentToken = useCallback(async () => {
        const { email } = user

        try {
            setLoading(true)
            await AuthAPI.resentToken({
                email,
            })
            alertRef.current && alertRef.current.show({ message: t('account-activision.send') })
        } catch ({ message }) {
            alertErrorRef.current && alertErrorRef.current.show({ message })
        } finally {
            setLoading(false)
        }
    }, [user, t])

    return user ? (
        <>
            <PageRegister
                loading={loading}
                title={t('activision')}
                message={t('account-activision.message')}
                stepsComplete={1}
                disabled={!valid}
                onNextClick={_activeToken}
                ilustration={Ilustration}
            >
                <Message
                    dangerouslySetInnerHTML={{
                        __html: t('account-activision.waring', { email: `<b>${user.email}</b>` }),
                    }}
                />
                <ContainerInputCode>
                    <InputCode
                        label={t('input.code.label')}
                        onChange={e => setValid((e || '').length === 6)}
                        onComplete={code => setCode(code)}
                    />
                </ContainerInputCode>
                <Message>
                    {t('account-activision.resend')}
                    <Bold primary pointer onClick={_resentToken}>
                        {t('click-here')}
                    </Bold>
                </Message>
            </PageRegister>
            <Alert ref={alertRef} />
            <AlertError ref={alertErrorRef} />
        </>
    ) : null
}

export default RegisterActive
