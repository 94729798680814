import { useFormik } from 'formik'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { AppAPI, InstitutionAPI } from '../../../../../api'
import { GroupForms, Input, Select } from '../../../../../components'
import { Alert, AlertError } from '../../../../../modals'
import { ContentContainer } from '../shared'

function CreateInstitution() {
    const history = useHistory()
    const alertErrorRef = useRef()
    const alertRef = useRef()
    const { t } = useTranslation()
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [loading, setLoading] = useState(false)

    const {
        isSubmitting,
        handleBlur,
        values,
        errors,
        touched,
        handleSubmit,
        dirty,
        isValid,
        setFieldValue,
        resetForm,
    } = useFormik({
        initialValues: {
            popular_name: '',
            cnes: '',
            cep: '',
            phone: '',
            uf: '',
            city: '',
            district: '',
            address: '',
            number: '',
            complement: '',
        },
        validationSchema: Yup.object().shape({
            popular_name: Yup.string()
                .trim()
                .min(3, t('input.name-institution.min'))
                .required(t('input.name-institution.required')),
            cep: Yup.string().trim().min(9, t('input.cep.min')).required(t('input.cep.required')),
            address: Yup.string().trim().min(5, t('input.address.min')).required(t('input.address.required')),
            cnes: Yup.string().trim().max(7, t('input.cnes.max')),
            number: Yup.string().trim().required(t('input.cnes.required')),
            uf: Yup.string().nullable(true).required(t('select.uf.required')),
            city: Yup.string()
                .nullable(true)
                .min(3, t('select.city.min'))
                .required(t('select.city.required')),
            district: Yup.string()
                .trim()
                .min(5, t('input.district.min'))
                .required(t('input.district.required')),
        }),
        onSubmit: async values => {
            try {
                await InstitutionAPI.create(values)
                if (alertRef.current) {
                    alertRef.current.show({ message: 'Instituição criada com sucesso' })
                }
                resetForm()
            } catch ({ message }) {
                if (alertErrorRef.current) {
                    alertErrorRef.current.show({ message })
                }
            }
        },
    })

    const _loadUf = useCallback(async () => {
        setLoading(true)
        try {
            const result = await AppAPI.UFlist()

            const states = result.items.map(item => {
                return { label: item.code, value: { uf: item.code, name: item.name }, key: item.code }
            })
            setStates(states)
        } catch (err) {
        } finally {
            setLoading(false)
        }
    }, [])

    const _loadCities = useCallback(async uf => {
        setLoading(true)
        try {
            const result = await AppAPI.listCitiesByUf(uf)

            const cities = result.items.map(item => {
                return { label: item.name, value: { id: item.id, name: item.name }, key: item.id }
            })
            setCities(cities)
        } catch (err) {
        } finally {
            setLoading(false)
        }
    }, [])

    const _setUf = useCallback(
        state => {
            if (state) {
                _loadCities(state.uf)
            } else {
                setCities([])
            }
            setFieldValue('uf', state)
        },
        [_loadCities, setFieldValue],
    )

    useEffect(() => {
        _loadUf()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <ContentContainer
                arrowBack
                loading={loading || isSubmitting}
                overflowHidden={false}
                title={t('create-institution.title')}
                disabled={!isValid || !dirty || isSubmitting}
                buttonTitle={t('create')}
                onNextClick={handleSubmit}
            >
                <Input
                    required
                    label={t('input.name-institution.label')}
                    name="institution_name"
                    value={values.popular_name}
                    onChangeText={e => setFieldValue('popular_name', e)}
                    onBlur={handleBlur('popular_name')}
                    errorMessage={touched.popular_name && errors.popular_name}
                />

                <Input
                    label={t('input.cnes.label')}
                    name="cnes"
                    value={values.cnes}
                    onChangeText={e => setFieldValue('cnes', e)}
                    onBlur={handleBlur('cnes')}
                    errorMessage={touched.cnes && errors.cnes}
                />

                <Input
                    required
                    label={t('input.cep.label')}
                    mask="99999-999"
                    name="cep"
                    value={values.cep}
                    onChangeText={e => setFieldValue('cep', e)}
                    onBlur={handleBlur('cep')}
                    errorMessage={touched.cep && errors.cep}
                />
                <GroupForms>
                    <Input
                        required
                        label={t('input.address.label')}
                        name="address"
                        value={values.address}
                        onChangeText={e => setFieldValue('address', e)}
                        onBlur={handleBlur('address')}
                        errorMessage={touched.address && errors.address}
                    />
                    <Input
                        required
                        label={t('input.number.label')}
                        name="number"
                        value={values.number}
                        onChangeText={e => setFieldValue('number', e)}
                        onBlur={handleBlur('number')}
                        errorMessage={touched.number && errors.number}
                    />
                </GroupForms>
                <GroupForms>
                    <Input
                        label={t('input.complement.label')}
                        name="complement"
                        value={values.complement}
                        onChangeText={e => setFieldValue('complement', e)}
                        onBlur={handleBlur('complement')}
                        errorMessage={touched.complement && errors.complement}
                    />
                    <Input
                        required
                        label={t('input.district.label')}
                        name="district"
                        value={values.district}
                        onChangeText={e => setFieldValue('district', e)}
                        onBlur={handleBlur('district')}
                        errorMessage={touched.district && errors.district}
                    />
                </GroupForms>
                <GroupForms>
                    <Select
                        name="uf"
                        className="small-field"
                        required
                        options={states}
                        label={t('select.uf.label')}
                        placeholder={t('select.uf.placeholder')}
                        selectedKey={values.uf?.uf}
                        onChange={_setUf}
                        onBlur={handleBlur('uf')}
                        errorMessage={touched.uf && errors.uf}
                    />
                    <Select
                        required
                        options={cities}
                        label={t('select.city.label')}
                        placeholder={t('select.city.placeholder')}
                        name="city"
                        selectedKey={values.city?.id}
                        onChange={e => setFieldValue('city', e)}
                        onBlur={handleBlur('city')}
                        errorMessage={touched.city && errors.city}
                    />
                </GroupForms>

                <Input
                    name="phone"
                    label={t('phone')}
                    mask="(99)99999-9999"
                    value={values.phone}
                    onChangeText={e => setFieldValue('phone', e)}
                    onBlur={handleBlur('phone')}
                    errorMessage={touched.phone && errors.phone}
                />
            </ContentContainer>
            <AlertError ref={alertErrorRef} />
            <Alert ref={alertRef} onClose={() => history.goBack()} />
        </>
    )
}

export default memo(CreateInstitution)
