import styled from 'styled-components'

import { Feather } from '#/components/icons'
const Container = styled.div`
    padding: 10px;
    display: flex;
    user-select: none;
    border-bottom: solid 1px;
    border-bottom-color: ${({ theme }) => theme.colors.border};
    cursor: pointer;
    &:hover {
        background-color: ${({ theme }) => theme.colors.hover};
    }
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    flex: 1;
`

const Icon = styled(Feather)`
    color: ${({ theme }) => theme.colors.text};
`

export { Container, Title, Icon }
