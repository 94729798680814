import React, { memo } from 'react'

import { Loading } from '../../../../../modals'
import { Container } from './widget-container.styled'

function WidgetContainer({ loading, children }) {
    return (
        <Container>
            {children}
            <Loading show={loading} />
        </Container>
    )
}

export default memo(WidgetContainer)
