import styled from 'styled-components'

import { Feather } from '../../../icons'
const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 5px;
`
const Text = styled.div`
    color: ${({ theme }) => theme.colors.danger};
    display: flex;
    font-size: 12px;
    flex: 1;
`

const Icon = styled(Feather).attrs({ name: 'AlertCircle', size: 12 })`
    color: ${({ theme }) => theme.colors.danger};
    margin-right: 4px;
`

export { Text, Icon, Container }
