import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setTheme } from '#/redux/reducers/theme'

import { Feather } from '../icons'
import { Container } from './toggle-theme.styled'
function ToggleTheme() {
    const theme = useSelector(state => state.theme)
    const dispatch = useDispatch()

    const _toggle = event => {
        if (theme === 'default') {
            dispatch(setTheme('dark'))
        } else {
            dispatch(setTheme('default'))
        }
    }

    return (
        <Container onClick={_toggle}>
            <Feather name="Repeat" color="#fff" />
        </Container>
    )
}

export default memo(ToggleTheme)
