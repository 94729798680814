import styled from 'styled-components'

const Container = styled.div`
    min-height: 300px;
    .search {
        margin: 10px 16px;
    }
    .item-member {
        padding: 5px 10px;
    }
`

const ContainerItem = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .item-member {
        flex: 1;
    }
    .button {
        width: 80px;
        border-radius: 2px;
        height: 28px;
        padding: 0px 6px;
        margin-right: 16px;
        text-transform: uppercase;
    }
`

export { Container, ContainerItem }
