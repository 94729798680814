import styled from 'styled-components'

const ContainerForm = styled.div`
    padding: 16px;
`

const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    .button {
        width: 120px;
    }
`

const Icon = styled.img`
    height: 15px;
    width: 15px;
    margin-right: 10px;
`

const Label = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
`
const Expand = styled.div`
    flex: 1;
`
export { ContainerForm, Expand, Icon, Label, Row }
