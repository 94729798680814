import { format, parseISO } from 'date-fns'
import _ from 'lodash'
import React, { memo, useCallback, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { GroupAPI } from '#/api'
import { Button, ContentSection, List, TitleBar, TransactionView } from '#/components'
import { Select } from '#/components/forms'
import { useLoadGroup } from '#/hooks'

import { ContainerItemTransaction, ContainerSelect, ContianerSettings } from './group-historic.styled'

const initialStateParams = {
    page: 1,
    total: 0,
}
function reducerParams(state, newParams) {
    return {
        ...state,
        ...newParams,
    }
}

function GroupHistoric() {
    useLoadGroup()
    const { t } = useTranslation()

    const user = useSelector(state => state.user)
    const color = useSelector(state => state.group?.color)
    const group_id = useSelector(state => state.group?.id)
    const is_admin = useSelector(state => state.group?.is_admin)
    const analysts = useSelector(state => state.group?.analysts)
    const [currentPage, setCurrentPage] = useState(1)
    const [params, setParams] = useReducer(reducerParams, initialStateParams)

    const [historicSettings, setHistorySettings] = useState({ months: [], members: [] })

    const [historicOriginal, setHistoricOriginal] = useState([])
    const [historic, setHistoric] = useState([])
    const [loading, setLoading] = useState(true)

    const _getHistorySettings = useCallback(async () => {
        if (!group_id || !user) {
            return { months: [], members: [] }
        }
        try {
            const result = await GroupAPI.getGroupHistorySettings(group_id)

            const monthsArray = result.months.map(item => ({
                key: item.month,
                label: item.label,
                value: item.month,
            }))

            const membersArray = result.members.map(item => ({
                key: item.id,
                label: item.name,
                value: item.id,
            }))
            if (is_admin || analysts.filter(analyst => analyst.id === user?.id).length > 0) {
                setHistorySettings({
                    months: [{ key: 'all', label: 'Todos' }, ...monthsArray],
                    members: [{ key: 'all', label: 'Todos' }, ...membersArray],
                })
            } else {
                setHistorySettings({
                    months: [{ key: 'all', label: 'Todos' }, ...monthsArray],
                    members: [
                        {
                            key: user.id,
                            label: user.name,
                            value: user.id,
                        },
                    ],
                })
            }
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }, [group_id, user, is_admin, analysts])

    const _setFilter = useCallback(
        data => {
            setParams({ ...params, ...data })
        },
        [params],
    )
    const _getHistory = useCallback(async () => {
        try {
            if (!params.month) {
                alert('Selecione um mês para carregar o histórico.')

                return
            }

            setLoading(true)

            const result = await GroupAPI.getGroupHistory(group_id, { ...params, page: 1, total: 0 })
            setParams({
                page: result.current_page,
                total: result.total,
            })

            const items = result.items.map(item => ({ ...item, date: item.date.substring(0, 10) }))

            setHistoricOriginal(items)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }, [group_id, params])

    const _loadMore = useCallback(async () => {
        if (params.total <= historicOriginal.length) {
            return
        }

        if (loading) {
            return
        }

        if (params.page + 1 === currentPage) {
            return
        }

        setCurrentPage(currentPage + 1)
        try {
            const result = await GroupAPI.getGroupHistory(group_id, { ...params, page: params.page + 1 })
            setParams({
                page: result.current_page,
                total: result.total,
            })

            const items = result.items.map(item => ({ ...item, date: item.date.substring(0, 10) }))
            setHistoricOriginal([...historicOriginal, ...items])
        } catch (err) {
            setCurrentPage(params.page)
        } finally {
            setLoading(false)
        }
    }, [params, loading, historicOriginal, currentPage, group_id])

    useEffect(() => {
        const historic = _.chain(historicOriginal)
            .groupBy('date')
            .map((value, key) => ({ title: format(parseISO(key), 'dd/MM/yyyy'), items: value || [] }))
            .value()

        setHistoric(historic)
    }, [historicOriginal])

    useEffect(() => {
        if (group_id) {
            const analyst = analysts.filter(analyst => analyst.id === user?.id).length > 0

            _getHistorySettings()
            setParams({
                month: format(new Date(), 'yyyy-MM'),
                member: is_admin || analyst ? 'all' : user?.id,
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group_id])

    return (
        <>
            <ContentSection loading={loading}>
                <ContianerSettings>
                    <ContainerSelect>
                        <Select
                            label={t('select.month.label')}
                            options={historicSettings.months}
                            onChange={e => _setFilter({ month: e })}
                            selectedKey={params.month || 'all'}
                        />
                    </ContainerSelect>
                    <ContainerSelect>
                        <Select
                            label={t('select.member.label')}
                            options={historicSettings.members}
                            onChange={e => _setFilter({ member: e })}
                            selectedKey={params.member || 'all'}
                        />
                    </ContainerSelect>
                    <Button title={t('generate-history')} dimension="auto" onClick={_getHistory} />
                </ContianerSettings>
                <List
                    data={historic}
                    onRenderItem={({ title, items }, i) => (
                        <div key={`item_${i}`}>
                            <TitleBar title={title} />
                            {items.map(({ from, to, type, transaction, offers }, i) => (
                                <ContainerItemTransaction key={`transaction_${i}`}>
                                    <TransactionView
                                        color={color && `#${color}`}
                                        from={from}
                                        transaction={transaction}
                                        offers={offers}
                                        to={to}
                                        type={type}
                                    />
                                </ContainerItemTransaction>
                            ))}
                        </div>
                    )}
                    loadMore={historicOriginal.length < params.total}
                    onLoadMoreClick={_loadMore}
                    isLoading={loading}
                />
            </ContentSection>
        </>
    )
}

export default memo(GroupHistoric)
