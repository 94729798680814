import styled from 'styled-components'

const Container = styled.div`
    margin-bottom: 10px;

    .user {
        margin-bottom: 10px;
    }
    .info-group {
        margin-bottom: 10px;
    }
    .transaction-view {
        margin-bottom: 10px;
    }
    :last-child {
        margin-bottom: 0;
    }
`

const Image = styled.img`
    width: 100%;
    object-fit: contain;
    margin-bottom: 20px;
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    margin-bottom: 10px;
`
const Message = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    margin: 10px 0px;
    :last-child {
        margin-bottom: 0px;
    }
`

export { Container, Image, Message, Title }
