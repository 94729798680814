function transformComments(comment) {
    return comment
        .replace(/<script[^>]*>/gi, '')
        .replace(/<\/script[^>]*>/gi, '')
        .replace(/<link[^>]*>/gi, '')
        .replace(/<\/link[^>]*>/gi, '')
        .replace(/<a[^>]*>/gi, '')
        .replace(/<\/a[^>]*>/gi, '')
        .replace(/<div[^>]*>/gi, '')
        .replace(/<\/div[^>]*>/gi, '')
        .replace(/<p[^>]*>/gi, '')
        .replace(/<\/p[^>]*>/gi, '')
}

export { transformComments }
