import { addMonths, format, parseISO, subMonths } from 'date-fns'
import localePT from 'date-fns/locale/pt-BR'
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { ScheduleAPI, ShiftAPI } from '#/api'
import {
    Badge,
    Button,
    ButtonText,
    Card,
    GridSchedule,
    HeaderGroup,
    NavLink,
    PageContainer,
    Search,
    Warning,
} from '#/components'
import { Input } from '#/components/forms'
import { GridScheduleContext } from '#/contexts'
import { useLoadGroup, useQuery } from '#/hooks'
import { ActiveEmail, Alert, AlertError, Confirmation, CustomModal } from '#/modals'
import { parseQuery } from '#/utils'

import {
    BackIcon,
    BannerWarning,
    Bold,
    Container,
    ContainerButtons,
    ContainerGrid,
    ContainerTitle,
    ContainerTools,
    IconWarning,
    Manager,
    Period,
    TextWarning,
    Title,
} from './manager-schedule.styled'

const initialStateParams = {
    page: 1,
    total: 0,
    limit: 100,
    search: null,
}
function reducerParams(state, newParams) {
    return {
        ...state,
        ...newParams,
    }
}

function ManagerSchedule() {
    useLoadGroup()
    const [params, setParams] = useReducer(reducerParams, initialStateParams)
    const { t } = useTranslation()
    const { status } = useQuery()
    const history = useHistory()
    const confirmationRef = useRef()
    const alertRef = useRef()
    const alertErrorRef = useRef()
    const customModalRef = useRef()
    const activeEmailRef = useRef()

    const unconfirmed = useSelector(state => state.user?.unconfirmed_data || [])

    const group_id = useSelector(state => state.group?.id)
    const groupColor = useSelector(state => state.group?.color)
    const manager = useSelector(state => state.group?.manager)
    const [shedules, setShedules] = useState([])
    const [vacancies, setVacancies] = useState(null)
    const [loading, setLoading] = useState(true)
    const { period } = useParams()
    const [currentPage, setCurrentPage] = useState(1)

    const schedulePeriod = useMemo(() => {
        if (period) {
            return format(parseISO(period), 'MMMM yyyy', { locale: localePT })
        }
    }, [period])

    const isOldMonth = useMemo(() => {
        const date = Number(format(new Date(), 'yyyyMM'))
        const scheduleDate = Number(period.replace(/[^0-9]/g, ''))
        return scheduleDate >= date
    }, [period])

    const hideTools = useMemo(() => {
        const scheduleDate = Number(period.replace(/[^0-9]/g, ''))
        const previousMonth = Number(format(subMonths(new Date(), 3), 'yyyyMM'))

        return scheduleDate >= previousMonth
    }, [period])

    const _goBack = useCallback(() => {
        history.goBack()
    }, [history])

    const _loadShifts = useCallback(
        async search => {
            try {
                const result = await ShiftAPI.list(group_id, period, { ...initialStateParams, search })
                setParams({
                    page: result.current_page,
                    total: result.total,
                    search: search,
                })
                setShedules(result.items)
            } catch (error) {}
        },
        [period, group_id],
    )

    const _loadMore = useCallback(async () => {
        if (params.total <= shedules.length) {
            return
        }

        if (loading) {
            return
        }
        setLoading(true)
        if (params.page + 1 === currentPage) {
            return
        }

        setCurrentPage(currentPage + 1)
        try {
            const result = await ShiftAPI.list(group_id, period, { ...params, page: params.page + 1 })
            setParams({
                page: result.current_page,
                limit: result.per_page,
                total: result.total,
            })
            setShedules([...shedules, ...result.items])
        } catch (err) {
            setCurrentPage(params.page)
        } finally {
            setLoading(false)
        }
    }, [params, loading, shedules, currentPage, group_id, period])

    const _onSearch = useCallback(
        search => {
            _loadShifts(search)
        },
        [_loadShifts],
    )

    const _loadVacancies = useCallback(async () => {
        try {
            const result = await ShiftAPI.listVacancies(group_id, period)

            setVacancies(result)
        } catch (error) {}
    }, [period, group_id])

    const _onAddShift = useCallback(
        (user_id, working_time_id, shift, minutes) => {
            const cloneShedules = [...shedules]

            const index = cloneShedules.findIndex(({ user }) => user.id === user_id)
            if (index !== -1) {
                const userShedules = cloneShedules[index]
                userShedules.minutes = minutes

                const workingtime = userShedules.items.find(
                    ({ workingtime }) => workingtime.id === working_time_id,
                )
                if (workingtime) {
                    const indexShift = workingtime.shifts.findIndex(({ date }) => date === shift.date)
                    if (indexShift !== -1) {
                        workingtime.shifts[indexShift] = shift
                    }
                }

                setShedules(cloneShedules)
            }
        },
        [shedules],
    )

    const _publishSchedule = useCallback(async () => {
        try {
            setLoading(true)
            const body = {
                status: 'publi',
            }
            await ScheduleAPI.update(group_id, period, body)
            if (alertRef.current) {
                alertRef.current.show({
                    title: 'Plantões Especiais',
                    message: 'Adicione um valor para plantões especiais caso haja feriados neste mês.',
                    buttonText: 'Adicionar agora',
                    icon: 'Calendar',
                    linkText: 'Agora não',
                })
            }
            history.replace({
                pathname: `/gerenciar-escala/${group_id}/${period}`,
                search: parseQuery({ status: 'publi' }),
            })
        } catch ({ message }) {
            alertErrorRef.current && alertErrorRef.current.show({ message })
        } finally {
            setLoading(false)
        }
    }, [alertErrorRef, alertRef, period, group_id, history])

    const _confirmPublish = useCallback(async () => {
        if (confirmationRef.current) {
            confirmationRef.current.show({
                title: 'Publicar Escala',
                message:
                    'Ao publicar a escala, todos os membros do grupo poderão visualizá-la. Tem certeza de que deseja publicá-la agora?',
                onYesClick: _publishSchedule,
            })
        }
    }, [confirmationRef, _publishSchedule])

    const _onRemoveShift = useCallback(
        (user_id, working_time_id, shift_id, minutes) => {
            const cloneShedules = [...shedules]

            const index = cloneShedules.findIndex(({ user }) => user.id === user_id)
            if (index !== -1) {
                const userShedules = cloneShedules[index]
                userShedules.minutes = minutes

                const workingtime = userShedules.items.find(
                    ({ workingtime }) => workingtime.id === working_time_id,
                )
                if (workingtime) {
                    const indexShift = workingtime.shifts.findIndex(({ id }) => id === shift_id)
                    if (indexShift !== -1) {
                        workingtime.shifts[indexShift].id = null
                    }
                }

                setShedules(cloneShedules)
            }
        },
        [shedules],
    )

    const _onChangeVacancy = useCallback(
        (working_time_id, vacancy, minutes, slots) => {
            const clonevacancies = vacancies

            if (clonevacancies) {
                clonevacancies.minutes = minutes

                const workingtime = clonevacancies.items.find(
                    ({ workingtime }) => workingtime.id === working_time_id,
                )
                if (workingtime) {
                    const indexVacancy = workingtime.vacancies.findIndex(({ date }) => date === vacancy.date)
                    if (indexVacancy !== -1) {
                        workingtime.vacancies[indexVacancy] = vacancy
                        workingtime.vacancies[indexVacancy].total = slots
                    }
                }

                setVacancies(clonevacancies)
            }
        },
        [vacancies],
    )

    const _replicate = useCallback(async () => {
        try {
            setLoading(true)
            const month = parseISO(period)
            let body = {
                from: period,
                to: format(addMonths(month, 1), 'yyyy-MM', { locale: localePT }).toUpperCase(),
            }
            const result = await ScheduleAPI.replicate(group_id, body)
            alertRef.current &&
                alertRef.current.show({
                    title: t('replicate-schedule-success'),
                    message: t('replicate-schedule-success-messenger'),
                })
        } catch ({ message }) {
            alertErrorRef.current && alertErrorRef.current.show({ message })
        } finally {
            setLoading(false)
        }
    }, [group_id, alertErrorRef, period])

    const _ShowModalReplicate = useCallback(() => {
        if (customModalRef.current) {
            customModalRef.current.show({ show: true, title: t('replicate-schedule'), onClick: _replicate })
        }
    }, [customModalRef, t, _replicate])

    useEffect(() => {
        if (group_id) {
            setTimeout(async () => {
                setLoading(true)
                await _loadShifts()
                await _loadVacancies()
                setLoading(false)
            }, 100)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group_id])

    return (
        <>
            <PageContainer backgroundContrast loading={loading} overflowYHidden>
                <Container className="container-manager">
                    <ContainerTitle className="tools">
                        <BackIcon onClick={_goBack} size={30} />
                        <Title>{t('scale-management-fixed')}</Title>
                    </ContainerTitle>
                    <Card withoutPadding overflowHidden>
                        <HeaderGroup
                            leftComponent={
                                manager && (
                                    <>
                                        <Period>{schedulePeriod}</Period>
                                        <Manager>
                                            {t('manager-schedule.manager', { name: manager.name })}
                                        </Manager>
                                    </>
                                )
                            }
                        />
                        <ContainerTools className="container-tools">
                            <Search onSearch={_onSearch} />
                            {status === 'draft' && <Badge variant="danger" title={t('draft-scale')} />}
                            {unconfirmed.includes('email') ? (
                                <BannerWarning>
                                    <IconWarning />
                                    <TextWarning>
                                        {t('warning.active-email.message')}
                                        <Bold
                                            onClick={() =>
                                                activeEmailRef.current && activeEmailRef.current.show()
                                            }
                                        >
                                            {t('warning.active-email.click')}
                                        </Bold>
                                    </TextWarning>
                                </BannerWarning>
                            ) : (
                                <ContainerButtons>
                                    <NavLink to={`/imprimir-escala/${group_id}/${period}`} target="_blank">
                                        <ButtonText iconName="Printer">{t('print')}</ButtonText>
                                    </NavLink>
                                    <NavLink
                                        to={{
                                            pathname: `/revisar-escala/${group_id}/${period}`,
                                            search: parseQuery({ status }),
                                        }}
                                    >
                                        <ButtonText iconName="Eye">{t('review')}</ButtonText>
                                    </NavLink>
                                    {hideTools && (
                                        <>
                                            <ButtonText iconName="Copy" onClick={_ShowModalReplicate}>
                                                {t('replicate')}
                                            </ButtonText>

                                            {status !== 'publi' && isOldMonth && (
                                                <Button
                                                    title={t('publish')}
                                                    dimension="auto"
                                                    onClick={_confirmPublish}
                                                />
                                            )}
                                        </>
                                    )}
                                </ContainerButtons>
                            )}
                        </ContainerTools>
                        <ContainerGrid className="container-grid">
                            {shedules.length > 0 && (
                                <GridScheduleContext.Provider
                                    value={{
                                        group_id,
                                        schedule_status: status,
                                        color: groupColor && `#${groupColor}`,
                                        onAddShift: _onAddShift,
                                        onRemoveShift: _onRemoveShift,
                                        onChangeVacancy: _onChangeVacancy,
                                        ignoreOld: status !== 'publi' && isOldMonth,
                                    }}
                                >
                                    <GridSchedule
                                        period={period}
                                        shedules={shedules}
                                        vacancies={vacancies}
                                        loadMore={shedules.length < params.total}
                                        onMoreClick={_loadMore}
                                    />
                                </GridScheduleContext.Provider>
                            )}
                        </ContainerGrid>
                    </Card>
                </Container>
            </PageContainer>
            <CustomModal ref={customModalRef}>
                <>
                    <Warning>{t('replication-schedule.warning')}</Warning>
                    <Input value={transformPeriod(period)} disabled label={t('replication-schedule.from')} />
                    <Input value={transformPeriod(period, 1)} disabled label={t('replication-schedule.to')} />
                </>
            </CustomModal>
            <Confirmation ref={confirmationRef} />
            <Alert ref={alertRef} />
            <AlertError ref={alertErrorRef} />
            <ActiveEmail ref={activeEmailRef} />
        </>
    )
}

function transformPeriod(period, add) {
    if (period) {
        const month = parseISO(period)
        if (add) {
            return format(addMonths(month, 1), 'MMMM yyyy', { locale: localePT }).toUpperCase()
        } else {
            return format(month, 'MMMM yyyy', { locale: localePT }).toUpperCase()
        }
    }
}

export default ManagerSchedule
