import styled, { css } from 'styled-components'

const Container = styled.div`
    display: flex;
    border-bottom: 1px ${({ theme }) => theme.colors.backgroundContrast} solid;
    padding: 10px;
    cursor: pointer;
    user-select: none;
    :last-child {
        border: none;
    }
    &:hover {
        background-color: ${({ theme }) => theme.colors.hover};
    }
    ${({ active }) =>
        active &&
        css`
            background-color: ${({ theme }) => theme.colors.primary + '26'};
        `}
`

const ContainerInfo = styled.div`
    flex: 1;
    margin-left: 5px;
`

export { Container, ContainerInfo }
