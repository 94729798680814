import React, { memo, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import Avatar from '../../../avatar/avatar'
import { FontPA } from '../../../icons'
import { Container, Description, Name } from './group-role.styled'
function GroupRole({ role, user }) {
    const { colors } = useContext(ThemeContext)
    if (!user) {
        return null
    }
    return (
        <Container>
            <FontPA name="manager" color={colors.yellow} size={40} />
            <Avatar source={user.photo} name={user.name} color={colors.yellow} border size={70} />
            <Name>{user.name}</Name>
            {!!user.description && <Description>{user.description}</Description>}
        </Container>
    )
}

export default memo(GroupRole)
