import styled from 'styled-components'

import { Feather } from '../../icons'

const Container = styled.div`
    background-color: ${({ isActive, theme: { colors } }) =>
        isActive ? colors.primary : colors.backgroundOpacity};
    padding: 5px 10px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    margin: 10px 0px;

    :first-child {
        margin-top: 0px;
    }
    :last-child {
        margin-bottom: 0px;
    }
    user-select: none;
`

const ContainerInfo = styled.div`
    flex: 1;
    display: flex;
    cursor: pointer;
`

const Label = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 10px;
    color: ${({ isActive, theme: { colors } }) => (isActive ? '#fff' : colors.text)};
`

const Time = styled.div`
    color: ${({ theme }) => theme.colors.text};
    color: ${({ isActive, theme: { colors } }) => (isActive ? '#fff' : colors.text)};
`

const Icon = styled(Feather)`
    color: ${({ theme }) => theme.colors.text};
    margin-left: 15px;
    cursor: pointer;
    color: ${({ isActive, theme: { colors } }) => (isActive ? '#fff' : colors.text)};
`

export { Container, Label, Time, Icon, ContainerInfo }
