import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    padding: 4px 5px;
    &:hover {
        background-color: ${({ theme }) => theme.colors.hover};
    }
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
`

export { Container, Title }
