import React, { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { getDistanceWord } from '#/utils'

import Avatar from '../avatar/avatar'
import Card from '../card/card'
import InfoGroup from '../info-group/info-group'
import ShiftDetails from '../shift-details/shift-details'
import TransactionView from '../transaction-view/transaction-view'
import { GroupRole, Schedule, Welcome } from './contents'
import { Container, Image, Message, Title } from './post.styled'
function Post({ data, type, date }) {
    const { t } = useTranslation()
    const {
        title,
        role,
        image,
        content,
        group,
        message,
        user,
        shift,
        offers,
        from,
        to,
        manager,
        non_manager,
        transaction,
        schedule,
        main,
        vacancy,
    } = data

    const getRoleName = useCallback(
        role =>
            ({
                manager: t('schedule-manager'),
                assist: t('schedule-assistant'),
            }[role || '']),
        [t],
    )

    const cardProps = useMemo(() => {
        switch (type) {
            case 'added_member':
                return {
                    title: t('post.added_member.title'),
                    subTitle: t('post.added_member.sub-title', { name: user.name }),
                    leftIcon: 'Users',
                }
            case 'amount_request':
                return {
                    title: t('post.amount_request.title'),
                    subTitle: t('post.amount_request.sub-title'),
                    leftIcon: 'DollarSign',
                }
            case 'assignment_confirmation':
                return {
                    title: t('post.assignment_confirmation.title'),
                    leftIcon: 'arrow-left',
                }
            case 'news_pick':
                return null
            case 'group_role':
                return {
                    title: t('post.group_role.title'),
                    subTitle: t('post.group_role.sub-title', {
                        name: manager?.name,
                        role: getRoleName(role),
                    }),
                    leftIcon: 'manager',
                }
            case 'exchange_confirmation':
                return {
                    title: t('post.exchange_confirmation.title'),
                    leftIcon: 'exchange',
                }
            case 'exchange_offer':
                return {
                    title: t('post.exchange_offer.title'),
                    leftIcon: 'exchange',
                }
            case 'official_notice':
                return {
                    title: t('post.official_notice.title'),
                    subTitle: t('post.official_notice.sub-title'),
                    leftIcon: 'Bell',
                }
            case 'schedule_publication':
                return {
                    title: t('post.schedule_publication.title'),
                    subTitle: t('post.schedule_publication.sub-title'),
                    leftIcon: 'Calendar',
                }
            case 'shift_removed':
                return {
                    title: t('post.shift_removed.title'),
                    leftIcon: 'Trash',
                }
            case 'vacancy_interest':
                return {
                    title: t('post.vacancy_interest.title'),
                    subTitle: t('post.vacancy_interest.sub-title'),
                    leftIcon: 'vacancy',
                }
            case 'vacancy_confirmation':
                return {
                    title: t('post.vacancy_confirmation.title'),
                    subTitle: t('post.vacancy_confirmation.sub-title'),
                    leftIcon: 'vacancy',
                }
            case 'welcome_message':
                return null
            default:
                return null
        }
    }, [type, user, manager, role, t, getRoleName])

    return (
        <Container>
            <Card {...cardProps} rightText={getDistanceWord(date)}>
                {!!image && <Image src={image} />}
                {!!user && type !== 'group_role' && (
                    <Avatar
                        className="user"
                        border
                        color={group?.color}
                        size={60}
                        name={user.name}
                        source={user.photo}
                        council={user.council}
                        description={user.description}
                        showDetails
                    />
                )}
                {!!group && <InfoGroup group={group} />}
                {!!role && <GroupRole role={role} user={user} />}
                {(!!shift || !!vacancy) && <ShiftDetails shift={shift || vacancy} horizontal />}
                {!!schedule && <Schedule schedule={schedule} />}
                {!!from && !!to && (
                    <TransactionView
                        isDateFormated
                        color={group?.color && `#${group.color}`}
                        from={from}
                        to={to}
                        transaction={transaction}
                        type={type}
                        offers={offers}
                    />
                )}
                {!!title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
                {!!message && <Message dangerouslySetInnerHTML={{ __html: message }} />}
                {!!content && <Message dangerouslySetInnerHTML={{ __html: content }} />}
                {!!main && <Message dangerouslySetInnerHTML={{ __html: main }} />}
                {type === 'welcome_message' && <Welcome manager={manager} noManager={non_manager} />}
                {/* {[
                    'added_member',
                    'amount_request',
                    'group_role',
                    'exchange_offer',
                    'official_notice',
                    'schedule_publication',
                    'vacancy_interest',
                ].includes(type) && <SeeDetails>{t('see-details')}</SeeDetails>} */}
            </Card>
        </Container>
    )
}

export default memo(Post)
