import styled from 'styled-components'

import { Input } from '../forms'
import { Feather } from '../icons'

const Container = styled.div`
    position: relative;
    display: flex;
    min-width: 300px;
    .input {
        margin-bottom: 0;
    }
`

const InputSearch = styled(Input)`
    .input-field {
        padding-left: 40px;
    }
`
const SearchIcon = styled(Feather).attrs({ name: 'Search' })`
    color: ${({ theme }) => theme.colors.text};
    position: absolute;
    height: ${({ theme }) => theme.sizes.formHeight}px;
    left: 6px;
`
const ClearIcon = styled(Feather).attrs({ name: 'X' })`
    color: ${({ theme }) => theme.colors.danger};
    position: absolute;
    height: ${({ theme }) => theme.sizes.formHeight}px;
    left: 6px;
    cursor: pointer;
`

export { InputSearch, SearchIcon, Container, ClearIcon }
