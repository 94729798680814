import { Axios } from './axios-config'

const list = (search = '') => {
    let params = {
        search,
    }

    return Axios().get('institutions?limit=10', { params })
}

const listWorksAt = (search = '', page = 1, limit = 20) => {
    let query = ''

    if (search) {
        query += `&search=${search}`
    }

    return Axios().get(`institutions/works-at?page=${page}&limit=${limit}${query}`)
}

const create = data => {
    let body = new FormData()

    Object.keys(data).forEach(key => {
        const item = data[key]
        if (['uf', 'city'].indexOf(key) === -1) {
            body.append(key, item)
        }
    })

    if (data.city) {
        body.append('city', data.city.name)
    }
    if (data.uf) {
        body.append('uf', data.uf.uf)
    }

    return Axios().post('institutions', body, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
}

export default { list, create, listWorksAt }
