import React, { memo, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { ContentSection } from '#/components'

import { ContentOpportunity } from '../shared'
import { Container, ContainerTabs, Tab } from './opportunity-my.styled'
import { TabActiveOpportunity, TabInactiveOpportunity } from './tabs'
function OpportunityList() {
    const history = useHistory()
    const tabActiveRef = useRef()
    const tabInactiveRef = useRef()
    const { t } = useTranslation()
    const [tabActive, setTabActive] = useState(1)

    const _goToCreate = useCallback(() => {
        history.push('/oportunidades/criar')
    }, [history])

    const _refreshActive = useCallback(() => {
        tabActiveRef.current && tabActiveRef.current.refresh()
    }, [tabActiveRef])

    const _refreshInactive = useCallback(() => {
        tabInactiveRef.current && tabInactiveRef.current.refresh()
    }, [tabInactiveRef])

    return (
        <ContentSection withoutPadding>
            <ContentOpportunity
                title={t('my-opportunities')}
                description={t('opportunity-my.description')}
                icon="FolderPlus"
                rightButtonText={t('new-opportunity')}
                rightButtonClick={_goToCreate}
                withoutPadding
            >
                <ContainerTabs>
                    <Tab active={tabActive === 1} onClick={() => setTabActive(1)}>
                        {t('actives')}
                    </Tab>
                    <Tab active={tabActive === 2} onClick={() => setTabActive(2)}>
                        {t('closed')}
                    </Tab>
                </ContainerTabs>
                <Container active={tabActive === 1}>
                    <TabActiveOpportunity ref={tabActiveRef} refreshInactive={_refreshInactive} />
                </Container>
                <Container active={tabActive === 2}>
                    <TabInactiveOpportunity ref={tabInactiveRef} refreshActive={_refreshActive} />
                </Container>
            </ContentOpportunity>
        </ContentSection>
    )
}

export default memo(OpportunityList)
