import { createActions, createReducer } from 'reduxsauce'

export const {
    Types,
    Creators: { setAuth, updateAuth, removeAuth },
} = createActions({
    setAuth: ['auth'],
    updateAuth: ['auth'],
    removeAuth: [],
})

const INITIAL_STATE = null

const set = (state = INITIAL_STATE, action) => action.auth

const update = (state = INITIAL_STATE, action) => ({
    ...state,
    ...action.auth,
})

const remove = (state = INITIAL_STATE, action) => null

const reducers = createReducer(INITIAL_STATE, {
    [Types.SET_AUTH]: set,
    [Types.UPDATE_AUTH]: update,
    [Types.REMOVE_AUTH]: remove,
})
export default reducers
