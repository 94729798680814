import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Avatar from '#/components/avatar/avatar'

import { Bold, Container, ContainerUser, HeaderRight, Logo } from './header.styled'
function Header() {
    const auth = useSelector(state => state.auth)

    const history = useHistory()
    const { t } = useTranslation()
    return (
        <Container>
            <Logo onClick={() => history.push('/')} />
            <HeaderRight>
                {auth ? (
                    <ContainerUser>
                        Olá, <Bold>{auth.name}</Bold>
                        {
                            <Avatar
                                size={40}
                                name={auth.name}
                                source={auth.photo}
                                onClick={() => history.push('/configuracoes')}
                            />
                        }
                    </ContainerUser>
                ) : (
                    <>
                        {t('have-account')}
                        <Bold pointer margin onClick={() => history.push('/login')}>
                            {t('log-in')}
                        </Bold>
                    </>
                )}
            </HeaderRight>
        </Container>
    )
}

export default memo(Header)
