import { replace } from 'connected-react-router'
import { format } from 'date-fns'

import { validateUserData } from '../../utils'
import { setAuth } from '../reducers/auth'
import { setUser } from '../reducers/user'

export const checkAccount = (userData, stateRoute) => {
    return async dispatch => {
        if (!userData) {
            return
        }
        try {
            const { status, token, id, name, call_name, photo, pending_stages, email } = userData

            dispatch(
                setAuth({
                    id,
                    name: call_name || name,
                    photo,
                    pending_stages: pending_stages || [],
                    token,
                    status,
                    last_refresh: format(new Date(), 'yyyy-MM-dd'),
                }),
            )
            dispatch(setUser(userData))

            if (status === 3 && email) {
                dispatch(replace('/ativar-conta'))
            } else {
                const pageRedirect = validateUserData(userData)
                if (pageRedirect) {
                    dispatch(replace(pageRedirect))
                } else {
                    dispatch(replace(stateRoute?.from ? stateRoute.from : '/'))
                }
            }
        } catch (err) {
        } finally {
        }
    }
}
