import styled from 'styled-components'

import LineWhite from '#/assets/images/line-white.svg'
import LogoDarkImage from '#/assets/images/logo-negative.svg'
import LogoImage from '#/assets/images/logo.svg'
import QRCodeLogin from '#/assets/images/qrcode.svg'
import IlustrationLogin from '#/assets/images/smartphone-login.png'
import { Input, Link } from '#/components'

const Content = styled.div`
    flex: 1;
    display: flex;
`

const ApresentationContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex: 1;

    background: url(${LineWhite}) 40% center, ${({ theme }) => theme.colors.primary};

    @media (max-width: 992px) {
        display: none;
    }
`

const ContainerInfoApp = styled.div`
    width: 100%;
    max-width: 280px;
    height: auto;
    border-radius: 15px;

    display: flex;
    align-items: center;
    justify-content: space-around;

    background: #ffffff;

    @media only screen and (min-width: 1600px) {
        max-width: 300px;
    }
`

const TextInfoApp = styled.div`
    color: ${({ theme }) => theme.colors.primary};

    line-height: 1.2;

    span {
        font-size: 10px;
        font-weight: 400;
    }

    p {
        display: flex;
        flex-direction: column;

        font-size: 18px;
        font-weight: 700;
    }
`

const QRCodeInfoApp = styled.img.attrs({ src: QRCodeLogin })`
    width: 80px;
    height: 80px;
    object-fit: cover;
`

const FormContainer = styled.div`
    max-width: 650px;

    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    display: flex;

    padding-left: 100px;
    padding-right: 160px;

    @media (max-width: 992px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (min-width: 1600px) {
        max-width: 800px;
    }
`

const InputLogin = styled(Input)`
    input {
        background: ${({ theme }) => theme.colors.backgroundInput};
        color: ${({ theme }) => theme.colors.colorTextInput};
    }
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 32px;
    font-weight: 700;
    text-align: left;
    width: 100%;
    margin-bottom: 15px;
`

const Forget = styled.div`
    width: 100%;
    text-align: right;
    margin-top: -10px;
    margin-bottom: 10px;
`

const LinkForget = styled(Link)`
    font-size: 13px;

    color: ${({ theme }) => theme.colors.text};
`

const CreateNewAccount = styled.div`
    margin-top: 30px;

    display: flex;
    color: ${({ theme }) => theme.colors.text};
`

const LinkCreateNewAccount = styled(Link)``

const UnderlineAccount = styled.div`
    display: flex;
    align-items: center;
    margin-top: -10px;
    margin-bottom: 5px;
    width: 100%;
    .line {
        flex: 1;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.text};
    }
    .text {
        margin-left: 20px;
        margin-right: 20px;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.text};
    }
`

const Ilustration = styled.img.attrs({
    src: IlustrationLogin,
})`
    width: 80%;
    max-width: 400px;
    object-fit: contain;

    @media only screen and (min-width: 1600px) {
        max-width: 500px;
    }
`

const Logo = styled.img.attrs(({ isDark }) => ({ src: isDark ? LogoDarkImage : LogoImage }))`
    width: 100%;
    max-width: 100px;
    object-fit: contain;

    position: absolute;
    right: 90px;
    top: 30px;

    @media (max-width: 992px) {
        right: 20px;
    }
`

const Footer = styled.div`
    background-color: ${({ theme }) => theme.colors.backgroundAccent};
    padding: 16px;
    width: 100%;
    color: ${({ theme }) => theme.colors.text};
    align-items: center;
    justify-content: center;
    display: flex;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    margin-top: auto;
`

const ContainerSocialButton = styled.div`
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    /* .button-google-social {
        width: 100%;
        margin-top: 20px;
    } */
`

export {
    ApresentationContainer,
    Content,
    Footer,
    Forget,
    FormContainer,
    InputLogin,
    Ilustration,
    LinkForget,
    Logo,
    UnderlineAccount,
    Title,
    ContainerSocialButton,
    ContainerInfoApp,
    TextInfoApp,
    QRCodeInfoApp,
    CreateNewAccount,
    LinkCreateNewAccount,
}
