import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import * as Icon from 'react-feather'

const Feather = memo(({ name, color, size, className, onClick, pointer }) => {
    const FeatherIcon = useMemo(() => Icon[name], [name])
    if (!name) {
        return <div>??</div>
    }
    return (
        <FeatherIcon
            style={{ cursor: pointer || onClick ? 'pointer' : 'auto' }}
            onClick={onClick}
            color={color}
            size={size}
            className={classNames('icon', name, className)}
        />
    )
})

export { Feather }
