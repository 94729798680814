import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 0px;
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    flex: 1;
`

export { Container, Title }
