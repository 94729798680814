import styled from 'styled-components'

const Content = styled.div`
    padding: 20px 0 0 20px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.textColor};

    font-weight: 700;

    cursor: pointer;
`

const Img = styled.img`
    width: 24px;
    height: 24px;
`

export { Content, Img }
