import React, { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import { ContentSection } from '#/components'
import { useLoadGroup } from '#/hooks'

import { EditInfoGroup, EditWorkingTime, OptionsSettings } from './containers'
function GroupSettings() {
    useLoadGroup()

    const group = useSelector(state => state.group)

    useEffect(() => {
        if (group) {
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group])

    return (
        <>
            <ContentSection withoutPadding>
                <Switch>
                    <Route exact path="/grupo/:group_id/configuracoes" component={OptionsSettings} />
                    <Route path="/grupo/:group_id/configuracoes/informacoes" component={EditInfoGroup} />
                    <Route path="/grupo/:group_id/configuracoes/turnos" component={EditWorkingTime} />
                </Switch>
            </ContentSection>
        </>
    )
}

export default memo(GroupSettings)
