import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    height: 100%;
`

const Column = styled.div`
    margin: 0px 10px;
    width: 200px;
    display: flex;
    flex-direction: column;
    background-color: red;
    background-color: ${({ theme }) => theme.colors.backgroundAccent};
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 2px 2px 1px rgba(9, 9, 9, 0.1);
`

const HeaderColumn = styled.div`
    background-color: ${({ theme, weekend }) => (weekend ? theme.colors.greyMedium : theme.colors.grey)};
    padding: 5px;
    width: 100%;
    box-shadow: 1px 2px 4px 1px rgba(9, 9, 9, 0.15);
`

const HeaderTitle = styled.div`
    text-align: center;
    text-transform: capitalize;
    font-size: 16px;
`

const Bold = styled.div`
    font-weight: bold;
`

const ContainerCard = styled.div`
    padding: 10px;
    height: 100%;
    overflow: auto;
    display: block;
    padding-bottom: 10px;
`

const Card = styled.div`
    padding: 10px;
    background-color: ${({ theme }) => theme.colors.background};
    margin: 10px 0px;
    width: 100%;
    height: fit-content;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 2px 4px 1px rgba(9, 9, 9, 0.15);

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
`

const HeaderCard = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    align-content: center;
    align-items: center;
`

const Label = styled.div`
    font-weight: bold;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.text};
`

const WorkingTime = styled.div`
    font-size: 14px;
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.text};
`

const ContainerMembers = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    user-select: none;
    justify-content: space-between;
    .avatar {
        margin: 2px;
    }
`

export {
    Column,
    Container,
    HeaderColumn,
    HeaderTitle,
    Bold,
    Card,
    ContainerCard,
    HeaderCard,
    Label,
    WorkingTime,
    ContainerMembers,
}
