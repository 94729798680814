import { replace } from 'connected-react-router'

import { removeAuth } from '../reducers/auth'
import { removeUser } from '../reducers/user'

export const logout = () => {
    return dispatch => {
        dispatch(replace('/login', { logout: true }))
        dispatch(removeAuth())
        dispatch(removeUser())
    }
}
