import styled, { css } from 'styled-components'

const Container = styled.div`
    /* display: inline-block; */
    width: 100%;
    height: 100%;
    /* margin: 0 !important;
    padding: 0 !important; */
    /* display: flex;
    flex-direction: column; */
    /* .separator {
        margin: 10px 0px;
    }

    .button-text {
        align-self: flex-end;
        margin: 0px 16px;
    } */

    @media print {
        page-break-inside: auto !important;
        min-height: min-content !important;
        min-width: 100% !important;
    }
`

const ContainerShiftsUser = styled.tr`
    display: inline-block;
    width: 100%;
    padding: 0 !important;
    /* display: flex;
    flex-direction: column; */
`

const ContainerShift = styled.tr`
    width: 50%;
    display: inline-block;
`

const ShiftContent = styled.td`
    /* width: 100%; */
    display: block;
    text-align: center;
    border: 2px #f2f2f3 solid;
    ${({ right }) =>
        right &&
        css`
            border-left: none;
            border-right: 2px #f2f2f3 solid;
        `}
`

const ShiftInfo = styled.span`
    display: block;
    font-size: 12px;
    color: #646464;
    height: 30px;

    ${({ info }) =>
        info &&
        css`
            width: 50%;
            margin: 0 auto;
            text-align: center;
        `}

    ${({ infoBar }) =>
        infoBar &&
        css`
            line-height: 28px;
            font-weight: bold;
            font-size: 12px;
        `}
`
const ShiftInfoContent = styled.div`
    border-left: 2px #f2f2f3 solid;
    border-right: 2px #f2f2f3 solid;
    ${({ right }) =>
        right &&
        css`
            border-left: none;
            border-right: 2px #f2f2f3 solid;
        `}
`

const MemberContainer = styled.th`
    display: block;
    width: 100%;
    padding: 5px;
    background-color: #f2f2f3;
`

const MemberName = styled.td`
    margin-right: 100%;
    display: inline-block;
    font-size: 12px;
    line-height: 14px !important;
    text-align: start;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const MemberInfo = styled.td`
    display: inline-block;
    margin-right: 100%;
    text-align: start;
    font-size: 10px;
    line-height: 12px !important;
    width: 100%;
    color: #888888;
    text-transform: uppercase;
`

export {
    Container,
    ContainerShift,
    ContainerShiftsUser,
    MemberContainer,
    MemberInfo,
    MemberName,
    ShiftInfoContent,
    ShiftInfo,
    ShiftContent,
}
