import React, { memo } from 'react'

import { FontPA } from '../font-pa/font-pa'
import { Amount, Container } from './vacancy.styled'
function Vacancy({ color, amount, size }) {
    return (
        <Container>
            <FontPA name="vacancy" color={color} size={size} />
            {amount && <Amount color={color}>{amount}</Amount>}
        </Container>
    )
}

export default memo(Vacancy)
