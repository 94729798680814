import { Element } from 'react-scroll'
import styled, { css } from 'styled-components'

import { Feather } from '#/components/icons'
const widthScreen = 420
const widthBox = widthScreen / 9

const Container = styled.div`
    width: 100%;
    margin: 8px 0;
    flex-direction: row;
    align-items: center;
    display: flex;
    user-select: none;
`

const Scroll = styled.div`
    flex: 1;
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    ::-webkit-scrollbar {
        display: none;
    }
`

const BoxDay = styled(Element)`
    align-items: center;
    display: flex;
    justify-content: center;
`

const Border = styled.div`
    width: ${widthBox - 4}px;
    height: ${widthBox - 4}px;
    border-radius: ${(widthBox - 4) / 2}px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    display: flex;
    ${({ isToday }) =>
        isToday &&
        css`
            border-width: 1px;
            border-color: ${({ theme }) => theme.colors.calendarToday};
        `}
`

const Circle = styled.div`
    width: ${widthBox - 8}px;
    height: ${widthBox - 8}px;
    border-radius: ${(widthBox - 8) / 2}px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    display: flex;
    ${({ selected, theme }) => {
        if (selected) {
            return css`
                background-color: ${theme.colors.primary};
                border-width: 1px;
                border-color: ${theme.colors.primary};
            `
        }

        return css`
            &:hover {
                background-color: ${({ theme }) => theme.colors.hover};
            }
        `
    }}
`
const Day = styled.div`
    font-size: ${(widthBox - 6) / 2.1}px;
    margin: 0px 5px;

    ${({ selected, theme, bold }) =>
        css`
            color: ${() => {
                if (selected) {
                    return '#fff'
                }

                if (bold) {
                    return theme.colors.text
                }

                return theme.colors.textLight
            }};
        `}
    ${({ bold }) =>
        bold &&
        css`
            font-weight: bold;
        `}
`

const Arrow = styled(Feather)`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 25px;
    height: 50%;
    width: ${widthBox}px;
    text-align: center;
    cursor: pointer;
    &:hover {
        background-color: ${({ theme }) => theme.colors.hover};
    }
`

const DayWeek = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 10px;
    padding-bottom: 2px;
    ${({ bold }) =>
        bold &&
        css`
            color: ${({ theme }) => theme.colors.text};
            font-weight: bold;
        `}
`

const ContainerDay = styled.div`
    align-items: center;
    min-width: ${widthBox}px;
    display: flex;
    overflow: hidden;
    cursor: pointer;
    flex-direction: column;
`

export { Container, Scroll, BoxDay, Circle, Day, Arrow, DayWeek, ContainerDay, Border }
