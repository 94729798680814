import React, { memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonText, Container, ContainerButton, Message, Title } from './welcome.styled'

function Welcome({ manager, noManager }) {
    const { t } = useTranslation()
    const [typeNotice, setTypeNotice] = useState(null)

    const notice = useMemo(() => {
        if (typeNotice === 'manager') {
            return manager
        }
        if (typeNotice === 'no-manager') {
            return noManager
        }

        return null
    }, [manager, noManager, typeNotice])

    const _toggleMessage = useCallback(
        type => {
            if (type === typeNotice) {
                setTypeNotice(null)
            }

            setTypeNotice(type)
        },
        [typeNotice],
    )

    return (
        <Container>
            {!!notice && (
                <>
                    <Title>{notice?.title}</Title>
                    <Message>{notice?.content}</Message>
                </>
            )}
            {typeNotice == null && (
                <ContainerButton>
                    <ButtonText onClick={() => _toggleMessage('manager')}>{t('i-am-manager')}</ButtonText>
                    <ButtonText onClick={() => _toggleMessage('no-manager')}>{t('i-am-member')}</ButtonText>
                </ContainerButton>
            )}
        </Container>
    )
}

export default memo(Welcome)
