import styled from 'styled-components'

import { Feather } from '../icons'

const Container = styled.div`
    margin: 5px 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
`

const Box = styled.div`
    background-color: ${({ theme: { colors }, active }) => (active ? colors.primary : colors.input)};
    height: 20px;
    width: 20px;
    border-radius: ${({ square }) => (square ? 0 : 10)}px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Icon = styled(Feather).attrs({
    name: 'Check',
    size: 14,
    color: '#fff',
})``

const Label = styled.div`
    color: ${({ theme }) => theme.colors.text};
    padding-left: 8px;
    flex: 1;
    text-align: start;
`

export { Container, Box, Label, Icon }
