import styled from 'styled-components'

import { Feather } from '#/components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px;
    max-width: 1600px;
    width: 100%;
    align-self: center;
    .card {
        flex: 1;
        .card-content {
            height: 1px;
            overflow: hidden;
        }
    }
`

const ContainerTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    .button-add {
        margin-right: 10px;
    }
    margin-bottom: 10px;
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 18px;
    flex: 1;
`
const BackIcon = styled(Feather).attrs({ name: 'ArrowLeft' })`
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    margin-right: 4px;
`

const Period = styled.div`
    color: #fff;
    font-weight: bold;
    text-transform: capitalize;
`

const Manager = styled.div`
    color: #fff;
    font-size: 12px;
`

const ContainerCards = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px 0px;
    overflow: hidden;
    display: flex;

    .warning {
        align-self: flex-start;
    }
`

const ContainerCalendar = styled.div`
    flex: 1;
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: center;
`
const ContainerForms = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    align-items: center;
`
const ContainerValues = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
`

const ContentForms = styled.div`
    width: 100%;
    height: 100%;
`

export {
    BackIcon,
    Container,
    ContainerCards,
    ContainerTitle,
    Manager,
    Period,
    Title,
    ContainerCalendar,
    ContainerForms,
    ContainerValues,
    ContentForms,
}
