import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.background + '99'};
    align-items: center;
    z-index: 10;
`

export { Container }
