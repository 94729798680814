import styled from 'styled-components'

const Container = styled.div`
    border-bottom: 1px ${({ theme }) => theme.colors.backgroundContrast} solid;
    padding: 10px;
    user-select: none;
    :last-child {
        border: none;
    }
`

const WorkingTime = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    margin: 10px 0px;
`

const ContainerCoworkers = styled.div`
    width: 100%;
    display: flex;
    overflow-y: none;
    align-items: center;
    overflow-x: auto;
`

const ContainerAvatar = styled.div`
    z-index: 3;
    padding: 5px;
    cursor: pointer;
    position: relative;
`

const Badge = styled.div`
    z-index: 2;
    background-color: ${({ color }) => color};
    height: 20px;
    width: 20px;
    border-radius: 10px;
    position: absolute;
    top: 2%;
    right: 2%;

    display: flex;
    justify-content: center;
    align-items: center;
`

export { Container, WorkingTime, ContainerCoworkers, ContainerAvatar, Badge }
