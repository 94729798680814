import styled, { css } from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    .button-text {
        align-self: flex-end;
    }
    padding: 20px 0px;

    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    &:last-child {
        border: none;
    }
    &:first-child {
        padding-top: 0;
    }
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 18px;
    width: 100%;
    margin: 0px 16px 10px 16px;
`

const ContainerInfo = styled.div`
    flex: 1;
    background-color: ${({ theme }) => theme.colors.backgroundAccent};
    padding: 10px;
    margin: 0px 16px;
    margin-bottom: 10px;
`

const ContainerButton = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0px 16px;
    .button-text {
        margin-left: 16px;
    }
`
const Bold = styled.span`
    font-weight: bold;
`

const Info = styled.div`
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: 10px;
`

const RowValues = styled.div`
    width: 100%;
    justify-content: space-between;
    padding: 5px 0px;
    display: flex;
`

const WorkingTime = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
`

const ContainerValues = styled.div``

const Value = styled.div`
    text-align: end;

    ${({ old, theme }) =>
        old
            ? css`
                  color: ${theme.colors.danger};
                  font-size: 12px;
                  text-decoration: line-through;
              `
            : css`
                  color: ${theme.colors.text};
                  font-size: 16px;
              `}
`

export {
    Container,
    ContainerButton,
    Title,
    ContainerInfo,
    Bold,
    Info,
    ContainerValues,
    WorkingTime,
    Value,
    RowValues,
}
