import { useFormik } from 'formik'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { AccountsAPI } from '#/api'
import Ilustration from '#/assets/images/ilustration-personal.svg'
import { Avatar, Input } from '#/components'
import { AlertError, CropImage } from '#/modals'
import { updateAuth } from '#/redux/reducers/auth'
import { setUser } from '#/redux/reducers/user'
import { checkAccount } from '#/redux/thunk'

import { PageRegister } from '../shared'
import { ContainerPicture, LabelPicture } from './register-personal.styled'
function RegisterPersonal() {
    const dispatch = useDispatch()

    const { t } = useTranslation()
    const user = useSelector(state => state.user)

    const alertErrorRef = useRef()
    const inputFileRef = useRef()
    const cropModalRef = useRef()

    const [profileURL, setProfileURL] = useState(null)

    const {
        isSubmitting,
        values,
        errors,
        touched,
        handleBlur,
        isValid,
        dirty,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: {
            name: null,
            call_name: null,
            phone: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().nullable().required(t('input.name.required')).min(3, t('input.name.min')),
            call_name: Yup.string().nullable().min(3, t('input.call_name.min')),
            phone: Yup.string().min(14, t('input.phone.invalid')).trim().required(t('input.phone.required')),
        }),
        onSubmit: async values => {
            try {
                const result = await AccountsAPI.update(values)

                dispatch(checkAccount(result))
            } catch ({ message }) {
                _showAlertErro(message)
            }
        },
    })

    const _setUserData = useCallback(() => {
        if (user) {
            const { name, call_name, phone } = user

            setFieldValue('name', name)
            setFieldValue('call_name', call_name)
            setFieldValue('phone', phone)
        }
    }, [user, setFieldValue])

    const _openFile = useCallback(() => {
        if (inputFileRef.current) {
            inputFileRef.current.click()
        }
    }, [inputFileRef])

    const _showAlertErro = useCallback(
        message => {
            try {
                alertErrorRef.current && alertErrorRef.current.show({ message })
            } catch (error) {}
        },
        [alertErrorRef],
    )

    const _uploadImage = useCallback(
        async fileImage => {
            if (fileImage) {
                try {
                    const result = await AccountsAPI.photo(fileImage)
                    const { photo } = result
                    dispatch(updateAuth({ photo }))
                    dispatch(setUser(result))
                } catch ({ message }) {
                    _showAlertErro(message)
                }
            }
        },
        [dispatch, _showAlertErro],
    )

    const _onCropImage = useCallback(
        file => {
            _uploadImage(file)
            let URL = (window.URL || window.webkitURL).createObjectURL(file)

            setProfileURL(URL)
        },
        [_uploadImage],
    )

    const _onSelectFile = useCallback(e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                if (cropModalRef.current) {
                    cropModalRef.current.show({ source: reader.result })
                }
            })
            reader.readAsDataURL(e.target.files[0])
        }
    }, [])

    useEffect(() => {
        _setUserData()
    }, [_setUserData])

    return (
        <>
            <PageRegister
                loading={isSubmitting}
                message={t('info-personal.message')}
                title={t('info-personal.title')}
                disabled={!isValid || !dirty || isSubmitting}
                ilustration={Ilustration}
                onNextClick={handleSubmit}
                stepsComplete={3}
            >
                <ContainerPicture>
                    <Avatar
                        size={100}
                        onClick={_openFile}
                        source={profileURL || user?.photo}
                        showIconPicker
                    />
                    <LabelPicture>{t('add-picture')}</LabelPicture>
                </ContainerPicture>

                <Input
                    required
                    name="name"
                    label={t('input.name.label')}
                    value={values.name}
                    onChangeText={e => setFieldValue('name', e)}
                    onBlur={handleBlur('name')}
                    errorMessage={touched.name && errors.name}
                />
                <Input
                    name="call_name"
                    label={t('input.call_name.label')}
                    value={values.call_name}
                    onChangeText={e => setFieldValue('call_name', e)}
                    onBlur={handleBlur('call_name')}
                    errorMessage={touched.call_name && errors.call_name}
                />
                <Input
                    required
                    name="phone"
                    label={t('phone')}
                    mask="(99)99999-9999"
                    value={values.phone}
                    onChangeText={e => setFieldValue('phone', e)}
                    onBlur={handleBlur('phone')}
                    errorMessage={touched.phone && errors.phone}
                />
            </PageRegister>
            <AlertError ref={alertErrorRef} />
            <CropImage ref={cropModalRef} onCrop={_onCropImage} />
            <input
                ref={inputFileRef}
                type="file"
                accept="image/*"
                onChange={_onSelectFile}
                style={{ display: 'none' }}
            />
        </>
    )
}

export default RegisterPersonal
