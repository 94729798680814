import React, { memo } from 'react'
import { Route, Switch } from 'react-router-dom'

import { Card } from '#/components'

import { SectionSettings } from '../sections'
import { WidgetProfileInfo } from '../widgets'
import { Container, ContainerSection, ContainerWidget } from './my-profile.styled'

function MyProfile() {
    return (
        <Container>
            <ContainerWidget>
                <Card withoutPadding>
                    <WidgetProfileInfo />
                </Card>
            </ContainerWidget>

            <ContainerSection>
                <Switch>
                    <Route path="/" component={SectionSettings} />
                </Switch>
            </ContainerSection>
        </Container>
    )
}

export default memo(MyProfile)
