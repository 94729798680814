import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
`

const Text = styled.div`
    color: ${({ theme }) => theme.colors.text};
    flex: 1;
    margin-left: 8px;
    user-select: none;
`

export { Container, Text }
