import styled from 'styled-components'

import { Feather } from '../icons'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    .button {
        margin-top: 16px;
    }
    margin: 10px 0px;
`

const ContainerIcon = styled.div`
    border: 2px solid ${({ theme }) => theme.colors.text};
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
    display: flex;
    justify-content: center;
`

const Icon = styled(Feather).attrs({ size: 30 })`
    color: ${({ theme }) => theme.colors.text};
    height: 100%;
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    margin: 5px 0px;
    font-size: 18px;
    text-align: center;
`

const Message = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 14px;
    text-align: center;
`

export { Container, Title, Message, ContainerIcon, Icon }
