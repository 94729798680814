import classNames from 'classnames'
import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef } from 'react'

import { ErrorMessage, Label } from '../shared'
import { Chevron, Container, ContainerSelect, CustomSelect, Placeholder } from './select.styled'

function Input(
    {
        label,
        options = [],
        errorMessage,
        required,
        placeholder,
        onChange,
        onBlur,
        onFocus,
        className,
        selectedKey,
        ...props
    },
    ref,
) {
    const inputRef = useRef()
    useImperativeHandle(ref, () => ({
        focus: () => {
            try {
                inputRef.current && inputRef.current.focus()
            } catch (error) {}
        },
    }))

    const _handleChange = useCallback(
        event => {
            if (onChange) {
                // eslint-disable-next-line eqeqeq
                onChange((options.find(item => item.key == event.target.value) || {}).value)
            }
        },
        [onChange, options],
    )

    return (
        <Container className={classNames('select', className)}>
            {!!label && (
                <Label htmlFor="" required={required}>
                    {label}:
                </Label>
            )}
            <ContainerSelect>
                <CustomSelect
                    isNull={!selectedKey}
                    ref={inputRef}
                    placeholder={placeholder}
                    onChange={_handleChange}
                    onBlur={onBlur}
                    value={selectedKey}
                    onFocus={onFocus}
                    {...props}
                >
                    {!!placeholder && <Placeholder selected={!selectedKey}>{placeholder}</Placeholder>}
                    {options.map((item, i) => (
                        <option key={`item_${i}`} value={item.key}>
                            {item.label}
                        </option>
                    ))}
                </CustomSelect>
                <Chevron />
            </ContainerSelect>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Container>
    )
}

export default memo(forwardRef(Input))
