import { dateBrToUe } from '../utils'
import { Axios } from './axios-config'

const listAccounts = ({ page, limit, search }) => {
    return Axios().get('accounts', {
        params: {
            page: page || 1,
            limit: limit || 20,
            search,
        },
    })
}

const getAccount = () => {
    return Axios().get('accounts/profile')
}

const viewAccount = id => {
    return Axios().get(`accounts/${id}`)
}

const update = data => {
    var body = new FormData()

    Object.keys(data).forEach(key => {
        if (
            [
                'about',
                'name',
                'call_name',
                'phone',
                'birth_date',
                'gender',
                'email',
                'profession',
                'council_state',
                'specialization',
                'council_id',
                'old_password',
                'password',
                'password_confirmation',
            ].indexOf(key) === -1
        ) {
            delete data[key]
        }
    })

    Object.keys(data).forEach(key => {
        const item = data[key]
        if (
            [
                'birth_date',
                'profession',
                'specialization',
                'council_state',
                'gender',
                'old_password',
                'password',
                'password_confirmation',
            ].indexOf(key) === -1
        ) {
            body.append(key, item)
        }
    })

    if (data.birth_date) {
        data.birth_date = dateBrToUe(data.birth_date)
    }

    if (data.profession) {
        data.profession_id = data.profession.id
    }

    if (data.specialization) {
        data.specialization_id = data.specialization.id
    }

    if (data.council_state) {
        data.council_state = data.council_state.uf
    }

    return Axios().patch('accounts/profile', data)
}

const photo = data => {
    var body = new FormData()
    body.append('photo', data)
    return Axios().post('accounts/profile/photo', body, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
}

const activateEmail = data => {
    return Axios().post('accounts/confirmation', data)
}
const requestEmail = data => {
    return Axios().post('/accounts/confirmation/request', data)
}

const savePlayerId = (playerID, token) => {
    const axios = Axios()

    if (token) {
        axios.interceptors.request.use(config => {
            config.headers.post.Authorization = 'Bearer ' + token
            return config
        })
    }

    const body = { token: playerID }

    return axios.post('accounts/devices', body)
}

const professionalList = (filter = false) => {
    let params = {}

    if (filter) {
        params.with_others = 0
    }

    return Axios().get('accounts/professions', { params })
}

const specializationList = (profession_id, filter = false) => {
    let params = {}

    if (filter) {
        params.with_others = 0
    }

    return Axios().get(`accounts/professions/${profession_id}/specializations`, { params })
}

const getProfilePrivacy = async () => {
    return Axios().get('accounts/profile/privacy')
}

const updateProfilePrivacy = async body => {
    return Axios().patch('accounts/profile/privacy', body)
}

export default {
    listAccounts,
    viewAccount,
    update,
    photo,
    getAccount,
    activateEmail,
    requestEmail,
    professionalList,
    savePlayerId,
    specializationList,
    getProfilePrivacy,
    updateProfilePrivacy,
}
