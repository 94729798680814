import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import BoardValues from '../../board-values/board-values'
import ButtonText from '../../button-text/button-text'
import { Container, ContainerButton } from './item-amount-block.styled'
function ItemAmountBlock({ onDelete, onUpdate, workingtimes = [], disabledBlocks = [], ...props }) {
    const { t } = useTranslation()
    return (
        <Container>
            <BoardValues workingtimes={workingtimes} disabledBlocks={disabledBlocks} {...props} readOnly />
            {(onDelete || onUpdate) && (
                <ContainerButton>
                    {!!onUpdate && (
                        <ButtonText onClick={onUpdate} iconName="Edit2">
                            {t('edit')}
                        </ButtonText>
                    )}
                    {!!onDelete && (
                        <ButtonText onClick={onDelete} iconName="Trash2">
                            {t('delete')}
                        </ButtonText>
                    )}
                </ContainerButton>
            )}
        </Container>
    )
}

export default memo(ItemAmountBlock)
