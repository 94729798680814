import React from 'react'
const GridScheduleContext = React.createContext({
    group_id: null,
    schedule_status: null,
    color: null,
    onAddShift: (user_id, working_time_id, shift, minutes) => {},
    onRemoveShift: (user_id, working_time_id, shift_id, minutes) => {},
    onChangeVacancy: (working_time_id, vacancy, minutes, slots) => {},
    ignoreOld: false,
})
const ProfileContext = React.createContext(null)
const OpportunityContext = React.createContext(null)

export { GridScheduleContext, ProfileContext, OpportunityContext }
