import styled from 'styled-components'

import { Feather } from '#/components/icons'
const Container = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000080;
    justify-content: flex-end;
    z-index: 10;
`

const Box = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: ${({ theme }) => theme.sizes.radius}px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow-x: hidden;
    overflow-y: auto;
    width: 420px;
    display: flex;
    flex-direction: column;
    @media (max-width: 992px) {
        max-width: 100%;
    }
`

const ContainerTitle = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 16px;
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 18px;
`

const Close = styled(Feather).attrs({ size: 18, name: 'X' })`
    color: ${({ theme }) => theme.colors.textOpacity};
    position: absolute;
    right: 8px;
    cursor: pointer;
`
const Segment = styled.div`
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
`

const SegmentTitle = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 18px;
`

const ContainerInfo = styled.div`
    display: flex;
    align-items: center;
    margin: 8px 0px;
`

const IconInfo = styled(Feather).attrs({ size: 20 })`
    color: ${({ theme }) => theme.colors.primary};
`

const Info = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    margin-left: 10px;
`
const Padding = styled.div`
    padding: 16px;
`
export {
    Box,
    Container,
    ContainerTitle,
    Title,
    Close,
    Segment,
    SegmentTitle,
    ContainerInfo,
    Info,
    IconInfo,
    Padding,
}
