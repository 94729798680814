import React, { memo, useCallback, useRef, useState } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AuthAPI } from '#/api'
import SourceFacebook from '#/assets/images/icon-social-facebook.png'
import SourceGoogle from '#/assets/images/icon-social-google.png'
import { Button, ContentSection } from '#/components'
import { Alert, AlertError } from '#/modals'
import { updateUser } from '#/redux/reducers/user'

import { SettingsContent } from '../shared'
import { ContainerForm, Expand, Icon, Label, Row } from './accounts.styled'
function Accounts() {
    const dispatch = useDispatch()

    const socialAccounts = useSelector(state => state.user?.social_accounts || [])
    const { t } = useTranslation()
    const alertErrorRef = useRef()
    const alertRef = useRef()

    const [loading, setLoading] = useState(false)

    const _showAlertErro = useCallback(
        message => {
            try {
                alertErrorRef.current && alertErrorRef.current.show({ message })
            } catch (error) {}
        },
        [alertErrorRef],
    )

    const _onGoogleAuth = useCallback(
        async ({ profileObj, tokenObj }) => {
            if (!tokenObj?.access_token) {
                return
            }
            try {
                setLoading(true)

                const user = await AuthAPI.loginSocial('google', tokenObj.access_token)
                dispatch(updateUser(user))
            } catch (e) {
                console.log('google Error', e)
            } finally {
                setLoading(false)
            }
        },
        [dispatch],
    )

    const _onFaceboookAuth = useCallback(
        async result => {
            if (!result?.accessToken) {
                return
            }
            try {
                setLoading(true)

                const user = await AuthAPI.loginSocial('facebook', result.accessToken)
                dispatch(updateUser(user))
            } catch (e) {
                console.log('facebook Error', e)
            } finally {
                setLoading(false)
            }
        },
        [dispatch],
    )

    const _unLinkAccount = useCallback(
        async provider => {
            setLoading(true)
            try {
                if (socialAccounts.includes(provider)) {
                    await AuthAPI.unLinkSocial(provider)
                    dispatch(
                        updateUser({ social_accounts: socialAccounts.filter(item => item !== provider) }),
                    )
                }
            } catch ({ message }) {
                _showAlertErro(message)
            }
            setLoading(false)
        },
        [socialAccounts, _showAlertErro, dispatch],
    )

    return (
        <SettingsContent title={t('accounts')} iconName="AtSign" withoutPadding loading={loading}>
            <ContentSection withoutPadding>
                <ContainerForm>
                    <Row>
                        <Icon src={SourceGoogle} />
                        <Label>Google</Label>
                        <Expand />

                        <GoogleLogin
                            className="button-google-social"
                            clientId={
                                '301973440649-89736v2ujbb6gojln4nm71v74c4kffcr.apps.googleusercontent.com'
                            }
                            onSuccess={_onGoogleAuth}
                            render={({ onClick }) => (
                                <Button
                                    title={socialAccounts.includes('google') ? 'Desvincular' : 'Vincular'}
                                    variant={socialAccounts.includes('google') ? 'danger' : 'primary'}
                                    onClick={
                                        socialAccounts.includes('google')
                                            ? () => _unLinkAccount('google')
                                            : onClick
                                    }
                                />
                            )}
                        />
                    </Row>
                    <Row>
                        <Icon src={SourceFacebook} />
                        <Label>Facebook</Label>
                        <Expand />
                        <FacebookLogin
                            appId="781134805391452"
                            fields="name,email,picture"
                            callback={_onFaceboookAuth}
                            render={({ onClick }) => (
                                <Button
                                    title={socialAccounts.includes('facebook') ? 'Desvincular' : 'Vincular'}
                                    variant={socialAccounts.includes('facebook') ? 'danger' : 'primary'}
                                    onClick={
                                        socialAccounts.includes('facebook')
                                            ? () => _unLinkAccount('facebook')
                                            : onClick
                                    }
                                />
                            )}
                        />
                    </Row>
                </ContainerForm>
            </ContentSection>
            <Alert ref={alertRef} />
            <AlertError ref={alertErrorRef} />
        </SettingsContent>
    )
}

export default memo(Accounts)
