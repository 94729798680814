import styled from 'styled-components'

const Container = styled.div`
    border: 2px solid #fff;
    border-radius: 4px;
    padding: 0px 4px;
    font-size: 12px;
    width: fit-content;
    font-weight: bold;
    color: #fff;
`

export { Container }
