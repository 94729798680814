import React, { memo, useCallback, useEffect, useState } from 'react'

import { ErrorMessage, Label } from '../shared'
import { Border, Container, ContainerColors, ContainerItem, Item } from './choose-color.styled'
const colors = [
    '#0091ac',
    '#1abc9c',
    '#16a085',
    '#f1c40f',
    '#f39c12',
    '#2ecc71',
    '#27ae60',
    '#e67e22',
    '#d35400',
    '#3498db',
    '#2980b9',
    '#e74c3c',
    '#c0392b',
    '#9b59b6',
    '#8e44ad',
    '#34495e',
    '#2c3e50',
]

function ChooseColor({ label, errorMessage, onChange, required, value }) {
    const [indexActive, setIndexActive] = useState(null)

    const _onClickItem = useCallback(
        index => {
            if (index === indexActive) {
                return
            }
            setIndexActive(index)
            if (onChange) {
                onChange(colors[index])
            }
        },
        [indexActive, onChange],
    )

    useEffect(() => {
        if (value) {
            const index = colors.findIndex(item => item.includes(value))
            if (index !== -1) {
                _onClickItem(index)
                return
            }
        }
        const random = Math.floor(Math.random() * colors.length)
        _onClickItem(random)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
        <Container>
            {!!label && (
                <Label required={required} htmlFor="">
                    {label}:
                </Label>
            )}
            <ContainerColors>
                <ContainerItem>
                    {colors.map((item, i) => (
                        <Border active={indexActive === i} key={`color_${i}`} onClick={() => _onClickItem(i)}>
                            <Item color={item} />
                        </Border>
                    ))}
                </ContainerItem>
            </ContainerColors>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Container>
    )
}

export default memo(ChooseColor)
