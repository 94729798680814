import React, { memo, useCallback, useMemo } from 'react'
import ReactPlayer from 'react-player'

import { UserAPI } from '#/api'

import { Card, Like } from '../../components'
import { Badge, Container, Icon, Image, Message, SeePlus, SeePlusText, Title } from './sponsored.styled'

const Sponsored = ({ pick }) => {
    const _onClick = useCallback(async () => {
        try {
            if (pick) {
                if (pick?.type === 'ads') {
                    return window.open(pick.link, '_blank')
                }

                if (pick.link && pick.type !== 'video') {
                    return () => window.open(pick.link, '_blank')
                }

                await UserAPI.getClickNews(pick?.id)
            }
            return null
        } catch (err) {}
    }, [pick])

    if (!pick) {
        return null
    }

    const { type, content, attachment } = pick

    return (
        <Container>
            <Card noPadding withoutPadding>
                {/* {type === 'ads' && <Title>Patrocionado</Title>}
                {type === 'blog' && <Title>Noticias e Artigos</Title>} */}

                {type === 'ads' && <Badge>Patrocinado</Badge>}
                {type === 'video' && attachment && (
                    <ReactPlayer width="100%" url={`https://youtu.be/${attachment}`} />
                )}
                {(type === 'image' || type === 'ads' || type === 'blog') && attachment && (
                    <Image onClick={_onClick} src={attachment} />
                )}
                {/* {type === 'ads' && (
                    <SeePlus onClick={_onClick}>
                        <SeePlusText>Saiba mais</SeePlusText>
                        <Icon name="ChevronRight" />
                    </SeePlus>
                )} */}
                {/* {(type === 'ads' || type === 'blog') && (
                    <Like likes={pick?.likes} is_liked={pick?.is_liked} id={pick?.id} />
                )} */}
                {!!content && <Message>{content}</Message>}
            </Card>
        </Container>
    )
}

export default memo(Sponsored)
