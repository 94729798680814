import React, { memo } from 'react'

import Card from '#/components/card/card'

import BlockContainer from '../block-container/block-container'
import { Container } from './section-control.styled'

function SectionControl({ children, contentControl }) {
    return (
        <Container>
            <BlockContainer marginRight>
                <Card withoutPadding className="card-container">
                    {contentControl}
                </Card>
            </BlockContainer>
            {children}
        </Container>
    )
}

export default memo(SectionControl)
