import useKey from '@rooks/use-key'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import * as Yup from 'yup'

import { AppAPI, AuthAPI } from '#/api'
import { Button, PageContainer, SocialButton } from '#/components'
import { AlertError, ConfirmRegister, RecoveryPassword } from '#/modals'
import { checkAccount } from '#/redux/thunk'

import {
    ApresentationContainer,
    ContainerInfoApp,
    ContainerSocialButton,
    Content,
    CreateNewAccount,
    Forget,
    FormContainer,
    Ilustration,
    InputLogin,
    LinkCreateNewAccount,
    LinkForget,
    Logo,
    QRCodeInfoApp,
    TextInfoApp,
    Title,
    UnderlineAccount,
} from './login.styled'

function Login({ location: { state } }) {
    const alertErrorRef = useRef()
    const recoveryRef = useRef()
    const confirmRegisterRef = useRef()
    const dispatch = useDispatch()
    const theme = useSelector(state => state.theme)
    const history = useHistory()
    const location = useLocation()
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)

    const { isSubmitting, values, errors, touched, handleSubmit, setFieldValue, handleBlur } = useFormik({
        initialValues: { login: '', password: '' },
        validationSchema: Yup.object().shape({
            login: Yup.string().trim().required(t('input.login.required')),
            password: Yup.string()
                .trim()
                .required(t('input.password.required'))
                .min(8, t('input.password.min')),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const result = await AuthAPI.login(values)
                dispatch(checkAccount(result, state))
            } catch ({ message }) {
                _showAlertError(message)
            } finally {
                setSubmitting(false)
            }
        },
    })
    useKey(['Enter'], handleSubmit)

    const _showAlertError = useCallback(
        message => {
            try {
                alertErrorRef.current && alertErrorRef.current.show({ message })
            } catch (error) {}
        },
        [alertErrorRef],
    )
    const _openRecovery = useCallback(
        (message, title, onPress) => {
            try {
                recoveryRef.current && recoveryRef.current.show({ message, title, onPress })
            } catch (error) {}
        },
        [recoveryRef],
    )
    const _openConfirmRegister = useCallback(
        (token, provider, email) => {
            try {
                confirmRegisterRef.current && confirmRegisterRef.current.show({ token, provider, email })
            } catch (error) {}
        },
        [confirmRegisterRef],
    )

    const _checkEmail = useCallback(
        async (email, token, provider) => {
            try {
                if (!email) {
                    const user = await AuthAPI.loginSocial(provider, token)
                    await dispatch(checkAccount(user, state))
                    return
                }
                const result = await AppAPI.checkEmail(email)
                if (result.exists) {
                    const user = await AuthAPI.loginSocial(provider, token)
                    await dispatch(checkAccount(user, state))
                } else {
                    _openConfirmRegister(token, provider, email)
                }
            } catch ({ message }) {
                _showAlertError(message)
            }
        },
        [_showAlertError, dispatch, _openConfirmRegister, state],
    )

    const _onGoogleAuth = useCallback(
        async ({ profileObj, tokenObj }) => {
            if (!tokenObj?.access_token) {
                return
            }
            try {
                setLoading(true)
                const email = profileObj.email

                await _checkEmail(email, tokenObj.access_token, 'google')
            } catch (e) {
                console.log('google Error', e)
            } finally {
                setLoading(false)
            }
        },
        [_checkEmail],
    )
    const _onFaceboookAuth = useCallback(
        async result => {
            if (!result?.accessToken) {
                return
            }
            try {
                setLoading(true)
                const email = result.email

                await _checkEmail(email, result.accessToken, 'facebook')
            } catch (e) {
                console.log('facebook Error', e)
            } finally {
                setLoading(false)
            }
        },
        [_checkEmail],
    )

    const _getQuery = () => {
        return new URLSearchParams(location.search)
    }

    const _openRecoveryPasswordPerAds = () => {
        if (_getQuery().get('ads') === 'forgot-password') {
            history.push('/login')
            if (_openRecovery) {
                _openRecovery()
            }
        }
    }

    useEffect(() => {
        _openRecoveryPasswordPerAds()
    }, [])

    return (
        <>
            <PageContainer loading={isSubmitting || loading} hideHeader>
                <Logo isDark={theme === 'dark'} />

                <Content>
                    <ApresentationContainer>
                        <Ilustration />
                        <ContainerInfoApp>
                            <TextInfoApp>
                                <p>
                                    <span>Aponte a câmera {'>>'}</span>
                                    Baixe o app <br />
                                    gratuitamente
                                </p>
                            </TextInfoApp>
                            <QRCodeInfoApp />
                        </ContainerInfoApp>
                    </ApresentationContainer>
                    <FormContainer>
                        <ContainerSocialButton>
                            <Title>Login</Title>

                            <GoogleLogin
                                className="button-google-social"
                                clientId={
                                    '301973440649-89736v2ujbb6gojln4nm71v74c4kffcr.apps.googleusercontent.com'
                                }
                                onSuccess={_onGoogleAuth}
                                render={({ onClick }) => (
                                    <SocialButton
                                        onClick={onClick}
                                        type="google"
                                        title={t('login-with-google')}
                                    />
                                )}
                            />
                            <FacebookLogin
                                appId="781134805391452"
                                fields="name,email,picture"
                                callback={_onFaceboookAuth}
                                render={({ onClick }) => (
                                    <SocialButton
                                        onClick={onClick}
                                        type="facebook"
                                        title={t('login-with-facebook')}
                                    />
                                )}
                            />
                        </ContainerSocialButton>

                        <UnderlineAccount>
                            <div className="line" />
                            <div className="text">{t('or-account')}</div>
                            <div className="line" />
                        </UnderlineAccount>

                        <InputLogin
                            label={t('input.login.label')}
                            name="login"
                            value={values.login}
                            placeholder="Digite seu e-mail"
                            onChangeText={e => setFieldValue('login', e)}
                            onBlur={handleBlur('login')}
                            errorMessage={touched.login && errors.login}
                        />
                        <InputLogin
                            label={t('input.password.label')}
                            name="password"
                            type="password"
                            placeholder="Digite sua senha secreta"
                            value={values.password}
                            onChangeText={e => setFieldValue('password', e)}
                            onBlur={handleBlur('password')}
                            errorMessage={touched.password && errors.password}
                        />
                        <Forget>
                            <LinkForget onClick={_openRecovery}>{t('forgot.password')}</LinkForget>
                        </Forget>
                        <Button disabled={isSubmitting} title={t('log-in')} onClick={handleSubmit} />

                        <CreateNewAccount>
                            {t('sign-up-for-free')}
                            <LinkCreateNewAccount onClick={() => history.push('/criar-conta')}>
                                {t('sign-up-for-free-link')}
                            </LinkCreateNewAccount>
                        </CreateNewAccount>
                    </FormContainer>
                </Content>
            </PageContainer>
            <AlertError ref={alertErrorRef} />
            <RecoveryPassword ref={recoveryRef} />
            <ConfirmRegister ref={confirmRegisterRef} />
        </>
    )
}

export default Login
