import React from 'react'

import GroupInfo from '#/components/info-group/info-group'
import ShiftInfo from '#/components/shift-info/shift-info'

import { Container, ContainerInfo } from './item-shift.styled'

function ItemShift({ shift, onClick }) {
    if (!shift) {
        return null
    }

    const { group } = shift
    return (
        <Container onClick={onClick}>
            <ContainerInfo>
                {group && <GroupInfo group={group} />}
                <ShiftInfo shift={shift} />
            </ContainerInfo>
        </Container>
    )
}

export default ItemShift
