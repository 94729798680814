import styled, { css } from 'styled-components'

import { Feather } from '#/components/icons'
const Container = styled.div`
    width: 100%;
    padding: 10px 0px;
    position: relative;
    .button-text {
        margin-top: 10px;
    }
`
const Row = styled.div`
    display: flex;
    width: 100%;

    .button {
        border-radius: 2px;
        height: 28px;
        padding: 0px 6px;
    }
`

const Time = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 12px;
    align-self: center;
    margin-left: auto;
`

const Date = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
    margin-left: 10px;
    font-size: 12px;
    align-self: center;
    text-transform: uppercase;
`
const Label = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 14px;
`
const Text = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 14px;
    margin-left: 5px;
    text-transform: uppercase;
`

const Profession = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    font-weight: bold;
`
const Specializations = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 12px;
`
const Localization = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 12px;
    text-transform: uppercase;
`

const ContainerIcon = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 12px;
    margin-left: 10px;
    &:first-child {
        margin-left: 0px;
    }
    .icon {
        color: ${({ theme }) => theme.colors.textLight};
        margin-right: 4px;
    }

    ${({ onClick, disabled, theme }) =>
        onClick &&
        css`
            cursor: ${() => (disabled ? 'auto' : 'pointer')};
            color: ${() => (disabled ? theme.colors.textLight : theme.colors.primary)};

            .icon {
                color: ${() => (disabled ? theme.colors.textLight : theme.colors.primary)};
            }
        `}
`

const Bottom = styled.div`
    width: 100%;
    margin-top: 10px;
    padding: 10px 0px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    display: flex;
    align-items: center;
`

const SideRight = styled.div`
    margin-left: auto;
    align-items: center;
    display: flex;
`

const Margin = styled.div`
    margin: 10px 0px;
`

const ContainerInfo = styled.div`
    flex: 1;
`

const ClosedText = styled.div`
    color: ${({ theme }) => theme.colors.danger};
    text-transform: uppercase;
    margin-left: 10px;
    font-weight: bold;
`

const MenuIcon = styled(Feather).attrs({ name: 'MoreVertical', size: 18 })`
    color: ${({ theme }) => theme.colors.text};
    margin-left: 5px;
`

export {
    Container,
    Row,
    Time,
    Date,
    Profession,
    Localization,
    ContainerIcon,
    ClosedText,
    Specializations,
    Label,
    Text,
    Bottom,
    SideRight,
    Margin,
    ContainerInfo,
    MenuIcon,
}
