import 'react-datepicker/dist/react-datepicker.css'

import classNames from 'classnames'
import pt from 'date-fns/locale/pt'
import React, { memo, useCallback, useState } from 'react'
import Picker from 'react-datepicker'
import { registerLocale } from 'react-datepicker'
import InputMask from 'react-input-mask'

import { CallActionText, ErrorMessage, Label } from '../shared'
import { Container, CustomInput } from './date-picker.styled'
registerLocale('pt', pt)
function DatePicker({
    label,
    errorMessage,
    placeholder,
    onChange,
    mask,
    onBlur,
    onFocus,
    value,
    disabled,
    required,
    callActionText,
    callActionClick,
    className,
    ...props
}) {
    const [date, setDate] = useState()
    const _setDate = useCallback(
        date => {
            setDate(date)
            if (onChange) {
                onChange(date)
            }
        },
        [onChange],
    )
    return (
        <Container className={classNames('input-picker', className)}>
            {!!label && (
                <Label htmlFor="" required={required}>
                    {label}:
                </Label>
            )}
            <Picker
                locale="pt"
                dateFormat="dd/MM/yyyy"
                customInput={
                    <InputMask
                        alwaysShowMask
                        maskChar={null}
                        mask="99/99/9999"
                        value={value === null ? '' : value}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        disabled={disabled}
                    >
                        {inputProps => (
                            <CustomInput
                                className="input-field"
                                disabled={disabled}
                                type="text"
                                placeholder={placeholder}
                                {...{ ...props, ...inputProps }}
                            />
                        )}
                    </InputMask>
                }
                placeholderText="00/00/0000"
                selected={date}
                onChange={_setDate}
                {...props}
            />
            {!!callActionText && <CallActionText onClick={callActionClick}>{callActionText}</CallActionText>}
            {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Container>
    )
}

export default memo(DatePicker)
