import React, { memo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { ContentSection, Warning } from '#/components'

import { BackIcon, ContainerTitle, ContentCard, Title } from './group-content.styled'

function GroupContent({ loading, overflowHidden, title, warning, children, arrowBack, withoutPadding }) {
    const history = useHistory()

    const _goBack = useCallback(() => {
        history.goBack()
    }, [history])

    return (
        <ContentSection loading={loading} overflowHidden={overflowHidden} withoutPadding={withoutPadding}>
            <ContentCard>
                <ContainerTitle>
                    {!!arrowBack && <BackIcon onClick={_goBack} />}
                    {!!title && <Title>{title}</Title>}
                </ContainerTitle>
                {!!warning && <Warning>{warning}</Warning>}
                {children}
            </ContentCard>
        </ContentSection>
    )
}

export default memo(GroupContent)
