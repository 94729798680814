import styled from 'styled-components'

const Container = styled.div`
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const ContainerColors = styled.div`
    width: 100%;
    overflow-x: auto;
    position: relative;
    height: 75px;
    display: block;
`

const ContainerItem = styled.div`
    position: absolute;
    display: flex;
`

const Item = styled.div`
    border-radius: 25px;
    overflow: hidden;
    background-color: ${({ color, theme }) => (color ? color : theme.colors.primary)};
    width: 35px;
    height: 35px;
`

const Border = styled.div`
    border: 3px solid ${({ active, theme: { colors } }) => (active ? colors.primary : colors.background)};
    border-radius: 22.5px;
    overflow: hidden;
    width: 45px;
    height: 45px;
    margin: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

export { Container, ContainerColors, Item, ContainerItem, Border }
