import { format, parseISO } from 'date-fns'
import localePT from 'date-fns/locale/pt-BR'
import React, { memo, useMemo } from 'react'

import { formatCurrency } from '../../utils'
import { Container, ContainerInfo, Icon, Label } from './shift-details.styled'

function ShiftDetails({ horizontal, shift }) {
    const { amount, label, end_date, start_date, interests, available } = shift

    const startDate = useMemo(() => {
        if (start_date) {
            return parseISO(start_date)
        }
        return null
    }, [start_date])

    const endDate = useMemo(() => {
        if (end_date) {
            return parseISO(end_date)
        }
        return null
    }, [end_date])

    const date = useMemo(() => {
        if (startDate) {
            return capitalizeDate(
                format(startDate, "E',' dd 'de' MMMM", {
                    locale: localePT,
                }),
            )
        }
        return null
    }, [startDate])

    const time = useMemo(() => {
        if (startDate) {
            return `${format(startDate, "HH'h'mm")} - ${format(endDate, "HH'h'mm")} (${label})`
        }

        return null
    }, [startDate, endDate, label])
    return (
        <Container horizontal={horizontal} flexStart className="shift-details">
            {!!start_date && (
                <>
                    <ContainerInfo>
                        <Icon name="Calendar" />
                        <Label>{date}</Label>
                    </ContainerInfo>
                    <ContainerInfo>
                        <Icon name="Clock" />
                        <Label>{time}</Label>
                    </ContainerInfo>
                </>
            )}
            {!!amount && (
                <ContainerInfo>
                    <Icon name="DollarSign" />
                    <Label>{formatCurrency(amount)}</Label>
                </ContainerInfo>
            )}
            {!!available && (
                <ContainerInfo>
                    <Icon name="Star" />
                    <Label>{`${available} ${available > 1 ? 'vagas' : 'vaga'}`}</Label>
                </ContainerInfo>
            )}
            {interests && (
                <ContainerInfo>
                    <Icon name="User" />
                    <Label>{`${interests.length} ${
                        interests.length > 1 ? 'interesses' : 'interesse'
                    }`}</Label>
                </ContainerInfo>
            )}
        </Container>
    )
}

function capitalizeDate(date) {
    const [day, month] = date.split(' de ')

    return `${capitalize(day)} de ${capitalize(month)}`
}

function capitalize(str) {
    return `${str[0].toUpperCase()}${str.slice(1)}`
}

export default memo(ShiftDetails)
