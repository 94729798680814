import React, { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getMonthName, parseQuery } from '#/utils'

import { Container, ContainerText, MonthText, Status, TextAccent } from './item-schedule.styled'

const ItemSchedule = ({ currentMonth, schedule, isPersonal, shiftsCount }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const { label, month, status, year } = schedule
    const [scheduleMonth, setScheduleMonth] = useState(0)

    const { color, id, role, is_admin } = useSelector(state => state.group || {})

    const _goToInternal = useCallback(() => {
        if (isPersonal) {
            // navigation.navigate('PersonalSchedule', {
            //     period: `${year}-${month}`,
            // })
        } else {
            const { month, status, year } = schedule
            history.push({
                pathname: `/gerenciar-escala/${id}/${year}-${month}`,
                search: parseQuery({ status }),
            })
        }
    }, [id, history, isPersonal, schedule])

    const _goToViewSchedule = useCallback(() => {
        if (isPersonal) {
            // navigation.navigate('PersonalSchedule', {
            //     period: `${year}-${month}`,
            // })
        } else {
            const { month, status, year } = schedule
            history.push({
                pathname: `/revisar-escala/${id}/${year}-${month}`,
                search: parseQuery({ status }),
            })
        }
    }, [history, id, isPersonal, schedule])

    const _checkRole = useCallback(() => {
        if (is_admin) {
            return _goToInternal()
        }
        if (role !== null) {
            if (role?.name === 'Observer' || role?.name === 'Analyst') {
                return _goToViewSchedule()
            }

            return () => {}
        }
        return () => {}
    }, [_goToInternal, _goToViewSchedule, is_admin, role])

    useEffect(() => {
        setScheduleMonth(Number(`${year}${month}`))
    }, [year, month])

    return (
        <Container onClick={_checkRole} groupColor={color} current={currentMonth === scheduleMonth}>
            <ContainerText>
                <MonthText current={currentMonth === scheduleMonth}>{getMonthName(month)}</MonthText>
                <Status status={status} current={currentMonth === scheduleMonth}>
                    {formatLabel(label, isPersonal, shiftsCount, t)}
                </Status>
            </ContainerText>
            <TextAccent current={currentMonth === scheduleMonth}>
                {isPersonal ? t('add-shifts') : is_admin ? t('scale-management') : t('scale-review')}
            </TextAccent>
        </Container>
    )
}

function formatLabel(label, isPersonal, shiftsCount, t) {
    if (isPersonal) {
        return `${shiftsCount || 0} ${t('shifts')}`
    }
    return label
}

export default memo(ItemSchedule)
