import styled, { css } from 'styled-components'

import { Feather } from '../icons'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${({ horizontal }) =>
        horizontal &&
        css`
            flex-direction: row;
            ${Step} {
                flex-direction: row;
            }
            ${Divider} {
                height: 2px;
                width: 25px;
            }
        `}
`

const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: 2px solid ${({ theme }) => theme.colors.text};
`

const Divider = styled.div`
    width: 2px;
    height: 25px;
    background-color: ${({ theme }) => theme.colors.text};
`

const Icon = styled(Feather).attrs(({ active, horizontal, complete }) => ({
    name: active || complete ? 'Check' : horizontal ? 'ChevronRight' : 'ChevronDown',
}))`
    color: ${({ theme }) => theme.colors.text};
`

const Step = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${({ active }) =>
        active &&
        css`
            ${Circle} {
                border: 2px solid ${({ theme }) => theme.colors.primary};
            }
            ${Icon} {
                color: ${({ theme }) => theme.colors.primary};
            }
            ${Divider} {
                background-color: ${({ theme }) => theme.colors.primary};
            }
        `}
    ${({ complete }) =>
        complete &&
        css`
            ${Circle} {
                border: 2px solid ${({ theme }) => theme.colors.primary};
                background-color: ${({ theme }) => theme.colors.primary};
            }
            ${Icon} {
                color: #fff;
            }
            ${Divider} {
                background-color: ${({ theme }) => theme.colors.primary};
            }
        `}
`

export { Container, Circle, Icon, Divider, Step }
