import styled from 'styled-components'

const Container = styled.div`
    display: flex;
`

const ContainerTitle = styled.div`
    display: flex;
    margin-bottom: 2px;
    align-items: center;
`

const GroupName = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 16px;
    flex: 1;
    text-transform: uppercase;
`

const InstitutionName = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 14px;
`
const ContainerInfo = styled.div`
    flex: 1;
    margin-left: 5px;
`

const ColorGroup = styled.div`
    background-color: ${({ theme, color }) => (color ? color : theme.colors.primary)};
    width: 12px;
    height: 12px;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 6px;
`

export { Container, GroupName, InstitutionName, ContainerTitle, ContainerInfo, ColorGroup }
