import styled from 'styled-components'

import { Feather } from '#/components'

const Content = styled.div`
    flex: 1;
    display: flex;
    overflow-y: hidden;
    @media (max-width: 992px) {
        flex-direction: column;
    }
`

const Form = styled.form`
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .submit {
        margin-top: 20px;
        align-self: flex-end;
    }
`
const SplitContent = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;

    padding: 20px;
    @media (max-width: 992px) {
        display: ${({ autoHiden }) => (autoHiden ? 'none' : 'block')};
    }
    @media (min-width: 992px) {
        flex: 1;
    }
`

const AlignCenter = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Ilustration = styled.img`
    width: 80%;
    max-width: 400px;
    object-fit: contain;
    margin-bottom: 20px;
`

const Message = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
`

const ContainerTitle = styled.div`
    width: 100%;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 16px;
    display: flex;
`

const BackIcon = styled(Feather).attrs({ name: 'ArrowLeft' })`
    color: ${({ theme }) => theme.colors.primary};
`

const Title = styled.div`
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.text};
`

export { AlignCenter, BackIcon, ContainerTitle, Content, Form, Ilustration, Message, SplitContent, Title }
