import styled from 'styled-components'

import { Feather } from '#/components'
const ContainerTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 10px;
`
const ContainerText = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 16px;
    line-height: initial;
`

const Description = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 12px;
    line-height: initial;
`

const ContentCard = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: ${({ withoutPadding }) => (withoutPadding ? 0 : 10)}px;
    padding-top: 0px;
`

const BackIcon = styled(Feather).attrs({ name: 'ArrowLeft' })`
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    margin-right: 4px;
`
const Icon = styled(Feather)`
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 4px;
`

export { BackIcon, ContainerTitle, ContentCard, Title, Icon, Description, ContainerText }
