import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { removeAds } from '#/redux/reducers/ads'
import { adsUrl } from '#/utils'

import { Button, PageContainer } from '../../../components'
import { logout } from '../../../redux/thunk'
import { Bold, Container, ContentHeader, Label, Logo, Message, Title } from './register-complete.styled'
function RegisterComplete() {
    const ads = useSelector(state => state?.ads)
    const auth = useSelector(state => state?.auth)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)

        setTimeout(() => {
            if (ads === 'create-account') {
                window.location.href = `${adsUrl}`
            }
            dispatch(removeAds())
            setLoading(false)
        }, 5000)
    }, [ads, auth, loading, dispatch])

    return (
        <PageContainer
            loading={loading}
            header={
                <ContentHeader>
                    {
                        <>
                            <Logo />
                            <Label onClick={() => dispatch(logout())}>
                                <Bold pointer>{t('log-out')}</Bold>
                            </Label>
                        </>
                    }
                </ContentHeader>
            }
        >
            <Container>
                <Title>Cadastro realizado com sucesso!</Title>
                <Message>Confira nossos video e veja como utilizar a ferramenta</Message>

                <iframe
                    id="ytplayer"
                    type="text/html"
                    width="500"
                    height="255"
                    src="http://www.youtube.com/embed/ZfkJaJlCSzs?autoplay=1"
                    frameborder="0"
                />
                <Button title="Iniciar" dimension="small" onClick={() => history.replace('/')} />
            </Container>
        </PageContainer>
    )
}

export default RegisterComplete
