import classNames from 'classnames'

import React, { memo } from '../../../node_modules/react'
import { Container } from './button.styled'
const Button = ({ onClick, title, variant, dimension, uppercase, disabled, className }) => {
    return (
        <Container
            uppercase={uppercase}
            onClick={!disabled ? onClick : null}
            className={classNames('button', variant, dimension, disabled ? 'disabled' : null, className)}
        >
            {title}
        </Container>
    )
}

export default memo(Button)
