import { useFormik } from 'formik'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { AccountsAPI, AppAPI } from '#/api'
import { Button, ContentSection, TitleBar } from '#/components'
import { GroupForms, Input, Select } from '#/components/forms'
import { AlertError } from '#/modals'
import { updateAuth } from '#/redux/reducers/auth'
import { setUser } from '#/redux/reducers/user'

import { SettingsContent } from '../shared'
import { ContainerButton, ContainerForm } from './profile.styled'
function Profile() {
    const user = useSelector(state => state.user)

    const { t } = useTranslation()
    const alertErrorRef = useRef()
    const dispatch = useDispatch()

    const [states, setStates] = useState([])
    const [professions, setProfessions] = useState([])
    const [specializations, setSpecializations] = useState([])
    const [loading, setLoading] = useState(true)

    const { isSubmitting, values, errors, touched, handleSubmit, setFieldValue, handleBlur } = useFormik({
        initialValues: {
            name: null,
            phone: null,
            call_name: null,
            profession: null,
            council_state: null,
            council_id: '',
            specialization: null,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().nullable().required(t('input.name.required')).min(3, t('input.name.min')),
            call_name: Yup.string().nullable().min(3, t('input.call_name.min')),
            phone: Yup.string().min(14, t('input.phone.invalid')).trim().required(t('input.phone.required')),
            email: Yup.string().trim().email(t('input.email.invalid')).required(t('input.email.required')),
            profession: Yup.object().nullable().required(t('select.profession.required')),
            council_state: Yup.object().when(['profession'], {
                is: val => (val && val.has_council_state) || false,
                then: Yup.object().nullable().required(t('select.council_state.required')),
                otherwise: Yup.object().nullable().notRequired(),
            }),
            specialization: Yup.object().when(['profession'], {
                is: val => (val && val.has_specializations) || false,
                then: Yup.object().nullable().required(t('select.specialty.required')),
                otherwise: Yup.object().nullable().notRequired(),
            }),
            council_id: Yup.number().when(['profession'], {
                is: val => (val && val.has_council) || false,
                then: Yup.number()
                    .typeError(t('onlynumber'))
                    .min(3, t('input.council.min'))
                    .required(t('input.council.required')),
                otherwise: Yup.number().notRequired(),
            }),
        }),
        onSubmit: async values => {
            try {
                const body = { ...values }
                if (user.phone === values.phone) {
                    delete body.phone
                }
                const result = await AccountsAPI.update(body)
                const { call_name, name, photo } = result
                dispatch(setUser(result))
                dispatch(updateAuth({ name: call_name || name, photo }))
            } catch ({ message }) {
                _showAlertErro(message)
            }
        },
    })

    const _showAlertErro = useCallback(
        message => {
            try {
                alertErrorRef.current && alertErrorRef.current.show({ message })
            } catch (error) {}
        },
        [alertErrorRef],
    )

    const _loadUf = useCallback(async () => {
        try {
            const result = await AppAPI.UFlist()

            const states = result.items.map(item => {
                return { label: item.code, value: { uf: item.code, name: item.name }, key: item.code }
            })
            setStates(states)
        } catch (err) {}
    }, [])

    const _loadSpecializations = useCallback(async profession_id => {
        try {
            const responseSpec = await AccountsAPI.specializationList(profession_id)

            const specializations = responseSpec.items.map(item => {
                return { label: item.name, value: item, key: item.id }
            })
            setSpecializations(specializations)
        } catch (err) {}
    }, [])

    const _setUserData = useCallback(
        user => {
            if (user) {
                const {
                    profession,
                    council,
                    specialization,
                    name,

                    call_name,
                    email,
                    phone,
                } = user
                setFieldValue('name', name)
                setFieldValue('call_name', call_name)

                setFieldValue('phone', phone)
                setFieldValue('email', email)

                if (profession) {
                    setFieldValue('profession', profession)
                }
                if (profession?.has_council && council) {
                    setFieldValue('council_id', council.id)
                    setFieldValue('council_state', { uf: council.state })
                }
                if (profession?.has_specializations && specialization) {
                    setFieldValue('specialization', specialization)
                }
            }
        },
        [setFieldValue],
    )

    const _init = useCallback(async () => {
        try {
            const responseProf = await AccountsAPI.professionalList()

            const professions = responseProf.items.map(item => {
                return { label: item.name, value: item, key: item.id }
            })

            setProfessions(professions)
            _setUserData(user)
        } catch (err) {
        } finally {
            setLoading(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {
        if (user) {
            if (professions.length === 0) {
                _init()
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, professions])

    useEffect(() => {
        if (values.profession) {
            if (values.profession.has_council_state) {
                _loadUf()
            } else {
                setStates([])
            }
            if (values.profession.has_specializations) {
                _loadSpecializations(values.profession.id)
            } else {
                setSpecializations([])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.profession, _loadUf])

    useEffect(() => {
        return () => {
            if (values.profession != null) {
                setFieldValue('council_state', null)
                setFieldValue('council_id', '')
                setFieldValue('specialization', null)
            }
        }
    }, [values.profession, setFieldValue])

    return (
        <SettingsContent title={t('account')} iconName="User" withoutPadding>
            <ContentSection loading={loading || isSubmitting} withoutPadding>
                <>
                    <TitleBar title={t('info-personal.title')} />
                    <ContainerForm>
                        <GroupForms>
                            <Input
                                name="name"
                                label={t('input.name.label')}
                                value={values.name}
                                onChangeText={e => setFieldValue('name', e)}
                                onBlur={handleBlur('name')}
                                errorMessage={touched.name && errors.name}
                            />
                            <Input
                                name="call_name"
                                label={t('input.call_name.label')}
                                value={values.call_name}
                                onChangeText={e => setFieldValue('call_name', e)}
                                onBlur={handleBlur('call_name')}
                                errorMessage={touched.call_name && errors.call_name}
                            />
                        </GroupForms>
                        <GroupForms>
                            <Input
                                name="phone"
                                label={t('phone')}
                                mask="(99)99999-9999"
                                value={values.phone}
                                onChangeText={e => setFieldValue('phone', e)}
                                onBlur={handleBlur('phone')}
                                errorMessage={touched.phone && errors.phone}
                            />

                            <Input
                                name="email"
                                label={t('input.email.label')}
                                type="email"
                                value={values.email}
                                onChangeText={e => setFieldValue('email', e)}
                                onBlur={handleBlur('email')}
                                errorMessage={touched.email && errors.email}
                            />
                        </GroupForms>
                    </ContainerForm>
                </>
                <>
                    <TitleBar title={t('info-profession.title')} />
                    <ContainerForm>
                        <GroupForms>
                            <Select
                                name="profession"
                                label={t('profession')}
                                placeholder={t('select.profession.placeholder')}
                                options={professions}
                                onChange={value => setFieldValue('profession', value)}
                                selectedKey={values.profession?.id}
                                errorMessage={touched.profession && errors.profession}
                            />
                            {values.profession?.has_specializations && (
                                <Select
                                    name="specialty"
                                    label={t('specialty')}
                                    placeholder={t('select.specialty.placeholder')}
                                    options={specializations}
                                    onChange={value => setFieldValue('specialization', value)}
                                    selectedKey={values.specialization?.id}
                                    errorMessage={touched.specialization && errors.specialization}
                                />
                            )}
                        </GroupForms>
                        <GroupForms>
                            {values.profession?.has_council_state && (
                                <Select
                                    name="uf"
                                    label={t('council_state')}
                                    placeholder={t('select.council_state.placeholder')}
                                    options={states}
                                    onChange={value => setFieldValue('council_state', value)}
                                    selectedKey={values.council_state?.uf}
                                    errorMessage={touched.council_state && errors.council_state}
                                />
                            )}
                            {values.profession?.has_council && (
                                <Input
                                    name="council"
                                    label={t('council')}
                                    value={values.council_id}
                                    onChangeText={e => setFieldValue('council_id', e)}
                                    onBlur={handleBlur('council_id')}
                                    errorMessage={touched.council_id && errors.council_id}
                                />
                            )}
                        </GroupForms>
                    </ContainerForm>
                </>
                <ContainerButton>
                    <Button title={t('update')} onClick={handleSubmit} disabled={isSubmitting || loading} />
                </ContainerButton>
            </ContentSection>
            <AlertError ref={alertErrorRef} />
        </SettingsContent>
    )
}

export default memo(Profile)
