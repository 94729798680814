import { Axios } from './axios-config'

const view = (group_id, vacancy_id) => {
    return Axios().get(`groups/${group_id}/vacancies/${vacancy_id}`)
}

const removeInterest = (group_id, vacancy_id, interest_id) => {
    return Axios().delete(`groups/${group_id}/vacancies/${vacancy_id}/interests/${interest_id}`)
}

const designate = (group_id, vacancy_id, body) => {
    return Axios().patch(`groups/${group_id}/vacancies/${vacancy_id}/interests`, body)
}

const remove = (group_id, vacancy_id, body) => {
    return Axios().delete(`groups/${group_id}/vacancies/${vacancy_id}`, { data: body })
}

export default {
    remove,
    designate,

    removeInterest,
    view,
}
