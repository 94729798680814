import { getDay, getDaysInMonth, startOfMonth } from 'date-fns'

const generateMonth = date => {
    const WEEK = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']

    if (date) {
        const startDay = startOfMonth(date)
        const maxDays = getDaysInMonth(date)
        const startWeek = getDay(startDay)

        let countDay = 1
        let matrix = [],
            H = 6, // 6 rows
            W = 7 // of 7 cells

        for (let y = 0; y <= H; y++) {
            matrix[y] = []
            for (let x = 0; x < W; x++) {
                if (y === 0) {
                    matrix[y][x] = WEEK[x]
                } else {
                    if (y === 1) {
                        if (startWeek > x) {
                            matrix[y][x] = null
                        } else {
                            matrix[y][x] = countDay
                            countDay++
                        }
                    } else {
                        if (countDay <= maxDays) {
                            matrix[y][x] = countDay
                            countDay++
                        }
                    }
                }
            }
        }

        matrix = matrix.filter(item => item.length > 0)
        return { monthNumeric: date.getMonth() + 1, year: date.getFullYear(), matrix: matrix }
    }

    return { monthNumeric: 0, year: 0, matrix: [] }
}

const makeEvent = ({
    date,
    colors = ['0096E2'],
    opacity = false,
    hasInfo = false,
    hasAlert = false,
    hasVacancy = false,
    data = null,
}) => {
    return {
        date,
        payload: {
            colors: colors ? colors.map(item => `#${item}`) : ['#0096E2'],
            hasEvent: true,
            opacity,
            hasInfo,
            hasAlert,
            hasVacancy,
            data,
        },
    }
}

const monthMapformatDate = (monthMap, day) => {
    let current_date

    current_date = `${monthMap.year}-${
        monthMap.monthNumeric < 10 ? '0' + monthMap.monthNumeric : monthMap.monthNumeric
    }-${day < 10 ? '0' + day : day}`

    return current_date
}

export { monthMapformatDate, generateMonth, makeEvent }
