import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AccountsAPI } from '#/api'
import { Button, InputCode, Link } from '#/components'
import Loading from '#/modals/loading/loading'
import { updateUser } from '#/redux/reducers/user'

import { ModalBase } from '../shared'
import { Container, Message } from './active-email.styled'

function ActiveEmail(props, ref) {
    const { t } = useTranslation()
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [code, setCode] = useState(null)
    const [loading, setLoading] = useState(true)

    const [errorMessage, setErrorMessage] = useState(null)
    const email = useMemo(() => {
        let formatedEmail = ''
        if (user && user.email) {
            const positionAt = user.email.indexOf('@')
            formatedEmail = user.email[0]
            formatedEmail += `******${user.email.substr(positionAt - 2)}`
        }

        return formatedEmail
    }, [user])

    const _requestCode = useCallback(async () => {
        setLoading(true)
        try {
            await AccountsAPI.requestEmail({
                type: 'email',
            })
        } catch ({ message }) {
            setErrorMessage(message)
        } finally {
            setLoading(false)
        }
    }, [])

    const _onClose = useCallback(() => {
        setShow(false)
    }, [])

    const _activeEmail = useCallback(async () => {
        setLoading(true)
        try {
            await AccountsAPI.activateEmail({
                token: code,
                type: 'email',
            })

            const unconfirmed_data = user.unconfirmed_data.filter(item => item !== 'email')
            dispatch(updateUser({ unconfirmed_data }))

            _onClose()
        } catch ({ message }) {
            setErrorMessage(message)
        } finally {
            setLoading(false)
        }
    }, [user, _onClose, dispatch, code])

    useImperativeHandle(ref, () => ({
        show: () => {
            setShow(true)
            _requestCode()
        },
    }))

    return (
        <ModalBase showButton={false} title="Ativar e-mail" visible={show} icon="Mail" onClose={_onClose}>
            <Container>
                <Message>{t('modal.active-email.message', { email })}</Message>
                <InputCode
                    label={t('modal.active-email.label')}
                    onComplete={code => setCode(code)}
                    errorMessage={errorMessage}
                />
                <Link onClick={_requestCode}>{t('modal.active-email.click')}</Link>
                <Button
                    disabled={(code || '').length !== 6}
                    title={t('modal.active-email.check')}
                    onClick={_activeEmail}
                />
                <Loading show={loading} />
            </Container>
        </ModalBase>
    )
}

export default memo(forwardRef(ActiveEmail))
