import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'

import { baseURL } from '#/api/axios-config'

import App from './App'
import betaSource from './assets/images/beta.png'
import * as serviceWorker from './serviceWorker'
ReactDOM.render(
    <React.StrictMode>
        <div className="app">
            <App />
            {baseURL.includes('beta') && <img className="banner-beta" src={betaSource} />}
        </div>
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
