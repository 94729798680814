import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import Ads from './ads'
import Auth from './auth'
import Group from './group'
import Loading from './loading'
import Profile from './profile'
import Theme from './theme'
import User from './user'

const createRootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        auth: Auth,
        isLoading: Loading,
        user: User,
        group: Group,
        theme: Theme,
        profile_id: Profile,
        ads: Ads,
    })

export default createRootReducer
