import React, { memo } from 'react'

import { Container, Icon, Label } from './schedule.styled'

function Schedule({ schedule }) {
    if (!schedule) {
        return null
    }

    const { year, month } = schedule
    return (
        <Container>
            <Icon name="Calendar" />
            <Label>
                {month}/{year}
            </Label>
        </Container>
    )
}

export default memo(Schedule)
