import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from 'react'

import { Box, Close, Container, ContainerStores, ContainerTitle, Message } from './call-to-mobile.styled'
function CallToMobile(props, ref) {
    const [visible, setVisible] = useState(true)

    const _onClick = useCallback(() => {
        setVisible(false)
    }, [])

    useImperativeHandle(ref, () => ({
        show: (props = {}) => {
            setVisible(true)
        },
    }))

    if (!visible) {
        return null
    }
    return (
        <Container className="w3-animate-opacity">
            <Box className="w3-animate-bottom">
                <ContainerTitle>
                    <Close onClick={_onClick} />
                </ContainerTitle>
                <Message bold>
                    Baixe o app no seu celular e tenha uma experiência ainda mais completa.
                </Message>
                <ContainerStores>
                    <a
                        href="https://plantaoativo.page.link/loja-ios"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="store-link"
                    >
                        <div className="store-icon">
                            <i className="icon-appstore.svg" />
                            <img
                                src="https://plantaoativo.com/wp-content/themes/plantaoativo_2020/assets/images/icon-appstore.svg?ver=1.1.2"
                                alt="Baixe o Plantão Ativo na App Store"
                            />
                        </div>
                        <div className="store-text">
                            <div className="store-head">BAIXAR NA</div>
                            <div className="store-title">App Store</div>
                        </div>
                    </a>
                    <a
                        href="https://plantaoativo.page.link/loja-android"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="store-link"
                    >
                        <div className="store-icon">
                            <i className="icon-googleplay.svg" />
                            <img
                                src="https://plantaoativo.com/wp-content/themes/plantaoativo_2020/assets/images/icon-googleplay.svg?ver=1.1.2"
                                alt="Baixe o Plantão Ativo no Google Play"
                            />
                        </div>
                        <div className="store-text">
                            <div className="store-head">DISPONÍVEL NO</div>
                            <div className="store-title">Google Play</div>
                        </div>
                    </a>
                </ContainerStores>
            </Box>
        </Container>
    )
}

export default memo(forwardRef(CallToMobile))
