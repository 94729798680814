import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router-dom'

import { HeaderGroup, ItemMember, Menu, SectionControl, TitleBar } from '#/components'

import GroupDetails from './group-details/group-details'
import GroupHistoric from './group-historic/group-historic'
import GroupMembers from './group-members/group-members'
import GroupReports from './group-reports/group-reports'
import GroupSchedule from './group-schedule/group-schedule'
import GroupSettings from './group-settings/group-settings'
import SearchMembers from './search-members/search-members'

function SectionCreateGroup() {
    const { t } = useTranslation()
    const { color, manager, assists, total_users, id, is_admin, analysts, observers } = useSelector(
        state => state.group || {},
    )
    const user = useSelector(state => state.user)

    const location = useLocation()

    const checkRole = useMemo(() => {
        if (analysts?.filter(analyst => analyst.id === user?.id).length > 0) {
            return 'analyst'
        }

        if (observers?.filter(observer => observer.id === user?.id).length > 0) {
            return 'observer'
        }
    }, [analysts, observers, user?.id])

    const activeIndex = useMemo(() => {
        if (location) {
            const { pathname } = location

            if (is_admin) {
                if (pathname.includes('configuracoes')) {
                    return 6
                }

                if (pathname.includes('valores')) {
                    return 5
                }

                if (pathname.includes('relatorio')) {
                    return 4
                }

                if (pathname.includes('historico')) {
                    return 3
                }

                if (pathname.includes('membros')) {
                    return 2
                }

                if (pathname.includes('/escalas')) {
                    return 1
                }

                if (pathname.includes('/grupo/')) {
                    return 0
                }
            } else if (checkRole === 'analyst' || checkRole === 'observer') {
                if (pathname.includes('configuracoes')) {
                    return 6
                }

                if (pathname.includes('valores')) {
                    return 5
                }

                if (pathname.includes('relatorio')) {
                    return 4
                }

                if (pathname.includes('historico')) {
                    return 3
                }

                if (pathname.includes('membros')) {
                    return 2
                }

                if (pathname.includes('/escalas')) {
                    return 1
                }

                if (pathname.includes('/grupo/')) {
                    return 0
                }
            } else {
                if (pathname.includes('configuracoes')) {
                    return 4
                }

                if (pathname.includes('valores')) {
                    return 3
                }

                if (pathname.includes('historico')) {
                    return 2
                }

                if (pathname.includes('membros')) {
                    return 1
                }

                if (pathname.includes('/grupo/')) {
                    return 0
                }
            }
        }
    }, [location, is_admin, checkRole])

    const menuItems = useMemo(() => {
        let items = []
        if (is_admin) {
            items = [
                { title: t('calendar'), icon: 'Calendar', router: `/grupo/${id}` },
                { title: t('schedules'), icon: 'Edit', router: `/grupo/${id}/escalas` },
                { title: t('members'), icon: 'Users', counter: total_users, router: `/grupo/${id}/membros` },
                { title: t('historic'), icon: 'Layers', router: `/grupo/${id}/historico` },
                { title: t('report'), icon: 'Clipboard', router: `/grupo/${id}/relatorio`, beta: true },
                // { title: t('values'), icon: 'DollarSign', router: `/grupo/${id}/valores` },
                { title: t('settings'), icon: 'Settings', router: `/grupo/${id}/configuracoes` },
            ]
        } else if (checkRole === 'observer') {
            items = [
                { title: t('calendar'), icon: 'Calendar', router: `/grupo/${id}` },
                { title: t('schedules'), icon: 'Edit', router: `/grupo/${id}/escalas` },
                { title: t('members'), icon: 'Users', counter: total_users, router: `/grupo/${id}/membros` },

                //{ title: t('values'), icon: 'DollarSign', router: `/grupo/${id}/valores` },
                { title: t('settings'), icon: 'Settings', router: `/grupo/${id}/configuracoes` },
            ]
        } else if (checkRole === 'analyst') {
            items = [
                { title: t('calendar'), icon: 'Calendar', router: `/grupo/${id}` },
                { title: t('schedules'), icon: 'Edit', router: `/grupo/${id}/escalas` },
                { title: t('members'), icon: 'Users', counter: total_users, router: `/grupo/${id}/membros` },
                { title: t('historic'), icon: 'Layers', router: `/grupo/${id}/historico` },
                { title: t('report'), icon: 'Clipboard', router: `/grupo/${id}/relatorio`, beta: true },
                //{ title: t('values'), icon: 'DollarSign', router: `/grupo/${id}/valores` },
                { title: t('settings'), icon: 'Settings', router: `/grupo/${id}/configuracoes` },
            ]
        } else {
            items = [
                { title: t('calendar'), icon: 'Calendar', router: `/grupo/${id}` },

                { title: t('members'), icon: 'Users', counter: total_users, router: `/grupo/${id}/membros` },
                { title: t('historic'), icon: 'Layers', router: `/grupo/${id}/historico` },
                //{ title: t('values'), icon: 'DollarSign', router: `/grupo/${id}/valores` },
                { title: t('settings'), icon: 'Settings', router: `/grupo/${id}/configuracoes` },
            ]
        }
        return items
    }, [is_admin, checkRole, t, id, total_users])

    return (
        <SectionControl
            contentControl={
                <>
                    <HeaderGroup />
                    <Menu
                        color={color && `#${color}`}
                        activeIndex={activeIndex}
                        items={menuItems}
                        footerComponent={
                            <>
                                <TitleBar title={t('management')} />
                                <div style={{ padding: 10 }}>
                                    {!!manager && (
                                        <ItemMember
                                            size={40}
                                            userId={manager.id}
                                            name={manager.name}
                                            description={manager.phone}
                                            photo={manager.photo}
                                            badge={t('manager')}
                                        />
                                    )}
                                    {!!assists &&
                                        assists.map(assist => (
                                            <ItemMember
                                                key={assist.id}
                                                size={40}
                                                userId={assist.id}
                                                name={assist.name}
                                                description={assist.phone}
                                                photo={assist.photo}
                                                badge={t('assist')}
                                            />
                                        ))}
                                </div>
                            </>
                        }
                    />
                </>
            }
        >
            <Switch>
                <Route exact path="/grupo/:group_id" component={GroupDetails} />
                <Route path="/grupo/:group_id/membros" component={GroupMembers} />
                <Route path="/grupo/:group_id/procurar-membros" component={SearchMembers} />
                <Route path="/grupo/:group_id/escalas" component={GroupSchedule} />
                <Route path="/grupo/:group_id/historico" component={GroupHistoric} />
                <Route path="/grupo/:group_id/relatorio" component={GroupReports} />
                {/* <Route path="/grupo/:group_id/valores" component={GroupValues} /> */}
                <Route path="/grupo/:group_id/configuracoes" component={GroupSettings} />
            </Switch>
        </SectionControl>
    )
}

export default memo(SectionCreateGroup)
