import React, { memo } from 'react'

import Avatar from '#/components/avatar/avatar'
import Badge from '#/components/badge/badge'
import Button from '#/components/button/button'

import { Close, Container, ContainerInfo, Description, Menu, Name } from './item-member.styled'

function ItemMember({
    name,
    description,
    council,
    photo,
    isInvide,
    isRemovable,
    hasMenu,
    onInvide,
    userId,
    onRemove,
    onMenuClick,
    size,
    badge,
    clickAllArea,
}) {
    return (
        <Container className="item-member" clickAllArea={clickAllArea}>
            <Avatar userId={userId} size={size} source={photo} name={name} />
            <ContainerInfo>
                {name && <Name>{name}</Name>}
                {description && <Description>{description}</Description>}
                {council && <Description>{council}</Description>}
            </ContainerInfo>
            {!!badge && <Badge title={badge} />}
            {!!isInvide && <Button title="Adicionar" dimension="auto" onClick={onInvide} />}
            {!!hasMenu && <Menu onClick={onMenuClick} />}
            {!!isRemovable && <Close onClick={onRemove} />}
        </Container>
    )
}

export default memo(ItemMember)
