import styled from 'styled-components'

import { Feather } from '#/components/icons'

const ContainerGroup = styled.div`
    background-color: ${({ theme }) => theme.colors.backgroundAccent};
    padding: 16px;
`

const Segment = styled.div`
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
`

const SegmentTitle = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 18px;
`

const ContainerCoworkers = styled.div`
    width: 100%;
    display: flex;
    overflow-y: none;
    overflow-x: auto;
`

const ContainerAvatar = styled.div`
    padding: 5px;
`

const ContainerCallToAction = styled.div`
    width: 100%;
    padding: 16px 8px;
    background-color: ${({ theme }) => theme.colors.primary + '20'};
    overflow: hidden;
    border-radius: 8px;
    margin-top: 20px;
`

const Message = styled.div`
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
`

const ContainerStores = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 16px;

    .store-icon {
        margin-right: 0.625rem;
        width: 1.8rem;
        line-height: 1;
    }
    .store-link {
        background-color: ${({ theme }) => theme.colors.primary + '20'};
        color: ${({ theme }) => theme.colors.text};
        padding: 0.5rem 0.8rem;
        display: -webkit-inline-box;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        width: fit-content;
        margin: 0 2%;
        border-radius: 0.625rem;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        text-decoration: none;
        text-align: left;
    }

    .store-head {
        font-size: 9px;
        margin-bottom: 0;
        line-height: 1.5;
    }

    .store-title {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1;
    }
`

const BannerWarning = styled.div`
    display: flex;
    background-color: ${({ theme }) => `${theme.colors.yellow}1f`};
    border-color: ${({ theme }) => theme.colors.yellow};
    border-width: ${({ theme }) => theme.sizes.borderWidth}px;
    border-radius: ${({ theme }) => theme.sizes.radius}px;
    padding: 4px 16px 16px 16px;
    width: 100%;
`
const IconWarning = styled(Feather).attrs({ name: 'Mail' })`
    color: ${({ theme }) => theme.colors.yellow};
    font-size: 25px;
`

const TextWarning = styled.div`
    flex: 1;
    color: ${({ theme }) => theme.colors.text};
    font-weight: 200;
    font-size: 13.5px;
    line-height: 20px;
    padding-left: 10px;
`
const Bold = styled.div`
    font-weight: bold;
    cursor: pointer;
`

export {
    BannerWarning,
    Bold,
    ContainerAvatar,
    ContainerCallToAction,
    ContainerCoworkers,
    ContainerGroup,
    ContainerStores,
    IconWarning,
    Message,
    Segment,
    SegmentTitle,
    TextWarning,
}
