import { useFormik } from 'formik'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { GroupAPI } from '#/api'
import { ButtonText, Input, ItemWorkingTime, List } from '#/components'
import { useLoadGroup } from '#/hooks'
import { AlertError } from '#/modals'
import { updateGroup } from '#/redux/reducers/group'
import { REGEX } from '#/utils'

import { ContentContainer } from '../shared'
import { Container, ContainerForm } from './create-working-time.styled'
function CreateWorkingTime() {
    const group_id = useSelector(state => state.group?.id)
    const has_workingtime = useSelector(state => state.group?.has_workingtime)
    const alertError = useRef()
    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation()

    useLoadGroup()

    const [workingTimes, setWorkingTimes] = useState([])
    const [workingTimeSelected, setWorkingTimeSelected] = useState(null)
    const [loading, setLoading] = useState(false)
    const {
        isValid,
        dirty,
        isSubmitting,
        values,
        errors,
        setValues,
        touched,
        handleSubmit,
        setFieldValue,
        handleBlur,
    } = useFormik({
        initialValues: { label: '', start_hour: '07:00', end_hour: '' },
        validationSchema: Yup.object().shape({
            label: Yup.string()
                .min(1, t('input.label.min'))
                .max(3, t('input.label.max'))
                .trim()
                .required(t('input.label.required')),
            start_hour: Yup.string()
                .required(t('input.start_hour.required'))
                .matches(REGEX.validateHours, t('input.start_hour.invalid')),
            end_hour: Yup.string()
                .required(t('input.end_hour.required'))
                .matches(REGEX.validateHours, t('input.end_hour.invalid')),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                if (!workingTimeSelected) {
                    const result = await GroupAPI.createWorkingTimes(group_id, values)
                    setWorkingTimes([...workingTimes, result])
                    if (!has_workingtime) {
                        dispatch(updateGroup({ has_workingtime: true }))
                    }
                } else {
                    const result = await GroupAPI.updateWorkingTimes(group_id, workingTimeSelected.id, values)
                    setWorkingTimes(
                        workingTimes.map(item => {
                            if (item.id === workingTimeSelected.id) {
                                return result
                            }
                            return item
                        }),
                    )
                    setWorkingTimeSelected(null)
                }
                resetForm()
            } catch ({ message }) {
                if (alertError.current) {
                    alertError.current.show({ message })
                }
            }
        },
    })

    useEffect(() => {
        if (workingTimeSelected) {
            setValues({
                label: workingTimeSelected.label,
                start_hour: workingTimeSelected.start_hour,
                end_hour: workingTimeSelected.end_hour,
            })
        } else {
            setValues({
                label: '',
                start_hour: '07:00',
                end_hour: '',
            })
        }
    }, [workingTimeSelected, setValues])

    const _deleteWorkingTime = useCallback(
        async workingTimeID => {
            if (workingTimeID) {
                try {
                    setLoading(true)
                    await GroupAPI.removeWorkingTimes(group_id, workingTimeID)
                    setWorkingTimes(workingTimes.filter(item => item.id !== workingTimeID))
                } catch ({ message }) {
                    if (alertError.current) {
                        alertError.current.show({ message })
                    }
                } finally {
                    setLoading(false)
                }
            }
        },
        [workingTimes, group_id, alertError],
    )

    const _loadWordkingTimes = useCallback(async () => {
        try {
            const result = await GroupAPI.listWorkingTimes(group_id)
            setWorkingTimes(result.items)
        } catch (error) {}
    }, [group_id])

    useEffect(() => {
        if (group_id) {
            _loadWordkingTimes()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group_id])

    return (
        <>
            <ContentContainer
                title={t('create-working-time.title')}
                warning={t('create-working-time.warning')}
                importData
                notNow
                loading={isSubmitting || loading}
                onNextClick={() =>
                    // history.push(
                    //     workingTimes.length > 0
                    //         ? `/criar-grupo/${group_id}/criar-valores`
                    //         : `/criar-grupo/${group_id}/adicionar-membros`,
                    // )
                    history.push(`/criar-grupo/${group_id}/adicionar-membros`)
                }
                onSkipClick={() =>
                    // history.push(
                    //     workingTimes.length > 0
                    //         ? `/criar-grupo/${group_id}/criar-valores`
                    //         : `/criar-grupo/${group_id}/adicionar-membros`,
                    // )
                    history.push(`/criar-grupo/${group_id}/adicionar-membros`)
                }
            >
                <Container>
                    <ContainerForm>
                        <Input
                            required
                            className="input-label"
                            name="label"
                            maxLength={3}
                            label={t('input.label.label')}
                            value={values.label}
                            onChangeText={e => setFieldValue('label', (e || '').toUpperCase())}
                            onBlur={handleBlur('label')}
                            errorMessage={touched.label && errors.label}
                            placeholder="EX: MT"
                        />
                        <Input
                            required
                            name="start_hour"
                            label={t('input.start_hour.label')}
                            value={values.start_hour}
                            mask="99:99"
                            onChangeText={e => setFieldValue('start_hour', e)}
                            onBlur={handleBlur('start_hour')}
                            errorMessage={touched.start_hour && errors.start_hour}
                            placeholder="00:00"
                            disabled={!!workingTimeSelected}
                        />
                        <Input
                            required
                            label={t('input.end_hour.label')}
                            value={values.end_hour}
                            mask="99:99"
                            onChangeText={e => setFieldValue('end_hour', e)}
                            onBlur={handleBlur('end_hour')}
                            errorMessage={touched.end_hour && errors.end_hour}
                            placeholder="00:00"
                            disabled={!!workingTimeSelected}
                        />
                    </ContainerForm>
                    <ButtonText
                        disabled={!isValid || !dirty || isSubmitting}
                        iconName={workingTimeSelected ? 'Edit' : 'Plus'}
                        onClick={handleSubmit}
                    >
                        {workingTimeSelected ? t('update-shift') : t('add-shift')}
                    </ButtonText>

                    <List
                        data={workingTimes}
                        onRenderItem={(item, i) => (
                            <ItemWorkingTime
                                workingTime={item}
                                key={`time_${i}`}
                                selectedWorkingTime={workingTimeSelected}
                                onItemClick={e => setWorkingTimeSelected(e)}
                                onDelete={_deleteWorkingTime}
                            />
                        )}
                    />
                </Container>
            </ContentContainer>
            <AlertError ref={alertError} />
        </>
    )
}

export default memo(CreateWorkingTime)
