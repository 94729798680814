import styled from 'styled-components'

const Container = styled.div`
    color: ${({ theme, type }) => (type ? theme.colors[type] : theme.colors.primary)};
    &:hover {
        box-shadow: 0px 0px 0px 0px !important;
        text-decoration: underline;
    }
    cursor: pointer;
    margin-left: 4px;
    margin-right: 4px;
`

export { Container }
