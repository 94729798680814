import classNames from 'classnames'
import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef } from 'react'

import { CallActionText, ErrorMessage, Label } from '../shared'
import { Container, CustomTextArea } from './text-area.styled'

function TextArea(
    {
        label,
        errorMessage,
        placeholder,
        onChangeText,
        onBlur,
        onFocus,
        value,
        disabled,
        required,
        callActionText,
        callActionClick,
        className,
        ...props
    },
    ref,
) {
    const inputRef = useRef()

    useImperativeHandle(ref, () => ({
        focus: () => {
            try {
                inputRef.current && inputRef.current.focus()
            } catch (error) {}
        },
    }))

    const _handleChange = useCallback(
        event => {
            if (onChangeText) {
                onChangeText(event.target.value)
            }
        },
        [onChangeText],
    )

    return (
        <Container className={classNames('input', className)}>
            {!!label && (
                <Label htmlFor="" required={required}>
                    {label}:
                </Label>
            )}

            <CustomTextArea
                ref={inputRef}
                className="input-field"
                type="text"
                placeholder={placeholder}
                value={value === null ? '' : value}
                onChange={_handleChange}
                onBlur={onBlur}
                onFocus={onFocus}
                disabled={disabled}
                {...props}
            />

            {!!callActionText && <CallActionText onClick={callActionClick}>{callActionText}</CallActionText>}
            {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Container>
    )
}

export default memo(forwardRef(TextArea))
