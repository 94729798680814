import { useFormik } from 'formik'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { GroupAPI } from '#/api'
import { ButtonText, Input, List } from '#/components'
import { ItemWorkingTime } from '#/components/items'
import { Loading } from '#/modals'
import { updateGroup } from '#/redux/reducers/group'
import { REGEX } from '#/utils'

import { BackIcon, Container, ContainerTitle, Title } from '../shared'
import { ContainerButtons, ContainerForm } from './edit-working-time.styled'

function EditWorkingTime() {
    const group_id = useSelector(state => state.group?.id)
    const has_workingtime = useSelector(state => state.group?.has_workingtime)
    const alertError = useRef()
    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation()

    const [workingTimes, setWorkingTimes] = useState([])
    const [workingTimeSelected, setWorkingTimeSelected] = useState(null)
    const [loading, setLoading] = useState(true)
    const [action, setAction] = useState('list')

    const {
        isValid,
        dirty,
        isSubmitting,
        values,
        errors,
        setValues,
        resetForm,
        touched,
        handleSubmit,
        setFieldValue,
        handleBlur,
    } = useFormik({
        initialValues: { label: '', start_hour: '07:00', end_hour: '' },
        validationSchema: Yup.object().shape({
            label: Yup.string()
                .min(1, t('input.label.min'))
                .max(3, t('input.label.max'))
                .trim()
                .required(t('input.label.required')),
            start_hour: Yup.string()
                .required(t('input.start_hour.required'))
                .matches(REGEX.validateHours, t('input.start_hour.invalid')),
            end_hour: Yup.string()
                .required(t('input.end_hour.required'))
                .matches(REGEX.validateHours, t('input.end_hour.invalid')),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                if (!workingTimeSelected) {
                    const result = await GroupAPI.createWorkingTimes(group_id, values)
                    setWorkingTimes([...workingTimes, result])
                    if (!has_workingtime) {
                        dispatch(updateGroup({ has_workingtime: true }))
                    }
                } else {
                    const result = await GroupAPI.updateWorkingTimes(group_id, workingTimeSelected.id, values)
                    setWorkingTimes(
                        workingTimes.map(item => {
                            if (item.id === workingTimeSelected.id) {
                                return result
                            }
                            return item
                        }),
                    )
                    setWorkingTimeSelected(null)
                }
                setAction('list')
                resetForm()
            } catch ({ message }) {
                if (alertError.current) {
                    alertError.current.show({ message })
                }
            }
        },
    })

    const _goBack = useCallback(() => {
        if (group_id) {
            history.replace(`/grupo/${group_id}/configuracoes`)
        }
    }, [history, group_id])

    const _cancell = useCallback(() => {
        setWorkingTimeSelected(null)
        setAction('list')
        resetForm()
    }, [resetForm])

    const _deleteWorkingTime = useCallback(
        async workingTimeID => {
            if (workingTimeID) {
                try {
                    setLoading(true)
                    await GroupAPI.removeWorkingTimes(group_id, workingTimeID)
                    setWorkingTimes(workingTimes.filter(item => item.id !== workingTimeID))
                } catch ({ message }) {
                    if (alertError.current) {
                        alertError.current.show({ message })
                    }
                } finally {
                    setLoading(false)
                }
            }
        },
        [workingTimes, group_id, alertError],
    )

    const _loadWordkingTimes = useCallback(async () => {
        try {
            const result = await GroupAPI.listWorkingTimes(group_id)
            setWorkingTimes(result.items)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }, [group_id])

    useEffect(() => {
        if (!loading && workingTimes.length < 1) {
            setAction('add')
        } else {
            setAction('list')
        }
    }, [workingTimes, loading])

    useEffect(() => {
        if (workingTimeSelected) {
            setAction('update')
            setValues({
                label: workingTimeSelected.label,
                start_hour: workingTimeSelected.start_hour,
                end_hour: workingTimeSelected.end_hour,
            })
        } else {
            setValues({
                label: '',
                start_hour: '07:00',
                end_hour: '',
            })
        }
    }, [workingTimeSelected, setValues])

    useEffect(() => {
        if (group_id) {
            _loadWordkingTimes()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group_id])

    return (
        <>
            <Container>
                <ContainerTitle>
                    <BackIcon onClick={_goBack} />
                    <Title>{t('group-settings.settings.working-time')}</Title>
                </ContainerTitle>
                {action !== 'list' && (
                    <>
                        <ContainerForm>
                            <Input
                                required
                                className="input-label"
                                name="label"
                                maxLength={3}
                                label={t('input.label.label')}
                                value={values.label}
                                onChangeText={e => setFieldValue('label', (e || '').toUpperCase())}
                                onBlur={handleBlur('label')}
                                errorMessage={touched.label && errors.label}
                                placeholder="EX: MT"
                            />
                            <Input
                                required
                                name="start_hour"
                                label={t('input.start_hour.label')}
                                value={values.start_hour}
                                mask="99:99"
                                onChangeText={e => setFieldValue('start_hour', e)}
                                onBlur={handleBlur('start_hour')}
                                errorMessage={touched.start_hour && errors.start_hour}
                                placeholder="00:00"
                                disabled={!!workingTimeSelected}
                            />
                            <Input
                                required
                                label={t('input.end_hour.label')}
                                value={values.end_hour}
                                mask="99:99"
                                onChangeText={e => setFieldValue('end_hour', e)}
                                onBlur={handleBlur('end_hour')}
                                errorMessage={touched.end_hour && errors.end_hour}
                                placeholder="00:00"
                                disabled={!!workingTimeSelected}
                            />
                        </ContainerForm>
                    </>
                )}
                <ContainerButtons>
                    {action !== 'list' && (
                        <ButtonText onClick={_cancell} type="danger" iconName="X">
                            {t('cancel')}
                        </ButtonText>
                    )}
                    <ButtonText
                        disabled={action === 'add' ? !isValid || !dirty || isSubmitting : false}
                        iconName={workingTimeSelected ? 'Edit' : 'Plus'}
                        onClick={['add', 'update'].includes(action) ? handleSubmit : () => setAction('add')}
                    >
                        {workingTimeSelected ? t('update-shift') : t('add-shift')}
                    </ButtonText>
                </ContainerButtons>

                {['list', 'update'].includes(action) && (
                    <List
                        data={workingTimes}
                        onRenderItem={(item, i) => (
                            <ItemWorkingTime
                                workingTime={item}
                                key={`time_${i}`}
                                selectedWorkingTime={workingTimeSelected}
                                onItemClick={e => setWorkingTimeSelected(e)}
                                onDelete={_deleteWorkingTime}
                            />
                        )}
                    />
                )}
            </Container>
            <Loading show={loading} />
        </>
    )
}

export default memo(EditWorkingTime)
