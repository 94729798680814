import { useFormik } from 'formik'
import React, { memo, useCallback, useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { AuthAPI } from '../../../api'
import { Button, Input } from '../../../components'
import AlertError from '../../alert-error/alert-error'
import Alert from '../../alert/alert'
import { RecoveryContext } from '../shared'
import { Logo, TextButton, Title } from './new-password.styled'
const NewPassword = () => {
    const { changeProps, recoveryProps, finished } = useContext(RecoveryContext)
    const alertErrorRef = useRef()
    const alertRef = useRef()

    const { t } = useTranslation()

    const [showButtonReset, setShowButtonReset] = useState(false)
    const { isSubmitting, values, errors, touched, handleSubmit, setFieldValue, handleBlur } = useFormik({
        initialValues: { password: '', password_confirmation: '' },
        validationSchema: Yup.object().shape({
            password: Yup.string()
                .trim()
                .min(8, t('input.password.min'))
                .required(t('input.password.required')),
            password_confirmation: Yup.string()
                .trim()
                .min(8, t('input.password.min'))
                .required(t('input.password_confirmation.required'))
                .oneOf([Yup.ref('password'), null], t('input.password_confirmation.not-corresponding')),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const { message } = await AuthAPI.resetPassword({
                    ...values,
                    email: recoveryProps.email,
                    token: recoveryProps.code,
                })
                _showAlert(t('recovery.new-password.complete'), message, () => finished())
            } catch ({ message, status }) {
                _showAlertErro(message)
                if (status === 422) {
                    setShowButtonReset(true)
                }
            } finally {
                setSubmitting(false)
            }
        },
    })

    const _showAlert = useCallback(
        (title, message, onPress) => {
            try {
                alertRef.current && alertRef.current.show({ title, message, onPress })
            } catch (error) {}
        },
        [alertRef],
    )
    const _showAlertErro = useCallback(
        message => {
            try {
                alertErrorRef.current && alertErrorRef.current.show({ message })
            } catch (error) {}
        },
        [alertErrorRef],
    )

    return (
        <>
            <div>
                <Logo />
                <Title>{t('recovery.new-password.title')}</Title>

                <Input
                    label={t('input.create-password.label')}
                    name="password"
                    type="password"
                    value={values.password}
                    onChangeText={e => setFieldValue('password', e)}
                    onBlur={handleBlur('password')}
                    errorMessage={touched.password && errors.password}
                />
                <Input
                    label={t('input.password_confirmation.label')}
                    name="password_confirmation"
                    type="password"
                    value={values.password_confirmation}
                    onChangeText={e => setFieldValue('password_confirmation', e)}
                    onBlur={handleBlur('password_confirmation')}
                    errorMessage={touched.password_confirmation && errors.password_confirmation}
                />
                <Button
                    title={t('recovery.code.reset-password')}
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                />
                {showButtonReset && (
                    <TextButton onClick={() => changeProps({ step: 3 })}>
                        {t('recovery.new-password.code-again')}
                    </TextButton>
                )}
            </div>
            <Alert ref={alertRef} />
            <AlertError ref={alertErrorRef} />
        </>
    )
}

export default memo(NewPassword)
