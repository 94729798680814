import styled from 'styled-components'

import { Feather } from '#/components'
const ContainerTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    .button-add {
        margin-right: 10px;
    }
    margin-bottom: 10px;
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 16px;
    flex: 1;
`

const ContentCard = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const BackIcon = styled(Feather).attrs({ name: 'ArrowLeft' })`
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    margin-right: 4px;
`

export { BackIcon, ContainerTitle, ContentCard, Title }
