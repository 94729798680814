import styled from 'styled-components'

const Container = styled.div`
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .react-datepicker {
        border: none;
        -webkit-box-shadow: 0px 19px 50px -21px rgba(0, 0, 0, 0.43);
        -moz-box-shadow: 0px 19px 50px -21px rgba(0, 0, 0, 0.43);
        box-shadow: 0px 19px 50px -21px rgba(0, 0, 0, 0.43);
        .react-datepicker__triangle {
            border-bottom-color: ${({ theme }) => theme.colors.primary} !important;
        }
        .react-datepicker__month-container {
            background-color: ${({ theme }) => theme.colors.background};
            .react-datepicker__header {
                background-color: ${({ theme }) => theme.colors.primary};
                .react-datepicker__current-month {
                    color: #fff;
                }

                .react-datepicker__day-name {
                    color: #fff;
                }
            }

            .react-datepicker__day {
                color: ${({ theme }) => theme.colors.text};
                &:hover {
                    background-color: ${({ theme }) => theme.colors.backgroundOpacity};
                }
            }
            .react-datepicker__day--today {
                color: #fff !important;
            }
            .react-datepicker__day--selected {
                color: #fff !important;
            }
            .react-datepicker__day--keyboard-selected {
                background-color: ${({ theme }) => theme.colors.primary};
            }
            .react-datepicker__day--disabled {
                color: ${({ theme }) => theme.colors.text};
                opacity: 0.3;
            }
        }
    }
`

const CustomInput = styled.input`
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.input};
    border: 0;
    border-radius: ${({ theme }) => theme.sizes.radius}px;
    font-size: 14px;
    height: ${({ theme }) => theme.sizes.formHeight}px;
    outline-width: 0;
    padding-left: 8px;
    padding-right: 8px;

    &::placeholder {
        color: ${({ theme }) => theme.colors.placeholder};
    }
`

export { Container, CustomInput }
