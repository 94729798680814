import styled from 'styled-components'

const ContianerSettings = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    .button {
        margin-bottom: 20px;
    }
    @media (max-width: 992px) {
        flex-direction: column;
        align-items: center;
        .button {
            margin-top: 10px;
            width: 100%;
        }
    }
`

const ContainerSelect = styled.div`
    flex: 1;
    margin-right: 10px;
    @media (max-width: 992px) {
        margin-right: 0px;
    }
`

const ContainerItemTransaction = styled.div`
    margin: 14px 0px;
`

export { ContianerSettings, ContainerSelect, ContainerItemTransaction }
