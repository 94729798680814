import styled from 'styled-components'

const Container = styled.div`
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`
const CustomInput = styled.input`
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.input};
    border: 0;
    border-radius: ${({ theme }) => theme.sizes.radius}px;
    font-size: 14px;
    height: ${({ theme }) => theme.sizes.formHeight}px;
    outline-width: 0;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    &::placeholder {
        color: ${({ theme }) => theme.colors.placeholder};
    }
`

export { Container, CustomInput }
