import styled, { css } from 'styled-components'

const Container = styled.div`
    position: relative;
    min-height: 200px;
    padding: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;

    .button {
        margin-top: 20px;
    }
`
const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    font-size: 18px;
`
const Message = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    text-align: center;
    margin-bottom: 20px;
`

const Bold = styled.span`
    font-weight: bold;
    ${({ click }) =>
        click &&
        css`
            cursor: pointer;
            text-decoration: underline;
            color: ${({ theme }) => theme.colors.primary};
        `}
`

const Image = styled.img`
    width: 100%;
    height: 180px;
    object-fit: contain;
    margin: 20px 0px;
`

export { Container, Title, Bold, Image, Message }
