import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useLoadGroup } from '../../../../../hooks'
import { ContentContainer } from '../shared'

function CreatedGroup() {
    useLoadGroup()

    const history = useHistory()
    const group_id = useSelector(state => state.group?.id)

    return (
        <>
            <ContentContainer
                onNextClick={() => history.push(`/criar-grupo/${group_id}/adicionar-membros`)}
                onSkipClick={() => history.push(`/criar-grupo/${group_id}/adicionar-membros`)}
            />
        </>
    )
}

export default memo(CreatedGroup)
