import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Feather } from '../icons'
import { Container } from './toggle-language.styled'
function ToggleLanguage() {
    const [isEN, setEN] = useState(false)
    const { i18n } = useTranslation()

    const _toggle = event => {
        if (isEN) {
            setEN(false)
            i18n.changeLanguage('pt-BR')
        } else {
            setEN(true)
            i18n.changeLanguage('en')
        }
    }

    return (
        <Container onClick={_toggle}>
            <Feather name="Globe" color="#fff" />
        </Container>
    )
}

export default memo(ToggleLanguage)
