import classesNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Badge from '../badge/badge'
import {
    ColorGroup,
    Container,
    ContainerInfo,
    ContainerTitle,
    GroupName,
    InstitutionName,
} from './info-group.styled'
function InfoGroup({ group, className, hiddenColor }) {
    const { t } = useTranslation()
    if (!group) {
        return null
    }

    const { name, institution, color, role } = group

    return (
        <Container className={classesNames('info-group', className)}>
            {!hiddenColor && <ColorGroup color={color ? `#${color}` : null} />}
            <ContainerInfo>
                <ContainerTitle>
                    {name && <GroupName>{name}</GroupName>}

                    {role !== null ? <Badge title={t(role?.name.toLowerCase())} /> : undefined}
                </ContainerTitle>
                {!!institution && <InstitutionName>{institution?.name}</InstitutionName>}
            </ContainerInfo>
        </Container>
    )
}

export default InfoGroup
