import styled from 'styled-components'

const ContainerOption = styled.div``

const Option = styled.div`
    font-size: 18px;
    padding: 8px;
    color: ${({ theme }) => theme.colors.primary};
    border-bottom: 1px ${({ theme }) => theme.colors.backgroundContrast} solid;
    :last-child {
        border: none;
    }
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.hover};
    }
`

export { ContainerOption, Option }
