import styled from 'styled-components'

const Container = styled.div`
    position: relative;
`

const Amount = styled.div`
    position: absolute;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    right: 5%;
    top: 5%;
    overflow: hidden;
    background-color: ${({ theme, color }) => (color ? color : theme.colors.text)};
    padding: 1px;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1.5px solid ${({ theme }) => theme.colors.background};
`

export { Container, Amount }
