import styled, { css } from 'styled-components'

import SourceLogo from '../../../assets/images/logo-negative.svg'
const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button {
        margin-top: 20px;

        width: 180px;
    }
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
`

const Message = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    margin: 20px 0px;
`

const ContentHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
`

const Logo = styled.img.attrs({ src: SourceLogo })`
    height: 100%;
    object-fit: contain;
`

const Bold = styled.span`
    font-weight: bold;
    ${({ primary }) =>
        primary &&
        css`
            color: ${({ theme }) => theme.colors.primary};
        `};
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'auto')};
`

const Label = styled.div`
    color: #fff;
`

export { Container, Title, Message, ContentHeader, Label, Logo, Bold }
