import { format, parseISO } from 'date-fns'
import localePT from 'date-fns/locale/pt-BR'
import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from 'react'

import { UserAPI } from '#/api'
import { SelectDay } from '#/components'
import { ItemShift } from '#/components/items'
import { Loading } from '#/modals'

import { ShiftDetails } from '../shared'
import { Box, Close, Container, ContainerTitle, Title } from './list-my-shifts.styled'
function ListMyShifts(props, ref) {
    const [visible, setVisible] = useState(false)
    const [onClose, setOnClose] = useState(null)
    const [date, setDate] = useState(null)
    const [shifts, setShifts] = useState([])
    const [shiftSelected, setShiftSelected] = useState(null)

    const [loading, setLoading] = useState(true)

    const _onClick = useCallback(() => {
        if (onClose) {
            onClose()
        }
        setShiftSelected(null)
        setVisible(false)
        setDate(null)
        setShifts([])
    }, [onClose])

    const _loadShifts = useCallback(async date => {
        if (!date) {
            return null
        }
        try {
            setLoading(true)

            const response = await UserAPI.getDayShifts(date)

            setShifts(response.items)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if (date) {
            _loadShifts(date)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date])

    useImperativeHandle(ref, () => ({
        show: (props = {}) => {
            const { date, onClose } = props
            if (onClose) {
                setOnClose(() => onClose)
            }
            setDate(date)
            setVisible(true)
        },
    }))

    if (!visible) {
        return null
    }
    return (
        <Container className="w3-animate-opacity">
            <div style={{ position: 'absolute', height: '100%', width: '100%' }} onClick={_onClick} />
            <Box className="w3-animate-right">
                <ContainerTitle>
                    <Title>
                        {format(parseISO(date), "EEEE, dd 'de' MMMM", {
                            locale: localePT,
                        }).toUpperCase()}
                    </Title>
                    <Close onClick={_onClick} />
                </ContainerTitle>
                {shiftSelected ? (
                    <ShiftDetails shift={shiftSelected} />
                ) : (
                    <>
                        {date && (
                            <>
                                <SelectDay initialDate={date} onSelectedDay={e => setDate(e)} />
                            </>
                        )}

                        {shifts.map((item, i) => (
                            <ItemShift i={`shift_${i}`} shift={item} onClick={() => setShiftSelected(item)} />
                        ))}
                    </>
                )}
                <Loading show={loading} />
            </Box>
        </Container>
    )
}

export default memo(forwardRef(ListMyShifts))
