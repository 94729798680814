const { default: styled } = require('styled-components')

const Container = styled.div`
    display: flex;
    flex: 1;
    .section-container {
        ::-webkit-scrollbar {
            width: 0px; /* Remove scrollbar space */
            background: transparent; /* Optional: just make scrollbar invisible */
        }
    }
    .block-container {
        max-width: 390px;
    }
`
const ContainerFeed = styled.div`
    display: flex;
    .list {
        flex: 1;
    }
`
const ContainerMySchedule = styled.div`
    max-width: 390px;
    margin-left: 16px;
`

export { Container, ContainerFeed, ContainerMySchedule }
