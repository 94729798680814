import { useFormik } from 'formik'
import React, { memo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { AccountsAPI } from '#/api'
import { Button, ContentSection } from '#/components'
import { Input } from '#/components/forms'
import { Alert, AlertError } from '#/modals'

import { SettingsContent } from '../shared'
import { ContainerButton, ContainerForm } from './password.styled'

function Password() {
    const { t } = useTranslation()
    const alertErrorRef = useRef()
    const alertRef = useRef()

    const { isSubmitting, values, errors, touched, handleSubmit, setFieldValue, handleBlur } = useFormik({
        initialValues: { old_password: '', password: '', password_confirmation: '' },
        validationSchema: Yup.object().shape({
            password: Yup.string()
                .trim()
                .min(8, t('input.new_password.min'))
                .required(t('input.new_password.required')),
            password_confirmation: Yup.string()
                .trim()
                .min(8, t('input.password.min'))
                .required(t('input.password_confirmation.required'))
                .oneOf([Yup.ref('password'), null], t('input.password_confirmation.not-corresponding')),
            old_password: Yup.string()
                .trim()
                .min(8, t('input.old_password.min'))
                .required(t('input.old_password.required')),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const { message } = await AccountsAPI.update({
                    ...values,
                })
                resetForm()
                alertRef.current &&
                    alertRef.current.show({
                        title: t('recovery.new-password.complete'),
                        message,
                    })
            } catch ({ message }) {
                alertErrorRef.current && alertErrorRef.current.show({ message })
            }
        },
    })

    return (
        <SettingsContent title={t('update-password')} iconName="Key" withoutPadding>
            <ContentSection loading={isSubmitting} withoutPadding>
                <>
                    <ContainerForm>
                        <Input
                            label={t('input.old_password.label')}
                            name="old_password"
                            type="password"
                            value={values.old_password}
                            onChangeText={e => setFieldValue('old_password', e)}
                            onBlur={handleBlur('old_password')}
                            errorMessage={touched.old_password && errors.old_password}
                        />
                        <Input
                            label={t('input.new_password.label')}
                            name="password"
                            type="password"
                            value={values.password}
                            onChangeText={e => setFieldValue('password', e)}
                            onBlur={handleBlur('password')}
                            errorMessage={touched.password && errors.password}
                        />
                        <Input
                            label={t('input.password_confirmation.label')}
                            name="password_confirmation"
                            type="password"
                            value={values.password_confirmation}
                            onChangeText={e => setFieldValue('password_confirmation', e)}
                            onBlur={handleBlur('password_confirmation')}
                            errorMessage={touched.password_confirmation && errors.password_confirmation}
                        />
                    </ContainerForm>
                </>
                <ContainerButton>
                    <Button title={t('update')} onClick={handleSubmit} disabled={isSubmitting} />
                </ContainerButton>
            </ContentSection>
            <Alert ref={alertRef} />
            <AlertError ref={alertErrorRef} />
        </SettingsContent>
    )
}

export default memo(Password)
