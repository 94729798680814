import styled from 'styled-components'

const ContainerTabs = styled.div`
    width: 100%;
    display: flex;
`

const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 20px;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
    background-color: ${({ theme, active }) =>
        active ? theme.colors.primary + '40' : theme.colors.backgroundAccent};
    color: ${({ theme, active }) => (!active ? theme.colors.textLight : theme.colors.text)};
`

const Container = styled.div`
    padding: 16px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    display: ${({ active }) => (active ? 'block' : 'none')};
`

export { ContainerTabs, Tab, Container }
