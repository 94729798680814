import { replace } from 'connected-react-router'
import { format, isToday, parseISO } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { AccountsAPI, AuthAPI } from '../api'
import { ToggleLnguage, ToggleTheme } from '../components'
import { CallToMobile, Loading, ModalProfile } from '../modals'
import { updateAuth } from '../redux/reducers/auth'
import { setUser } from '../redux/reducers/user'
import { MainRoutes } from '../routes'
import { DarkTheme, DefaultTheme } from '../theme'
import { timeout, validateUserData } from '../utils'

function Main() {
    const auth = useSelector(state => state.auth)
    const theme = useSelector(state => state.theme)

    const [loadComplete, setLoadComplete] = useState(false)

    const dispatch = useDispatch()

    const _refreshToken = useCallback(async () => {
        try {
            const token = await AuthAPI.refreshToken(auth.token)
            dispatch(updateAuth({ token, last_refresh: format(new Date(), 'yyyy-MM-dd') }))
            await timeout(200)
            return token
        } catch (error) {
            return auth.token
        } finally {
            return null
        }
    }, [auth, dispatch])

    const _loadProfileAsync = useCallback(async () => {
        try {
            const result = await AccountsAPI.getAccount()
            const { status, id, name, photo, call_name, email } = result

            dispatch(updateAuth({ id, name: call_name || name, photo }))
            dispatch(setUser(result))
            if (status === 3 && email) {
                dispatch(replace('/ativar-conta'))
            } else {
                const pageRedirect = validateUserData(result)
                if (pageRedirect) {
                    // OneSignal.setSubscription(true)
                    // dispatch(setRootPage('App'))
                    dispatch(replace(pageRedirect))
                }
            }
        } catch (err) {
        } finally {
            setLoadComplete(true)
        }
    }, [dispatch])

    const _init = useCallback(async () => {
        try {
            if (auth) {
                if (auth.last_refresh && isToday(parseISO(auth.last_refresh))) {
                } else {
                    await _refreshToken()
                }
                _loadProfileAsync()
            }
        } catch (err) {
        } finally {
            setLoadComplete(true)
        }
    }, [_refreshToken, auth, _loadProfileAsync])

    useEffect(() => {
        _init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ThemeProvider theme={theme === 'dark' ? DarkTheme : DefaultTheme}>
            <>
                {loadComplete ? <MainRoutes /> : <Loading show />}
                {!process.env.NODE_ENV ||
                    (process.env.NODE_ENV === 'development' && (
                        <>
                            <ToggleLnguage />
                            <ToggleTheme />
                        </>
                    ))}
                {!!auth && isMobile && <CallToMobile />}
                <ModalProfile />
            </>
        </ThemeProvider>
    )
}

export default Main
