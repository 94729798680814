import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AppAPI } from '#/api'
import { ContentSection } from '#/components'

import { SettingsContent } from '../shared'
import { Message } from './terms.styled'
function Terms() {
    const { t } = useTranslation()
    const [terms, setTerms] = useState('')
    const [loading, setLoading] = useState(true)

    const getTermsUse = async () => {
        try {
            setLoading(true)
            const { content } = await AppAPI.getTermsUse()

            setTerms(content)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getTermsUse()
    }, [])
    return (
        <SettingsContent title={t('about')} iconName="HelpCircle" withoutPadding>
            <ContentSection loading={loading} withoutPadding>
                <Message dangerouslySetInnerHTML={{ __html: terms }} />
            </ContentSection>
        </SettingsContent>
    )
}

export default memo(Terms)
