import { createActions, createReducer } from 'reduxsauce'

export const {
    Types,
    Creators: { setAds, removeAds },
} = createActions({
    setAds: ['ads'],
    removeAds: [],
})

const INITIAL_STATE = null

const set = (state = INITIAL_STATE, action) => action.ads

const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_ADS]: set,
})

export default reducer
