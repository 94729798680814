import styled from 'styled-components'

const Container = styled.div`
    position: relative;
    .button {
        margin-top: 20px;
    }
`

const Message = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: start;
    font-size: 14px;
`

export { Container, Message }
