import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AccountsAPI } from '#/api'
import { Avatar } from '#/components'
import { TextArea } from '#/components/forms'
import { usePrevious } from '#/hooks'
import { removeProfile } from '#/redux/reducers/profile'

import Loading from '../loading/loading'
import { ModalBase } from '../shared'
import {
    ButtonWhatsApp,
    CallName,
    Container,
    ContainerInfo,
    ContainerPicture,
    Content,
    ContentCard,
    Council,
    Description,
    Icon,
    ImageWhats,
    Info,
    Name,
    Row,
    Title,
    TopBarCard,
} from './modal-profile.styled'

function ModalProfile({ onClose }) {
    const [show, setShow] = useState(false)
    const profileID = useSelector(state => state.profile_id)
    const dispatch = useDispatch()
    const [profile, setProfile] = useState(null)
    const { t } = useTranslation()
    const profileIDPrev = usePrevious(profileID)
    const [loading, setLoading] = useState(true)
    const phone = useMemo(() => {
        if (profile?.phone) {
            return 55 + (profile.phone || '').replace(/\D+/g, '')
        }
        return null
    }, [profile])
    const description = useMemo(() => {
        if (!profile) {
            return
        }
        let job = ''
        if (profile.profession?.name) {
            job = profile.profession.name
        }

        if (profile.specialization?.name) {
            job += ` - ${profile.specialization.name}`
        }

        return job
    }, [profile])

    const council = useMemo(() => {
        if (!profile) {
            return
        }
        let council = ''
        if (profile.council) {
            council = `${profile.council.id} / ${profile.council.state}`
        }

        return council
    }, [profile])

    const _loadProfile = useCallback(
        async user_id => {
            if (user_id === profileIDPrev) {
                return
            }
            try {
                setLoading(true)
                const result = await AccountsAPI.viewAccount(user_id)
                setProfile(result)
            } catch (error) {
            } finally {
                setLoading(false)
            }
        },
        [profileIDPrev],
    )

    const _onClose = useCallback(
        (refresh = false) => {
            setShow(false)
            setTimeout(() => {
                setProfile(null)
                dispatch(removeProfile())
            }, 300)
            if (onClose) {
                onClose(refresh)
            }
        },
        [onClose, dispatch],
    )

    useEffect(() => {
        if (profileID) {
            setShow(true)
            _loadProfile(profileID)
        } else {
            setShow(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileID])
    if (!show) {
        return null
    }
    return (
        <ModalBase
            withoutPadding
            showButton={false}
            title={t('profile')}
            icon={null}
            visible={show}
            headerPrimary
            onClose={_onClose}
        >
            <Container>
                {profile && (
                    <>
                        <ContentCard>
                            <TopBarCard />
                            <ContainerPicture>
                                <Avatar size={100} name={profile.name} source={profile.photo} />
                            </ContainerPicture>

                            <CallName>{profile.call_name}</CallName>
                            <Name>{profile.name}</Name>
                            <Description>{description}</Description>
                            <Council>{council}</Council>
                        </ContentCard>
                        <Content>
                            {profile.about && <TextArea value={profile.about} readOnly disabled />}

                            <Row>
                                <Title>{t('contact')}</Title>
                            </Row>
                            <ContainerInfo>
                                <Icon name="Mail" />
                                <Info>{profile.email}</Info>
                            </ContainerInfo>
                            <ContainerInfo>
                                <Icon name="Phone" />
                                <Info>{profile.phone}</Info>
                            </ContainerInfo>
                            {!!phone && (
                                <a
                                    href={`https://api.whatsapp.com/send?phone=${phone}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <ButtonWhatsApp>
                                        <ImageWhats />
                                        entre em contato
                                    </ButtonWhatsApp>
                                </a>
                            )}
                        </Content>
                    </>
                )}
                <Loading show={loading} />
            </Container>
        </ModalBase>
    )
}
export default memo(ModalProfile)
