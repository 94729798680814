import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .react-autosuggest__container {
        width: 100%;
        position: relative;
    }
    .input-field {
        &::-webkit-search-cancel-button {
            cursor: pointer;
        }
        margin-bottom: 0px;
    }

    .input-field--focused {
        outline: none;
    }

    .react-autosuggest__suggestions-container {
        display: none;
    }

    .react-autosuggest__suggestions-container--open {
        display: block;
        position: absolute;
        top: 70px;
        min-width: 280px;
        width: 100%;
        border: 1px solid #aaa;
        background-color: #fff;
        font-family: Helvetica, sans-serif;
        font-weight: 300;
        font-size: 16px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 2;
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        max-height: 300px;
        overflow-y: auto;
    }

    .react-autosuggest__suggestion {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.background};
    }

    .react-autosuggest__suggestion--highlighted {
        background-color: #ddd;
    }
`

const ContainerItem = styled.div`
    width: 100%;
    padding: 10px;
`

const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    text-transform: uppercase;
`

const SubTitle = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 14px;
`

export { Container, ContainerItem, Title, SubTitle }
