import styled from 'styled-components'

const Container = styled.div`
    flex: 1;
    display: flex;
    padding: 16px;
    max-width: 1600px;
    width: 100%;
    position: absolute;
    align-self: center;
`

const ContainerWidget = styled.div`
    height: calc(100vh - 92px);
    overflow: hidden;
    width: 360px;
    display: flex;
    flex-direction: column;
    .card {
        flex: 1;
        overflow-y: auto;
    }
`

const ContainerSection = styled.div`
    height: calc(100vh - 92px);
    overflow: hidden;
    flex: 1;
    padding-left: 10px;
    display: flex;
`

export { Container, ContainerSection, ContainerWidget }
