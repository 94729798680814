import React, { memo, useCallback, useEffect, useState } from 'react'
import Autosuggest from 'react-autosuggest'

import Input from '../input/input'
import { Container, ContainerItem, SubTitle, Title } from './auto-complete.styled'

function AutoComplete({
    label,
    errorMessage,
    placeholder,
    onSelected,
    className,
    value,
    callActionText,
    callActionClick,
    stringValue,
    required,
    onSearching,
}) {
    const [suggestions, setSuggestions] = useState([])
    const [selected, setSelected] = useState(null)
    const [searchValue, setSearchValue] = useState(null)

    useEffect(() => {
        let timeout
        if (searchValue) {
            timeout = setTimeout(async () => {
                let data
                if (onSearching) {
                    data = await onSearching(searchValue)
                }
                setSuggestions(data || [])
            }, 500)
        } else {
            setSuggestions([])
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [onSearching, searchValue])

    const _onSuggestionsFetchRequested = useCallback(({ value }) => {
        setSearchValue(value)
    }, [])

    const _onSuggestionsClearRequested = useCallback(() => {
        setSuggestions([])
    }, [])

    const _getSuggestionValue = useCallback(suggestion => {
        return suggestion.title
    }, [])

    const _onSuggestionSelected = useCallback(
        (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
            if (onSelected) {
                onSelected(suggestion)
            }
        },
        [onSelected],
    )

    const _renderSuggestion = useCallback(suggestion => {
        return (
            <ContainerItem>
                <Title>{suggestion.title}</Title>
                <SubTitle>{suggestion.subTitle}</SubTitle>
            </ContainerItem>
        )
    }, [])

    const _onChange = useCallback(
        (event, { newValue }) => {
            setSelected(newValue)
            if (value) {
                if (onSelected) {
                    onSelected(null)
                }
            }
        },
        [onSelected, value],
    )

    const _onBlur = useCallback(
        (event, { newValue }) => {
            if (!value) {
                setSelected(null)
            }
        },
        [value],
    )

    return (
        <Container className={className}>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={_onSuggestionsFetchRequested}
                onSuggestionsClearRequested={_onSuggestionsClearRequested}
                getSuggestionValue={_getSuggestionValue}
                renderSuggestion={_renderSuggestion}
                onSuggestionSelected={_onSuggestionSelected}
                renderInputComponent={props => (
                    <Input
                        {...props}
                        callActionText={callActionText}
                        callActionClick={callActionClick}
                        errorMessage={errorMessage}
                        label={label}
                        required={required}
                    />
                )}
                inputProps={{
                    value: stringValue || selected || '',
                    onChange: _onChange,
                    onBlur: _onBlur,
                    type: 'search',
                    placeholder: placeholder,
                }}
            />
        </Container>
    )
}

export default memo(AutoComplete)
